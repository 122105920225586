import React from "react";
import PayOutList from "../../components/admin/payout/PayOutList";
import AdminATechHeader from "../../components/common/AdminATechHeader";

const PayOut = () => {
  return (
    <div className="ff-Project page-color">
      <AdminATechHeader HeaderName={"PayOut"} />
      <PayOutList />
    </div>
  );
};

export default PayOut;
