import { useSelector } from "react-redux";
import AdminATechHeader from "../../../components/common/AdminATechHeader";
import ProfileBox from "../../../components/common/ProfileBox";

const AdminProfile = () => {
  const userDetails = useSelector((state) => state.AuthReducer.userDetails);
  return (
    <>
      <AdminATechHeader HeaderName={"Profile"} />

      <div className="mx-sm-3 mx-1 bg-white p-md-4 p-2 br-10 mt-3">
        <div className="container-fluid my-4  profile-scroll">
          <ProfileBox userDetails={userDetails} />
        </div>
      </div>
    </>
  );
};
export default AdminProfile;
