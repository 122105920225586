import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreatordashboardDetails } from "../../../../redux/action/creator/Dashboard";
import ClientGraph from "./ClientGraph";
import EarningGraph from "./EarningGraph";
import ProjectGraphs from "./ProjectGraphs";
import ErrorMessage from "../../../common/ErrorMessage";

const DashboardGraphs = () => {
  const dispatch = useDispatch();
  const creatorDashboard = useSelector(
    (state) => state.client.creatorDashboard
  );
  const [dashBoardLoading, setDashBoardLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(CreatordashboardDetails(setDashBoardLoading, setError));
  }, [dispatch]);

  return (
    <div className="row">
      <div>{error !== "" ? <ErrorMessage message={error} /> : ""}</div>
      <EarningGraph
        creatorDashboard={creatorDashboard}
        dashBoardLoading={dashBoardLoading}
      />
      <ClientGraph
        creatorDashboard={creatorDashboard}
        dashBoardLoading={dashBoardLoading}
      />
      <ProjectGraphs
        creatorDashboard={creatorDashboard}
        dashBoardLoading={dashBoardLoading}
      />
    </div>
  );
};
export default DashboardGraphs;
