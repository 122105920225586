import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import ATechLogo from "../components/common/ATechLogo";
import SignupFormWrapper from "../components/hoc/SignupFormWrapper";
import { verifyTokenAction } from "../redux/action/auth/AuthAction";

function VerifyEmail({ history, match }) {
  const { tokenId, token, duration } = match.params;
  const dispatch = useDispatch();
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (!tokenId) return;
      const data = {
        user: Number(tokenId),
        token: token,
        duration: Number(duration),
      };
      dispatch(verifyTokenAction(data, setVerifyLoading, setError, history));
    }, 4000);
  }, [tokenId, dispatch, history, duration, token]);

  return (
    <SignupFormWrapper>
      <div className=" col-lg-6 col-sm-10 col-12">
        <div className="bg-white box-shadow br-10 p-sm-5 p-3">
          <ATechLogo />
          <p className="common-heading mb-1">Verify Email</p>
          {error ? (
            <div className="error_container mt-5">
              <p className="p-4 text-center font-20">
                Reset password link has expired. Please try again.
              </p>
            </div>
          ) : (
            <div className="Verify_container mt-5">
              <p className="p-4 text-center">
                Please wait we are verifying your email.
              </p>
            </div>
          )}
          <div className="mt-3">
            <button
              type="submit"
              onClick={() => history.push("/")}
              disabled={verifyLoading}
              className=" w-100 mt-sm-2 mt-1  atech-primary-button  py-sm-3 px-4 py-2"
            >
              Back To Home
            </button>
          </div>
        </div>
      </div>
    </SignupFormWrapper>
  );
}
export default withRouter(VerifyEmail);
