import React from "react";
import PendingImg from "../../../../src/assets/img/svg/clickicon.svg";

const Pending = ({ creatorEarning, loading }) => {
  return (
    <div className="col-xxl-4 mt-4 col-md-6 ">
      <div className="d-flex p-3 h-100  justify-content-between align-items-center box-shadow br-10 bg-white ">
        <div>
          <p className="common-text fs-xs-14">Pending</p>
          <h1 className="common-heading-Medium ">
            $
            {loading
              ? "Loading..."
              : creatorEarning && creatorEarning.amount_pending}
          </h1>
        </div>
        <span
          className="rounded-50  dashboard-icon d-inline-block "
          style={{
            backgroundColor: "#fff6e7",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${PendingImg})`,
          }}
        ></span>
      </div>
    </div>
  );
};

export default Pending;
