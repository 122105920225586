import React from "react";
import { Route } from "react-router";
import ChangePassword from "../view/ChangePassword";
import ForgotPassword from "../view/ForgotPassword";
import Login from "../view/Login";
import SignUp from "../view/SignUp";
import SignUpDetails from "../components/auth/signUpForms/SignUpDetails";
import SignUpProfile from "../components/auth/signUpForms/SignUpProfile";
import AddGear from "../components/auth/signUpForms/AddGear";
import SignUpWork from "../components/auth/signUpForms/SignUpWork";
import EmailMessage from "../view/EmailMessage";
import ResetPassword from "../view/ResetPassword";
import VerifyEmail from "../view/VerifyEmail";

const AuthRoutes = () => {
  return (
    <>
      <Route exact path="/" component={Login} />
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/sign-up/details" component={SignUpDetails} />
      <Route exact path="/sign-up/add-past-work" component={SignUpWork} />
      <Route exact path="/sign-up/bio/profile" component={SignUpProfile} />
      <Route exact path="/sign-up/add-gear" component={AddGear} />
      <Route exact path="/sign-up/change-password" component={ChangePassword} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/send-message/:email" component={EmailMessage} />
      <Route
        exact
        path="/reset/form/:tokenId/:token/:duration"
        component={ResetPassword}
      />
      <Route
        exact
        path="/reset/password/:tokenId/:token/:duration"
        component={VerifyEmail}
      />
    </>
  );
};

export default AuthRoutes;
