import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import ApplyJobSuccessfullyModal from "./ApplyJobSuccessfullyModal";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { creatorApplayForJob } from "../../../redux/action/creator/CreatorJob";
import ErrorMessage from "../../common/ErrorMessage";

function ApplyJobForm({ jobId }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    const dataValue = { job: Number(jobId), price: Number(data.price) };
    const finalResult = Object.assign(data, dataValue);
    dispatch(creatorApplayForJob(setLoading, finalResult, setShow, setError));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error !== "" ? <ErrorMessage message={error} /> : ""}
      <div className="mt-4 mb-3">
        <Form.Group
          className=" textarea-focus"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Control
            placeholder="Message For Client"
            as="textarea"
            rows={5}
            type="text"
            {...register("message", { required: true })}
          />
        </Form.Group>
        {errors.message?.type === "required" && (
          <p className="common-text xxs-font text-red">
            Message For Client is required{" "}
          </p>
        )}
      </div>
      <div className="row">
        <div className="col-md-6 mb-md-0 mb-3">
          <div className="position-relative">
            <input
              type="number"
              {...register("price", { required: true })}
              className="form-control py-3 atech-input  ps-3"
              placeholder=" Your Quote"
              aria-describedby="emailHelp"
            />
            {errors.price?.type === "required" && (
              <p className="common-text xxs-font text-red">
                Quote is required{" "}
              </p>
            )}
            <span className="d-inline-block position-absolute ps-2 top-28 color-8B8C8D">
              $
            </span>
          </div>
        </div>

        <div className="col-md-6">
          <input
            type="number"
            {...register("durations", { required: true })}
            className="form-control py-3 atech-input"
            placeholder="Delivery in day"
            aria-describedby="emailHelp"
          />
          {errors.durations?.type === "required" && (
            <p className="common-text xxs-font text-red">
              Delivery Date is required{" "}
            </p>
          )}
        </div>
      </div>
      <p className="common-text py-3">
        Note: A-tech will charge 10% of your quote.
      </p>
      <>
        <ApplyJobSuccessfullyModal
          show={show}
          setShow={setShow}
          loading={loading}
        />
      </>
    </form>
  );
}

export default ApplyJobForm;
