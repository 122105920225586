import { Accordion } from "react-bootstrap";

function ActorsAndModel({ value, index, FootageType }) {
  return (
    <Accordion.Item className="my-3" eventKey={index}>
      <Accordion.Header className="Footage Elements accordian_border">
        <span>
          {FootageType} {index + 1}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="bg-F8F8F8 p-2">
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Age</span>
            <span className="text-black">
              {value && value.age ? value.age : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Height</span>
            <span className="text-black">
              {value && value.heights ? value.heights : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Eyes Color</span>
            <span className="text-black">
              {value && value.eye_color ? value.eye_color : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Ethnicity</span>
            <span className="text-black">
              {" "}
              {value && value.ethnicity ? value.ethnicity : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Hair</span>
            <span className="text-black">
              {" "}
              {value && value.hair_color ? value.hair_color : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Gender</span>
            <span className="text-black">
              {value && value.gender ? value.gender : "N/A"}
            </span>
          </p>

          <p className="mt-4 mb-3 common-text px-2 px-lg-4">Clothing/Makeup:</p>
          <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
            {value && value.clothings ? value.clothings : "N/A"}
          </p>
          <p className="mt-4 common-text px-2 px-lg-4">Body type:</p>
          <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
            {value && value.body_type ? value.body_type : "N/A"}
          </p>
          <p className="mt-4 common-text px-2 px-lg-4">
            Other details e.g. Characcter name:
          </p>
          <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
            {value && value.other_details ? value.other_details : "N/A"}
          </p>
          <p className="mt-4 common-text px-2 px-lg-4">Actions:</p>
          <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
            {value && value.actions ? value.actions : "N/A"}
          </p>
          <p className="mt-4 common-text px-2 px-lg-4">Dialogue:</p>
          <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
            {value && value.dialogs ? value.dialogs : "N/A"}
          </p>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default ActorsAndModel;
