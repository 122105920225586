import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ObjectFootage from "./jobModel/button/ObjectFootage.jsx";
import AddBuilding from "./jobModel/button/AddBuilding";
import AddWildLifeFootage from "./jobModel/button/AddWildLifeFootage";
import AddGreenScreenFootage from "./jobModel/button/AddGreenScreenFootage";
import AddEffectsFootage from "./jobModel/button/AddEffectsFootage";
import { withRouter } from "react-router";
import PeopleFootage from "./jobModel/button/PeopleFootage";
import FormElementsName from "./FormElementsName.jsx";
import { getAllJobsDetailsList } from "../../../../redux/action/client/Job.jsx";
import { useDispatch } from "react-redux";

const VariousElement = ({ history, match }) => {
  const { jobId } = match.params;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reloadElements, setReloadElements] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (jobId) {
      setReloadElements(false);
      dispatch(getAllJobsDetailsList(jobId, setLoading, setError));
    }
  }, [jobId, dispatch, reloadElements]);

  return (
    <Col>
      <section>
        <p className="common-heading-Medium xs-font">
          Step-3 : Add Various Elements to Your Footage
        </p>

        <div className="border-top-1-E8E8E8 mt-2"></div>
        <Row className="mt-sm-5 mt-2">
          <Col xxl={6} lg={7}>
            <p className="common-text mb-2">Add People to Your Footage</p>
            <PeopleFootage setReloadElements={setReloadElements} />
            <div className="mt-sm-5 mt-2">
              <p className="common-text mb-2">Add objects to your footage</p>
              <ObjectFootage setReloadElements={setReloadElements} />
            </div>
            <div className="mt-sm-5 mt-2">
              <p className="common-text mb-2">Add buildings to your footage</p>
              <AddBuilding setReloadElements={setReloadElements} />
            </div>
            <div className="mt-sm-5 mt-2">
              <p className="common-text mb-2">
                Add wildlife or pets to your footage
              </p>
              <AddWildLifeFootage setReloadElements={setReloadElements} />
            </div>
            <div className="mt-sm-5 mt-2">
              <p className="common-text mb-2">
                Does your footage require a green screen background?
              </p>
              <AddGreenScreenFootage setReloadElements={setReloadElements} />
            </div>
            <div className="mt-sm-5 mt-2">
              <p className="common-text mb-2">
                Does your footage contain any effects?
              </p>
              <AddEffectsFootage setReloadElements={setReloadElements} />
            </div>
            <div className="mt-lg-5 pt-lg-5 mt-4">
              <button
                className="atech-primary-button mr-10 create-job-btn"
                onClick={() =>
                  history.push(`/client/create/job/technical/aspects/${jobId}`)
                }
              >
                Next
              </button>
            </div>
          </Col>
          <FormElementsName
            loading={loading}
            error={error}
            setReloadElements={setReloadElements}
          />
        </Row>
      </section>
    </Col>
  );
};

export default withRouter(VariousElement);
