import React from "react";
import OnGoingJob from "./OnGoingJob";
import { withRouter } from "react-router";
import EmptyCard from "../../common/EmptyCard";

const OnGoingJobList = ({ clientJobList, history }) => {
  return (
    <div
      className={`${
        clientJobList.length > 0 ? "h-50 admin-dahboard-height" : "h-100"
      } mt-4  dahboard-color`}
    >
      {clientJobList && clientJobList.length > 0 ? (
        <section className="myjob p-4 ">
          <div className="d-flex justify-content-between">
            <p className="common-text mb-0">Ongoing Jobs</p>
            <p
              className="common-text xxs-font view-all mb-0"
              onClick={() => history.push("/client/job-feed/on-going-jobs")}
            >
              View All
            </p>
          </div>
          <section>
            {clientJobList.map((item, index) => {
              return (
                <OnGoingJob
                  item={item}
                  index={index}
                  history={history}
                  key={index}
                  type="client/view/all-jobs/jobs"
                />
              );
            })}
          </section>
        </section>
      ) : (
        <EmptyCard message={"You’ve not any On-going job List Yet!"} />
      )}
    </div>
  );
};

export default withRouter(OnGoingJobList);
