import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { creatorReportJob } from "../../../redux/action/creator/CreatorJob";
import ErrorMessage from "../../common/ErrorMessage";
import { CloseIcon } from "../../common/icons/Icon";
import Loader from "../../common/Loader";

function ReportJobModal({ setShow, show, jobId }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [reportDescription, setReportDescription] = useState("");
  const [error, setError] = useState("");

  const handleReportJob = (e) => {
    if (reportReason !== "") {
      if (reportReason === "Other" && reportDescription === "") {
        setError("Please Explain Your Reason For Reporting This Job");
      } else {
        setError("");
        const data = {
          job: Number(jobId),
          reason: reportReason,
          description: reportDescription,
        };
        dispatch(creatorReportJob(data, setLoading, setError, setShow));
      }
    } else {
      setError("Please select any reason to report this job");
    }
  };
  return (
    <div>
      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center">
            <p className="common-heading-Medium md-font text-dark-black mb-0">
              Report Job
            </p>
            <div className="cursor-pointer " onClick={() => setShow(false)}>
              <CloseIcon />
            </div>
          </div>
          <div className="border-bottom my-3"></div>
          {error !== "" ? <ErrorMessage message={error} /> : ""}

          <div className=" mt-2">
            <div className="row  flex-sm-row  flex-column">
              <div className="col-auto my-sm-0 my-3  pe-sm-0">
                <button
                  className={`${
                    reportReason === "Inappropriate"
                      ? "atech-popup-button-active w-100 py-2 px-4"
                      : "atech-popup-button w-100 py-2 px-4"
                  }`}
                  onClick={() => setReportReason("Inappropriate")}
                >
                  Inappropriate
                </button>
              </div>
              <div className="col-auto  pe-sm-0">
                <button
                  className={`${
                    reportReason === "Fake Job"
                      ? "atech-popup-button-active w-100 py-2 px-4"
                      : "atech-popup-button w-100 py-2 px-4"
                  }`}
                  onClick={() => setReportReason("Fake Job")}
                >
                  Fake Job
                </button>
              </div>
              <div className="col-auto  pe-sm-0">
                <button
                  className={`${
                    reportReason === "Abuse"
                      ? "atech-popup-button-active w-100 py-2 px-4"
                      : "atech-popup-button w-100 py-2 px-4"
                  }`}
                  onClick={() => setReportReason("Abuse")}
                >
                  Abuse
                </button>
              </div>
              <div className="col-auto  pe-sm-0">
                <button
                  className={`${
                    reportReason === "Other"
                      ? "atech-popup-button-active w-100 py-2 px-4"
                      : "atech-popup-button w-100 py-2 px-4"
                  }`}
                  onClick={() => setReportReason("Other")}
                >
                  Other
                </button>
              </div>
            </div>
          </div>
          <div className="mt-4 mb-3">
            <Form.Group
              className=" textarea-focus"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                placeholder="Explain your report"
                as="textarea"
                rows={5}
                disabled={reportReason === "Other" ? false : true}
                onChange={(e) => setReportDescription(e.target.value)}
              />
            </Form.Group>
          </div>

          <div className="d-flex flex-sm-row  flex-column mt-3">
            <button
              className="px-4 atech-primary-button  py-sm-2 py-2 ms-sm-2  my-2"
              onClick={() => handleReportJob()}
              disabled={loading}
            >
              {loading ? <Loader /> : "Report"}
            </button>
            <button
              className="px-4  py-sm-2 py-2 ms-sm-2  my-2  atech-fade-button"
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ReportJobModal;
