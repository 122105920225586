import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import StripeErrorMessage from "./StripeErrorMessage";
import StripeCardField from "./StripeCardField";
import StripeSubmitButton from "./StripeSubmitButton";
import { useEffect } from "react";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { paymentToPlatFormByClient } from "../../redux/action/client/JobForm";
import { ApplictionAcceptOffer } from "../../redux/action/client/jobFeed";

const StripeForm = ({
  clientJobApplicationDetails,
  jobId,
  applicationId,
  history,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [acceptOffer, setAcceptOffer] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);

    if (card == null) {
      return;
    }

    if (error) {
      card.focus();
      return;
    }

    if (cardComplete) {
      setLoadingPayment(true);
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card,
    });

    setLoadingPayment(false);
    if (payload.error) {
      setError(payload.error);
    } else {
      setPaymentMethod(payload.paymentMethod.id);
    }
  };

  useEffect(() => {
    if (paymentMethod !== "") {
      const data = {
        job: Number(jobId),
        application: Number(applicationId),
        payment_method_id: paymentMethod,
        amount: Number(
          (
            clientJobApplicationDetails &&
            clientJobApplicationDetails.price &&
            clientJobApplicationDetails.price +
              clientJobApplicationDetails.price * 0.1
          ).toFixed(0) * 100
        ),
      };
      dispatch(
        paymentToPlatFormByClient(
          data,
          setLoadingPayment,
          setError,
          setAcceptOffer
        )
      );
    }
    if (acceptOffer === true) {
      const data = { is_approved_by_client: true };
      dispatch(
        ApplictionAcceptOffer(
          setLoadingPayment,
          jobId,
          applicationId,
          setError,
          data,
          setAcceptOffer,
          history
        )
      );
    }
  }, [
    paymentMethod,
    jobId,
    acceptOffer,
    applicationId,
    dispatch,
    clientJobApplicationDetails,
    history,
  ]);

  useEffect(() => {
    if (acceptOffer === true) {
      const data = { is_approved_by_client: true };
      dispatch(
        ApplictionAcceptOffer(
          setLoadingPayment,
          jobId,
          applicationId,
          setError,
          data,
          setAcceptOffer,
          history
        )
      );
    }
  }, [jobId, acceptOffer, applicationId, dispatch, history]);

  return (
    <div>
      <section className="bg-white br-10   px-sm-3  py-4  ">
        <p className="xs-font f-w-600 text-black mb-0  pb-4">Payment</p>
        <form className="Form" onSubmit={handleSubmit}>
          <fieldset className="FormGroup pb-4">
            <StripeCardField
              onChange={(e) => {
                setError(e.error);
                setCardComplete(e.complete);
              }}
            />
          </fieldset>
          <div className="border-1-E8E8E8 br-10 py-3 px-3 d-flex justify-content-between">
            <p className="common-text xxs-font mb-0">Total Amount to Pay:</p>
            <p className="common-text xxs-font mb-0">
              $
              {clientJobApplicationDetails &&
                clientJobApplicationDetails.price &&
                clientJobApplicationDetails.price +
                  clientJobApplicationDetails.price * 0.1}
            </p>
          </div>

          {error && (
            <StripeErrorMessage>
              <span className="text-red fw-bold">
                {error.message ? "" : "Some thing went wrong"}
              </span>
            </StripeErrorMessage>
          )}
          <StripeSubmitButton loadingPayment={loadingPayment} error={error}>
            {loadingPayment ? "Processing..." : "Payment"}
          </StripeSubmitButton>
        </form>
      </section>
    </div>
  );
};
export default withRouter(StripeForm);
