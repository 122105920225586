import { withRouter } from "react-router-dom";
import { getDate } from "../../common/utils/Functions";

const MyJobList = ({ item, history }) => {
  return (
    <div className="col-xxl-6 mt-3">
      <div
        className={`${
          item > 0 ? "h-100" : ""
        } job-feed-card br-10 p-xl-4 p-sm-3 p-2  me-xl-3`}
      >
        <p className="common-heading-Medium xs-font text-black my-job-text">
          {item.title}
        </p>
        <p className="common-text xxs-font mb-0">Fixed-Price</p>
        <p className="common-heading text-blue f-w-600">${item.price}</p>

        <p className="common-text pe-xl-5 giveMeEllipsis">{item.description}</p>
        <div className="row justify-content-between">
          <div className="col-auto">
            <p className="common-text text-start">Start Date:</p>
            <p className="common-text text-start">Deadline:</p>
            <p className="common-text text-start">Deliveries Made:</p>
          </div>
          <div className="col-auto">
            <p className="common-text text-end">
              {new Date(item.updated_at).toLocaleDateString()}
            </p>
            <p className="common-text text-end">
              {getDate(item.created_at, item.duration)}
            </p>
            <p className="common-text text-end">{item.deliveries_made}</p>
          </div>
        </div>
        <div className="d-flex flex-sm-row flex-column">
          <button
            className="atech-primary-button px-3 py-2 my-sm-0 my-3"
            onClick={() => history.push(`/creator/view/my-jobs/${item.id}`)}
          >
            View Job
          </button>
        </div>
      </div>
    </div>
  );
};
export default withRouter(MyJobList);
