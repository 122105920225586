import { Accordion } from "react-bootstrap";
import PropertyLandMarineAndBird from "./CommonFields/PropertyLandMarineAndBird";

function MarineAnimal({ jobList }) {
  return (
    <Accordion className="footage-accordian">
      {jobList &&
        jobList.marine &&
        jobList.marine.length > 0 &&
        jobList.marine.map((value, index) => {
          return (
            <PropertyLandMarineAndBird
              FootageType={"Marine Animals/Fish"}
              value={value}
              index={index}
            />
          );
        })}
    </Accordion>
  );
}

export default MarineAnimal;
