import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChatList } from "../../../redux/action/chat/ClientChat";
import { ClientdashboardDetails } from "../../../redux/action/client/Dashboard";
import { getJobListForClient } from "../../../redux/action/client/jobFeed";
import { CreateJobFeedList } from "../../../redux/action/creator/CreatorJob";
import ErrorMessage from "../../common/ErrorMessage";
import ClientMessageList from "./ClientMessageList";
import ClientMyJobLIst from "./ClientMyJobLIst";
import OnGoingJobList from "./OnGoingJobList";

const ClientDashBoardContent = ({ setDashBoardLoading }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState(false);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(true);

  const chatList = useSelector((state) => state.chat.chatList);
  const creatorJobList = useSelector((state) => state.jobs.creatorJobList);
  const clientJobList = useSelector((state) => state.jobs.clientJobList);

  useEffect(() => {
    const pageNo = 0;
    dispatch(getChatList(setLoading, setError, pageNo, setHasMore));
  }, [dispatch]);

  useEffect(() => {
    const types = "me";
    const shortedValue = 0;
    const page = 0;
    dispatch(
      CreateJobFeedList(types, setJobLoading, shortedValue, page, setHasMore)
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(ClientdashboardDetails(setDashBoardLoading, setError));
  }, [dispatch, setDashBoardLoading]);

  useEffect(() => {
    const types = "on-going";
    const page = 0;
    const shortedValue = 0;
    dispatch(getJobListForClient(setLoading, types, page, shortedValue));
  }, [dispatch]);

  return (
    <div className="flex-grow-1 pb-sm-5 main_height_adminDashboard overflow_hidden">
      <div className="my-3">
        {error !== "" ? <ErrorMessage message={error} /> : ""}
      </div>
      <div className="row mt-4 pb-3  h-100">
        <div className="col-lg-6 d-flex flex-column h-100">
          <ClientMyJobLIst
            creatorJobList={creatorJobList}
            jobLoading={jobLoading}
          />
          <OnGoingJobList clientJobList={clientJobList} />
        </div>
        <div className="col-lg-6  h-100">
          <ClientMessageList
            chatList={chatList}
            loading={loading}
            hasMore={hasMore}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientDashBoardContent;
