import React from "react";
import Crossicon from "../../../../assets/img/svg/crossIcon.svg";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import BubblesLoader from "../../../common/BubblesLoader";
import ErrorMessage from "../../../common/ErrorMessage";

function JobTechnicalModel({ setModalShow, modalShow, loading, error }) {
  const clientTechElementsList = useSelector(
    (state) => state.jobs.clientTechElementsList
  );
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className="w-100">
            <div className="d-flex justify-content-between">
              <h5 className="md-font f-w-600 text-black common-heading-Medium">
                Technical Aspects
              </h5>
              <img
                className="cursor-pointer"
                onClick={() => setModalShow(false)}
                src={Crossicon}
                alt=""
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        {error !== "" ? <ErrorMessage message={error} /> : ""}

        {loading ? (
          <div className="d-flex justify-content-center py-5">
            <BubblesLoader />
          </div>
        ) : (
          <Modal.Body>
            <div className="p-sm-3">
              <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 flex-column">
                <span>Camera Type</span>{" "}
                <span className="text-black">
                  {clientTechElementsList &&
                  clientTechElementsList[0] &&
                  clientTechElementsList[0].camera_type
                    ? clientTechElementsList[0].camera_type
                    : "N/A"}
                </span>
              </p>
              <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3  flex-column">
                <span>Sound</span>{" "}
                <span className="text-black">
                  {clientTechElementsList &&
                  clientTechElementsList[0] &&
                  clientTechElementsList[0].is_sound_on
                    ? clientTechElementsList[0].is_sound_on
                      ? "Yes"
                      : "No"
                    : "N/A"}
                </span>
              </p>
              <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3  flex-column">
                <span>Recorder</span>
                <span className="text-black">
                  {" "}
                  {clientTechElementsList &&
                  clientTechElementsList[0] &&
                  clientTechElementsList[0].recorder_type
                    ? clientTechElementsList[0].recorder_type
                    : "N/A"}
                </span>
              </p>
              <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3  flex-column">
                <span>Camera Angle</span>
                <span className="text-black">
                  {" "}
                  {clientTechElementsList &&
                  clientTechElementsList[0] &&
                  clientTechElementsList[0].camera_angle
                    ? clientTechElementsList[0].camera_angle
                    : "N/A"}
                </span>
              </p>
              <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3  flex-column">
                <span>Shot Type</span>{" "}
                <span className="text-black">
                  {" "}
                  {clientTechElementsList &&
                  clientTechElementsList[0] &&
                  clientTechElementsList[0].shot_type
                    ? clientTechElementsList[0].shot_type
                    : "N/A"}
                </span>
              </p>
              <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3  flex-column">
                <span>Camera Movement</span>
                <span className="text-black">
                  {" "}
                  {clientTechElementsList &&
                  clientTechElementsList[0] &&
                  clientTechElementsList[0].camera_movement
                    ? clientTechElementsList[0].camera_movement
                    : "N/A"}
                </span>
              </p>
              <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3  flex-column">
                <span>Stabalisation</span>
                <span className="text-black">
                  {" "}
                  {clientTechElementsList &&
                  clientTechElementsList[0] &&
                  clientTechElementsList[0].stabalisation
                    ? clientTechElementsList[0].stabalisation
                    : "N/A"}
                </span>
              </p>
              <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3  flex-column">
                <span>Resolution</span>{" "}
                <span className="text-black">
                  {" "}
                  {clientTechElementsList &&
                  clientTechElementsList[0] &&
                  clientTechElementsList[0].resulations
                    ? clientTechElementsList[0].resulations
                    : "N/A"}
                </span>
              </p>
              <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3  flex-column">
                <span>Slow Motion</span>
                <span className="text-black">
                  {" "}
                  {clientTechElementsList &&
                  clientTechElementsList[0] &&
                  clientTechElementsList[0].slow_motion
                    ? clientTechElementsList[0].slow_motion
                    : "N/A"}
                </span>
              </p>
              <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3">
                <span>Duration</span>{" "}
                <span className="text-black">
                  {clientTechElementsList &&
                  clientTechElementsList[0] &&
                  clientTechElementsList[0].durations
                    ? clientTechElementsList[0].durations + " sec"
                    : "N/A"}
                </span>
              </p>
              <p className="mt-4 common-text">Other Notes:</p>
              <p className="common-text mb-1">
                {clientTechElementsList &&
                clientTechElementsList[0] &&
                clientTechElementsList[0].others
                  ? clientTechElementsList[0].others
                  : "N/A"}
              </p>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}

export default JobTechnicalModel;
