import { useSelector } from "react-redux";
import TotalJobGraph from "./TotalJobGraph";
import MoneySpendGraph from "./MoneySpendGraph";
import CurrentProjectGraphs from "./CurrentProjectGraphs";

const ClientDashboardGraphs = ({ dashBoardLoading }) => {
  const clientDashboard = useSelector((state) => state.client.clientDashboard);
  return (
    <div className="row">
      <MoneySpendGraph
        clientDashboard={clientDashboard}
        dashBoardLoading={dashBoardLoading}
      />
      <TotalJobGraph
        clientDashboard={clientDashboard}
        dashBoardLoading={dashBoardLoading}
      />
      <CurrentProjectGraphs
        clientDashboard={clientDashboard}
        dashBoardLoading={dashBoardLoading}
      />
    </div>
  );
};
export default ClientDashboardGraphs;
