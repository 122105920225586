import { getTime, getDate } from "../../../common/utils/Functions";

const CompletedJobList = ({ history, item, index }) => {
  return (
    <div className="col-xxl-6 mt-xxl-3 mt-4" key={index}>
      <div className="job-feed-card br-10 p-xl-4 h-100 p-sm-3 p-2">
        <div className="d-flex justify-content-between align-items-center">
          <p className="giveMeEllipsis common-heading-Medium xs-font text-black job-feed-text mb-0">
            {item.title}
          </p>
        </div>
        <p className="common-text xxs-font mb-0">
          Posted {getTime(item.created_at)}
        </p>
        <p className="common-heading text-blue f-w-600">$ {item.price}</p>
        <div className="row justify-content-between">
          <div className="col-auto">
            <p className="common-text text-start">Start Date:</p>
            <p className="common-text text-start">Completed Date:</p>
          </div>
          <div className="col-auto">
            <p className="common-text text-end">
              {new Date(item.updated_at).toLocaleDateString()}
            </p>
            <p className="common-text text-end">
              {getDate(item.updated_at, item.duration)}
            </p>
          </div>
        </div>
        <div className="d-flex flex-sm-row flex-column my-sm-0 my-3">
          <button
            className="atech-primary-button px-3 py-2"
            onClick={() =>
              history.push(`/client/${"completed"}/jobs/${item.id}`)
            }
          >
            View Job
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompletedJobList;
