import Swal from "sweetalert2";
import Preloader from "../../../components/common/Preloader";
import {
  deleteGearDetailsApi,
  GetUserGearListApi,
  submitGearDetailsApi,
  UpdateGearDetailsApi,
} from "../../api/creator/CreatorGrarsWorkApi";

export const GET_USER_GEAR_LIST = "GET_USER_GEAR_LIST";
export const SUBMIT_GEAR_DETAILS_SUCCESS = "SUBMIT_GEAR_DETAILS_SUCCESS";
export const DELETE_GEAR_DETAILS_SUCCESS = "DELETE_GEAR_DETAILS_SUCCESS";
export const UPDATES_GEAR_DETAILS_SUCCESS = "UPDATES_GEAR_DETAILS_SUCCESS";

/**
 * Get user work list action
 * @param {Object} data
 * @returns
 */
const GetUserGearListAction = (data) => ({
  type: GET_USER_GEAR_LIST,
  data,
});

export const GetUserGearList = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await GetUserGearListApi();
    if (response.success) {
      dispatch(GetUserGearListAction(response.data));
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
  }
};

/**
 * submit user work details action
 * @param {Object} data
 * @returns
 */
const submitGearDetailsAction = (data) => ({
  type: SUBMIT_GEAR_DETAILS_SUCCESS,
  data,
});

export const submitGearDetails =
  (data, setLoading, history, type, setShowForm) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await submitGearDetailsApi(data);
      if (response.success) {
        dispatch(submitGearDetailsAction(response.data));
        setLoading(false);
        if (type !== "login") {
          <Preloader />;
          history.push("/");
          window.location.reload();
        } else {
          setShowForm(false);
        }
      } else {
        setLoading(false);
        Swal.fire("Opps!", "Failed To Add Gear Work", "error");
        setTimeout(Swal.close, 2000);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Opps!", "Failed To Add Gear Work", "error");
      setTimeout(Swal.close, 2000);
    }
  };

/**
 * updates user work details action
 * @param {Object} data
 * @returns
 */
const UpdateGearDetailsAction = (data) => ({
  type: UPDATES_GEAR_DETAILS_SUCCESS,
  data,
});

export const UpdateGearDetails =
  (data, setLoading, handleClose, id, index) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await UpdateGearDetailsApi(data, id);
      if (response.success) {
        dispatch(UpdateGearDetailsAction({ data: response.data, index }));
        setLoading(false);
        handleClose();
      } else {
        setLoading(false);
        Swal.fire("Opps!", "Failed To Updates past Work", "error");
        setTimeout(Swal.close, 2000);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Opps!", "Failed To Updates past Work", "error");
      setTimeout(Swal.close, 2000);
    }
  };

/**
 * Delete user work details action
 * @param {Object} data
 * @returns
 */
const deleteGearDetailsAction = (data) => ({
  type: DELETE_GEAR_DETAILS_SUCCESS,
  data,
});

export const deleteGearDetails = (id, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await deleteGearDetailsApi(id);
    if (response.success) {
      dispatch(deleteGearDetailsAction(id));
      setLoading(false);
    } else {
      setLoading(false);
      Swal.fire("Opps!", "Failed To delete Work", "error");
      setTimeout(Swal.close, 2000);
    }
  } catch (error) {
    setLoading(false);
    Swal.fire("Opps!", "Failed To delete Work", "error");
    setTimeout(Swal.close, 2000);
  }
};
