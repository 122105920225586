import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import CreatorRoutes from "./routes/CreatorRoutes";
import { getAccessToken } from "./redux/action/auth/AuthAction";
import { userDetailAction } from "./redux/action/auth/UserDetailsAction";
import Preloader from "./components/common/Preloader";
import AdminRoutes from "./routes/AdminRoutes";
import ClientRoutes from "./routes/ClientRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./App.css";
import AuthRoutes from "./routes/AuthRoutes";
import { getUnseenNotification } from "./redux/action/admin/AdminAcountsAction";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import JoinChat from "./redux/hook/socket/JoinChat";

function App({ history }) {
  JoinChat();
  const pathname = window.location.pathname;
  const stripePromise = loadStripe(
    "pk_test_51LA9ZeLqE8f2aS4aJUUI20PbAwBkSW20rWb2jWfuQZpg4lD8X2JWUz9xmSbx5ZFisJ3r4OOKDMrQnAVOLhPEu2Z800ZS38uLG9"
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.AuthReducer.auth);
  const authValue = useSelector((state) => state);
  const userName = authValue.AuthReducer.userDetails;
  const userDetailsRole = useSelector(
    (state) => state.AuthReducer.userDetailsRole
  );

  useEffect(() => {
    if (pathname === "/sign-up") {
      localStorage.removeItem("a-tech-access-token");
      localStorage.removeItem("a-tech-refresh-token");
    }
  }, [pathname]);

  /**
   * set token to local storage when user login
   */
  useEffect(() => {
    const access = localStorage.getItem("a-tech-access-token");
    const refresh = localStorage.getItem("a-tech-refresh-token");
    if (refresh !== "") {
      if (access !== "") {
        dispatch(userDetailAction(access, setLoading, history));
        dispatch(getUnseenNotification());
      } else {
        const data = {
          refresh: refresh,
        };
        dispatch(getAccessToken(data, setLoading, history));
      }
    }
  }, [auth, history, dispatch]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        {auth ? (
          userDetailsRole && userDetailsRole.is_admin ? (
            window.location.pathname.includes("/sign-up") ? (
              <AuthRoutes />
            ) : (
              <AdminRoutes
                userName={userName}
                userDetailsRole={userDetailsRole}
              />
            )
          ) : userDetailsRole && userDetailsRole.is_creator ? (
            <>
              {window.location.pathname.includes("/sign-up") ? (
                <AuthRoutes />
              ) : (
                <CreatorRoutes
                  userName={userName}
                  userDetailsRole={userDetailsRole}
                />
              )}
            </>
          ) : (
            <>
              {window.location.pathname.includes("/sign-up") ? (
                <AuthRoutes />
              ) : (
                <ClientRoutes
                  userName={userName}
                  userDetailsRole={userDetailsRole}
                />
              )}
            </>
          )
        ) : (
          <AuthRoutes />
        )}
      </Elements>
    </BrowserRouter>
  );
}
export default withRouter(App);
