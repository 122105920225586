import { axiosRequest } from "../../helper";

/**
 * Login Api
 * @param {Object} data
 * @returns
 */
export const LoginApi = async (data) => {
  return await axiosRequest("POST", `/auth/login/`, data);
};

/**
 * Reset Password Api
 * @param {Object} data
 * @returns
 */
export const ResetPasswordApi = async (data) => {
  return await axiosRequest("POST", `/auth/password-reset/`, data);
};

/**
 * verify token and Id Api
 * @param {Object} data
 * @returns
 */
export const verifyTokenApi = async (data) => {
  return await axiosRequest("POST", `/auth/reset-password/verify-token/`, data);
};

/**
 * Sign up Api
 * @param {Object} data
 * @returns
 */
export const SignUpApi = async (data) => {
  return await axiosRequest("POST", `/auth/signup/`, data);
};

export const getAccessTokenApi = async (refresh) => {
  return await axiosRequest("POST", `/auth/token/refresh/`, refresh);
};

/**
 * Change Password Api
 * @param {Object} data
 * @returns
 */
export const ChangePasswordApi = async (data) => {
  return await axiosRequest("PUT", `/auth/change-password/`, data);
};

/**
 * Forgot Password Api
 * @param {Object} data
 * @returns
 */
export const ForgotPasswordApi = async (data) => {
  return await axiosRequest("PUT", `/auth/reset-password/`, data);
};
