import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import BackIcon from "../../../assets/img/png/back-icon.png";
import { getJobViewDetails } from "../../../redux/action/client/jobFeed";
import ClientViewJobDetails from "./ClientViewJobDetails";
import DeleteJobModel from "./JobModel/DeleteJobModel";
import ViewJobApplicationsModel from "./ViewJobApplicationsModel";

function ClientViewJob({ history, match }) {
  const dispatch = useDispatch();
  const { jobId, type } = match.params;
  const clientJobViewDetails = useSelector(
    (state) => state.jobs.clientJobViewDetails
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(getJobViewDetails(setLoading, jobId));
  }, [dispatch, jobId]);

  return (
    <>
      <div className="container-fluid">
        <div className="box-shadow bg-white br-10 p-4 mt-4 ">
          <div className="d-flex flex-sm-row flex-column justify-content-between">
            <button
              className="px-sm-3 px-2 py-2  atech-fade-button"
              onClick={() => history.push(`/client/job-feed/${type}`)}
            >
              <span className="px-2">
                <img src={BackIcon} alt="backicon" />
              </span>
              <span className="d-inline-block ">Back</span>
            </button>
            <div className="mt-sm-0 mt-3 d-flex flex-sm-row flex-column">
              {clientJobViewDetails &&
              clientJobViewDetails.is_application_approved === false ? (
                <DeleteJobModel jobId={jobId} history={history} />
              ) : (
                ""
              )}
              <ViewJobApplicationsModel
                jobId={jobId}
                buttonName="View Applicants"
                jobApproved={clientJobViewDetails.is_application_approved}
              />
            </div>
          </div>
          <div className="border-bottom my-4"></div>

          <ClientViewJobDetails
            clientJobViewDetails={clientJobViewDetails}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}

export default withRouter(ClientViewJob);
