import React, { useState } from "react";
import ClientATechHeader from "../../../components/client/ClientATechHeader";
import ErrorMessage from "../../../components/common/ErrorMessage";
import BasicJobForm from "../../../components/client/createJob/job/BasicJobForm";
import { useLayoutProviderContext } from "../../../context/LayoutProvider";

const BasicDetails = () => {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();

  const [error, setError] = useState("");

  return (
    <div className=" h-screen d-flex flex-column">
      <ClientATechHeader
        HeaderName={"Create Jobs"}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div className="px-3">
        <div className="flex-grow-1 p-lg-4 p-3 br-10 bg-white my-4 main-box-shadow ">
          <p className="xs-font f-w-600 text-black border-bottom-e8e8e8 mb-0  pb-3 mx-xxl-2">
            Step-1 : Basic Details
          </p>
          {error !== "" ? <ErrorMessage message={error} /> : ""}
          <BasicJobForm setError={setError} />
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
