import React from "react";
import JobReport from "../../../components/admin/report/JobReport";
import { useLayoutProviderContext } from "../../../context/LayoutProvider";
import AdminATechHeader from "../../../components/common/AdminATechHeader";

const JobReportContainer = () => {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext;
  return (
    <div className="ff-Project page-color ">
      <AdminATechHeader
        HeaderName={"Job Reports"}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <JobReport />
    </div>
  );
};

export default JobReportContainer;
