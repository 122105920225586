import { combineReducers } from "redux";
import AuthReducer from "./reducer/auth/AuthReducer";
import AdminAcountsReducer from "./reducer/admin/AdminAcountsReducer";
import jobs from "./reducer/job";
import chat from "./reducer/chat";
import client from "./reducer/client/Client";

const reducers = {
  AuthReducer,
  AdminAcountsReducer,
  jobs,
  chat,
  client,
};
export const rootReducer = combineReducers(reducers);
