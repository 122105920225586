import React, { useEffect, useState } from "react";
import CreaterProfileGear from "./CreaterProfileGear";
import CreaterPastWork from "./CreaterPastWork";
import ProfileBox from "../../common/ProfileBox";
import AddGearForm from "./AddGearForm";
import AddWorkForm from "./AddWorkForm";
import { useDispatch, useSelector } from "react-redux";
import { GetUserWorkList } from "../../../redux/action/creator/CreatorWorkAction";
import { GetUserGearList } from "../../../redux/action/creator/CreatorGrarsWorkAction";
import Loader from "../../common/Loader";
import EmptyCard from "../../common/EmptyCard";

const CreatorProfileEdit = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingGear, setLoadingGear] = useState(false);
  const userDetails = useSelector((state) => state.AuthReducer.userDetails);
  const userGearList = useSelector((state) => state.AuthReducer.userGearList);
  const userPastWorkList = useSelector(
    (state) => state.AuthReducer.userPastWorkList
  );

  useEffect(() => {
    dispatch(GetUserWorkList(setLoading));
    dispatch(GetUserGearList(setLoadingGear));
  }, [dispatch]);

  return (
    <div className="profile-scroll bg-white  p-md-3 p-2  br-10">
      <div className="container-fluid my-4  ">
        <ProfileBox userDetails={userDetails} />
        {/* ====================================Past-work-section===================================== */}
        <div className="row">
          <div className="col">
            <h1 className="my-4 common-heading-Medium  sm-font">Past Work</h1>
          </div>
        </div>
        {loading ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : userPastWorkList && userPastWorkList.length > 0 ? (
          userPastWorkList.map((item, index) => {
            return (
              <CreaterPastWork
                key={index}
                item={item}
                index={index}
                userPastWorkList={userPastWorkList}
              />
            );
          })
        ) : (
          <EmptyCard message={"You’ve no Past Work List Yet!"} />
        )}

        <AddWorkForm />

        {/* ====================================My-Gear-section===================================== */}
        <hr className="bg-5D5E60" />
        <div className="row">
          <div className="col">
            <h1 className="my-4 common-heading-Medium  sm-font">My Gear</h1>
          </div>
        </div>
        {loadingGear ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : userGearList && userGearList.length > 0 ? (
          userGearList.map((item, index) => {
            return (
              <CreaterProfileGear
                key={index}
                item={item}
                index={index}
                userGearList={userGearList}
              />
            );
          })
        ) : (
          <EmptyCard message={"You’ve no Gear List Yet!"} />
        )}
        <AddGearForm />
      </div>
    </div>
  );
};
export default CreatorProfileEdit;
