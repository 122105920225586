import React from "react";
import VideoImg from "../../../assets/img/png/videoimgbtn.png";
import BubblesLoader from "../../common/BubblesLoader";
import EmptyCard from "../../common/EmptyCard";
import Loader from "../../common/Loader";

const JobDelivery = ({
  setShow,
  jobDeliveriesList,
  HandleApprovedJob,
  approvedLoading,
  setShowVedio,
  clientJobViewDetails,
  deliveryLoading,
  approvedjob,
  jobType,
}) => {
  return (
    <>
      <p className="common-text">Deliveries</p>
      <div className="row justify-content-center">
        {deliveryLoading ? (
          <div className="h-100 d-flex justify-content-center align-items-center flex-column">
            <BubblesLoader />
            <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
              Please wait...
            </p>
          </div>
        ) : jobDeliveriesList && jobDeliveriesList.length > 0 ? (
          jobDeliveriesList.map((item, index) => {
            return (
              <div className="col-md-4 col-sm-6 col-12">
                <div className="position-relative">
                  <img
                    className="w-100 deliver-img br-10"
                    src={item && item.thumb_path}
                    alt="delivery-img"
                  />
                  <div className="position-absolute top-0 d-flex justify-content-center h-100 w-100 align-items-center">
                    <img
                      className="cursor-pointer"
                      onClick={() => {
                        setShow(true);
                        setShowVedio(item);
                      }}
                      src={VideoImg}
                      alt=""
                    />
                  </div>
                </div>
                <p className="common-text text-black pt-3">
                  Delivery-{index + 1}
                </p>
              </div>
            );
          })
        ) : (
          <EmptyCard message={"You don't have any deliveries yet!"} />
        )}
      </div>
      {jobType === "" ? (
        ""
      ) : (
        <>
          {window.location.pathname.includes("/admin")
            ? ""
            : clientJobViewDetails &&
              clientJobViewDetails.is_completed_by_creator === false &&
              !approvedjob
            ? jobDeliveriesList &&
              jobDeliveriesList.length > 0 && (
                <div className="mt-3">
                  <button
                    className="atech-primary-button px-4 py-3 "
                    onClick={() => HandleApprovedJob()}
                  >
                    {approvedLoading ? <Loader /> : "Approve Job"}
                  </button>
                </div>
              )
            : ""}
        </>
      )}
    </>
  );
};

export default JobDelivery;
