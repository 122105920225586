import { useSelector } from "react-redux";
import EmptyCard from "../../../common/EmptyCard";
import VideoPlayModal from "../../../creator/creatorProfile/VideoPlayModal";

function AdminUserPastWorkDetail() {
  const adminUserWorkDetails = useSelector(
    (state) => state.AdminAcountsReducer.adminUserWorkDetails
  );
  return (
    <>
      <p className="my-4 common-heading-Medium  sm-font">Past Work</p>
      {adminUserWorkDetails && adminUserWorkDetails.length > 0 ? (
        adminUserWorkDetails.map((item, index) => {
          return (
            <div className="box-shadow my-3" key={index}>
              <div className="row justify-content-xl-start justify-content-center">
                <div className="col-xl-3 col-md-5 col-sm-7 ">
                  <VideoPlayModal item={item} type={"work"} />
                </div>
                <div className="col-xl-9 col-12 d-flex justify-content-center flex-column py-4">
                  <p className="common-heading-Medium  sm-font px-xl-0 px-sm-4 px-2">
                    {item.title}
                  </p>
                  <p className="common-text max-w-956 px-xl-0 px-sm-4 px-2">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyCard message={"User don't have any Past Work Details Yet!"} />
      )}
    </>
  );
}

export default AdminUserPastWorkDetail;
