import React, { useEffect, useState } from "react";
import DisputesImg from "../../../assets/img/png/dipsuteimg.png";

const DisputesList = ({ history, setShowChat, item, index, id, setPageNo }) => {
  const displuteChatHandler = (item) => {
    setPageNo(0);
    history.push(`/${pathToRedirect}/disputes/${item}`);
    setShowChat(true);
  };

  const [pathToRedirect, setPathToRedirect] = useState("");
  const pathname = window.location.pathname;

  useEffect(() => {
    if (pathname.includes("/admin")) {
      setPathToRedirect("admin");
    } else if (pathname.includes("/creator")) {
      setPathToRedirect("creator");
    } else {
      setPathToRedirect("client");
    }
  }, [pathname]);

  return (
    <section
      className={`${
        item.id === Number(id) ? "dispute-active-chat" : ""
      } dispute-chat-list p-2 mb-4 mb-4 d-flex justify-content-between align-items-center cursor-pointer`}
      onClick={() => displuteChatHandler(item.id)}
      key={index}
    >
      <div className="d-flex align-items-center mx-2">
        <span className="dispute-msg-circle">
          <img src={DisputesImg} alt="" />
        </span>
        <p className="common-heading smll-font mb-0 mx-xxl-3 mx-2">
          Dispute-{index + 1}
        </p>
      </div>
      {item && item.total_unseen === 0 ? (
        ""
      ) : (
        <span>
          <span className="bg-number-dipsute no-list-dipsute mb-0 ">
            {item.total_unseen}
          </span>
        </span>
      )}
    </section>
  );
};

export default DisputesList;
