import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReportListItems from "./ReportListItems";
import EmptyCard from "../../../components/common/EmptyCard";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { getReportJobList } from "../../../redux/action/admin/AdminAcountsAction";
import BubblesLoader from "../../common/BubblesLoader";

const JobReport = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [listError, setListError] = useState("");
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const jobReportList = useSelector(
    (state) => state.AdminAcountsReducer.jobReportList
  );

  const pageChangeHandler = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    dispatch(getReportJobList(setLoading, page, setHasMore, setListError));
  }, [dispatch, setLoading, page]);

  const jobReportListArray =
    page === 0 && loading ? (
      <div className="h-100 d-flex justify-content-center align-items-center flex-column">
        <BubblesLoader />
        <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
          Please wait...
        </p>
      </div>
    ) : jobReportList && !!jobReportList.length ? (
      jobReportList.map((item, index) => {
        return <ReportListItems key={index} item={item} loading={loading} />;
      })
    ) : (
      <EmptyCard message={"You’ve not any Job Report List Yet!"} />
    );

  return (
    <div className="container-fluid">
      <div className="bg-white box-shadow p-sm-4 p-2  mt-3 br-10 overflow-x-hidden">
        <div className="d-flex flex-sm-row flex-column  justify-content-sm-between align-items-center mt-3">
          <p className="common-heading-Medium xs-font mb-0">Job Reports</p>
        </div>
        <span className="border-bottom d-block pt-3"></span>
        {listError !== "" ? <ErrorMessage message={listError} /> : ""}

        <div className=" mt-4 notification-scroll " id="admin-job-report-list">
          <div className="infinite_scroll h-100">
            <InfiniteScroll
              className={`${
                page === 0 && !loading ? "" : "h-100 "
              } scrollbar_desgin`}
              dataLength={jobReportList.length}
              next={pageChangeHandler}
              hasMore={hasMore}
              scrollableTarget="admin-job-report-list"
              loader={
                page > 0 && loading ? (
                  <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                    <BubblesLoader />
                  </div>
                ) : (
                  ""
                )
              }
            >
              {jobReportListArray}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobReport;
