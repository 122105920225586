import ChatMassage from "./ChatMessage";
import MessageList from "./MessageList";
import { useEffect, useState } from "react";
import NoMessages from "../nullStates/NoMessages";
import ClientATechHeader from "../client/ClientATechHeader";
import ATechHeader from "../common/ATechHeader";
import AdminATechHeader from "../common/AdminATechHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  getChatList,
  getChatMessageList,
  startMessageUserDetails,
} from "../../redux/action/chat/ClientChat";
import { withRouter } from "react-router-dom";
import BubblesLoader from "../common/BubblesLoader";
import ErrorMessage from "../common/ErrorMessage";

const ChatBox = ({ match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const pathname = window.location.pathname;

  const chatList = useSelector((state) => state.chat.chatList);
  const chatMessageList = useSelector((state) => state.chat.chatMessageList);
  const startChatUserDetails = useSelector(
    (state) => state.chat.startChatUserDetails
  );

  const [clickOnChat, setClickOnChat] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [hasMessageMore, setHasMessageMore] = useState(true);

  const pageChangeHandler = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    dispatch(getChatList(setLoading, setError, page, setHasMore));
  }, [dispatch, page]);

  const pageMessageChangeHandler = () => {
    setPageNo(pageNo + 1);
  };

  useEffect(() => {
    if (id !== "view") {
      dispatch(
        getChatMessageList(
          id,
          setMessageLoading,
          setError,
          pageNo,
          setHasMessageMore
        )
      );
    }
  }, [id, dispatch, setError, setMessageLoading, pageNo, setHasMessageMore]);

  useEffect(() => {
    if (id !== "view") {
      dispatch(startMessageUserDetails(id));
    }
  }, [id, dispatch]);

  return (
    <div className="mx-lg-2">
      {pathname.includes("/admin") ? (
        <AdminATechHeader HeaderName={"Messages"} />
      ) : pathname.includes("/client") ? (
        <ClientATechHeader HeaderName={"Messages"} />
      ) : pathname.includes("/creator") ? (
        <ATechHeader HeaderName={"Messages"} />
      ) : (
        ""
      )}
      {error !== "" ? (
        <div className="mx-3 mt-2">
          <ErrorMessage message={error} />
        </div>
      ) : (
        ""
      )}
      {loading && page === 0 ? (
        <div className="h-calc-100vh-170 d-flex justify-content-center align-items-center flex-column">
          <BubblesLoader />
          <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
            Please wait...
          </p>
        </div>
      ) : chatList && chatList.length > 0 ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="bg-white p-lg-4 p-2 mt-3 br-10">
                <div className="row">
                  <div
                    className={`${
                      clickOnChat || showChat
                        ? "friend-list-none hide-chat"
                        : ""
                    } myjob mt-lg-0 mt-4  col-xxl-3 col-lg-4 col-sm-12 border-end-e8e8e8 pr-20`}
                  >
                    <MessageList
                      chatList={chatList}
                      page={page}
                      pageChangeHandler={pageChangeHandler}
                      hasMore={hasMore}
                      setShowChat={setShowChat}
                      loading={loading}
                      setPageNo={setPageNo}
                      userId={id}
                    />
                  </div>

                  <div
                    className={`${
                      clickOnChat || showChat
                        ? " show-chat  d-flex chat-box-height flex-column"
                        : " hide-chat"
                    }  col-xxl-9 col-lg-8 col-sm-12  ps-lg-4   `}
                  >
                    {id === "view" ? (
                      <NoMessages
                        message={"Select You friend and start chating."}
                      />
                    ) : messageLoading && pageNo === 0 ? (
                      <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                        <BubblesLoader />
                        <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                          Please wait...
                        </p>
                      </div>
                    ) : (
                      <ChatMassage
                        chatMessageList={chatMessageList}
                        setShowChat={setShowChat}
                        setClickOnChat={setClickOnChat}
                        startChatUserDetails={startChatUserDetails}
                        hasMessageMore={hasMessageMore}
                        pageMessageChangeHandler={pageMessageChangeHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-calc-100vh-190 mt-4">
          <NoMessages message={"You don't have any chat list yet!"} />
        </div>
      )}
    </div>
  );
};
export default withRouter(ChatBox);
