import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import img_1 from "../../../../assets/img/svg/Notification-img.svg";
import { DeleteIcon } from "../../../common/icons/Icon";
import ErrorMessage from "../../../common/ErrorMessage";
import Loader from "../../../common/Loader";
import { useDispatch } from "react-redux";
import { deleteJobAction } from "../../../../redux/action/client/jobFeed";

const DeleteJobModel = ({ jobId, history }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleDeleteJobs = () => {
    dispatch(deleteJobAction(setLoading, jobId, setError, history));
  };

  return (
    <>
      <button
        className="px-sm-3 px-2 py-2 mt-sm-0 mt-3 atech-fade-button me-sm-2"
        onClick={() => setShow(true)}
      >
        <span className="px-2">
          <DeleteIcon />
        </span>
        <span className=" d-inline-block fs-Montserrat ">Remove Job</span>
      </button>

      <Modal
        className="clear-notificatin-modal"
        show={show}
        onHide={() => {
          setShow(false);
          setError("");
        }}
        centered
      >
        <Modal.Header>
          <div className="d-flex justify-content-between w-100">
            <Modal.Title className="common-heading-Medium">
              <span className="common-heading-Medium"> Jobs</span>
            </Modal.Title>
            <span
              className="modal-close-btn d-inline-block cursor-pointer"
              onClick={() => {
                setShow(false);
                setError("");
              }}
            ></span>
          </div>
        </Modal.Header>
        {error !== "" ? <ErrorMessage message={error} /> : ""}
        <Modal.Body className="d-flex justify-content-center p-3">
          <div>
            <img
              className="d-flex justify-content-center mx-auto"
              src={img_1}
              alt=""
            />
            <p className="common-heading-Medium md-font">
              Are you sure to Delete This Job?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center p-5">
          <div>
            <button
              className="atech-primary-button px-sm-3 mr-15 px-1 py-2 "
              onClick={() => handleDeleteJobs()}
              disabled={loading}
            >
              {loading ? <Loader /> : "Remove Job"}
            </button>
            <button
              className="atech-fade-button px-sm-3 px-1 py-2 "
              onClick={() => {
                setShow(false);
                setError("");
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteJobModel;
