import React, { useState } from "react";
import { EditIcon } from "../../common/icons/Icon";
import Deleteicon from "../../../assets/img/svg/delete-icon.svg";
import VideoPlayModal from "./VideoPlayModal";
import { useDispatch } from "react-redux";
import { deleteWorkDetails } from "../../../redux/action/creator/CreatorWorkAction";
import Loader from "../../common/Loader";
import WorkPopup from "./WorkPopup";

function CreaterPastWork({ item, index, userPastWorkList }) {
  const [openEditForm, setOpenEditForm] = useState(false);
  const [IndexValue, setIndex] = useState(0);

  const dispatch = useDispatch();
  const [indexValue, setIndexValue] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(null);

  const handleClose = () => setOpenEditForm(false);

  const EditPastWork = (index) => {
    setOpenEditForm(true);
    setIndex(index);
  };
  return (
    <>
      <div className="row my-4">
        <div className="col">
          <div className="box-shadow br-10">
            <div className="d-flex flex-sm-row flex-column   align-items-center">
              <div className="col-lg-3  col-sm-4  col-7">
                <VideoPlayModal item={item} type={"work"} />
              </div>
              <div className=" col-xxl-9 col-lg-7 col-sm-8 col-12 d-flex justify-content-center flex-column px-sm-4 px-2 py-3">
                <p className="common-heading-Medium  sm-font text-sm-start text-center">
                  {item.title}
                </p>
                <p className="common-text max-w-956 text-sm-start text-center">
                  {item.description}
                </p>
                <div className="d-flex flex-column flex-sm-row">
                  <button
                    className="py-2 px-3 atech-primary-button "
                    onClick={() => EditPastWork(index)}
                  >
                    <span className="edit-icon pe-2">
                      <EditIcon />
                    </span>
                    Edit
                  </button>

                  <button
                    className="py-2 px-3 mt-3 mt-sm-0 ms-0 ms-sm-2 atech-fade-button"
                    onClick={() => {
                      dispatch(deleteWorkDetails(item.id, setDeleteLoading));
                      setIndexValue(index);
                    }}
                  >
                    {deleteLoading && index === indexValue ? (
                      <Loader />
                    ) : (
                      <>
                        <img src={Deleteicon} alt="" />
                        <span className="ms-2">Delete</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openEditForm ? (
        <WorkPopup
          handleClose={handleClose}
          show={openEditForm}
          gearWorkArray={userPastWorkList}
          index={IndexValue}
        />
      ) : null}
    </>
  );
}

export default CreaterPastWork;
