import { Modal } from "react-bootstrap";
import JobApplied from "../../../assets/img/png/jobapplied.png";
import Loader from "../../common/Loader";
import { withRouter } from "react-router";

function ApplyJobSuccessfullyModal({ show, setShow, loading, history }) {
  return (
    <>
      <button
        type="submit"
        className=" mt-sm-2 mt-1  atech-primary-button  py-sm-3 px-4 py-2 mb-3"
      >
        {loading ? <Loader /> : "Apply For Job"}
      </button>

      <Modal
        className="job-applicants-successfully-modal  "
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header>
          <div className="d-flex mb-4 pt-4 align-items-center justify-content-between w-100">
            <span
              className="btn-close d-inline-block cursor-pointer"
              onClick={() => setShow(false)}
            ></span>
          </div>
        </Modal.Header>

        <Modal.Body className=" p-5 pt-0">
          <div className="job-application-img-width mx-auto">
            <img className="w-100" src={JobApplied} alt="" />
          </div>
          <p className="common-heading-Medium text-center mt-2 ">
            Job Applied Successfull
          </p>
          <p className="common-text text-center">
            Thankyou for applying for this job
          </p>
          <div className="mx-auto d-flex  justify-content-center pt-3">
            <button
              className="atech-primary-button px-sm-3  px-1 py-2 "
              onClick={() => history.push("/creator/job-feed/recent")}
            >
              Return to Job Feed
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withRouter(ApplyJobSuccessfullyModal);
