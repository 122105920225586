import React from "react";
import { withRouter } from "react-router";
import ATechLogo from "../components/common/ATechLogo";
import SignUpForm from "../components/auth/signUpForms/SignUpForm";
import SignupFormWrapper from "../components/hoc/SignupFormWrapper";

function SignUp({ history }) {
  return (
    <SignupFormWrapper>
      <div className=" col-lg-6 col-sm-10 col-12">
        <div className="bg-white box-shadow br-10 p-sm-5 p-3">
          <ATechLogo />
          <p className="common-heading mb-1">Sign Up</p>
          <p className="common-text  pb-4">
            Provide your details to create an account
          </p>
          {/** Sign Up Form */}
          <SignUpForm history={history} />
          <p className="text-center common-text pt-3">
            Already a user?{" "}
            <span
              className="common-text text-blue cursor-pointer"
              onClick={() => history.push("/")}
            >
              Log In
            </span>
          </p>
        </div>
      </div>
    </SignupFormWrapper>
  );
}
export default withRouter(SignUp);
