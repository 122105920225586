import Swal from "sweetalert2";
import {
  applayForJobApi,
  CreateJobFeedListApi,
  creatorEarningDetailsAPi,
  creatorJobDetailsApi,
  creatorJobReviewsApi,
  creatorReportJobApi,
  getTransationHistoryListApi,
  searchJobListApi,
} from "../../api/creator/CreatorJob";

export const GET_CREATOR_JOB_FEED_LIST = "GET_CREATOR_JOB_FEED_LIST";
export const SEARCH_JOBS_LIST_FOR_CREATOR = "SEARCH_JOBS_LIST_FOR_CREATOR";
export const CREATOR_JOB_DETAILS = "CREATOR_JOB_DETAILS";
export const CREATOR_EARNING_DETAILS = "CREATOR_EARNING_DETAILS";
export const GET_TRANSACTION_HISTORY_LIST = "GET_TRANSACTION_HISTORY_LIST";

/**
 * Get user work list action
 * @param {Object} data
 * @returns
 */
const CreateJobFeedAction = (data) => ({
  type: GET_CREATOR_JOB_FEED_LIST,
  data,
});

export const CreateJobFeedList =
  (types, setLoading, shortedValue, page, setHasMore) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await CreateJobFeedListApi(types, shortedValue, page);
      if (response.success) {
        dispatch(CreateJobFeedAction({ data: response.data, page: page }));
        setLoading(false);
        if (response.data.length < 10) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
        Swal.fire("Opps!", "Failed to load data", "error");
        setTimeout(Swal.close, 2000);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Opps!", "Something went wrong", "error");
      setTimeout(Swal.close, 2000);
    }
  };

/**
 * Get search job list in creator action
 * @param {Array} data
 * @returns
 */
export const searchJobAction = (data) => ({
  type: SEARCH_JOBS_LIST_FOR_CREATOR,
  data,
});

export const getSearchJobListByQuery =
  (setLoading, type, query) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await searchJobListApi(type, query);
      if (response.success) {
        dispatch(searchJobAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

/**
 * Get job details action for creator job page action
 * @param {Array} data
 * @returns
 */
export const creatorJobDetailsAction = (data) => ({
  type: CREATOR_JOB_DETAILS,
  data,
});

export const creatorJobDetails =
  (setLoading, jobId, setError) => async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await creatorJobDetailsApi(jobId);
      if (response.success) {
        dispatch(creatorJobDetailsAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
        setError("Failed to load My Job Details");
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong");
    }
  };

/**
 *
 * @param {Booleen} setLoading
 * @param {object} data
 * @returns
 */
export const creatorApplayForJob =
  (setLoading, data, setShow, setError) => async () => {
    setLoading(true);
    setError("");
    try {
      const response = await applayForJobApi(data);
      if (response.success) {
        setLoading(false);
        setShow(true);
      } else {
        setLoading(false);
        setError("Ooops! Failed to apply for this job.Try again later");
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Failed to apply for this job.Try again later");
    }
  };

/**
 * Report job action for creator job page action
 * @param {Object} data
 * @param {Boolen} setLoading
 * @param {Boolen} setError
 * @returns
 */
export const creatorReportJob =
  (data, setLoading, setError, setShow) => async () => {
    setLoading(true);
    setError("");
    try {
      const response = await creatorReportJobApi(data);
      if (response.success) {
        setLoading(false);
        setShow(false);
        Swal.fire("Success!", "Report has been sent successfully", "success");
        setTimeout(Swal.close, 2000);
      } else {
        setLoading(false);
        setError(
          response &&
            response.response &&
            response.response.data &&
            response.response.data.message
            ? response.response.data.message
            : "Oops! Failed to report job"
        );
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * creator Job Reviews Action
 * @param {Object} data
 * @param {Boolen} setLoading
 * @param {Boolen} setError
 * @returns
 */
export const creatorJobReviewsAction =
  (data, setLoading, setError, setShow) => async () => {
    setLoading(true);
    setError("");
    try {
      const response = await creatorJobReviewsApi(data);
      if (response.success) {
        setLoading(false);
        setShow(false);
        Swal.fire("Success!", "Job Review Added Successfully", "success");
        setTimeout(Swal.close, 2000);
      } else {
        setLoading(false);
        setError(
          response &&
            response.response &&
            response.response.data &&
            response.response.data.message
            ? response.response.data.message
            : "Oops! Failed to add job Review"
        );
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * creator Job earning details Action for creator job page action
 * @param {Boolen} setLoading
 * @param {Boolen} setError
 * @returns
 */

export const creatorEarningDetails = (data) => ({
  type: CREATOR_EARNING_DETAILS,
  data,
});
export const creatorEarningDetailsAction =
  (setLoading, setError) => async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await creatorEarningDetailsAPi();
      if (response.success) {
        setLoading(false);
        dispatch(creatorEarningDetails(response.data));
      } else {
        setLoading(false);
        setError(
          response &&
            response.response &&
            response.response.data &&
            response.response.data.message
            ? response.response.data.message
            : "Oops! Failed to add job Review"
        );
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Get transactions earning list action for creator job page action
 * @param {Array} data
 * @returns
 */
export const getTransationHistoryList = (data) => ({
  type: GET_TRANSACTION_HISTORY_LIST,
  data,
});

export const getTransationHistoryListAction =
  (setLoading, page, setHasMore, setError) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await getTransationHistoryListApi(page);
      if (response.success) {
        dispatch(getTransationHistoryList(response.data));
        setLoading(false);
        if (response.data.length < 10) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
        setError(
          response &&
            response.response &&
            response.response.data &&
            response.response.data.message
            ? response.response.data.message
            : "Oops! Failed to load Transactions History"
        );
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Failed to load Transactions History");
    }
  };
