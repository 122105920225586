import React from "react";
import ATechHeader from "../../components/common/ATechHeader";
import DashboardContainer from "../../components/creator/dashboard/DashboardContainer";

const DashBoard = () => {
  return (
    <div className="ff-Project page-color">
      <ATechHeader HeaderName={"Dashboard"} />
      <DashboardContainer />
    </div>
  );
};

export default DashBoard;
