import { useState } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { startSendMessage } from "../../../redux/action/admin/AdminAcountsAction";
import { getJobApplicationDetails } from "../../../redux/action/client/jobFeed";
import JobApplicationsDetailsModel from "./JobModel/JobApplicationsDetailsModel";

function ViewApplicationListItem({ item, jobId, history, jobApproved }) {
  const dispatch = useDispatch();
  const clientJobApplicationDetails = useSelector(
    (state) => state.jobs.clientJobApplicationDetails
  );
  const [loading, setLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);

  const HandleJobDetails = (applicationId) => {
    setShow(true);
    if (jobId && applicationId) {
      dispatch(
        getJobApplicationDetails(setLoading, jobId, applicationId, setError)
      );
    }
  };

  const sendMessageHandler = (userId) => {
    const data = {
      receiver: userId.id,
    };
    dispatch(startSendMessage(data, setStartLoading, history, "client"));
  };

  return (
    <>
      <section
        onClick={() => HandleJobDetails(item.id)}
        className=" applications-job  w-100 p-2 my-3 d-flex justify-content-between align-items-center cursor-pointer"
      >
        <div className="d-flex align-items-center mx-2">
          <img
            className="massage-list-img object-contain"
            src={item && item.user && item.user.profile_image_path}
            alt=""
          />
          <p className="common-heading smll-font  mb-0 mx-xxl-3 mx-2 application-wrap">
            {item && item.user && item.user.first_name
              ? item &&
                item.user &&
                item.user.first_name + " " + item.user.last_name
              : item && item.user && item.user.username}
          </p>
        </div>
        <p className="common-heading-Medium smll-font mb-0 application-wrap">
          {item.is_approved_by_client ? "Approved" : "Pending"}
        </p>
        <p className="common-heading-Medium text-blue mb-0 application-wrap">
          ${item.price}
        </p>
      </section>
      <JobApplicationsDetailsModel
        show={show}
        setShow={setShow}
        loading={loading}
        error={error}
        clientJobApplicationDetails={clientJobApplicationDetails}
        startLoading={startLoading}
        sendMessageHandler={sendMessageHandler}
        applicationId={item.id}
        jobId={jobId}
        jobApproved={jobApproved}
      />
    </>
  );
}
export default withRouter(ViewApplicationListItem);
