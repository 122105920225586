// CONVERT TIME TO DAYS

export const getTime = (date,duration) => {
  let totalTime=Date.parse(new Date(date))+duration*24*60*60*1000;
  let timeInMs = new Date(totalTime)-new Date();
  let days=Math.floor(timeInMs/(1000*60*60*24));
  
  if(days<0){
    return "Expired "+Math.abs(days)+" days ago";
  }

  if (days > 30) {
    return `${Math.floor(days/30)} month left`;
  } else if (days <30&&days>0) {
    return days + " days left";
  } else if (days > 1) {
    return days + " hr left";
  } else if (days < 1 && days > 0.1) {
    return days+ " minutes left";
  } else if (days < 0.1) {
    return "Just now";
  }
};

export function getDate(date, duration) {
  return new Date(
    Date.parse(new Date(date)) + duration * 24 * 60 * 60 * 1000
  ).toLocaleDateString();
}

export function getDateRemaning(date, duration) {
  let diff =
    Date.now() + duration * 24 * 60 * 60 * 1000 - Date.parse(new Date(date));
  let days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days + " days left";
}
