import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import {
  getClientSearchJobByQuery,
  getJobListForClient,
} from "../../../../redux/action/client/jobFeed";
import ClientCompletedJob from "./ClientCompletedJob";

function ClientPendingJob({ history }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [pageValue, setPage] = useState(0);

  useEffect(() => {
    const types = "completed";
    const page = 0;
    const shortedValue = 0;
    dispatch(
      getJobListForClient(
        setLoading,
        types,
        page,
        pageValue,
        shortedValue,
        setHasMore
      )
    );
  }, [dispatch, pageValue]);

  const handleSearchJob = (value) => {
    const types = "completed";
    const page = 0;
    setSearchValue(value);
    if (searchValue) {
      dispatch(
        getClientSearchJobByQuery(
          setLoading,
          types,
          searchValue,
          page,
          pageValue,
          setHasMore
        )
      );
    }
  };

  const handleShorting = (shortedValue) => {
    const page = 0;
    const types = "completed";
    dispatch(getJobListForClient(setLoading, types, page, shortedValue));
  };

  return (
    <ClientCompletedJob
      history={history}
      handleShorting={handleShorting}
      loading={loading}
      hasMore={hasMore}
      pageValue={pageValue}
      setPage={setPage}
      searchValue={searchValue}
      handleSearchJob={handleSearchJob}
    />
  );
}

export default withRouter(ClientPendingJob);
