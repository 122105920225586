import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { BackIconImg, StartDisputeIcon } from "../../../common/icons/Icon";
import ViewJobTechnicalElementsButton from "../ViewJobTechnicalElementsButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobsDeliveries,
  getJobViewDetails,
  jobApprovedByClient,
} from "../../../../redux/action/client/jobFeed";
import ErrorMessage from "../../../common/ErrorMessage";
import JobDelivery from "../JobDelivery";
import DeliveryModel from "./DeliveryModel";
import RequestChangesModel from "./RequestChangesModel";
import { getDate } from "../../../common/utils/Functions";
import StartDispute from "../../../chat/popup/StartDispute";
import BubblesLoader from "../../../common/BubblesLoader";

function ViewOnGoingJob({ history, match }) {
  const dispatch = useDispatch();
  const { jobId, jobType } = match.params;

  const jobDeliveriesList = useSelector(
    (state) => state.jobs.jobDeliveriesList
  );
  const clientJobViewDetails = useSelector(
    (state) => state.jobs.clientJobViewDetails
  );
  const [show, setShow] = useState(false);
  const [showDisplute, setShowDisplute] = useState(false);
  const [modelError, setModelError] = useState(false);
  const [requestData, setRequestData] = useState("");
  const [showVedio, setShowVedio] = useState({});
  const [requestChanges, setRequestChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const [approvedLoading, setApprovedLoading] = useState(false);
  const [approvedjob, setApprovedJob] = useState(false);
  const [error, setError] = useState("");
  // const [isReleaseFundLoding, setIsReleaseFundLoding] = useState(false);
  // const [releaseFund, setReleaseFund] = useState(true);

  useEffect(() => {
    dispatch(getJobsDeliveries(setDeliveryLoading, jobId, setError));
  }, [dispatch, jobId]);

  useEffect(() => {
    dispatch(getJobViewDetails(setLoading, jobId));
  }, [dispatch, jobId]);

  /**
   * @description: Client Approved Job by Client and
   * redirect to job feed page with success message
   */
  const HandleApprovedJob = () => {
    const data = {
      is_completed_by_creator: true,
    };
    dispatch(
      jobApprovedByClient(setApprovedLoading, jobId, data, setApprovedJob)
    );
  };

  return (
    <div className="mx-3">
      <div className="container-fluid p-md-4 p-3 bg-white my-5 br-10 delivery-scroll">
        <div className="d-sm-flex justify-content-between align-items-center mt-3 mb-2 ">
          <div>
            <button
              className="common-text border-e8e8e8 py-2 px-md-3 px-2 my-2 Start-Dispute-hover w-xs-100"
              onClick={() => history.goBack()}
            >
              <BackIconImg />
              Back
            </button>
          </div>
          {jobType === "completed" ? (
            ""
          ) : (
            <>
              {(clientJobViewDetails &&
                clientJobViewDetails.is_completed_by_creator === false) ||
                (approvedjob && (
                  <>
                    {" "}
                    <div className="d-flex ">
                      <div>
                        <button
                          onClick={() => setShowDisplute(true)}
                          className="me-sm-2 w-xs-100 border-e8e8e8 py-2 px-md-3 px-2 para br-3 Start-Dispute-hover common-text"
                        >
                          <span className="pe-2">
                            <StartDisputeIcon />
                          </span>
                          Start Dispute
                        </button>
                      </div>
                    </div>
                  </>
                ))}{" "}
            </>
          )}
        </div>
        <div className="hr"></div>
        {loading ? (
          <div className="h-100 d-flex justify-content-center align-items-center flex-column">
            <BubblesLoader />
            <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
              Please wait...
            </p>
          </div>
        ) : (
          <>
            {error !== "" ? <ErrorMessage message={error} /> : ""}

            <p className="common-heading-Medium mt-3 pt-3">
              {clientJobViewDetails.title}
            </p>
            <p className="common-text">
              Start Date:{" "}
              {new Date(clientJobViewDetails.updated_at).toLocaleDateString()}
              <span className="ms-2">
                Deadline:{" "}
                {getDate(
                  clientJobViewDetails.updated_at,
                  clientJobViewDetails.duration
                )}
              </span>
            </p>
            <p className="common-text d-flex">
              Job Price:
              <p className="common-heading-Medium text-blue margin-5">
                {" "}
                ${clientJobViewDetails.price}
              </p>
            </p>
            <p className="common-text d-flex">
              Application Price:{" "}
              <p className="common-heading-Medium text-blue margin-5">
                {" "}
                $
                {clientJobViewDetails &&
                clientJobViewDetails.application_user &&
                clientJobViewDetails.application_user.amount
                  ? clientJobViewDetails.application_user.amount
                  : clientJobViewDetails.price}
              </p>
            </p>
            <p className="common-text mb-4">
              {clientJobViewDetails.description}
            </p>
            <div className="hr my-4"></div>
            <ViewJobTechnicalElementsButton />
            <JobDelivery
              setShow={setShow}
              jobDeliveriesList={jobDeliveriesList}
              HandleApprovedJob={HandleApprovedJob}
              approvedLoading={approvedLoading}
              setShowVedio={setShowVedio}
              clientJobViewDetails={clientJobViewDetails}
              deliveryLoading={deliveryLoading}
              approvedjob={approvedjob}
              jobType={jobType}
            />
            {jobType === "completed"
              ? "Your job is completed now. All the watermarks are removed in deliverables."
              : ""}
          </>
        )}
        <DeliveryModel
          show={show}
          setShow={setShow}
          modelError={modelError}
          setModelError={setModelError}
          setRequestData={setRequestData}
          setRequestChanges={setRequestChanges}
          approvedLoading={approvedLoading}
          showVedio={showVedio}
          clientJobViewDetails={clientJobViewDetails}
          approvedjob={approvedjob}
        />
        <RequestChangesModel
          setRequestChanges={setRequestChanges}
          modelError={modelError}
          setModelError={setModelError}
          requestData={requestData}
          clientJobViewDetails={clientJobViewDetails}
          setRequestData={setRequestData}
          requestChanges={requestChanges}
          setLoading={setLoading}
          loading={loading}
        />
      </div>
      <StartDispute
        setShow={setShowDisplute}
        show={showDisplute}
        startChatUserDetails={clientJobViewDetails}
        jobId={jobId}
      />
    </div>
  );
}

export default withRouter(ViewOnGoingJob);
