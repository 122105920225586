import React, { useEffect, useState } from "react";
import AdminDashboardJobList from "./AdminDashboardJobList.jsx";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAdminAllJobList } from "../../../redux/action/admin/AdminJobs.jsx";
import EmptyCard from "../../common/EmptyCard.jsx";
import BubblesLoader from "../../common/BubblesLoader.jsx";
import DashBoardChat from "./DashBoardChat";

const AdminDashboardMyJob = ({ history }) => {
  const dispatch = useDispatch();
  const adminAllJobList = useSelector((state) => state.jobs.adminAllJobList);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const page = 0;
    const shortedValue = 0;
    const types = "pending-jobs";
    dispatch(getAdminAllJobList(setLoading, types, page, shortedValue));
  }, [dispatch]);

  return (
    <>
      {/* <AdminDashboardCard /> */}

      <div className="col-lg-7  col-12 h-100 my-3">
        <div
          className={`${
            adminAllJobList.length > 0 || loading === true
              ? "admin-dahboard-height"
              : ""
          }   p-3 dahboard-color h-100  `}
        >
          <div className="d-flex justify-content-between">
            <p className="common-text cursor-pointer">Pending Jobs</p>
            <p
              className="common-text cursor-pointer"
              onClick={() => history.push("/admin/jobs/pending-jobs")}
            >
              View All
            </p>
          </div>
          {loading ? (
            <div className="h-100 d-flex justify-content-center flex-column align-items-center">
              <BubblesLoader />
              <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                Please wait...
              </p>
            </div>
          ) : adminAllJobList && !!adminAllJobList.length ? (
            adminAllJobList.map((item, index) => {
              return (
                <AdminDashboardJobList
                  item={item}
                  index={index}
                  history={history}
                />
              );
            })
          ) : (
            <EmptyCard
              message={"You’ve not any have any pending job List Yet!"}
            />
          )}
        </div>
      </div>
      <DashBoardChat />
    </>
  );
};

export default withRouter(AdminDashboardMyJob);
