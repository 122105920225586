import { Accordion } from "react-bootstrap";
import GreenCGIandPractical from "./CommonFields/GreenCGIandPractical";

function AddCGI({ jobList }) {
  return (
    <Accordion className="footage-accordian">
      {jobList &&
        jobList.cgi &&
        jobList.cgi.length > 0 &&
        jobList.cgi.map((value, index) => {
          return (
            <GreenCGIandPractical
              FootageType={"CGI Effect"}
              value={value}
              index={index}
            />
          );
        })}
    </Accordion>
  );
}

export default AddCGI;
