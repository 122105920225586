import React from "react";
import { getTime } from "../../common/utils/Functions";

const MyJobsListItem = ({ item, history }) => {
  return (
    <>
      <section
        className="job-list p-4 mb-4"
        onClick={() => history.push(`/creator/view/my-jobs/${item.id}`)}
      >
        <p className="common-text text-black f-w-600 mb-0 ">{item.title}</p>
        <p className="common-text xxs-font mb-0 mt-1">
          {item.description}, {getTime(item && item.created_at)}
        </p>
        <p className="common-text text-blue f-w-600 mt-1 mb-0">${item.price}</p>
      </section>
    </>
  );
};

export default MyJobsListItem;
