import { Accordion } from "react-bootstrap";

function Vehicle({ jobList }) {
  return (
    <Accordion className="footage-accordian">
      {jobList &&
        jobList.reporters.length > 0 &&
        jobList.reporters.map((value, index) => {
          return (
            <Accordion.Item className="my-3" eventKey={index}>
              <Accordion.Header className="accordian_border">
                <span>Vehicle {index + 1}</span>
              </Accordion.Header>
              <Accordion.Body>
                <div className="bg-F8F8F8 p-2">
                  <p className="mt-4 mb-3 common-text px-2 px-lg-4">
                    Type of Vehicle:
                  </p>
                  <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
                    {value && value.details ? value.details : "N/A"}
                  </p>
                  <p className="mt-4 common-text px-2 px-lg-4">
                    Describe Vehicle :
                  </p>
                  <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
                    {value && value.actions ? value.actions : "N/A"}
                  </p>
                  <p className="mt-4 common-text px-2 px-lg-4">
                    Describe What is done with the vehicle:
                  </p>
                  <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
                    {value && value.dialogs ? value.dialogs : "N/A"}
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
    </Accordion>
  );
}

export default Vehicle;
