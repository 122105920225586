import React from "react";
import DisputeListItem from "./DipsuteListItem";
const AdminDisputesMessagaeList = ({
  disputesList,
  setShowChat,
  pageChangeHandler,
  hasMore,
  page,
  loading,
  setPageNo,
  userId,
  error,
}) => {
  return (
    <>
      <section
        className={`${
          error ? "h-calc-100vh-250 " : "h-calc-100vh-160"
        }  overflow-auto scroll-bar-none  `}
      >
        <DisputeListItem
          disputesList={disputesList}
          page={page}
          pageChangeHandler={pageChangeHandler}
          hasMore={hasMore}
          setShowChat={setShowChat}
          loading={loading}
          setPageNo={setPageNo}
          userId={userId}
        />
      </section>
    </>
  );
};

export default AdminDisputesMessagaeList;
