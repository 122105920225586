import Swal from "sweetalert2";
import Preloader from "../../../components/common/Preloader";
import {
  AddUserDeatilsApi,
  GetUserDeatialApi,
  UpdateUserDetailsApi,
} from "../../api/auth/UserDetailsApi";
import { RemoveFileUrlLink } from "../UploadImageAction";
import { LoginSuccess } from "./AuthAction";

export const SIGN_UP_DETAILS_SUCCESSFULLY = "SIGN_UP_DETAILS_SUCCESSFULLY";

/**
 * Add user details action
 * @param {Object} data
 * @returns
 */

export const AddUserDeatils =
  (data, setLoading, history, setError) => async () => {
    setLoading(true);
    try {
      const response = await AddUserDeatilsApi(data);
      setError("");
      if (response.success) {
        history.push("/sign-up/bio/profile");
      } else {
        setLoading(false);
        setError(
          response &&
            response.response &&
            response.response.data &&
            response.response.data.message
            ? response.response.data.message
            : "Ooops! Something went wrong"
        );
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong");
    }
  };

const signUpDetailsSuccess = (data) => ({
  type: SIGN_UP_DETAILS_SUCCESSFULLY,
  data,
});

/**
 * Add user details action
 * @param {Object} data
 * @returns
 */
export const UpdateUserDetails =
  (data, setLoading, history, checkAuth, setError, setOpenProfile) =>
  async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await UpdateUserDetailsApi(data);
      if (response.success) {
        setLoading(false);
        if (checkAuth !== "login") {
          if (response.user_roles.is_creator) {
            history.push("/sign-up/add-past-work");
          } else {
            <Preloader />;
            history.push("/");
            window.location.reload();
          }
        }
        dispatch(signUpDetailsSuccess(response));
        dispatch(RemoveFileUrlLink(""));
        Swal.fire("Success!", "Profile updated successfully", "success");
        setTimeout(Swal.close, 2000);
        setOpenProfile(false);
      } else {
        setLoading(false);
        setError("Ooops! Something went wrong");
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong");
    }
  };

/**
 *
 * @param {*} access
 * @param {Boolean} setLoading
 * @param {Object} history
 * @returns
 */
export const userDetailAction = (access, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await GetUserDeatialApi(access);
    if (response.success) {
      dispatch(LoginSuccess(response));
      setLoading(false);
    } else {
      setLoading(false);
    }
  } catch {
    setLoading(false);
  }
};
