import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import BubblesLoader from "../../../components/common/BubblesLoader";
import EmptyCard from "../../../components/common/EmptyCard";
import WithDrawEarningList from "./WithDrawEarningList";

const WithDrawEarningTable = ({
  setShow,
  pageChangeHandler,
  page,
  EarningLoading,
  hasMore,
  creatorEarning,
}) => {
  const creatorEarningList = useSelector(
    (state) => state.jobs.creatorEarningList
  );
  const creatorEarningListArray =
    page === 0 && EarningLoading ? (
      <div className="h-100 d-flex justify-content-center align-items-center flex-column">
        <BubblesLoader />
        <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
          Please wait...
        </p>
      </div>
    ) : creatorEarningList && creatorEarningList.length > 0 ? (
      creatorEarningList.map((value, index) => (
        <WithDrawEarningList index={index} value={value} />
      ))
    ) : (
      <EmptyCard message={"You’ve not any Client List Yet!"} />
    );

  return (
    <div className="container-fluid">
      <div className="bg-white box-shadow p-sm-4 p-2  mt-3 br-10 overflow-x-hidden ">
        <div className="d-flex  justify-content-between align-items-center mt-sm-0 mt-3">
          <p className="common-heading-Medium xs-font  mb-0">
            All transactions
          </p>

          <button
            className={`atech-primary-button py-2 px-3 ${
              creatorEarning.amount_available === 0
                ? "cursor-not-allowed"
                : "curson-pointer"
            }`}
            onClick={() => setShow(true)}
            disabled={creatorEarning.amount_available === 0 ? true : false}
          >
            Withdraw money
          </button>
        </div>
        <span className="border-bottom d-block pt-3"></span>
        <div className="mt-4 earning_height" id="notification-list">
          {creatorEarningList.length > 0 ? (
            <div className="earning_bg p-2">
              <div className="row">
                <div className="col-3">
                  <div>
                    <p className="common-text mb-0">Date</p>
                  </div>
                </div>
                <div className="col-3">
                  <div>
                    <p className="common-text mb-0">Amount</p>
                  </div>
                </div>
                <div className="col-3">
                  <div>
                    <p className="common-text mb-0">Ref ID</p>
                  </div>
                </div>
                <div className="col-3">
                  <div>
                    <p className="common-text mb-0">Status</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            className={`${
              EarningLoading && creatorEarningList.length === 0 ? "h-100" : ""
            } `}
            id="earning_list_for_creator"
          >
            <InfiniteScroll
              className={`${
                page === 0 && EarningLoading ? "" : "h-100 scrollbar_desgin"
              }`}
              dataLength={creatorEarningList.length}
              next={pageChangeHandler}
              hasMore={hasMore}
              scrollableTarget="earning_list_for_creator"
              loader={
                page > 0 && EarningLoading ? (
                  <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                    <BubblesLoader />
                  </div>
                ) : (
                  ""
                )
              }
            >
              {creatorEarningListArray}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithDrawEarningTable;
