import React from "react";
import UserReportViewBox from "../../../components/admin/report/UserReportViewBox";
import AdminATechHeader from "../../../components/common/AdminATechHeader";

const UserReportView = () => {
  return (
    <>
      <AdminATechHeader HeaderName={"User Reports"} />
      <div className="mx-3 mx-1 bg-white p-md-4 p-2 br-10 mt-3 ">
        <div className="container-fluid my-4  profile-scroll">
          <UserReportViewBox />
        </div>
      </div>
    </>
  );
};

export default UserReportView;
