import React from "react";
import ReactPlayer from "react-player";

const MessageByMe = ({ value, index }) => {
  return (
    <div className="my-3 d-flex flex-row-reverse" key={index}>
      <div>
        <div className="sender-message">
          {value && value.file !== null && value.is_image ? (
            <img src={value.file} alt="" />
          ) : (
            <ReactPlayer
              width="100%"
              className={`${value.file ? "my-message-video" : ""}`}
              height="100%"
              stopOnUnmount={true}
              controls={true}
              light={value.file}
              url={value.main_file}
              // playIcon={<VideoPlayIcon />}
              // playing={thumbnail.url ? true : false}
            />
          )}
          <div className="text-end">
            <span className="fs-16-500 color-fff d-inline-block me-message">
              {value && value.message}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageByMe;
