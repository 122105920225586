import { useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AddCommanDetailsForJob } from "../../../../../redux/action/client/JobForm";
import Loader from "../../../../common/Loader";
import ErrorMessage from "../../../../common/ErrorMessage";
import { withRouter } from "react-router-dom";

const CommonFormForSomeJobs = ({ match, type, message, setReloadElements }) => {
  const { jobId } = match.params;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setShow(false);
    setError("");
  };

  const handleShow = () => setShow(true);

  const onSubmit = (data) => {
    const dataValue = { job: Number(jobId), type_of: type };
    const finalResult = Object.assign(data, dataValue);
    dispatch(
      AddCommanDetailsForJob(
        finalResult,
        setLoading,
        setError,
        handleClose,
        setReloadElements
      )
    );
    reset();
  };

  return (
    <>
      <div className="my-sm-0 my-2 me-sm-2 ">
        <Button
          onClick={handleShow}
          className="atech-primary-button  primary-button-padding w-100"
        >
          {message}
        </Button>

        <Offcanvas
          className="Sidebar-width h-100vh "
          show={show}
          onHide={handleClose}
          placement="end"
        >
          <Offcanvas.Body className="d-flex justify-content-center   pt-lg-2">
            <div className="container">
              <Offcanvas.Header
                className="p-0 d-flex justify-content-end pt-3"
                closeButton
              ></Offcanvas.Header>
              <h1 className="dash_head pb-lg-4 pb-3 pt-3">{message}</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                {error !== "" ? <ErrorMessage message={error} /> : ""}
                <div className="row ">
                  <div className="col-12  ">
                    <div className="my-2">
                      <input
                        {...register("name", { required: true })}
                        type="text"
                        className="form-control py-3 atech-input inputs_font_size"
                        placeholder="Name Prop"
                      />
                      {errors.name?.type === "required" && (
                        <p className="common-text xxs-font text-red">
                          Name Prop is required
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="my-2">
                  <Form.Group
                    className=" textarea-focus"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      {...register("details", { required: true })}
                      placeholder="Describe Prop"
                      as="textarea"
                      rows={5}
                      className="inputs_font_size"
                    />
                    {errors.details?.type === "required" && (
                      <p className="common-text xxs-font text-red">
                        Describe Prop is required
                      </p>
                    )}
                  </Form.Group>
                </div>
                <div className="my-2">
                  <Form.Group
                    className=" textarea-focus"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      {...register("describe", { required: true })}
                      placeholder="Describe what is done with the prop"
                      as="textarea"
                      rows={5}
                      className="inputs_font_size"
                    />
                    {errors.describe?.type === "required" && (
                      <p className="common-text xxs-font text-red">
                        Describe is required
                      </p>
                    )}
                  </Form.Group>
                </div>

                <div className="d-flex flex-sm-row  flex-column mt-3">
                  <button
                    className={`${
                      loading ? "cursor-not-allowed" : ""
                    } px-4 atech-primary-button  py-sm-2 py-2 ms-sm-2  my-2`}
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Save"}
                  </button>
                  <button
                    className="px-4  py-sm-2 py-2 ms-sm-2  my-2  atech-fade-button"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default withRouter(CommonFormForSomeJobs);
