import ATechHeader from "../../components/common/ATechHeader";
import CreatorProfileEdit from "../../components/creator/creatorProfile/CreatorProfileEdit";

const CreatorProfile = () => {
  return (
    <>
      <ATechHeader HeaderName={"Profile"} />
      <div className="mx-0 mx-xl-4 mt-3">
        <CreatorProfileEdit />
      </div>
    </>
  );
};
export default CreatorProfile;
