import FriendListItem from "./FriendListItem";

const MessageList = ({
  chatList,
  setShowChat,
  pageChangeHandler,
  hasMore,
  page,
  loading,
  setPageNo,
  userId,
}) => {
  return (
    <section className="overflow-auto scroll-bar-none h-calc-100vh-156">
      <FriendListItem
        chatList={chatList}
        page={page}
        pageChangeHandler={pageChangeHandler}
        hasMore={hasMore}
        setShowChat={setShowChat}
        loading={loading}
        setPageNo={setPageNo}
        userId={userId}
      />
    </section>
  );
};

export default MessageList;
