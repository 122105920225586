import VideoImg from "../../../../assets/img/png/videoimgbtn.png";
import BubblesLoader from "../../../common/BubblesLoader";

const DeliveredFile = ({ jobDeliveriesList, videoHandler, listLoading }) => {
  return (
    <>
      <p className="common-text">Already Deliveries File:-</p>
      <div className="row justify-content-md-start justify-content-center">
        {listLoading ? (
          <BubblesLoader />
        ) : (
          jobDeliveriesList &&
          !!jobDeliveriesList.length &&
          jobDeliveriesList.map((item, index) => {
            return (
              <div className="col-md-4 col-sm-6 col-12" key={index}>
                <div className="position-relative">
                  <img
                    className="deliver-img br-10"
                    width="100%"
                    src={item.thumb_path}
                    alt="thumb_path"
                  />
                  <div className="position-absolute top-0 d-flex justify-content-center h-100 w-100 align-items-center">
                    <img
                      className="cursor-pointer"
                      onClick={() => videoHandler(item.file)}
                      src={VideoImg}
                      alt=""
                    />
                  </div>
                </div>
                <p className="common-text text-black pt-3">
                  Delivery-{index + 1}
                </p>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default DeliveredFile;
