import { Accordion } from "react-bootstrap";
import PropertyLandMarineAndBird from "./CommonFields/PropertyLandMarineAndBird";

function LandAnimal({ jobList }) {
  return (
    <Accordion className="footage-accordian">
      {jobList &&
        jobList.animals &&
        jobList.animals.length > 0 &&
        jobList.animals.map((value, index) => {
          return (
            <PropertyLandMarineAndBird
              FootageType={"Land Animals"}
              value={value}
              index={index}
            />
          );
        })}
    </Accordion>
  );
}

export default LandAnimal;
