import CurrentEventForm from "../SignificantJobType/CurrentEventForm";
import LandMarkForm from "../SignificantJobType/LandMarkForm";

const FootageLocation = ({
  significantFormdTypes,
  setSignificantFormTypes,
}) => {
  return (
    <>
      <div className=" mb-2">
        <label for="inputYes/no" className="form-label common-text mb-2">
          Does your footage contain a famous landmark or take place during a
          current world event?
        </label>
        <select
          id="inputYes/no"
          onChange={(e) =>
            setSignificantFormTypes({
              ...significantFormdTypes,
              is_landmark_or_current_world_event: e.target.value,
            })
          }
          className="form-select common-text text-8B8C8D form-control border-b9babb br-3   py-md-3 "
        >
          <option className="common-text text-8B8C8D" selected value="no">
            No
          </option>
          <option className="common-text text-8B8C8D" value="landmark">
            Landmark
          </option>
          <option
            className="common-text text-8B8C8D"
            value="current_world_event"
          >
            Current Event
          </option>
        </select>
      </div>

      {/** ========================== TYPES OF FORM =============================== */}
      {significantFormdTypes.is_landmark_or_current_world_event ===
      "landmark" ? (
        <LandMarkForm
          setSignificantFormTypes={setSignificantFormTypes}
          significantFormdTypes={significantFormdTypes}
        />
      ) : significantFormdTypes.is_landmark_or_current_world_event ===
        "current_world_event" ? (
        <CurrentEventForm
          setSignificantFormTypes={setSignificantFormTypes}
          significantFormdTypes={significantFormdTypes}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default FootageLocation;
