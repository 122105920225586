import React, { useState } from "react";
import { ChoosFileIcon } from "../common/icons/Icon";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  RemoveFileUrlLink,
  sendMessageFile,
} from "../../redux/action/UploadImageAction";
import Loader from "../common/Loader";
import { SendMessagesIcon } from "../common/icons/Icon";
import { useSocket } from "../../redux/hook/socket/SocketProvider";
import { isImage } from "../common/Content";

const ChatInput = ({
  userDetails,
  match,
  startChatUserDetails,
  setWatingMessageList,
  watingMessageArray,
}) => {
  const { id } = match.params;
  const fileUrl = useSelector((state) => state.AuthReducer.fileUrl);
  const dispatch = useDispatch();
  const socket = useSocket();
  const [messages, setMessages] = useState("");
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState("");
  const [uploadImg, setUploadImg] = useState("");
  const [checkImage, setCheckImage] = useState(false);

  const onImageChange = (e) => {
    dispatch(sendMessageFile(e, setLoadingImage));
    const img = URL.createObjectURL(e.target.files[0]);
    setUploadImg(img);
  };

  const messageHandler = () => {
    setMessagesLoading(true);
    if (isImage(fileUrl)) {
      setCheckImage(true);
    } else {
      setCheckImage(false);
    }
    if (socket) {
      const messageValue = {
        message: messages,
        chat: Number(id),
        file: fileUrl,
        receiver: startChatUserDetails.user.id,
        sender: userDetails.user,
        uuid: startChatUserDetails.uuid,
      };
      if (id) {
        socket.send(
          JSON.stringify({
            event: "send-chat-message",
            message: messageValue,
          })
        );
        const dataValue = { uploadImg: uploadImg, checkImage: checkImage };
        const finalResult = Object.assign(messageValue, dataValue);
        watingMessageArray.push(finalResult);
        setWatingMessageList(watingMessageArray);
        setMessages("");
        dispatch(RemoveFileUrlLink());
        setMessagesLoading(false);
      }
    }
  };

  return (
    <div className="msg-send-input w-100 d-flex align-items-center">
      <img
        className="me-xxl-3 me-2 chat-icon-section"
        src={userDetails && userDetails.profile_image_path}
        alt="img"
      />
      <input
        className="fs-16-500 color-8b8c8d border-0 w-100"
        type="text"
        value={messages}
        onChange={(e) => setMessages(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            messageHandler();
          }
        }}
        placeholder="Type a message here..."
      />
      <div className=" float-end d-flex ms-2">
        <button className="Attachment me-2 d-lg-block px-3">
          <input
            type="file"
            hidden
            id="my-input"
            onChange={(e) => onImageChange(e)}
          />
          {loadingImage ? (
            <Loader />
          ) : (
            <label
              htmlFor="my-input"
              className="d-flex align-items-center fs-16-500 color-5d5e60 cursor-pointer justify-content-center"
            >
              {fileUrl !== "" ? (
                "100%"
              ) : (
                <span>
                  <ChoosFileIcon />
                </span>
              )}
            </label>
          )}
        </button>

        <button
          className={`${
            messages === ""
              ? fileUrl === ""
                ? "cursor-not-allowed"
                : false
              : messagesLoading
              ? "cursor-not-allowed"
              : "cursor-pointer"
          } fs-16-600 color-fff send-message d-flex align-items-center justify-content-center`}
          onClick={() => messageHandler()}
          disabled={
            messages === "" ? (fileUrl === "" ? true : false) : messagesLoading
          }
        >
          {messagesLoading ? <Loader /> : <SendMessagesIcon />}
        </button>
      </div>
    </div>
  );
};

export default withRouter(ChatInput);
