import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getJobApplicationList } from "../../../redux/action/client/jobFeed";
import JobApplicationsListModel from "./JobModel/JobApplicationsListModel";

const ViewJobApplicationsModel = ({
  jobId,
  buttonName,
  jobApproved,
  status,
}) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => setShow(false);

  const handleJobApplicationList = () => {
    setShow(true);
    if (jobId) {
      dispatch(getJobApplicationList(setLoading, jobId, setError));
    }
  };

  return (
    <>
      {status === "accepted" ? (
        <button
          onClick={handleJobApplicationList}
          className="atech-primary-button px-3 py-2 my-sm-0 my-3 me-sm-3"
        >
          {buttonName}
        </button>
      ) : (
        ""
      )}

      <JobApplicationsListModel
        handleClose={handleClose}
        loading={loading}
        error={error}
        show={show}
        jobApproved={jobApproved}
        jobId={jobId}
      />
    </>
  );
};

export default ViewJobApplicationsModel;
