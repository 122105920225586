import Swal from "sweetalert2";
import {
  chatListApi,
  DisputesChatListApi,
  getChatMessageListApi,
  StartDisputeForJobApi,
  startMessageUserApi,
  startReportPersonApi,
  getJobListForDispluteApi,
  startDisputesUserDetailsApi,
  getDisputeMessageListApi,
} from "../../api/chat/ClientAuth";

export const GET_CHAT_LIST = "GET_CHAT_LIST";
export const GET_DISPUTES_CHAT_LIST = "GET_DISPUTES_CHAT_LIST";
export const GET_CHAT_MESSAGE_LIST = "GET_CHAT_MESSAGE_LIST";
export const START_MESSAGE_USER_DETAILS = "START_MESSAGE_USER_DETAILS";
export const GET_JOB_LIST_FOR_DISPLUTE = "GET_JOB_LIST_FOR_DISPLUTE";
export const GET_CURRENT_CHAT_MESSAGE_LIST = "GET_CURRENT_CHAT_MESSAGE_LIST";
export const START_DISPUTES_USER_DETAILS = "START_DISPUTES_USER_DETAILS";
export const GET_DISPUTE_MESSAGE_LIST = "GET_DISPUTE_MESSAGE_LIST";
export const GET_CURRENT_DISPUTE_MESSAGE_LIST =
  "GET_CURRENT_DISPUTE_MESSAGE_LIST";

/**
 * chat list api (GET) and dispatch action to store (redux)
 * @param {Object} data
 * @returns
 */
const chatListAction = (data) => ({
  type: GET_CHAT_LIST,
  data,
});

export const getChatList =
  (setLoading, setError, page, setHasMore) => async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await chatListApi(page);
      if (response.success) {
        dispatch(chatListAction({ data: response.data, page: page }));
        setLoading(false);
        if (response.data.length < 10) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
        setError("Oops! Failed to load chat list!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * chat list api (GET) and dispatch action to store (redux)
 * @param {Object} data
 * @returns
 */

export const getCurrentChatMessageListAction = (data) => ({
  type: GET_CURRENT_CHAT_MESSAGE_LIST,
  data,
});

export const getChatMessageListAction = (data) => ({
  type: GET_CHAT_MESSAGE_LIST,
  data,
});

export const getChatMessageList =
  (chatId, setLoading, setError, pageNo, setHasMessageMore) =>
  async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await getChatMessageListApi(chatId, pageNo);
      if (response.success) {
        dispatch(
          getChatMessageListAction({ data: response.data, pageNo: pageNo })
        );
        if (response.data.length < 10) {
          setHasMessageMore(false);
        }
        setLoading(false);
      } else {
        setLoading(false);
        setError("Oops! Failed to load chat message list!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Dispute chat list api (GET) and dispatch action to store (redux)
 * @param {Object} data
 * @returns
 */

export const getCurrentDisputeMessageAction = (data) => ({
  type: GET_CURRENT_DISPUTE_MESSAGE_LIST,
  data,
});

export const getDisputeMessageListAction = (data) => ({
  type: GET_DISPUTE_MESSAGE_LIST,
  data,
});

export const getDisputeMessageList =
  (chatId, setLoading, setError, pageNo, setHasMessageMore) =>
  async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await getDisputeMessageListApi(chatId, pageNo);
      if (response.success) {
        dispatch(
          getDisputeMessageListAction({ data: response.data, pageNo: pageNo })
        );
        if (response.data.length < 10) {
          setHasMessageMore(false);
        }
        setLoading(false);
      } else {
        setLoading(false);
        setError("Oops! Failed to load Dispute chat message list!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * chat list api (GET) and dispatch action to store (redux)
 * @param {Object} data
 * @returns
 */
const startMessageUserAction = (data) => ({
  type: START_MESSAGE_USER_DETAILS,
  data,
});

export const startMessageUserDetails = (chatId) => async (dispatch) => {
  try {
    const response = await startMessageUserApi(chatId);
    if (response.success) {
      dispatch(startMessageUserAction(response.data));
    } else {
      Swal.fire("Oops!", "Please check your network connection", "error");
      setTimeout(Swal.close, 2000);
    }
  } catch (error) {
    Swal.fire("Oops!", "Please check your network connection", "error");
    setTimeout(Swal.close, 2000);
  }
};

/**
 * chat list api (GET) and dispatch action to store (redux)
 * @param {Object} data
 * @returns
 */
const startDisputesUserDetailsAction = (data) => ({
  type: START_DISPUTES_USER_DETAILS,
  data,
});

export const startDisputesUserDetails = (chatId) => async (dispatch) => {
  try {
    const response = await startDisputesUserDetailsApi(chatId);
    if (response.success) {
      dispatch(startDisputesUserDetailsAction(response.data));
    } else {
      Swal.fire("Oops!", "Please check your network connection", "error");
      setTimeout(Swal.close, 2000);
    }
  } catch (error) {
    Swal.fire("Oops!", "Please check your network connection", "error");
    setTimeout(Swal.close, 2000);
  }
};

/**
 * admin dispute chat list api (GET) and dispatch action to store (redux)
 * @param {Object} data
 * @returns
 */
const DisputesChatListAction = (data) => ({
  type: GET_DISPUTES_CHAT_LIST,
  data,
});

export const DisputesChatList =
  (setLoading, setError, page, setHasMore) => async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await DisputesChatListApi(page);
      if (response.success) {
        dispatch(DisputesChatListAction(response.data));
        setLoading(false);
        if (response.data.length < 10) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
        setError("Oops! Failed to load disputes chat list!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * start displute chat api (GET) and dispatch action to store (redux)
 * @param {Object} data
 * @returns
 */

export const StartDisputeForJob =
  (data, setLoading, setError, setShow, setReason) => async () => {
    setLoading(true);
    try {
      setError("");
      const response = await StartDisputeForJobApi(data);
      if (response.success) {
        setLoading(false);
        setReason("");
        setShow(false);
        Swal.fire("Success!", "Dispute started successfully", "success");
        setTimeout(Swal.close, 2000);
      } else {
        setLoading(false);
        setError(
          response &&
            response.response &&
            response.response.data &&
            response.response.data.message
            ? response.response.data.message
            : "Oops! Failed to start disputes with this job!"
        );
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * start displute chat api (GET) and dispatch action to store (redux)
 * @param {Object} data
 * @returns
 */
const getJobListForDispluteAction = (data) => ({
  type: GET_JOB_LIST_FOR_DISPLUTE,
  data,
});

export const getJobListForDisplute =
  (userId, setLoading, setError) => async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await getJobListForDispluteApi(userId);
      if (response.success) {
        dispatch(getJobListForDispluteAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
        setError("Oops! Failed to Job List with this User!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * start report chat api (GET) and dispatch action to store (redux)
 * @param {Object} data
 * @returns
 */

export const startReportPerson =
  (data, setLoading, setError, setShow) => async () => {
    setLoading(true);
    try {
      setError("");
      const response = await startReportPersonApi(data);
      if (response.success) {
        setLoading(false);
        setShow(false);
        Swal.fire(
          "Success!",
          "Person Report has been sent successfully",
          "success"
        );
        setTimeout(Swal.close, 2000);
      } else {
        setLoading(false);
        setError("Oops! Failed to Report Person for this job!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };
