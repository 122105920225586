import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  RemoveFileUrlLink,
  uploadImage,
} from "../../../redux/action/UploadImageAction";
import { UpdateWorkDetails } from "../../../redux/action/creator/CreatorWorkAction";
import AddNewWorkForm from "./AddNewWorkForm";

const WorkPopup = ({ handleClose, show, gearWorkArray, index }) => {
  const dispatch = useDispatch();
  const [loadingImage, setLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const fileUrl = useSelector((state) => state.AuthReducer.fileUrl);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  /**
   * submit work details to server
   * @param {Object} data
   */
  const onSubmit = (data) => {
    setError(true);
    const dataValue = {
      media_link: fileUrl,
      description: data.description,
      title: data.title,
      type: data.type,
    };
    if (!fileUrl) {
      delete dataValue.media_link;
    }
    dispatch(RemoveFileUrlLink());
    dispatch(
      UpdateWorkDetails(
        dataValue,
        setLoading,
        handleClose,
        gearWorkArray[index].id,
        index
      )
    );
  };

  /**
   * upload video file to server and set
   * file url to redux store for later use
   * in submit work details to server
   * function above in onSubmit function
   * above in this file
   * @param {Event} e
   */
  const uploadVedioValue = (e) => {
    const type = "video";
    dispatch(uploadImage(e, setLoadingImage, type));
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p>Edit Work</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-center flex-column ">
            <AddNewWorkForm
              fileUrl={fileUrl}
              uploadVedioValue={uploadVedioValue}
              register={register}
              loadingImage={loadingImage}
              errors={errors}
              loading={loading}
              index={index}
              gearWorkArray={gearWorkArray}
              error={error}
              setError={setError}
              handleClose={handleClose}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default withRouter(WorkPopup);
