import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getChatList } from "../../../redux/action/chat/ClientChat";
import BubblesLoader from "../../common/BubblesLoader";

import ErrorMessage from "../../common/ErrorMessage";
import NoMessages from "../../nullStates/NoMessages";
import DashboardMessageListItem from "./DashboardMessageListItem";

const DashboardMessageList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const chatList = useSelector((state) => state.chat.chatList);

  useEffect(() => {
    const pageNo = 0;
    dispatch(getChatList(setLoading, setError, pageNo, setHasMore));
  }, [dispatch]);

  return (
    <>
      <Col lg={6} className="h-100">
        <section
          className={`${
            chatList.length > 0 && loading === true ? "overflow-auto" : ""
          }  myjob p-4  creator_height  mt-lg-0 mt-4`}
        >
          <p className="common-text">{chatList.length > 0 ? "Messages" : ""}</p>
          <>
            {error !== "" ? <ErrorMessage message={error} /> : ""}

            {loading ? (
              <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                <BubblesLoader />
                <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                  Please wait...
                </p>
              </div>
            ) : chatList && chatList.length > 0 ? (
              chatList.map((item, index) => {
                return (
                  <DashboardMessageListItem
                    item={item}
                    key={index}
                    hasMore={hasMore}
                  />
                );
              })
            ) : (
              <NoMessages message={"You have no message"} />
            )}
          </>
        </section>
      </Col>
    </>
  );
};

export default DashboardMessageList;
