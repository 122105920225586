import React, { useRef } from "react";
import ChatInput from "./ChatInput";
import SenderMessage from "./chatMsg/SenderMessage";
import MyMessage from "./chatMsg/MyMessage";
import ChatHeader from "./ChatHeader";
import NoMessages from "../nullStates/NoMessages";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import BubblesLoader from "../common/BubblesLoader";
import JoinChat from "../../redux/hook/socket/JoinChat";

const ChatMessage = ({
  setClickOnChat,
  setShowChat,
  chatMessageList,
  startChatUserDetails,
  hasMessageMore,
  pageMessageChangeHandler,
}) => {
  const { setWatingMessageList, watingMessageArray } = JoinChat();
  const timelineRef = useRef();
  const userDetails = useSelector((state) => state.AuthReducer.userDetails);
  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      {/* CHAT HEADER  */}
      <ChatHeader
        startChatUserDetails={startChatUserDetails}
        setClickOnChat={setClickOnChat}
        setShowChat={setShowChat}
      />
      {/* <!-- CHAT BOX --> */}
      {chatMessageList && chatMessageList.length > 0 ? (
        <div
          className={`my-xxl-3 my-2 mt-xxl-4 d-flex flex-column-reverse pe-xxl-5  overflow-auto chat-height pe-3 chat-box`}
          id="chat-message-scroll-div"
          style={{
            flexDirection: "column-reverse",
          }}
          ref={timelineRef}
        >
          <InfiniteScroll
            dataLength={chatMessageList.length}
            next={pageMessageChangeHandler}
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              overflowX: "hidden",
            }} //To put endMessage and loader to the top.
            inverse={true}
            hasMore={hasMessageMore}
            loader={
              <div className="d-flex justify-content-center">
                <BubblesLoader />
              </div>
            }
            scrollableTarget="chat-message-scroll-div"
          >
            {chatMessageList.map((value, index) => (
              <div key={index}>
                {value.sender === userDetails.user ? (
                  <MyMessage value={value} />
                ) : (
                  <SenderMessage value={value} />
                )}
              </div>
            ))}
          </InfiniteScroll>
          {/**{watingMessageList &&
            watingMessageList.length > 0 &&
            watingMessageList.map((value, index) => {
              return (
                <div
                  key={index}
                  inverse={true}
                  style={{
                    display: "flex",
                    // flexDirection: "column-reverse",
                    overflowX: "hidden",
                  }}
                >
                  <WatingMessageList value={value} />
                </div>
              );
            })} */}
        </div>
      ) : (
        <NoMessages
          message={`Here you can send messages and share files with @${
            startChatUserDetails &&
            startChatUserDetails.user &&
            startChatUserDetails.user.first_name
              ? startChatUserDetails &&
                startChatUserDetails.user &&
                startChatUserDetails.user.first_name +
                  " " +
                  startChatUserDetails.user.last_name
              : startChatUserDetails &&
                startChatUserDetails.user &&
                startChatUserDetails.user.username
          }`}
        />
      )}
      {/* MESSAGE INPUT  */}
      <ChatInput
        userDetails={userDetails}
        startChatUserDetails={startChatUserDetails}
        setWatingMessageList={setWatingMessageList}
        watingMessageArray={watingMessageArray}
      />
    </div>
  );
};
export default ChatMessage;
