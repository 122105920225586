import React from "react";
import uploadImg from "../../../assets/img/svg/upload-img.svg";
import Loader from "../../common/Loader";

function AddNewWorkForm({
  fileUrl,
  setError,
  uploadVedioValue,
  register,
  loadingImage,
  errors,
  loading,
  gearWorkArray,
  handleClose,
}) {
  return (
    <div className="container-fluid">
      <div className="row atech-border br-10 justify-content-center ">
        <div className="col-11 col-lg-4 mt-4 mt-lg-0 p-4 p-lg-0 bg-F8F8F8 atech-border d-flex flex-column justify-content-center align-items-center">
          {fileUrl ? (
            <>
              <div className=" pt-2 text-center w-100">
                <input
                  onChange={(e) => uploadVedioValue(e)}
                  type="file"
                  id="my-file"
                  accept="video/mp4"
                  disabled={loadingImage}
                  hidden
                />
                <button type="button" className="py-2 px-3 atech-fade-button ">
                  <label
                    for="my-file"
                    className={`${
                      loadingImage ? "cursor-not-allowed" : "cursor-pointer"
                    } text-wrap-css `}
                  >
                    <span className="me-2">
                      <img
                        className=" px-2 top-22"
                        src={uploadImg}
                        alt="uploadImg"
                      />
                    </span>
                    {loadingImage ? "Loading..." : fileUrl}
                  </label>
                </button>
              </div>
            </>
          ) : (
            <div className=" pt-2 text-center">
              <input
                onChange={(e) => uploadVedioValue(e)}
                type="file"
                id="my-file"
                accept="video/mp4"
                disabled={loadingImage}
                hidden
              />
              <button type="button" className="py-2 px-3 atech-fade-button ">
                <label
                  for="my-file"
                  className={`${
                    loadingImage ? "cursor-not-allowed" : "cursor-pointer"
                  } text-wrap-css `}
                >
                  <span className="me-2">
                    <img
                      className=" px-2 top-22"
                      src={uploadImg}
                      alt="uploadImg"
                    />
                  </span>
                  {loadingImage ? "Loading..." : "Upload Video"}
                </label>
              </button>
            </div>
          )}
        </div>
        <div className="col-12 col-lg-8 my-sm-4 my-2 py-2 px-sm-3 px-2">
          <div className="mt-2">
            <div>
              <input
                {...register("title", { required: true })}
                type="text"
                className="form-control py-3 atech-input"
                placeholder="Enter Title"
                aria-describedby="emailHelp"
                gearWorkArray={gearWorkArray && gearWorkArray.title}
              />
              {errors.title?.type === "required" && (
                <p className="common-text xxs-font text-red mb-0">
                  Title is required
                </p>
              )}
            </div>
          </div>
          <div className="mt-2">
            <div>
              <input
                {...register("description", { required: true })}
                type="text"
                className="form-control py-3 atech-input"
                placeholder="Description(max 100 words)"
                aria-describedby="emailHelp"
              />
              {errors.description?.type === "required" && (
                <p className="common-text xxs-font text-red">
                  Description is required
                </p>
              )}
            </div>
          </div>
          <div className="d-flex flex-sm-row  flex-column mt-3">
            <button
              type="submit"
              disabled={loading}
              className={`${
                loading ? "cursor-not-allowed" : "cursor-pointer"
              } px-4 atech-primary-button  py-sm-2 py-2 ms-sm-2  my-2`}
              onClick={() => setError(true)}
            >
              {loading ? <Loader /> : "Save Work"}
            </button>
            <button
              type="button"
              onClick={() => handleClose()}
              className="px-4  py-sm-2 py-2 ms-sm-2  my-2  atech-fade-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddNewWorkForm;
