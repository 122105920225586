import { CreatordashboardDetailsApi } from "../../api/creator/CreatorWorkApi";

export const CREATOR_DASHBOARD_DETAILS = "CREATOR_DASHBOARD_DETAILS";

/**
 * Get user work list action
 * @param {Object} data
 * @returns
 */
const CreatorDashboard = (data) => ({
  type: CREATOR_DASHBOARD_DETAILS,
  data,
});

export const CreatordashboardDetails =
  (setLoading, setError) => async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await CreatordashboardDetailsApi();
      if (response.success) {
        dispatch(CreatorDashboard(response.data));
        setLoading(false);
      } else {
        setLoading(false);
        setError("Oops! Failed to load dashboard details");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };
