import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { requestChangesAction } from "../../../../redux/action/client/Job";
import Loader from "../../../common/Loader";
import ErrorMessage from "../../../common/ErrorMessage";

const RequestChangesModel = ({
  setRequestChanges,
  requestChanges,
  match,
  loading,
  setModelError,
  modelError,
  requestData,
  setRequestData,
  setLoading,
  clientJobViewDetails,
}) => {
  const { jobId } = match.params;
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const detailHandler = () => {
    setModelError(true);
    const data = {
      job: parseInt(jobId),
      application: parseInt(clientJobViewDetails.application_id),
      details: requestData,
    };
    if (data.job && data.application && data.details !== "") {
      dispatch(
        requestChangesAction(
          data,
          setLoading,
          setRequestChanges,
          setRequestData,
          setModelError,
          setError
        )
      );
    }
  };
  return (
    <Modal
      show={requestChanges}
      size="lg"
      onHide={() => setRequestChanges(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p className="common-heading-Medium md-font text-dark-black mb-0">
          Request Changes
        </p>
        <div className="border-bottom my-3"></div>
        {error !== "" ? <ErrorMessage message={error} /> : ""}
        {modelError && requestData === "" ? (
          <ErrorMessage message="Explain Changes is Required" />
        ) : (
          ""
        )}
        <div className="mt-4 mb-3">
          <Form.Group
            className=" textarea-focus"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Control
              placeholder="Explain your Changes"
              as="textarea"
              onChange={(e) => setRequestData(e.target.value)}
              value={requestData}
              rows={5}
            />
          </Form.Group>
        </div>
        <div className="d-flex flex-sm-row  flex-column mt-3">
          <button
            className="px-4 atech-primary-button  py-sm-2 py-2 ms-sm-2  my-2"
            onClick={() => detailHandler()}
            disabled={loading}
          >
            {loading ? <Loader /> : "Send Request"}
          </button>
          <button
            className="px-4  py-sm-2 py-2 ms-sm-2  my-2  atech-fade-button"
            onClick={() => setRequestChanges(false)}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(RequestChangesModel);
