import React from "react";
import AdminATechHeader from "../../components/common/AdminATechHeader";
import NotificationTable from "../../components/common/notification/NotificationTable";

function AdminNotification() {
  return (
    <div className="ff-Project page-color">
      <AdminATechHeader HeaderName={"Notifications"} />
      <NotificationTable />
    </div>
  );
}

export default AdminNotification;
