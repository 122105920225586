import React from "react";
import { useSelector } from "react-redux";
import EmptyCard from "../../../common/EmptyCard";
import VideoPlayModal from "../../../creator/creatorProfile/VideoPlayModal";

function AdminUserGearsDetail() {
  const adminUserGearsDetails = useSelector(
    (state) => state.AdminAcountsReducer.adminUserGearsDetails
  );
  return (
    <>
      <p className="my-4 common-heading-Medium  sm-font">My Gear</p>
      {adminUserGearsDetails && adminUserGearsDetails.length > 0 ? (
        adminUserGearsDetails.map((item, index) => {
          return (
            <div className="box-shadow my-3" key={index}>
              <div className="row justify-content-xl-start justify-content-center">
                <div className="col-xl-3 col-md-5 col-sm-7">
                  <VideoPlayModal item={item} type={"gear"} />
                </div>
                <div className="col-xl-9 col-12 d-flex justify-content-center flex-column py-4">
                  <p className="common-heading-Medium mb-0 sm-font px-xl-0 px-sm-4 px-2">
                    {item.title}
                  </p>
                  <p className="jobs-client px-xl-0 px-sm-4 px-2 mt-2 mb-2">
                    {item.type}
                  </p>
                  <p className="common-text max-w-956 px-xl-0 px-sm-4 px-2">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyCard message={"User don't have any Gears Details Yet!"} />
      )}
    </>
  );
}

export default AdminUserGearsDetail;
