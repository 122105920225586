import { axiosRequest } from "../../helper";

/**
 * Chat list api (GET) and dispatch action to store (redux)
 * @returns
 */
export const chatListApi = async (page) => {
  return await axiosRequest("GET", `/chats/?offset=${page * 10}&&limit=10`);
};

/**
 * Chat message list api (GET) and dispatch action to store (redux)
 * @returns
 */
export const getChatMessageListApi = async (chatId, pageNo) => {
  return await axiosRequest(
    "GET",
    `/chats/${chatId}/messages/?offset=${pageNo * 20}&&limit=20`
  );
};

/**
 * Chat message list api (GET) and dispatch action to store (redux)
 * @returns
 */
export const getDisputeMessageListApi = async (chatId, pageNo) => {
  return await axiosRequest(
    "GET",
    `/disputes/${chatId}/messages/?offset=${pageNo * 20}&&limit=20`
  );
};

/**
 * start message chat user details api
 * @returns
 */
export const startMessageUserApi = async (chatId) => {
  return await axiosRequest("GET", `/chats/${chatId}/`);
};

/**
 * start message chat user details api
 * @returns
 */
export const startDisputesUserDetailsApi = async (chatId) => {
  return await axiosRequest("GET", `/disputes/${chatId}/`);
};

/**
 * Disputes Chat List api (GET) and dispatch action to store (redux)
 * @returns
 */
export const DisputesChatListApi = async (page) => {
  return await axiosRequest("GET", `/disputes/?offset=${page}&&limit=10`);
};

/**
 * start dispute chat for job with user api
 * @param {Object} data
 * @returns
 */
export const StartDisputeForJobApi = async (data) => {
  return await axiosRequest("POST", `/jobs/disputes/`, data);
};

/**
 * get job between user for start dispute chat for job with user api
 * @param {STRING} userId
 * @returns
 */
export const getJobListForDispluteApi = async (userId) => {
  return await axiosRequest("GET", `/jobs/${userId}/mutual-jobs/`);
};

/**
 * start reporting person for job api
 * @param {Object} data
 * @returns
 */
export const startReportPersonApi = async (data) => {
  return await axiosRequest("POST", `/users/reports/`, data);
};
