import React from "react";
import ChatDropDown from "./ChatDropDown";
import { useMediaQuery } from "react-responsive";
import { BackIconImg } from "./../common/icons/Icon";
const ChatHeader = ({ setClickOnChat, startChatUserDetails, setShowChat }) => {
  const closeHandler = () => {
    setClickOnChat((pre) => !pre);
    setShowChat(false);
  };

  const BeforeDesktop = ({ children }) => {
    const isBeforeDesktop = useMediaQuery({ maxWidth: 991.98 });
    return isBeforeDesktop ? children : null;
  };
  return (
    <>
      <div className="mt-16 mt-2 d-flex align-items-center justify-content-between  border-bottom-e8e8e8 pb-3 mb-xxl-2 mb-1">
        <div>
          <img
            className=" me-sm-3 me-1 messages-image"
            src={
              startChatUserDetails &&
              startChatUserDetails.user &&
              startChatUserDetails.user.proile_image_path &&
              startChatUserDetails.user.proile_image_path
            }
            alt=""
          />
          <span className="fs-25-600 color-17191c text-wrap-css">
            {startChatUserDetails &&
            startChatUserDetails.user &&
            startChatUserDetails.user.first_name
              ? startChatUserDetails &&
                startChatUserDetails.user &&
                startChatUserDetails.user.first_name +
                  " " +
                  startChatUserDetails.user.last_name
              : startChatUserDetails &&
                startChatUserDetails.user &&
                startChatUserDetails.user.username}
          </span>
        </div>

        <div className="d-flex align-items-center">
          <BeforeDesktop>
            <span
              onClick={() => closeHandler()}
              className="px-sm-2 cursor-pointer"
            >
              <BackIconImg />
            </span>
          </BeforeDesktop>
          <ChatDropDown startChatUserDetails={startChatUserDetails} />
        </div>
      </div>
    </>
  );
};

export default ChatHeader;
