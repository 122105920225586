import { axiosRequest } from "../../helper";

/**
 * Get Admin all user list api
 * @param {Object} data
 * @returns
 */
export const getAdminAllUserListApi = async (type, page) => {
  return await axiosRequest(
    "GET",
    `/users/all/?type=${type}&&offset=${page * 10}&limit=10`
  );
};

/**
 * Get all user profile details api
 * @param {String} userId
 * @returns
 */
export const adminUserProfileDetailsApi = async (userId) => {
  return await axiosRequest("GET", `/users/all/${userId}/`);
};

/**
 * Get all user work details api
 * @param {String} userId
 * @returns
 */
export const adminUserWorkDetailsApi = async (userId) => {
  return await axiosRequest("GET", `/users/works/?user_id=${userId}`);
};

/**
 * Get all user Gears details api
 * @param {String} userId
 * @returns
 */
export const adminUserGearsDetailsApi = async (userId) => {
  return await axiosRequest("GET", `/users/gears/?user_id=${userId}`);
};

/**
 * lock user Account api
 * @param {Object} data
 * @param {String} userId
 * @returns
 */
export const lockUserAccountApi = async (userId, data) => {
  return await axiosRequest("PATCH", `/admin/users/${userId}/`, data);
};

/**
 * start chat with user api
 * @param {Object} data
 * @param {String} userId
 * @returns
 */
export const startSendMessageApi = async (data) => {
  return await axiosRequest("POST", `/chats/`, data);
};

/**
 * Get dashboard chat details api
 * @returns
 */
export const getDashboardChatDetailsApi = async () => {
  return await axiosRequest("GET", `/admin/dashboard/`);
};

/**
 * Get job report api for admin api
 * @returns
 */
export const getReportJobListApi = async (page) => {
  return await axiosRequest(
    "GET",
    `/jobs/reports/?offset=${page * 10}&limit=10`
  );
};

/**
 * Get job report api for admin api
 * @returns
 */
export const getReportJobDetailsApi = async (jobId) => {
  return await axiosRequest("GET", `/jobs/reports/${jobId}/`);
};

/**
 * Get job report api for admin api
 * @returns
 */
export const getReportUserListApi = async () => {
  return await axiosRequest("GET", `/users/reports/`);
};

/**
 * Get job report api for admin api
 * @returns
 */
export const getReportUserDetailsApi = async (jobId) => {
  return await axiosRequest("GET", `/users/reports/${jobId}/`);
};
