import { Accordion } from "react-bootstrap";
import PropertyLandMarineAndBird from "./CommonFields/PropertyLandMarineAndBird";

function Property({ jobList }) {
  return (
    <Accordion className="footage-accordian">
      {jobList &&
        jobList.property &&
        jobList.property.length > 0 &&
        jobList.property.map((value, index) => {
          return (
            <PropertyLandMarineAndBird
              FootageType={"Property"}
              value={value}
              index={index}
            />
          );
        })}
    </Accordion>
  );
}

export default Property;
