import React, { useState, useEffect } from "react";
import Crossicon from "../../../../assets/img/svg/crossIcon.svg";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllJobsDetailsList } from "../../../../redux/action/client/Job.jsx";
import BubblesLoader from "../../../common/BubblesLoader";
import ErrorMessage from "../../../common/ErrorMessage";
import Reporter from "./FootageElements/Reporter";
import Extras from "./FootageElements/Extras";
import Model from "./FootageElements/Model";
import Actors from "./FootageElements/Actors";
import LandAnimal from "./FootageElements/LandAnimal";
import MarineAnimal from "./FootageElements/MarineAnimal";
import AddBird from "./FootageElements/AddBird";
import Property from "./FootageElements/Property";
import GreenScreenBg from "./FootageElements/GreenScreenBg";
import AddCGI from "./FootageElements/AddCGI";
import PracticalEffects from "./FootageElements/PracticalEffects";
import TreePlants from "./FootageElements/TreePlants";
import Building from "./FootageElements/Building";
import Vehicle from "./FootageElements/Vehicle";
import Location from "./FootageElements/Location";
import EmptyCard from "../../../common/EmptyCard";

function FootageElementModel({ footageModal, setFootageModal, jobId }) {
  const dispatch = useDispatch();
  const [footageLoading, setFootageLoading] = useState(false);
  const [error, setError] = useState("");
  const jobList = useSelector((state) => state.jobs.jobList);

  useEffect(() => {
    dispatch(getAllJobsDetailsList(jobId, setFootageLoading, setError));
  }, [dispatch, jobId]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={footageModal}
      onHide={() => setFootageModal(false)}
    >
      <Modal.Header className="d-flex flex-column">
        <div className=" w-100">
          {error !== "" ? <ErrorMessage message={error} /> : ""}
        </div>
        <Modal.Title id="contained-modal-title-vcenter" className="w-100">
          <div className="d-flex justify-content-between">
            <h5 className="md-font f-w-600 text-black common-heading-Medium">
              Footage Elements
            </h5>
            <img
              className="cursor-pointer"
              onClick={() => setFootageModal(false)}
              src={Crossicon}
              alt=""
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      {footageLoading ? (
        <div className="h-100 d-flex justify-content-center align-items-center flex-column py-5">
          <BubblesLoader />
          <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
            Please wait...
          </p>
        </div>
      ) : (
        <Modal.Body>
          {jobList && jobList === null ? (
            <EmptyCard message={"You’ve not any Footage Elemetns List Yet!"} />
          ) : (
            <>
              <Location jobList={jobList} />
              <Actors jobList={jobList} />
              <Reporter jobList={jobList} />
              <Model jobList={jobList} />
              <Extras jobList={jobList} />
              <Property jobList={jobList} />
              <Vehicle jobList={jobList} />
              <TreePlants jobList={jobList} />
              <Building jobList={jobList} />
              <LandAnimal jobList={jobList} />
              <MarineAnimal jobList={jobList} />
              <AddBird jobList={jobList} />
              <GreenScreenBg jobList={jobList} />
              <AddCGI jobList={jobList} />
              <PracticalEffects jobList={jobList} />
            </>
          )}
        </Modal.Body>
      )}
    </Modal>
  );
}

export default FootageElementModel;
