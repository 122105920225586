import React from "react";
import { Route } from "react-router-dom";
import AdminSidebar from "../components/admin/sidebar/AdminSidebar";
import AdminNotification from "../view/notifications/AdminNotification";
import AdminDashbord from "../view/admin/AdminDashbord";
import AdminAccounts from "../view/admin/account/AdminAccounts";
import ChatBox from "../components/chat/ChatBox";
import CreateAdminDisputesChat from "../components/chat/AdminDisputesChat/CreateAdminDisputesChat";
import Jobs from "../view/admin/jobs/Jobs";
import AdminCreatorProfile from "../view/admin/account/AdminCreatorProfile";
import AdminClientProfile from "../view/admin/account/AdminClientProfile";
import JobReportContainer from "../view/admin/report/JobReportContainer";
import UserReportContainer from "../view/admin/report/UserReportContainer";
import ViewJobReport from "../view/admin/report/ViewJobReport";
import UserReportView from "../view/admin/report/UserReportView";
import AdminProfile from "../view/admin/account/AdminProfile";
import PayOut from "../view/admin/PayOut";
import OnGoingJob from "../view/client/job/OnGoingJob";
import AdminViewJobs from "../view/admin/jobs/AdminViewJobs";

function AdminRoutes({ userDetailsRole }) {
  return (
    <div className="h-100vh d-flex">
      <AdminSidebar />
      <div className="dashboard-section h-screen ">
        <Route exact path="/admin/notifications">
          <AdminNotification />
        </Route>
        <Route exact path="/admin/jobs/:type">
          <Jobs />
        </Route>
        <Route exact path="/">
          <AdminDashbord userDetailsRole={userDetailsRole} />
        </Route>
        <Route exact path="/payout">
          <PayOut />
        </Route>
        <Route exact path="/admin/accounts/:type">
          <AdminAccounts />
        </Route>
        <Route exact path="/admin/job/report">
          <JobReportContainer />
        </Route>
        <Route exact path="/admin/user/report">
          <UserReportContainer />
        </Route>
        <Route exact path="/admin/job/report/view/:jobId">
          <ViewJobReport />
        </Route>
        <Route exact path="/admin/user/report/view/:jobId">
          <UserReportView />
        </Route>
        <Route exact path="/admin/chat/:id">
          <ChatBox />
        </Route>
        <Route exact path="/admin/disputes/:id">
          <CreateAdminDisputesChat />
        </Route>
        <Route exact path="/admin/jobs/view/:jobId">
          <AdminViewJobs />
        </Route>
        <Route exact path="/admin/completed-job/:jobId">
          <OnGoingJob />
        </Route>
        <Route exact path="/admin/creator/profile/:userId">
          <AdminCreatorProfile />
        </Route>
        <Route exact path="/admin/client/profile/:userId">
          <AdminClientProfile />
        </Route>
        <Route exact path="/admin/profile">
          <AdminProfile />
        </Route>
      </div>
    </div>
  );
}

export default AdminRoutes;
