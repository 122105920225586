import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  ReportPersonIcon,
  ThreeDotsIcon,
  ViewJobIcon,
} from "../common/icons/Icon";
import ReportPerson from "./popup/ReportPerson";
import ViewAllJobModel from "./ViewAllJobModel";

const ChatDropDown = ({ startChatUserDetails }) => {
  const [showReport, setShowReport] = useState(false);
  const [show, setShow] = useState(false);
  return (
    <>
      <Dropdown className="three-dots">
        <Dropdown.Toggle
          className="   float-end border-0 bg-transparent py-0 "
          id="dropdown-basic"
        >
          <ThreeDotsIcon />
        </Dropdown.Toggle>

        <Dropdown.Menu className="common-heading smll-font">
          <Dropdown.Item href="#/action-1" onClick={() => setShowReport(true)}>
            <span className="mr-20 dropdown-icon">
              <ReportPersonIcon />
            </span>
            <span>Report Person</span>
          </Dropdown.Item>
          <Dropdown.Item href="#/action-2" onClick={() => setShow(true)}>
            <span className="mr-20 dropdown-icon">
              <ViewJobIcon />
            </span>
            <span>View Job</span>
          </Dropdown.Item>
        </Dropdown.Menu>
        <ReportPerson
          setShowReport={setShowReport}
          showReport={showReport}
          startChatUserDetails={startChatUserDetails}
        />
      </Dropdown>
      <ViewAllJobModel
        show={show}
        setShow={setShow}
        userId={
          startChatUserDetails &&
          startChatUserDetails.user &&
          startChatUserDetails.user.id
        }
      />
    </>
  );
};

export default ChatDropDown;
