import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ShowEye from "../../assets/img/svg/eye-icon.svg";
import Eye from "../../assets/img/png/Eye.png";
import { ChangePasswordAction } from "../../redux/action/auth/AuthAction";
import { withRouter } from "react-router-dom";
import ErrorMessage from "../common/ErrorMessage";

function ChangePasswordForm({ history }) {
  const [oldPasswordhide, setOldPasswordHide] = useState(false);
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState(false);
  const [statedata, setStatedata] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    var passwordValues = {
      old_password: data.oldPassword,
      new_password: data.newPassword,
    };
    setStatedata(data);
    dispatch(
      ChangePasswordAction(passwordValues, setLoading, history, setError)
    );
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error !== "" ? <ErrorMessage message={error} /> : ""}
      <div className=" position-relative my-2">
        <input
          type={oldPasswordhide ? "text" : "password"}
          {...register("oldPassword", {
            required: true,
            minLength: {
              value: 8,
              message: "Old must have at least 8 characters",
            },
          })}
          className="form-control py-3 atech-input"
          placeholder="Old Password"
          aria-describedby="emailHelp"
        />
        {oldPasswordhide ? (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setOldPasswordHide(!oldPasswordhide)}
            src={Eye}
            alt="eye-img"
          />
        ) : (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setOldPasswordHide(!oldPasswordhide)}
            src={ShowEye}
            alt="eye-img"
          />
        )}
        {errors.oldPassword?.type === "required" && (
          <p className="common-text xxs-font text-red">
            Old Password field is required
          </p>
        )}
        {errors.oldPassword?.type === "minLength" && (
          <p className="common-text xxs-font text-red">
            {errors.oldPassword.message}
          </p>
        )}
      </div>

      <div className=" position-relative my-2">
        <input
          type={newPassword ? "text" : "password"}
          {...register("newPassword", {
            required: true,
            minLength: {
              value: 8,
              message: "Password must have at least 8 characters",
            },
          })}
          className="form-control py-3 atech-input"
          placeholder="New Password"
          aria-describedby="emailHelp"
        />
        {newPassword ? (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setNewPassword(!newPassword)}
            src={Eye}
            alt="eye-img"
          />
        ) : (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setNewPassword(!newPassword)}
            src={ShowEye}
            alt="eye-img"
          />
        )}
        {errors.newPassword?.type === "required" && (
          <p className="common-text xxs-font text-red">
            New Password field is required
          </p>
        )}
        {errors.newPassword?.type === "minLength" && (
          <p className="common-text xxs-font text-red">
            {errors.newPassword.message}
          </p>
        )}
      </div>
      <div className=" position-relative my-2">
        <input
          type={confirmPassword ? "text" : "password"}
          className="form-control py-3 atech-input"
          {...register("confirmPassword", {
            required: true,
            minLength: {
              value: 8,
            },
          })}
          placeholder="Confirm New Password"
          aria-describedby="emailHelp"
        />
        {confirmPassword ? (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setConfirmPassword(!confirmPassword)}
            src={Eye}
            alt="eye-img"
          />
        ) : (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setConfirmPassword(!confirmPassword)}
            src={ShowEye}
            alt="eye-img"
          />
        )}
        {errors.confirmPassword?.type === "required"}
        {errors.confirmPassword?.type === "required" ? (
          <p className="common-text xxs-font text-red">
            ConfirmPassword field is required
          </p>
        ) : statedata.confirmPassword !== statedata.newPassword ? (
          <p className="common-text xxs-font text-red">Password not match</p>
        ) : errors.confirmPassword?.type === "minLength" ? (
          <p className="common-text xxs-font text-red">
            Password must have at least 8 characters
          </p>
        ) : (
          ""
        )}
      </div>

      {/* Next  Button */}
      <div>
        <button
          type="submit"
          className=" w-100 mt-sm-2 mt-1  atech-primary-button  py-sm-3 px-4 py-2"
        >
          {loading ? "Loading" : "Change Password"}
        </button>
      </div>
    </form>
  );
}
export default withRouter(ChangePasswordForm);
