import React from "react";
import Pending from "./Pending";
import TotalEarning from "./TotalEarning";
import Withdraw from "./Withdraw";

const EarningCards = ({ loading, creatorEarning }) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <TotalEarning creatorEarning={creatorEarning} loading={loading} />
        <Withdraw creatorEarning={creatorEarning} loading={loading} />
        <Pending creatorEarning={creatorEarning} loading={loading} />
      </div>
    </div>
  );
};

export default EarningCards;
