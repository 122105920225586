import React from "react";
import { withRouter } from "react-router";

const AccountsListItem = ({ accountData, history, type }) => {
  const { profile_image_path, first_name, last_name, id } = accountData;

  return (
    <>
      <div className="br-10 notification-section p-2 my-3">
        <div className="d-flex align-items-center justify-content-between ">
          <div className=" d-flex align-items-center pe-0">
            <span>
              <img
                className="notification-user-img "
                src={profile_image_path}
                alt="notifyimg"
              />
            </span>
            <div className="ps-sm-3 ps-2">
              <p className="mb-0 common-text text-black wrap f-w-600 ">
                {first_name} {last_name}
              </p>
            </div>
          </div>
          <div className=" ps-0">
            <button
              className="px-sm-3 px-2 py-2  atech-fade-button"
              onClick={() =>
                history.push(
                  type === "creator"
                    ? `/admin/creator/profile/${id}`
                    : `/admin/client/profile/${id}`
                )
              }
            >
              <span className=" d-inline-block fs-Montserrat ">
                View Profile
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(AccountsListItem);
