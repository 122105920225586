import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getReleaseFundsList,
  updatePaymentStatus,
} from "../../../redux/action/client/JobForm";
import BubblesLoader from "../../common/BubblesLoader";
import EmptyCard from "../../common/EmptyCard";
import ErrorMessage from "../../common/ErrorMessage";
import PayOutListDetails from "./PayOutListDetails";
import { Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";

const PayOutList = (match) => {
  const { search } = match.location;
  const searchValue = search && search.split("=");
  const SESSION_ID =
    searchValue && searchValue[1] && searchValue[1].split("&")[0];
  const payoutId = searchValue[2];

  const dispatch = useDispatch();
  const releaseFundsList = useSelector(
    (state) => state.AdminAcountsReducer.releaseFundsList
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const pageChangeHandler = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    dispatch(getReleaseFundsList(setLoading, setError, setHasMore, page));
  }, [dispatch, page]);

  useEffect(() => {
    if (!!payoutId && !!SESSION_ID) {
      const data = {
        payout_id: SESSION_ID,
        status: "paid",
      };
      dispatch(updatePaymentStatus(data, payoutId, setLoading, setError));
    }
  }, [dispatch, page, SESSION_ID, payoutId]);
  const releaseFundsListArray =
    page === 0 && loading ? (
      <div className="h-100 d-flex justify-content-center align-items-center flex-column">
        <BubblesLoader />
        <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
          Please wait...
        </p>
      </div>
    ) : releaseFundsList && !!releaseFundsList.length ? (
      <Table responsive>
        <thead>
          <tr>
            <th className="white-space-nowrap"> Creator Name</th>
            <th className="text-center white-space-nowrap">Buyer Name</th>
            <th className="text-center white-space-nowrap">Job Title</th>
            <th className="text-center white-space-nowrap">Status</th>
            <th className="text-end white-space-nowrap">Action</th>
          </tr>
        </thead>
        <tbody>
          {releaseFundsList.map((item, index) => {
            return (
              <PayOutListDetails key={index} item={item} loading={loading} />
            );
          })}
        </tbody>
      </Table>
    ) : (
      <EmptyCard message={"You’ve not any Release Fund list yet!"} />
    );

  return (
    <div className="bg-white box-shadow p-sm-4  mx-3 mt-4 br-10">
      <div className="container-fluid">
        <div className="d-flex flex-column">
          {error !== "" ? <ErrorMessage message={error} /> : ""}
          <div className=" mt-4 notification-scroll " id="release-funds-list">
            <div className="infinite_scroll h-100">
              <InfiniteScroll
                className={`${
                  (page === 0 && loading) || releaseFundsList.length === 0
                    ? "h-100"
                    : ""
                } scrollbar_desgin`}
                dataLength={releaseFundsList.length}
                next={pageChangeHandler}
                hasMore={hasMore}
                scrollableTarget="release-funds-list"
                loader={
                  page > 0 && loading ? (
                    <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                      <BubblesLoader />
                    </div>
                  ) : (
                    ""
                  )
                }
              >
                {releaseFundsListArray}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PayOutList);
