import { Accordion } from "react-bootstrap";
import TreeAndBuilding from "./CommonFields/TreeAndBuilding";

function TreePlants({ jobList }) {
  return (
    <Accordion className="footage-accordian">
      {jobList &&
        jobList.trees &&
        jobList.trees.length > 0 &&
        jobList.trees.map((value, index) => {
          return (
            <TreeAndBuilding
              FootageType={"Tree/Plants"}
              value={value}
              index={index}
            />
          );
        })}
    </Accordion>
  );
}

export default TreePlants;
