import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  RemoveFileUrlLink,
  sendMessageFile,
} from "../../../redux/action/UploadImageAction";
import SignUpAddGearForm from "./SignUpAddGearForm";

function SignUpAddGear({
  gearWorkArray,
  handleClose,
  setShow,
  openEditFormData,
  index,
}) {
  const dispatch = useDispatch();
  const [loadingImage, setLoadingImage] = useState(false);
  const [error, setError] = useState(false);
  const fileUrl = useSelector((state) => state.AuthReducer.fileUrl);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: openEditFormData && openEditFormData,
  });

  const onSubmit = (data) => {
    setError(true);
    if (!!index) {
      gearWorkArray.splice(index, 1, data);
      handleClose();
    } else if (setShow) {
      if (fileUrl) {
        const dataValue = {
          media_link: fileUrl,
          description: data.description,
          title: data.title,
          type: data.type,
        };
        gearWorkArray.push(dataValue);
        dispatch(RemoveFileUrlLink());
        setShow(false);
      } else {
        Swal.fire("Opps!", "Please upload any gear video", "error");
      }
    }
  };

  const uploadVedioValue = (e) => {
    dispatch(sendMessageFile(e, setLoadingImage));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-center flex-column ">
        <SignUpAddGearForm
          fileUrl={fileUrl}
          uploadVedioValue={uploadVedioValue}
          register={register}
          handleClose={handleClose}
          loadingImage={loadingImage}
          errors={errors}
          error={error}
          setError={setError}
          openEditFormData={openEditFormData}
        />
      </div>
    </form>
  );
}
export default SignUpAddGear;
