import React from "react";
import { getTime } from "../../common/utils/Functions";

const AdminDashboardJobList = ({ item, history }) => {
  return (
    <div
      className="inside-box-myjob p-3 mb-3 cursor-pointer"
      onClick={() => history.push(`/admin/jobs/view/${item.id}`)}
    >
      <div className="inside-box-head text-wrap-css">{item.title}</div>
      <div className="inside-box-content pt-2">
        Posted {getTime(item.created_at)}
      </div>
      <div className="dollars pt-2 text-wrap-css">${item.price}</div>
    </div>
  );
};

export default AdminDashboardJobList;
