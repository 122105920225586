import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { StartDisputeForJob } from "../../../redux/action/chat/ClientChat";
import ErrorMessage from "../../common/ErrorMessage";
import { CloseIcon } from "../../common/icons/Icon";
import Loader from "../../common/Loader";

function StartDispute({ setShow, show, startChatUserDetails, jobId }) {
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const StartDisputeHandler = () => {
    if (reason !== "") {
      const data = {
        seller:
          startChatUserDetails &&
          startChatUserDetails.application_user &&
          startChatUserDetails.application_user.id,
        buyer:
          startChatUserDetails &&
          startChatUserDetails.user &&
          startChatUserDetails.user.id,
        message: reason,
        job: Number(jobId),
      };
      dispatch(
        StartDisputeForJob(data, setLoading, setError, setShow, setReason)
      );
    } else {
      setError("Please enter reason for starting dispute");
    }
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        setShow(false);
        setError("");
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between">
          <p className="common-heading-Medium md-font text-dark-black mb-0 ">
            Start Dispute
          </p>
          <span
            className="cursor-pointer"
            onClick={() => {
              setShow(false);
              setError("");
            }}
          >
            <CloseIcon />
          </span>
        </div>
        <div className="border-bottom my-3"></div>
        {error !== "" ? <ErrorMessage message={error} /> : ""}
        <div className="mt-4 mb-3">
          <Form.Group
            className=" textarea-focus"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Control
              placeholder="Describe why are you creating dispute?"
              as="textarea"
              rows={5}
              onChange={(e) => setReason(e.target.value)}
            />
          </Form.Group>
        </div>

        <div className="d-flex flex-sm-row  flex-column mt-3">
          <button
            className="px-4 atech-primary-button  py-sm-2 py-2 ms-sm-2  my-2"
            onClick={() => StartDisputeHandler()}
            disabled={loading}
          >
            {loading ? <Loader /> : "Submit"}
          </button>
          <button
            className="px-4  py-sm-2 py-2 ms-sm-2  my-2  atech-fade-button"
            onClick={() => {
              setShow(false);
              setError("");
            }}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default StartDispute;
