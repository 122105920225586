import React from "react";
import { Modal } from "react-bootstrap";
import BackIcon from "../../../../assets/img/png/back-icon.png";
import ErrorMessage from "../../../common/ErrorMessage";
import BubblesLoader from "../../../common/BubblesLoader";
import Loader from "../../../common/Loader";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const JobApplicationsDetailsModel = ({
  show,
  setShow,
  error,
  loading,
  clientJobApplicationDetails,
  history,
  startLoading,
  sendMessageHandler,
  applicationId,
  jobId,
  jobApproved,
}) => {
  const userDetailsRole = useSelector(
    (state) => state.AuthReducer.userDetailsRole
  );
  return (
    <>
      <Modal
        className="view-applicants-modal modal-dialog-scrollable"
        show={show}
        onHide={() => setShow(false)}
        centered
        size="xl"
      >
        <Modal.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Modal.Title className=" f-w-600 text-dark-black md-font">
              Job Applications
            </Modal.Title>
            <button
              className="px-sm-3 px-2 py-2 me-2 atech-fade-button fs-Montserrat"
              onClick={() =>
                history.push(
                  `/client/creator/profile/${
                    clientJobApplicationDetails &&
                    clientJobApplicationDetails.user &&
                    clientJobApplicationDetails.user.id
                  }`
                )
              }
              disabled={startLoading}
            >
              {"User profile"}
            </button>
            <img
              className="cursor-pointer"
              onClick={() => setShow(false)}
              src={BackIcon}
              alt="BackIcon"
            />
          </div>
        </Modal.Header>
        <div className="px-3">
          <div className="border-bottom"></div>
        </div>
        {error !== "" ? <ErrorMessage message={error} /> : ""}
        {loading ? (
          <div className="h-calc-100vh-170 d-flex justify-content-center align-items-center flex-column">
            <BubblesLoader />
            <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
              Please wait...
            </p>
          </div>
        ) : (
          <Modal.Body>
            <div className="text-center py-3">
              <img
                className="applicant-user-image"
                src={
                  clientJobApplicationDetails &&
                  clientJobApplicationDetails.user &&
                  clientJobApplicationDetails.user.profile_image_path
                }
                alt=""
              />
            </div>
            <h1 className="md-font f-w-600 text-center">
              {clientJobApplicationDetails &&
              clientJobApplicationDetails.user &&
              clientJobApplicationDetails.user.first_name
                ? clientJobApplicationDetails &&
                  clientJobApplicationDetails.user &&
                  clientJobApplicationDetails.user.first_name +
                    " " +
                    clientJobApplicationDetails.user.last_name
                : clientJobApplicationDetails &&
                  clientJobApplicationDetails.user &&
                  clientJobApplicationDetails.user.username}
            </h1>
            {userDetailsRole && userDetailsRole.is_buyer === true && (
              <div className="text-center my-3">
                <button
                  className="px-sm-3 px-2 py-2 me-2 atech-fade-button fs-Montserrat"
                  onClick={() =>
                    sendMessageHandler(clientJobApplicationDetails.user)
                  }
                  disabled={startLoading}
                >
                  {startLoading ? <Loader /> : "Send Message"}
                </button>
                {jobApproved === true ? (
                  ""
                ) : (
                  <button
                    className="atech-primary-button px-3 py-2 my-sm-0 my-3 ms-2"
                    onClick={() =>
                      history.push(`/client/payment/${applicationId}/${jobId}`)
                    }
                  >
                    {"Accept Offer"}
                  </button>
                )}
              </div>
            )}
            <div className="px-1 pt-sm-5 pt-2">
              <div className="border-bottom"></div>
            </div>
            <p className="common-text mb-0 pt-3">Quote:</p>
            <p className="common-heading-Medium text-blue">
              $
              {clientJobApplicationDetails && clientJobApplicationDetails.price
                ? clientJobApplicationDetails.price
                : "N/A"}
            </p>
            <p className="common-text mb-0 pt-sm-3 pt-1">Message:</p>
            <p className="common-text mb-0 pt-2">
              {clientJobApplicationDetails &&
              clientJobApplicationDetails.message
                ? clientJobApplicationDetails.message
                : "N/A"}
            </p>
            <p className="common-text mb-0 pt-3">Expected Delivery In:</p>
            <p className="common-text mb-0 pt-2">
              {clientJobApplicationDetails &&
              clientJobApplicationDetails.durations
                ? clientJobApplicationDetails.durations
                : "N/A"}
              Days
            </p>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default withRouter(JobApplicationsDetailsModel);
