import React from "react";
import { withRouter } from "react-router-dom";
import ClientOnGoingJobs from "./ClientOnGoingJobs";
import ClientAllJobs from "./ClientAllJobs";
import ClientApprovedJob from "./ClientApprovedJob";
import ClientJobFeedTypes from "./ClientJobFeedTypes";
import ClientPendingJob from "./ClientPendingJob";
import CompletedJob from "./CompletedJob";

const JobFeed = ({ match }) => {
  const { type } = match.params;

  return (
    <div className="d-flex flex-xl-row flex-column mt-4">
      <ClientJobFeedTypes type={type} />
      {type === "approved" ? (
        <ClientApprovedJob type={type} />
      ) : type === "all-jobs" ? (
        <ClientAllJobs type={type} />
      ) : type === "on-going-jobs" ? (
        <ClientOnGoingJobs type={type} />
      ) : type === "completed-job" ? (
        <CompletedJob type={type} />
      ) : (
        <ClientPendingJob type={type} />
      )}
    </div>
  );
};

export default withRouter(JobFeed);
