import { Accordion } from "react-bootstrap";

function Extras({ jobList }) {
  return (
    <Accordion className="footage-accordian">
      {jobList &&
        jobList.extras.length > 0 &&
        jobList.extras.map((value, index) => {
          return (
            <Accordion.Item className="my-3" eventKey={index}>
              <Accordion.Header className="accordian_border">
                <span>Extras {index + 1}</span>
              </Accordion.Header>
              <Accordion.Body>
                <div className="bg-F8F8F8 p-2">
                  <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
                    <span>Males</span>
                    <span className="text-black">
                      {value && value.males ? value.males : "N/A"}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
                    <span>Females</span>
                    <span className="text-black">
                      {value && value.females ? value.females : "N/A"}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
                    <span>Other/s</span>
                    <span className="text-black">
                      {value && value.others ? value.others : "N/A"}
                    </span>
                  </p>
                  <p className="mt-4 mb-3 common-text px-2 px-lg-4">
                    Clothing/Makeup:
                  </p>
                  <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
                    {value && value.clothings ? value.clothings : "N/A"}
                  </p>
                  <p className="mt-4 common-text px-2 px-lg-4">
                    Describe the extras:
                  </p>
                  <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
                    {value && value.details ? value.details : "N/A"}
                  </p>
                  <p className="mt-4 common-text px-2 px-lg-4">Actions:</p>
                  <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
                    {value && value.actions ? value.actions : "N/A"}
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
    </Accordion>
  );
}

export default Extras;
