import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CrossIcon from "../../../assets/img/svg/crossIcon.svg";
import { ReleaseFundsForUser } from "../../../redux/action/client/JobForm";

const EarningModal = ({ show, setShow, creatorEarning }) => {
  const dispatch = useDispatch();
  const [isReleaseFundLoding, setIsReleaseFundLoding] = useState(false);

  /**
   * Release funds for user when job is completed
   * by client and client has to pay for the job
   */
  const ReleaseFundsHandler = () => {
    const data = {
      amount: creatorEarning.amount_available,
    };
    dispatch(ReleaseFundsForUser(data, setIsReleaseFundLoding));
  };

  return (
    <Modal size="lg" centered show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center pb-3">
          <p className="common-heading-Medium xs-font mb-sm-0">
            Withdraw Earnings
          </p>
          <img
            className="cursor-pointer"
            onClick={() => setShow(false)}
            src={CrossIcon}
            alt="CrossIcon"
          />
        </div>
        <span className="border-bottom d-block pt-3"></span>
        <p className="common-text pt-3">
          Amount to withdraw to your stripe Account:-
        </p>
        <p className="common-text ">Enter other amount</p>
        <input
          type="text"
          className="form-control py-3 atech-input"
          placeholder={`${creatorEarning.amount_available}`}
          disabled={true}
          aria-describedby="emailHelp"
        />
        <button
          className="atech-primary-button px-3 py-3 mt-4 "
          onClick={() => ReleaseFundsHandler()}
          disabled={isReleaseFundLoding}
        >
          {isReleaseFundLoding ? "Loading..." : "Proceed to withdraw"}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default EarningModal;
