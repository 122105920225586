import React from "react";
import { useSelector } from "react-redux";
import ProfileBox from "../../common/ProfileBox";
import ClientATechHeader from "../ClientATechHeader";

const ClientProfile = () => {
  const userDetails = useSelector((state) => state.AuthReducer.userDetails);
  return (
    <>
      <ClientATechHeader HeaderName={"Profile"} />

      <div className="mx-sm-3 mx-1 bg-white p-md-4 p-2 br-10 mt-3">
        <div className="container-fluid my-4  profile-scroll">
          <ProfileBox userDetails={userDetails} />
        </div>
      </div>
    </>
  );
};
export default ClientProfile;
