import React from "react";
import { Modal } from "react-bootstrap";
import PayOutSelection from "./PayOutSelection";
import CrossIcon from "../../../assets/img/svg/crossIcon.svg";
import { withRouter } from "react-router-dom";

const PayOutDetailsModal = ({ setShow, show, item, history }) => {
  const creator_id = item.creator_details.id;
  const buyer_id = item.buyer_details.id;
  let totalAmount = item.payment_details.amount / 100;
  let totalPrice = item && item.application_details.price * 0.1;
  totalPrice = totalPrice.toFixed(2);
  let totalAmountToPay = item && item.application_details.price - totalPrice;

  return (
    <Modal size="xl" centered show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center pb-3">
          <p className="common-heading-Medium xs-font mb-sm-0">Job Details</p>
          <img
            className="cursor-pointer"
            onClick={() => setShow(false)}
            src={CrossIcon}
            alt="CrossIcon"
          />
        </div>
        <div className="container-fluid">
          <div className="row pt-4">
            <div className="col-12">
              <div className="d-flex flex-column ">
                <p className="common-heading-Medium mb-0 sm-font  mb-2">
                  {item && item.job_details && item.job_details.title}
                </p>
                <p className="common-text xxs-font giveMeEllipsis  word-break-text">
                  {item && item.job_details && item.job_details.description}
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="d-flex flex-row  align-items-sm-center justify-content-between ">
                <p className="common-text xl-font ">Buyer:</p>
                <p
                  className="common-text xxs-font text-wrap-css cursor-pointer text_hover"
                  onClick={() =>
                    history.push(`/admin/client/profile/${buyer_id}`)
                  }
                >
                  {item && item.buyer_details && item.buyer_details.username}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-row  align-items-sm-center justify-content-between ">
                <p className="common-text xl-font ">Creator:</p>
                <p
                  className="common-text xxs-font text-wrap-css cursor-pointer text_hover"
                  onClick={() =>
                    history.push(`/admin/creator/profile/${creator_id}`)
                  }
                >
                  {item &&
                    item.creator_details &&
                    item.creator_details.username}
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="d-flex flex-row  align-items-sm-center justify-content-between ">
                <p className="common-text xl-font ">Duration:</p>
                <p className="common-text xxs-font text-wrap-css">
                  {item && item.job_details && item.job_details.duration}
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="d-flex flex-row  align-items-sm-center justify-content-between ">
                <p className="common-text xl-font ">Delivery Made:</p>
                <p className="common-text xxs-font text-wrap-css">
                  {item && item.total_delivery}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-row  align-items-sm-center justify-content-between ">
                <p className="common-text xl-font ">Change Request Made:</p>
                <p className="common-text xxs-font text-wrap-css">
                  {item && item.total_change_request}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-row  align-items-sm-center justify-content-between ">
                <p className="common-text xl-font ">Pay From Client:</p>
                <p className="common-text xxs-font text-wrap-css">
                  $ {totalAmount.toFixed(2)}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-row  align-items-sm-center justify-content-between ">
                <p className="common-text xl-font ">Disputed:</p>
                <p className="common-text xxs-font text-wrap-css">
                  {item.is_disputed ? "Yes" : "No"}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-row  align-items-sm-center justify-content-between ">
                <p className="common-text xl-font ">Application Amount:</p>
                <p className="common-text xxs-font text-wrap-css">
                  $ {item && item.application_details.price}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-row  align-items-sm-center justify-content-between ">
                <p className="common-text xl-font ">
                  Creator's Stripe Connected:
                </p>
                <p className="common-text xxs-font text-wrap-css">
                  {item.is_stripe_account_connected ? "Yes" : "No"}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-row  align-items-sm-center justify-content-between ">
                <p className="common-text xl-font ">PlatForm Charge:</p>
                <p className="common-text xxs-font text-wrap-css">
                  $ {totalPrice}
                </p>
              </div>
            </div>

            <span className="border-bottom d-inline-block pt-3 w-100"></span>
            <div className="d-flex flex-row  align-items-sm-center justify-content-between pt-3">
              <p className="common-text xl-font fw-bold ">Price:</p>
              <p className="common-text xxs-font text-blue fw-bold">
                $ {totalAmountToPay.toFixed(2)}
              </p>
            </div>
          </div>
          <PayOutSelection item={item} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(PayOutDetailsModal);
