import React from "react";
import ULogo from "./../../assets/img/svg/logo.svg";
function ATechLogo() {
  return (
    <div>
      <img src={ULogo} alt="" />
      {/* <p className="fs-Montserrat  A-Tech-text">
        <span className="text-blue ">A.</span>Tech
      </p> */}
    </div>
  );
}

export default ATechLogo;
