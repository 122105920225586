import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import BubblesLoader from "../../common/BubblesLoader";
import BackIcon from "../../../assets/img/png/back-icon.png";
import ViewJobTechnicalElementsButton from "../../client/ClientJobFeed/ViewJobTechnicalElementsButton";
import ApprovedJobModel from "./ApprovedJobModel";
import { getAdminJobDetails } from "../../../redux/action/admin/AdminJobs";
import { getTime } from "../../common/utils/Functions";
import AdminRejectJobModel from "./AdminRejectJobModel";

function AdminViewJobDetails({ history, match }) {
  const { jobId } = match.params;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const adminJobDetails = useSelector((state) => state.jobs.adminJobDetails);

  useEffect(() => {
    if (jobId) {
      dispatch(getAdminJobDetails(jobId, setLoading));
    }
  }, [dispatch, jobId]);

  return (
    <div className="px-3">
      <div className="box-shadow bg-white br-10 p-4 mt-4  ">
        <div className="d-flex justify-content-between">
          <button
            className="px-sm-3 px-2 py-2  atech-fade-button"
            onClick={() => history.goBack()}
          >
            <span className="px-sm-2">
              <img src={BackIcon} alt="backicon" />
            </span>
            <span className="d-inline-block ps-2">Back</span>
          </button>
        </div>
        <div className="border-bottom my-4"></div>
        <div className="job-scroll">
          {loading ? (
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <BubblesLoader />
              <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                Please wait...
              </p>
            </div>
          ) : (
            <>
              <p className="common-heading-Medium veiw-job-heading">
                {adminJobDetails && adminJobDetails.title
                  ? adminJobDetails.title
                  : "N/A"}
              </p>
              <p className="common-text">
                Posted {getTime(adminJobDetails.created_at)}
              </p>
              <p className="common-heading-Medium text-blue">
                $
                {adminJobDetails && adminJobDetails.price
                  ? adminJobDetails.price
                  : "N/A"}
              </p>
              <p className="common-text pe-sm-5 me-sm-5">
                {adminJobDetails && adminJobDetails.description
                  ? adminJobDetails.description
                  : "N/A"}
              </p>
              <div className="border-bottom "></div>
              <div className="d-flex flex-sm-row flex-column ">
                <ViewJobTechnicalElementsButton />
              </div>
              {adminJobDetails && !adminJobDetails.is_approved_by_admin ? (
                <div className="d-flex flex-sm-row flex-column mt-sm-3">
                  <ApprovedJobModel history={history} jobId={jobId} />
                  <AdminRejectJobModel history={history} jobId={jobId} />
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(AdminViewJobDetails);
