import { axiosRequest } from "../../helper";

/**
 * Submit basic information of user job cretion api (POST)
 * @param {Object} data
 * @returns
 */
export const CreateBasicDetailsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/`, data);
};

/**
 * Submit Significant Places or Events of user job cretion api (POST)
 * @param {Object} data
 * @returns
 */
export const SignificantPlacesDetailsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/locations/`, data);
};

/**
 * GET all jobs form list api (GET) and dispatch action to store (redux)
 * @param {String} jobId
 * @returns
 */
export const getAllJobsDetailsListApi = async (jobId) => {
  return await axiosRequest("GET", `/jobs/${jobId}/all/`);
};

/**
 * Add actor job details action (POST)
 * @param {Object} data
 * @returns
 */
export const AddActorJobDetailsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/filmmodels/`, data);
};

/**
 * Add reporter job details action (POST)
 * @param {Object} data
 * @returns
 */
export const AddReporterJobDetailsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/reporters/`, data);
};

/**
 * Add extras job details action (POST)
 * @param {Object} data
 * @returns
 */
export const AddExtrasJobDetailsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/extras/`, data);
};

/**
 * Add extras job details action (POST)
 * @param {Object} data
 * @returns
 */
export const AddVehicleJobDetailsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/vehicles/`, data);
};

/**
 * Add Comman Details For job details action (POST)
 * @param {Object} data
 * @returns
 */
export const AddCommanDetailsForJobApi = async (data) => {
  return await axiosRequest("POST", `/jobs/birdoranimals/`, data);
};

/**
 * Add trees/plants job Details For job details action (POST)
 * @param {Object} data
 * @returns
 */
export const AddTreePlantsJobDetailsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/trees/`, data);
};

/**
 * Add Buildings job Details For job details action (POST)
 * @param {Object} data
 * @returns
 */
export const AddBuildingJobDetailsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/treeandbuildings/`, data);
};

/**
 * Add green screen job Details For job details action (POST)
 * @param {Object} data
 * @returns
 */
export const AddGreenScreenDetailsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/green/`, data);
};

/**
 * Add CGI job Details For job details action (POST)
 * @param {Object} data
 * @returns
 */
export const AddCGIJobDetailsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/cgis/`, data);
};

/**
 * Add Effects job Details For job details action (POST)
 * @param {Object} data
 * @returns
 */
export const AddGSBandParticleEffectsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/effects/`, data);
};

/**
 * Add Job Technical Aspects Api job details action (POST)
 * @param {Object} data
 * @returns
 */
export const AddJobTechnicalAspectsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/technicals/`, data);
};

/**
 * delete job elements  to Your Footage
 * @param {String} jobId
 * @param {String} type
 * @param {String} elementId
 * @returns
 */
export const deleteJobElementsFootageApi = async (jobId, type, elementId) => {
  return await axiosRequest("DELETE", `/jobs/${jobId}/${type}/${elementId}/`);
};

/**  ============================= JOB FEED =========================== */

/**
 * get Client All OnGoing Job List Api (GET)
 * @param {String} type
 * @returns
 */
export const getJobListForClientApi = async (type, page, shortedValue) => {
  return await axiosRequest(
    "GET",
    `/jobs/?type=${type}&&sort=${shortedValue}&&offset=${page}&&limit=10`
  );
};

/**
 * searchJobApi api
 * @param {Object} data
 * @returns
 */
export const clientSearchJobApi = async (type, query) => {
  return await axiosRequest(
    "GET",
    `/jobs/search-client/?type=${type}&query=${query}`
  );
};
/**
 * get Client job view details Api (GET)
 * @param {number} jobId
 * @returns
 */
export const getJobViewDetailsApi = async (jobId) => {
  return await axiosRequest("GET", `/jobs/${jobId}/`);
};

/**
 * delete job api (DELETE)
 * @param {number} jobId
 * @returns
 */
export const deleteJobApi = async (jobId) => {
  return await axiosRequest("DELETE", `/jobs/${jobId}/`);
};

/**
 * Job Approve Api (PATCH)
 * @param {number} jobId
 * @returns
 */
export const jobApprovedByClientApi = async (jobId, data) => {
  return await axiosRequest("PATCH", `/jobs/${jobId}/`, data);
};

/**
 * get Client job applications list Api (GET)
 * @param {Number} jobId
 * @returns
 */
export const getJobApplicationListApi = async (jobId) => {
  return await axiosRequest("GET", `/jobs/${jobId}/jobapplications/`);
};

/**
 * get Client job applications list Api (GET)
 * @param {Number} jobId
 * @returns
 */
export const getJobApplicationDetailsApi = async (jobId, applicationId) => {
  return await axiosRequest(
    "GET",
    `/jobs/${jobId}/jobapplications/${applicationId}/`
  );
};

/**
 * Accept Client job applications Api (GET)
 * @param {Number} jobId
 * @returns
 */
export const ApplictionAcceptOfferApi = async (jobId, applicationId, data) => {
  return await axiosRequest(
    "PATCH",
    `/jobs/${jobId}/jobapplications/${applicationId}/`,
    data
  );
};

/**
 * get Client job Technical Elements Details Api (GET)
 * @param {Number} jobId
 * @param {String} jobId
 * @returns
 */
export const getTechnicalElementsDetailsApi = async (
  jobId,
  elementsType,
  elementsId
) => {
  return await axiosRequest(
    "GET",
    `/jobs/${jobId}/${elementsType}/${elementsId}/`
  );
};

/**
 * get Client job Technical Elements list Api (GET)
 * @param {Number} jobId
 * @param {String} jobId
 * @returns
 */
export const getTechnicalElementsListApi = async (jobId, elementsType) => {
  return await axiosRequest("GET", `/jobs/${jobId}/${elementsType}/`);
};

/**
 * Get JOb Delivries Api (GET)
 * @param {String} jobId
 * @returns
 */
export const getJobsDeliveriesApi = async (jobId) => {
  return await axiosRequest("GET", `/jobs/${jobId}/deliveries/`);
};

/**
 * Get JOb Delivries Api (GET)
 * @param {String} jobId
 * @returns
 */
export const getJobsDeliveriesDetailsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/deliveries/`, data);
};
/**
 * Post Change Request Api (POST)
 * @param {String} jobId
 * @returns
 */
export const getChangeRequestApi = async (data) => {
  return await axiosRequest("POST", `/jobs/change-request/`, data);
};

/**
 * Post Change Request Api (POST)
 * @param {String} jobId
 * @returns
 */
export const getChangeRequestListApi = async (jobId) => {
  return await axiosRequest("GET", `/jobs/${jobId}/change-request/`);
};

/**
 * Payment to Client Api for A-tech site (POST)
 * @param {OBJECT} data
 * @returns
 */
export const paymentToPlatFormByClientApi = async (data) => {
  return await axiosRequest("POST", `/payments/`, data);
};

/**
 * connect stripe account Api (GET)
 * @returns
 */
export const connectStripeAccountApi = async () => {
  return await axiosRequest(
    "GET",
    `/payments/link/?redirect_url=${window.location.href}`
  );
};

/**
 * verify stripe account and redirect to next link (payment)
 * @param {String} code
 * @returns
 */
export const verifyStripeAccountConnectionApi = async (code) => {
  return await axiosRequest("GET", `/payments/link/callback/?code=${code}`);
};

/**
 * Release Fund Api (POST)
 * @param {Object} data
 * @returns
 */
export const ReleaseFundsApi = async (data) => {
  return await axiosRequest("POST", `/payments/payouts/`, data);
};

/**
 * Release Fund list Api (POST)
 * @param {Number} page
 * @returns
 */
export const getReleaseFundsListApi = async (page) => {
  return await axiosRequest(
    "GET",
    `/payments/payouts/?offset=${page}&limit=10`
  );
};

/**
 * change payment status Api (POST)
 * @param {Object} data
 * @returns
 */
export const updatePaymentStatusApi = async (data, payoutId) => {
  return await axiosRequest("PATCH", `/payments/payouts/${payoutId}/`, data);
};

/**
 * Release Fund by admin Api (PATCH)
 * @param {Object} data
 * @returns
 */
export const ReleasePaymentByAdminApi = async (itemId, data) => {
  return await axiosRequest(
    "PATCH",
    `/payments/payouts-update/${itemId}/`,
    data
  );
};

/**
 * Payment to creator Api (POST)
 * @param {Object} data
 * @returns
 */
export const paymentToCreatorApi = async (data) => {
  return await axiosRequest("POST", `/payments/p-link/`, data);
};
