import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ViewJobTechnicalElementsButton from "../../client/ClientJobFeed/ViewJobTechnicalElementsButton";
import CreatorJobDelivery from "../../client/createJob/job/CreatorJobDelivery";
import CreatorChangeRequest from "./CreatorChangeRequest";
import StartDispute from "../../chat/popup/StartDispute";
import { creatorJobDetails } from "../../../redux/action/creator/CreatorJob";
import { useDispatch, useSelector } from "react-redux";
import MyJobDetailsView from "./MyJobDetailsView";
import BubblesLoader from "../../common/BubblesLoader";
import ErrorMessage from "../../common/ErrorMessage";

const MyJobDetails = ({ history, match }) => {
  const dispatch = useDispatch();
  const { jobId } = match.params;
  const [show, setShow] = useState(false);
  const [showRequest, setRequestShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const myJobDetails = useSelector(
    (state) => state.jobs.creatorRecentJobDetails
  );

  useEffect(() => {
    if (jobId) {
      dispatch(creatorJobDetails(setLoading, jobId, setError));
    }
  }, [dispatch, jobId]);

  return (
    <div className="container-fluid p-md-4 p-3 bg-white my-2 br-10 view-job-scroll">
      {error !== "" ? <ErrorMessage message={error} /> : ""}

      {loading ? (
        <div className="h-100 d-flex justify-content-center align-items-center flex-column">
          <BubblesLoader />
          <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
            Please wait...
          </p>
        </div>
      ) : (
        <>
          <MyJobDetailsView
            myJobDetails={myJobDetails}
            history={history}
            setShow={setShow}
            setRequestShow={setRequestShow}
          />
          <ViewJobTechnicalElementsButton />
          <CreatorJobDelivery jobId={jobId} myJobDetails={myJobDetails} />
          <StartDispute
            setShow={setShow}
            show={show}
            startChatUserDetails={myJobDetails}
            jobId={jobId}
          />
          <CreatorChangeRequest
            setRequestShow={setRequestShow}
            showRequest={showRequest}
            jobId={jobId}
            myJobDetails={myJobDetails}
          />
        </>
      )}
    </div>
  );
};

export default withRouter(MyJobDetails);
