import ClientATechHeader from "../client/ClientATechHeader";
import BubblesLoader from "../common/BubblesLoader";
import ErrorMessage from "../common/ErrorMessage";
import Stripe from "./Stripe";

const StripeModel = ({
  showStripeForm,
  setShowStripeForm,
  clientJobApplicationDetails,
  loading,
  error,
  jobId,
  applicationId,
  clientJobViewDetails,
}) => {
  return (
    <>
      <ClientATechHeader HeaderName={"Payment"} />
      <div className="container-fluid">
        <div className="bg-white p-4 br-10 payment-height my-3 overflow-auto">
          <p className="xs-font f-w-600 text-black border-bottom-e8e8e8 mb-0  pb-3">
            Pay Payment
          </p>
          {loading ? (
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <BubblesLoader />
              <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                Please wait...
              </p>
            </div>
          ) : (
            <div className="row justify-content-between h-100 pt-lg-5 ">
              {error !== "" ? <ErrorMessage message={error} /> : ""}
              <div className="col-lg-6  order-lg-1 order-2">
                <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between ">
                  <p className="common-text xl-font ">Job Title:</p>
                  <p className="common-text xxs-font text-wrap-css">
                    {clientJobViewDetails && clientJobViewDetails.title
                      ? clientJobViewDetails.title
                      : "N/A"}
                  </p>
                </div>
                <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between pt-2">
                  <p className="common-text xl-font ">Job Detail:</p>
                  <p className="common-text xxs-font text-wrap-css">
                    {clientJobViewDetails && clientJobViewDetails.title
                      ? clientJobViewDetails.description
                      : "N/A"}
                  </p>
                </div>
                <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between pt-2">
                  <p className="common-text xl-font ">Client Name:</p>
                  <p className="common-text xxs-font ">
                    {clientJobApplicationDetails &&
                    clientJobApplicationDetails.user &&
                    clientJobApplicationDetails.user.first_name
                      ? clientJobApplicationDetails &&
                        clientJobApplicationDetails.user &&
                        clientJobApplicationDetails.user.first_name +
                          " " +
                          clientJobApplicationDetails.user.last_name
                      : clientJobApplicationDetails &&
                        clientJobApplicationDetails.user &&
                        clientJobApplicationDetails.user.username}
                  </p>
                </div>
                <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between pt-2">
                  <p className="common-text xl-font ">Delivery Day:</p>
                  <p className="common-text xxs-font ">
                    {clientJobApplicationDetails &&
                      clientJobApplicationDetails.durations}{" "}
                    Days
                  </p>
                </div>
                <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between pt-2">
                  <p className="common-text xl-font ">Pay to Creator:</p>
                  <p className="common-text xxs-font text-blue fw-bold">{`$${
                    clientJobApplicationDetails &&
                    clientJobApplicationDetails.price
                  }`}</p>
                </div>
                <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between pt-2 border-bottom-e8e8e8">
                  <p className="common-text xl-font ">Platform Charge:</p>
                  <p className="common-text xxs-font text-blue fw-bold">{`$${
                    clientJobApplicationDetails &&
                    Number((clientJobApplicationDetails.price * 0.1).toFixed(0))
                  }`}</p>
                </div>
                <div className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between pt-3 ">
                  <p className="common-text xl-font ">Amount to Pay:</p>
                  <p className="common-text xxs-font text-blue fw-bold">{`$${
                    clientJobApplicationDetails &&
                    clientJobApplicationDetails.price &&
                    clientJobApplicationDetails.price +
                      clientJobApplicationDetails.price * 0.1
                  }`}</p>{" "}
                </div>
              </div>
              <div className="col-lg-5  order-lg-2 order-1 mb-lg-auto mb-3">
                <Stripe
                  showStripeForm={showStripeForm}
                  setShowStripeForm={setShowStripeForm}
                  clientJobApplicationDetails={clientJobApplicationDetails}
                  jobId={jobId}
                  applicationId={applicationId}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StripeModel;
