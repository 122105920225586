import React from "react";
import { withRouter } from "react-router-dom";

const DashboardMessageListItem = ({ item, history }) => {
  return (
    <>
      <section
        className="job-list p-2 mb-4 d-flex justify-content-between align-items-center cursor-pointer"
        onClick={() => history.push(`/creator/chat/${item.id}`)}
      >
        <div className="d-flex align-items-center mx-2">
          <img
            className="massage-img"
            src={item && item.user && item.user.proile_image_path}
            alt="user"
          />
          <p className="common-text text-black mb-0 mx-lg-4 mx-2">
            {item && item.user && item.user.first_name
              ? item &&
                item.user &&
                item.user.first_name + " " + item.user.last_name
              : item && item.user && item.user.username}
          </p>
        </div>
        {item && item.total_unseen === 0 ? (
          ""
        ) : (
          <div className="number-bg mx-3">
            <p className="no-list mb-0 ">{item.total_unseen}</p>
          </div>
        )}
      </section>
    </>
  );
};

export default withRouter(DashboardMessageListItem);
