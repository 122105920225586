import { axiosRequest } from "../../helper";

/**
 * Create Job Feed List Api
 * @returns
 */
export const CreateJobFeedListApi = async (types, shortedValue, page) => {
  return await axiosRequest(
    "GET",
    `/jobs/?type=${types}&sort=${shortedValue}&&offset=${page * 10}&&limit=10`
  );
};

/**
 * get search list for creator Job Api
 * @param {Object} data
 * @returns
 */
export const searchJobListApi = async (type, query) => {
  return await axiosRequest("GET", `/jobs/search/?type=${type}&query=${query}`);
};

/**
 * get job details api
 * @param {String} jobId
 * @returns
 */
export const creatorJobDetailsApi = async (jobId) => {
  return await axiosRequest("GET", `/jobs/${jobId}/`);
};

/**
 * apply job api for creator job api
 * @param {Object} data
 * @returns
 */
export const applayForJobApi = async (data) => {
  return await axiosRequest("POST", `/jobs/jobapplications/`, data);
};

/**
 * report job api for creator job api
 * @param {Object} data
 * @returns
 */
export const creatorReportJobApi = async (data) => {
  return await axiosRequest("POST", `/jobs/reports/`, data);
};

/**
 *Job reviews api
 * @param {Object} data
 * @returns
 */
export const creatorJobReviewsApi = async (data) => {
  return await axiosRequest("POST", `/jobs/reviews/`, data);
};

/**
 * creator earning details
 * @param {Object} data
 * @returns
 */
export const creatorEarningDetailsAPi = async () => {
  return await axiosRequest("GET", `/admin/dashboard-transactional`);
};

/**
 * creator transactions hisator earning details
 * @param {Object} data
 * @returns
 */
export const getTransationHistoryListApi = async (page) => {
  return await axiosRequest(
    "GET",
    `/payments/payouts/all/?offset=${page * 10}&limit=10`
  );
};
