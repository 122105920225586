import React from "react";
import { Modal } from "react-bootstrap";
import { VideoPlayIcon } from "../../../../../components/common/icons/Icon";
import ReactPlayer from "react-player";

const CreatorVideoModal = ({ show, setShow, showVideo }) => {
  return (
    <>
      <Modal
        show={show}
        id="video-modal"
        onHide={() => setShow(false)}
        centered
        dialogClassName="modal-100w p-4"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="head_earn text-dark-black"
            id="example-custom-modal-styling-title"
          >
            Video
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="creator-vid-modal">
            <ReactPlayer
              width="100%"
              className="w-100-video modal-video-height object-fit-cover"
              loop={true}
              playing={true}
              muted={true}
              autoPlay={true}
              url={showVideo}
              playIcon={<VideoPlayIcon />}
            />
          </div>
          <p className="common-text mt-2"></p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreatorVideoModal;
