import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import FormCar from "../../../../assets/img/png/FormCar.png";
import LocationExact from "./SignificantForm/LocationExact";
import LocationDetails from "./SignificantForm/LocationDetails";
import FootageLocation from "./SignificantForm/FootageLocation";
import { useDispatch } from "react-redux";
import { SignificantPlacesDetails } from "../../../../redux/action/client/Job";
import Loader from "../../../common/Loader";

const SignificantForm = ({ history, match, setError }) => {
  const { jobId } = match.params;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [significantFormdTypes, setSignificantFormTypes] = useState({
    job: Number(jobId),
    is_landmark_or_current_world_event: "no",
    type_of_location: "interior",
    is_exact_location: false,
    type_of_location_explaination: null,
    landmark_name: null,
    landmark_country: null,
    landmark_city: null,
    event_name: null,
    event_country: null,
    event_city: null,
    event_details: null,
    location_explaination: null,
    country: null,
    city: null,
    state: null,
    postal_code: null,
    weather_explaination: null,
    is_extream_weather: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      SignificantPlacesDetails(
        significantFormdTypes,
        setLoading,
        setError,
        history,
        jobId
      )
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mt-4 align-items-center justify-content-center flex-grow-1 h-100">
        <div className="col-12  overflow-auto h-100 col-lg-6 ps-xxl-4 order-lg-1 order-2">
          <FootageLocation
            setSignificantFormTypes={setSignificantFormTypes}
            significantFormdTypes={significantFormdTypes}
          />

          <LocationExact
            setSignificantFormTypes={setSignificantFormTypes}
            significantFormdTypes={significantFormdTypes}
          />

          <LocationDetails
            setSignificantFormTypes={setSignificantFormTypes}
            significantFormdTypes={significantFormdTypes}
          />

          <div className="d-flex pb-4 flex-column flex-md-row mt-5">
            <button
              type="submit"
              className={`${
                loading ? "cursor-not-allowed" : ""
              } form-next-btn border-0  me-md-2`}
            >
              {loading ? <Loader /> : "Next"}
            </button>
          </div>
        </div>
        <div className="col-sm-7 d-lg-flex h-100 col-lg-6 pe-xxl-4 my-2 justify-content-center align-items-center order-lg-2 order-1 ">
          <img className="w-100 mb-lg-0 mb-3" src={FormCar} alt="" />
        </div>
      </div>
    </form>
  );
};

export default withRouter(SignificantForm);
