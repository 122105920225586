import React from "react";
import { getTime } from "../../common/utils/Functions";
import ViewJobApplicationsModel from "../ClientJobFeed/ViewJobApplicationsModel";

const DashboardMyJobList = ({ item }) => {
  return (
    <section className="job-list p-lg-3 p-2 mt-3">
      <p className="common-text text-black f-w-600 mb-0 ">{item.title}</p>
      <p className="common-text xxs-font mb-0 mt-1">
        {getTime(item.created_at)}
      </p>
      <p className="common-text text-blue f-w-600  mt-1 mb-0">${item.price}</p>
      <div className="d-flex justify-content-between align-items-center">
        {
          <span className="ps-2 d-flex">
            {item &&
              item.applications.length > 0 &&
              item.applications.map((value, index) => {
                return (
                  <>
                    {index < 3 ? (
                      <span className="ml-_12">
                        <img
                          className="w-35 h-35 br-50"
                          src={value.user.profile_image_path}
                          alt="img"
                          key={index}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            {item && item.applications.length > 3 ? (
              <span className="counting-number ml-_12  d-flex align-items-center ">
                <p className="mb-0 text-center w-100 ">
                  +{item.applications.length - 3}
                </p>
              </span>
            ) : (
              ""
            )}
          </span>
        }
        {item && item.applications.length > 0 ? (
          <ViewJobApplicationsModel
            jobId={item.id}
            jobApproved={item.is_application_approved}
            buttonName="View Applications"
          />
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default DashboardMyJobList;
