import React, { useState } from "react";
import FormImg from "../../../../assets/img/png/FormImg.png";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { CreateBasicDetails } from "../../../../redux/action/client/Job";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import Loader from "../../../common/Loader";

const BasicJobForm = ({ history, setError }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(CreateBasicDetails(data, setLoading, setError, history));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row  mt-4 pt-2 justify-content-center  ">
        <div className="col-lg-6 col-12 my-2 ps-xxl-4 order-lg-1 order-2">
          <div className="mb-2">
            <input
              type="text"
              {...register("title", { required: true })}
              className="form-control py-3 atech-input"
              placeholder="Title of Job"
              minLength="0"
              maxLength="40"
            />

            {errors.title?.type === "required" && (
              <p className="common-text xxs-font text-red">Title is required</p>
            )}
          </div>
          <div className="mb-2">
            <div className="position-relative">
              <input
                type="number"
                {...register("price", { required: true })}
                className="form-control py-3 atech-input ps-3"
                placeholder="Your Budget(USD)"
                minLength="1"
                min="1"
                maxLength="40"
              />
              {errors.price?.type === "required" && (
                <p className="common-text xxs-font text-red">
                  Budget(USD) is required
                </p>
              )}
              <span
                className={`${
                  errors.price?.type === "required" ? " top-19" : " top-28"
                } d-inline-block position-absolute top-28 ps-2 color-8B8C8D`}
              >
                $
              </span>
            </div>
          </div>
          <div className="mb-2">
            <Form.Group
              className="textarea-focus"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                {...register("description", { required: true })}
                placeholder="Short Description of Job"
                as="textarea"
                rows={5}
              />
            </Form.Group>

            {errors.description?.type === "required" && (
              <p className="common-text xxs-font text-red">
                Description is required
              </p>
            )}
          </div>
          <div className="d-flex flex-column flex-md-row mt-lg-5 mt-3">
            <button
              type="submit"
              className={`${
                loading ? "cursor-not-allowed" : ""
              } border-0 form-next-btn me-md-2`}
              disabled={loading}
            >
              {loading ? <Loader /> : "Next"}
            </button>
          </div>
        </div>
        <div className="col-lg-6 col-sm-8 my-2 pe-xxl-4 order-lg-2 order-1">
          <img className="w-100" src={FormImg} alt="" />
        </div>
      </div>
    </form>
  );
};

export default withRouter(BasicJobForm);
