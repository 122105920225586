import React from "react";

export default function SignupFormWrapper({ children }) {
  return (
    <div className="d-flex justify-content-center flex-column container py-5 h-100vh overflow-hidden br-10">
      <div className="row justify-content-center align-items-center h-100vh overflow-auto sign_up  br-10">
        {children}
      </div>
    </div>
  );
}
