import React from "react";
import { withRouter } from "react-router-dom";
import { ViewBackIcon } from "../../common/icons/Icon";

const ReportListItems = ({ history, item }) => {
  return (
    <div className="br-10 notification-section p-2 my-2  ">
      <div className="d-flex align-items-center justify-content-between ">
        <div className="d-flex align-items-center pe-0">
          <span>
            <img
              className="notification-user-img "
              src={item.user_details.profile_image_path}
              alt="notifyimg"
            />
          </span>
          <div className="ps-sm-3 ps-2">
            <p className="mb-0 common-text text-black wrap f-w-600 ">
              {item.message}
            </p>
            <p className="mb-0 common-text xxs-font wrap">
              {new Date(item.created_at).toLocaleString()}
            </p>
          </div>
        </div>
        <div className="ps-0">
          <button
            className="px-sm-3 px-2 py-2  atech-fade-button"
            onClick={() => {
              history.push(`/admin/job/report/view/${item.id}`);
            }}
          >
            <span className="pe-2">
              <ViewBackIcon />
            </span>
            <span className=" d-inline-block fs-Montserrat ">view</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReportListItems);
