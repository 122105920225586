import React, { useMemo, useState } from "react";
import { withRouter } from "react-router";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Uploadicon from "../../assets/img/svg/Upload-icon.svg";
import { UpdateUserDetails } from "../../../src/redux/action/auth/UserDetailsAction";
import ProfileView from "./ProfileView";
import Loader from "./Loader";
import { uploadImage } from "../../redux/action/UploadImageAction";
import ErrorMessage from "./ErrorMessage";
import countryList from "react-select-country-list";

function ProfileBox({ userDetails, history }) {
  const options = useMemo(() => countryList().getData(), []);
  const dispatch = useDispatch();
  const fileUrl = useSelector((state) => state.AuthReducer.fileUrl);
  const [country, setCountry] = useState("");
  const [countryValue, setCountryValue] = useState("");

  const [uploadImg, setUploadImg] = useState("");
  const [error, setError] = useState("");
  const [openProfile, setOpenProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [UpdateUserDetailsValue, setUpdateUserDetails] = useState({
    bio: userDetails && userDetails.bio ? userDetails.bio : "",
    first_name:
      userDetails && userDetails.first_name ? userDetails.first_name : "",
    last_name:
      userDetails && userDetails.last_name ? userDetails.last_name : "",
    city: userDetails && userDetails.city ? userDetails.city : "",
  });

  const changeImg = (e) => {
    if (e) {
      dispatch(uploadImage(e, setLoadingImage, "image"));
      const img = URL.createObjectURL(e.target.files[0]);
      setUploadImg(img);
    }
  };

  const UpdateUserHandler = () => {
    const data = {
      file: fileUrl,
      bio: UpdateUserDetailsValue.bio,
      first_name: UpdateUserDetailsValue.first_name,
      last_name: UpdateUserDetailsValue.last_name,
      city: UpdateUserDetailsValue.city,
      country: countryValue,
    };
    dispatch(
      UpdateUserDetails(
        data,
        setLoading,
        history,
        "login",
        setError,
        setOpenProfile
      )
    );
  };

  const changeHandler = (value) => {
    setCountryValue(value.label);
    setCountry(value);
  };

  return (
    <>
      <div className="row justify-content-center  pb-4 br-bottom">
        {error && <ErrorMessage message={error} />}
        <div className="col-sm-7 col-10 col-lg-4 col-md-6 mb-4 mb-lg-0     d-flex justify-content-md-start justify-content-center">
          {uploadImg !== "" ? (
            <img
              className="profile-upload br-10 w-100"
              src={uploadImg}
              alt=""
            />
          ) : (
            <img
              className="profile-upload br-10 w-100"
              src={
                userDetails &&
                userDetails.profile_image_path &&
                userDetails.profile_image_path
              }
              alt=""
            />
          )}
        </div>

        {openProfile ? (
          <>
            <div className="col-12 col-lg-4 col-md-6 mb-4 mb-lg-0   my-auto pt-1 pt-xxl-0 pt-3">
              <div className="  mb-2  ">
                <input
                  type="text"
                  className=" w-100  py-3 ps-4 atech-input pargraph"
                  placeholder={
                    userDetails && userDetails.first_name
                      ? userDetails.first_name
                      : "First Name"
                  }
                  onChange={(e) =>
                    setUpdateUserDetails({
                      ...UpdateUserDetailsValue,
                      first_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-2">
                <input
                  type="text"
                  className=" w-100  py-3 ps-4 atech-input pargraph"
                  placeholder={
                    userDetails && userDetails.last_name
                      ? userDetails.last_name
                      : "Last Name"
                  }
                  onChange={(e) =>
                    setUpdateUserDetails({
                      ...UpdateUserDetailsValue,
                      last_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="my-2 react-select">
                <Select
                  className="border-0 focus-input"
                  options={options}
                  value={country}
                  onChange={changeHandler}
                  menuPlacement="top"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-2">
                <input
                  type="text"
                  className="form-control atech-input pargraph py-3 ps-4"
                  id="floatingPassword"
                  placeholder={`${
                    userDetails && userDetails.city ? userDetails.city : "City"
                  }`}
                  onChange={(e) =>
                    setUpdateUserDetails({
                      ...UpdateUserDetailsValue,
                      city: e.target.value,
                    })
                  }
                />
              </div>
              <input
                type="file"
                id="my-file"
                onChange={(e) => changeImg(e)}
                hidden
                disabled={loadingImage}
              />
              <div className="d-flex flex-xxl-row flex-column mb-xxl-0 mb-3">
                <button className="py-2 px-3 pargraph atech-fade-button  mb-2">
                  <label
                    for="my-file"
                    className={`${
                      loadingImage ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                  >
                    <span className="me-2">
                      <img src={Uploadicon} alt="" />
                    </span>
                    {loadingImage ? "Loading..." : "Change Image"}
                  </label>
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6 mb-4 mb-lg-0   mt-2 mt-md-0 ">
              <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-lg-end">
                <button
                  className="py-2 px-3 atech-fade-button"
                  onClick={() => setOpenProfile(false)}
                >
                  Cancel
                </button>
                <button
                  onClick={() => UpdateUserHandler()}
                  disabled={loading}
                  className={`${
                    loading ? "cursor-not-allowed" : ""
                  } py-2 px-3 atech-primary-button  mt-2 mt-sm-0 ms-0 ms-sm-2`}
                >
                  {loading ? <Loader /> : "Save Changes"}
                </button>
              </div>
            </div>
          </>
        ) : (
          <ProfileView
            userDetails={userDetails}
            setOpenProfile={setOpenProfile}
            setError={setError}
            history={history}
          />
        )}
      </div>

      {/* ====================================Bio-section===================================== */}
      <div className="row">
        <div className="col-12 pb-4  br-bottom">
          <p className="my-sm-4 my-2 common-heading-Medium  sm-font">Bio</p>
          {openProfile ? (
            <div className="  mb-2  ">
              <textarea
                type="text"
                className="w-100  py-3 ps-4 atech-input pargraph"
                value={UpdateUserDetailsValue.bio}
                placeholder={
                  userDetails && userDetails.bio
                    ? userDetails.bio
                    : "Please add Your BIO."
                }
                onChange={(e) =>
                  setUpdateUserDetails({
                    ...UpdateUserDetailsValue,
                    bio: e.target.value,
                  })
                }
              />
            </div>
          ) : (
            <div className=" atech-border br-3">
              <p className="common-text py-3 px-lg-4 px-2 mb-0">
                {userDetails && userDetails.bio
                  ? userDetails.bio
                  : "Please add Your BIO."}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(ProfileBox);
