import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LocationIcon from "../../../assets/img/svg/location.svg";
import { getReportJobDetails } from "../../../redux/action/admin/AdminAcountsAction";
import { withRouter } from "react-router-dom";
import ErrorMessage from "../../common/ErrorMessage";
import BubblesLoader from "../../common/BubblesLoader";

const JobReportDetails = ({ match, history }) => {
  const { jobId } = match.params;
  const dispatch = useDispatch();
  const jobReportDetails = useSelector(
    (state) => state.AdminAcountsReducer.jobReportDetails
  );

  const jobViewId =
    jobReportDetails &&
    jobReportDetails.user_details &&
    jobReportDetails.user_details.id;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (jobId) {
      dispatch(getReportJobDetails(setLoading, setError, jobId));
    }
  }, [dispatch, jobId]);

  return (
    <>
      {error !== "" ? <ErrorMessage message={error} /> : ""}

      {loading ? (
        <div className="h-100 d-flex justify-content-center align-items-center flex-column">
          <BubblesLoader />
          <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
            Please wait...
          </p>
        </div>
      ) : (
        <>
          <div className="row  pb-4 br-bottom">
            <div className="col-xxl-4 col-sm-6  d-flex justify-content-xxl-start justify-content-center pb-3">
              <img
                className="profile-upload w-100 br-10"
                src={
                  jobReportDetails &&
                  jobReportDetails.user_details &&
                  jobReportDetails.user_details.profile_image_path
                }
                alt=""
              />
            </div>
            <div className="col-xxl-4 col-sm-6  pt-1 my-auto d-flex justify-content-between  align-items-xxl-start align-items-center ">
              <div>
                <div className="mb-2">
                  <p className="common-heading">
                    {jobReportDetails &&
                      jobReportDetails.user_details &&
                      jobReportDetails.user_details.first_name}{" "}
                    {jobReportDetails &&
                      jobReportDetails.user_details &&
                      jobReportDetails.user_details.last_name}
                  </p>
                </div>
                <div className="mb-2 d-flex">
                  <span>
                    <img className="pe-3" src={LocationIcon} alt="location" />
                  </span>
                  <p className="common-text">
                    {jobReportDetails &&
                      jobReportDetails.user_details &&
                      jobReportDetails.user_details.city}
                    ,
                    {jobReportDetails &&
                      jobReportDetails.user_details &&
                      jobReportDetails.user_details.country}
                  </p>
                </div>
              </div>
              <div>
                <div className="mb-2">
                  <p className="common-heading">
                    {jobReportDetails && jobReportDetails.total_reports}
                  </p>
                </div>
                <div className="mb-2">
                  <p className="common-text f-w-600">Total Reports</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 mt-2 mt-sm-0 ">
              <div className="d-flex flex-sm-row flex-column  justify-content-center justify-content-sm-end">
                <button
                  className="py-2 px-2 atech-primary-button  mt-2 mt-sm-0 me-sm-3"
                  onClick={() =>
                    history.push(
                      `/admin/${
                        jobReportDetails &&
                        jobReportDetails.user_details &&
                        jobReportDetails.user_details.is_creator === true
                          ? "creator"
                          : "client"
                      }/profile/${jobViewId}`
                    )
                  }
                >
                  View Profile
                </button>
                <button
                  onClick={() =>
                    history.push(`/admin/completed-job/${jobViewId}`)
                  }
                  className="py-2 px-3 atech-fade-button my-sm-0 my-3"
                >
                  View Job
                </button>
                {/* <button
                  className="py-2 px-2 atech-primary-button  mt-2 mt-sm-0 me-sm-3"
                  onClick={() => sendMessageHandler()}
                  disabled={startLoading}
                >
                  <span className="pe-2">
                    <MessageIcon />
                  </span>
                  {startLoading ? <Loader /> : "Send Message to Owner"}
                </button>
                <button
                  className="py-2 px-2 atech-fade-button my-sm-0 my-3"
                  onClick={() => handleDeleteJobs()}
                  disabled={deleteLoading}
                >
                  <span className="pe-2">
                    <DeleteIcon />
                  </span>
                  {deleteLoading ? <Loader /> : "Delete Job"}
                </button> */}
              </div>
            </div>
          </div>

          {/* ====================================Bio-section===================================== */}
          <span className="border-bottom d-block "></span>
          <div className="row">
            <div className="col-12 pb-4  br-bottom">
              <p className="mt-4 common-heading-Medium  sm-font">
                Why job is reported
              </p>
              <p className="common-text ">
                {jobReportDetails && jobReportDetails.description
                  ? jobReportDetails.description
                  : jobReportDetails && jobReportDetails.reason
                  ? jobReportDetails.reason
                  : "N/A"}
              </p>{" "}
              <p className="common-text ">
                {jobReportDetails && jobReportDetails.message
                  ? jobReportDetails.message
                  : "N/A"}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withRouter(JobReportDetails);
