import {
  ADMIN_ALL_JOBS_LIST,
  ADMIN_JOB_DETAILS,
  SEARCH_JOBS_LIST,
} from "../action/admin/AdminJobs";
import { GET_JOB_LIST_FOR_DISPLUTE } from "../action/chat/ClientChat";
import {
  GET_ALL_JOBS_FORMS_DETAILS_LIST,
  GET_CHANGE_REQUEST_LIST,
} from "../action/client/Job";
import {
  GET_CLIENT_ALL_JOBS_LIST,
  GET_JOBS_DELIVERIES_DETAILS_LIST,
  GET_JOBS_DELIVERIES_LIST,
  GET_JOB_APPLICATION_DETAILS,
  GET_JOB_APPLICATION_LIST,
  GET_JOB_VIEW_DETAILS,
  GET_TECHNICAL_ELEMENTS_DETAILS,
  GET_TECHNICAL_ELEMENTS_LIST,
  SEARCH_JOBS_LIST_FOR_CLIENT,
} from "../action/client/jobFeed";
import {
  CREATOR_EARNING_DETAILS,
  CREATOR_JOB_DETAILS,
  GET_CREATOR_JOB_FEED_LIST,
  GET_TRANSACTION_HISTORY_LIST,
  SEARCH_JOBS_LIST_FOR_CREATOR,
} from "../action/creator/CreatorJob";

const initialState = {
  jobList: [],
  adminAllJobList: [],
  adminJobDetails: {},
  clientJobList: [],
  clientJobViewDetails: {},
  clientJobApplicationList: [],
  clientTechElementsDetails: {},
  clientTechElementsList: [],
  searchAdminAllJobList: [],
  creatorSearchJobList: [],
  creatorRecentJobDetails: {},
  creatorEarning: {},
  searchClientJobList: [],
  clientJobApplicationDetails: {},
  jobDeliveriesdetails: {},
  jobDeliveriesList: [],
  changeRequestList: [],
  jobListForDisplutes: [],
  creatorEarningList: [],
  creatorJobList: [],
};

export default function jobs(state = initialState, action) {
  switch (action.type) {
    /**
     * GET all Jobs forms details list action
     * to save jobs forms details list in
     * store and display in job list
     * page and add job form page
     */
    case GET_ALL_JOBS_FORMS_DETAILS_LIST: {
      return {
        ...state,
        jobList: action.data.data,
      };
    }

    // GET all admin Jobs list action
    case ADMIN_ALL_JOBS_LIST: {
      return {
        ...state,
        adminAllJobList:
          action.data.page === 0
            ? action.data.data
            : state.adminAllJobList.concat(action.data.data),
      };
    }

    // GET all admin Jobs list action
    case ADMIN_JOB_DETAILS: {
      return {
        ...state,
        adminJobDetails: action.data,
      };
    }

    // Get Client all on goings job list action
    case GET_CLIENT_ALL_JOBS_LIST: {
      return {
        ...state,
        clientJobList:
          action.data.page === 0
            ? action.data.data
            : state.clientJobList.concat(action.data.data),
      };
    }

    // Get Client all on goings job list action
    case SEARCH_JOBS_LIST_FOR_CLIENT: {
      return {
        ...state,
        searchClientJobList:
          action.data.page === 0
            ? action.data.data
            : state.clientJobList.concat(action.data.data),
      };
    }

    // Get Client job view details action
    case GET_JOB_VIEW_DETAILS: {
      return {
        ...state,
        clientJobViewDetails: action.data,
      };
    }

    // Get Client job application list action
    case GET_JOB_APPLICATION_LIST: {
      return {
        ...state,
        clientJobApplicationList: action.data,
      };
    }

    // Get Client job application list action
    case GET_JOB_APPLICATION_DETAILS: {
      return {
        ...state,
        clientJobApplicationDetails: action.data,
      };
    }

    // Get Client job TECHNICAL ELEMENTS DETAILS action
    case GET_TECHNICAL_ELEMENTS_DETAILS: {
      return {
        ...state,
        clientTechElementsDetails: action.data,
      };
    }

    // Get Client job TECHNICAL ELEMENTS LIST action
    case GET_TECHNICAL_ELEMENTS_LIST: {
      return {
        ...state,
        clientTechElementsList: action.data.valueConcat
          ? state.clientTechElementsList.concat(action.data)
          : action.data.data,
      };
    }

    // Get GET CREATOR JOB FEED LIST action
    case GET_CREATOR_JOB_FEED_LIST: {
      return {
        ...state,
        creatorJobList:
          action.data.page === 0
            ? action.data.data
            : state.creatorJobList.concat(action.data.data),
      };
    }

    //Get search admin all job list action
    case SEARCH_JOBS_LIST: {
      return {
        ...state,
        searchAdminAllJobList: action.data,
      };
    }

    // GET all admin Jobs list action
    case SEARCH_JOBS_LIST_FOR_CREATOR: {
      return {
        ...state,
        creatorSearchJobList: action.data,
      };
    }

    // creator Recent Job Details action
    case CREATOR_JOB_DETAILS: {
      return {
        ...state,
        creatorRecentJobDetails: action.data,
      };
    }

    // get job deliveries list action
    case GET_JOBS_DELIVERIES_LIST: {
      return {
        ...state,
        jobDeliveriesList: action.data,
      };
    }

    // get job deliveries DETAILS action
    case GET_JOBS_DELIVERIES_DETAILS_LIST: {
      return {
        ...state,
        jobDeliveriesdetails: action.data,
      };
    }

    // get change request list action
    case GET_CHANGE_REQUEST_LIST: {
      return {
        ...state,
        changeRequestList: action.data,
      };
    }

    // get change request list action
    case GET_JOB_LIST_FOR_DISPLUTE: {
      return {
        ...state,
        jobListForDisplute: action.data,
      };
    }

    // get craetor earning details action
    case CREATOR_EARNING_DETAILS: {
      return {
        ...state,
        creatorEarning: action.data,
      };
    }

    // get craetor earning details action
    case GET_TRANSACTION_HISTORY_LIST: {
      return {
        ...state,
        creatorEarningList: action.data,
      };
    }

    default:
      return state;
  }
}
