import React from "react";
import DashboardMyJobList from "./DashboardMyJobList";
import { withRouter } from "react-router";
import BubblesLoader from "../../common/BubblesLoader";
import EmptyCard from "../../common/EmptyCard";

const ClientMyJobLIst = ({ history, jobLoading, creatorJobList }) => {
  return (
    <div
      className={`${
        creatorJobList.length > 0 ? "admin-dahboard-height h-50" : "h-100 "
      }  myjob  d-flex flex-column justify-content-center align-items-center`}
    >
      {creatorJobList && creatorJobList.length > 0 ? (
        <section className=" p-4 h-100 w-100">
          {jobLoading ? (
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <BubblesLoader />
              <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                Please wait...
              </p>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between">
                <p className="common-text mb-0">My Jobs</p>
                <p
                  className="common-text xxs-font view-all mb-0"
                  onClick={() => history.push("/client/job-feed/approved")}
                >
                  View All
                </p>
              </div>
              <section>
                {creatorJobList.map((item, index) => {
                  return <DashboardMyJobList item={item} key={index} />;
                })}
              </section>
            </>
          )}
        </section>
      ) : (
        <section className="bg-job p-4">
          <EmptyCard message={"You don't have any my job list yet!"} />
        </section>
      )}
    </div>
  );
};

export default withRouter(ClientMyJobLIst);
