import React from "react";
import { withRouter } from "react-router";
import { getTime } from "../../common/utils/Functions";

const AdminJobListItem = ({ item, history, match }) => {
  const { type } = match.params;
  return (
    <div className="col-xxl-6 mt-3">
      <div className="job-feed-card br-10 p-xl-4 p-sm-3 p-2  me-xl-3 h-100">
        <div className="d-flex justify-content-between align-items-center">
          <p className="giveMeEllipsis common-heading-Medium xs-font text-black job-feed-text mb-0">
            {item.title}
          </p>
          {type === "all-jobs" ? (
            <div
              className={`${
                item.status === "accepted"
                  ? "accept-btn text-white "
                  : item.status === "rejected"
                  ? "reject-btn text-white "
                  : "blue-btn"
              }  br-10 px-3 py-2`}
            >
              <p className="xxs-font mb-0">{item.status}</p>
            </div>
          ) : (
            ""
          )}
        </div>
        <p className="common-text xxs-font mb-0">
          Posted {getTime(item.created_at)}
        </p>
        <p className="common-heading text-blue f-w-600 text-wrap-css">
          ${item.price}
        </p>
        <p className="common-text pe-xl-5 giveMeEllipsis">{item.description}</p>
        <div
          className="d-flex flex-sm-row flex-column"
          onClick={() =>
            history.push(
              window.location.pathname === "/admin/jobs/completed-jobs"
                ? `/admin/completed-job/${item.id}`
                : `/admin/jobs/view/${item.id}`
            )
          }
        >
          <button className="atech-primary-button px-3 py-2 my-sm-0 my-3">
            View Job
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AdminJobListItem);
