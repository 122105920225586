import React from "react";
import { useMediaQuery } from "react-responsive";
import { Dropdown } from "react-bootstrap";
import {
  BackIconImg,
  ThreeDotsIcon,
  ViewJobIcon,
} from "../../common/icons/Icon";
import DisputesImg from "../../../assets/img/png/dipsuteimg.png";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

const DisputeHeader = ({
  setClickOnChat,
  startDisputesUserDetailsList,
  setShowChat,
  history,
}) => {
  const userDetailsRole = useSelector(
    (state) => state.AuthReducer.userDetailsRole
  );
  const closeHandler = () => {
    setClickOnChat((pre) => !pre);
    setShowChat(false);
  };

  const BeforeDesktop = ({ children }) => {
    const isBeforeDesktop = useMediaQuery({ maxWidth: 991.98 });
    return isBeforeDesktop ? children : null;
  };

  return (
    <div className="mt-16 mt-2 d-md-flex align-items-center justify-content-between  border-bottom-e8e8e8 pb-3 mb-xxl-2 mb-1">
      <div className="d-flex align-items-center mb-3 mb-md-0">
        <span className="dispute-msg-circle">
          <img className="w-sm-45 h-sm-45" src={DisputesImg} alt="" />
        </span>
        <div>
          <p className="fs-25-600 ps-3 color-17191c text-wrap-css-350 text-wrap-css-md-194 mb-0 ">
            Dispute for job -
            {startDisputesUserDetailsList &&
              startDisputesUserDetailsList.job &&
              startDisputesUserDetailsList.job.title}
          </p>
          <p className="common-text ps-3 mb-0 text-wrap-css">
            Dispute-
            {startDisputesUserDetailsList && startDisputesUserDetailsList.id}
          </p>
        </div>
      </div>
      <div className="d-flex">
        <BeforeDesktop>
          <span onClick={closeHandler} className="px-sm-2 cursor-pointer">
            <BackIconImg />
          </span>
        </BeforeDesktop>

        <Dropdown className="three-dots">
          <Dropdown.Toggle
            className=" cursor-pointer  float-end border-0 bg-transparent "
            id="dropdown-basic"
          >
            <ThreeDotsIcon />
          </Dropdown.Toggle>

          <Dropdown.Menu className="common-heading smll-font">
            <Dropdown.Item
              onClick={() =>
                history.push(
                  userDetailsRole.is_admin
                    ? `/admin/completed-job/${startDisputesUserDetailsList.job.id}`
                    : userDetailsRole.is_buyer
                    ? `/client/view/all-jobs/jobs/${startDisputesUserDetailsList.job.id}`
                    : `/creator/view/${"my-job"}/${
                        startDisputesUserDetailsList.job.id
                      }`
                )
              }
            >
              <span className="mr-20 dropdown-icon">
                <ViewJobIcon />
              </span>
              <span>View Job</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export default withRouter(DisputeHeader);
