import React from "react";
import { useLayoutProviderContext } from "../../../context/LayoutProvider";
import AdminATechHeader from "../../../components/common/AdminATechHeader";
import AdminAccountsTable from "../../../components/admin/accounts/AdminAccountsTable";

function AdminAccounts() {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();
  return (
    <div className="ff-Project page-color">
      <AdminATechHeader
        HeaderName={"Accounts"}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <AdminAccountsTable />
    </div>
  );
}

export default AdminAccounts;
