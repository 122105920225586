import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Addicon from "../../../assets/img/svg/add-icon.svg";
import {
  RemoveFileUrlLink,
  uploadImage,
} from "../../../redux/action/UploadImageAction";
import AddNewWorkForm from "./AddNewWorkForm";
import { submitWorkDetails } from "../../../redux/action/creator/CreatorWorkAction";

const AddWorkForm = ({ history }) => {
  const dispatch = useDispatch();
  const [loadingImage, setLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const fileUrl = useSelector((state) => state.AuthReducer.fileUrl);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const handleClose = () => {
    setShowForm(false);
  };

  /**
   * submit work details to server
   * @param {Object} data
   */
  const onSubmit = (data) => {
    setError(true);
    if (fileUrl) {
      const dataValue = {
        media_link: fileUrl,
        description: data.description,
        title: data.title,
      };
      const workDetails = {
        works: [dataValue],
      };
      dispatch(RemoveFileUrlLink());
      dispatch(
        submitWorkDetails(
          workDetails,
          setLoading,
          history,
          "login",
          setShowForm
        )
      );
    }
  };

  /**
   * upload video file to server and set
   * file url to redux store for later use
   * in submit work details to server
   * function above in onSubmit function
   * above in this file
   * @param {Event} e
   */
  const uploadVedioValue = (e) => {
    const type = "video";
    dispatch(uploadImage(e, setLoadingImage, type));
  };

  return (
    <>
      {showForm ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-center flex-column px-sm-3 px-2">
            <AddNewWorkForm
              fileUrl={fileUrl}
              uploadVedioValue={uploadVedioValue}
              register={register}
              loadingImage={loadingImage}
              errors={errors}
              loading={loading}
              error={error}
              setError={setError}
              handleClose={handleClose}
            />
          </div>
        </form>
      ) : (
        <div className="add-gear d-flex flex-sm-row flex-column justify-content-end br-bottom">
          <button
            className="py-2 px-3 ms-sm-2 atech-fade-button "
            onClick={() => setShowForm(!showForm)}
          >
            <img className="me-1" src={Addicon} alt="Addicon" />
            <span className="ms-2">Add Work</span>
          </button>
        </div>
      )}
    </>
  );
};

export default withRouter(AddWorkForm);
