import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { VideoIcon, VideoPlayIcon } from "../../common/icons/Icon";
import ReactPlayer from "react-player";

const VideoPlayModal = ({ item, type }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="h-100 position-relative pt-xl-0 px-sm-0 px-3  pt-4">
        <img
          className="h-100 w-100 br-10"
          src={
            item && item.thumbnails && item.thumbnails[0] && item.thumbnails[0]
          }
          alt="pastworkimg"
        />
        {type === "work" ? (
          <div
            className="position-absolute bottom-0 p-2 "
            onClick={() => setShow(true)}
          >
            <VideoIcon />
          </div>
        ) : item.isVideo ? (
          <div
            className="position-absolute bottom-0 p-2 "
            onClick={() => setShow(true)}
          >
            <VideoIcon />
          </div>
        ) : (
          ""
        )}
      </div>

      <Modal
        show={show}
        id="video-modal"
        onHide={() => setShow(false)}
        centered
        dialogClassName="modal-100w p-4"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="head_earn text-dark-black"
            id="example-custom-modal-styling-title"
          >
            {item && item.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="profile-modal">
            <ReactPlayer
              width="100%"
              className="w-100-video modal-video-height "
              stopOnUnmount={true}
              controls={true}
              light={
                item &&
                item.thumbnails &&
                item.thumbnails[1] &&
                item.thumbnails[1]
              }
              autoPlay={true}
              url={item && item.media_link}
              playIcon={<VideoPlayIcon />}
            />
          </div>
          <p className="common-text mt-2">{item && item.description}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoPlayModal;
