import React from "react";
import noMessages from "../../assets/img/svg/Nomessages.svg";

function NoMessages({ message }) {
  return (
    <div className="bg-white d-flex justify-content-center flex-column align-items-center p-sm-5 p-2 mx-3 h-100">
      <img className="pb-3" src={noMessages} alt="noMessages" />
      <p className="text-center common-heading-Medium">{message}</p>
    </div>
  );
}
export default NoMessages;
