import { useState } from "react";
import ClientATechHeader from "../../../components/client/ClientATechHeader";
import SignificantForm from "../../../components/client/createJob/job/SignificantForm";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { useLayoutProviderContext } from "../../../context/LayoutProvider";

const SignificantPlaceEvent = () => {
  const [error, setError] = useState("");
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();

  return (
    <div className=" d-flex flex-column ">
      <ClientATechHeader
        HeaderName={"Create Jobs"}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />

      <div className="px-3 place_event_section">
        <section className="br-10 bg-white p-lg-4 p-3  main-box-shadow my-4 pt-3 pb-5 flex-grow-1 overflow-hidden d-flex">
          <div className="h-100 d-flex flex-column  ">
            <p className="xs-font f-w-600  mb-0 text-black border-bottom-e8e8e8  mx-xxl-2 pb-3">
              Step-2 : Significant Places or Events
            </p>
            <div className="mt-2">
              {error !== "" ? <ErrorMessage message={error} /> : ""}
            </div>
            <SignificantForm setError={setError} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default SignificantPlaceEvent;
