import React from "react";
import { getTime } from "./utils/Functions";
import ViewJobApplicationsModel from "../client/ClientJobFeed/ViewJobApplicationsModel";

const JobDetails = ({ history, index, item, type }) => {
  const pathname = window.location.pathname;
  return (
    <div className="col-xxl-6 mt-3" key={index}>
      <div className="job-feed-card br-10 p-xl-4 p-sm-3 p-2 h-100 me-xxl-3">
        <div className="d-flex justify-content-between align-items-center">
          <p className="common-heading-Medium xs-font text-black job-feed-text text-wrap-css-350 mb-0">
            {item.title}
          </p>
          {pathname === "/client/job-feed/all-jobs" ||
          pathname === "/client/job-feed/pending-jobs" ? (
            <div
              className={`${
                item.status === "accepted"
                  ? "accept-btn text-white "
                  : item.status === "rejected"
                  ? "reject-btn text-white "
                  : "atech-primary-button"
              }  br-10 px-3 py-2`}
            >
              <p className="xxs-font mb-0">
                {item.is_completed_by_creator === true
                  ? "Completed"
                  : item.status}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
        <p className="common-text xxs-font mb-0">
          Posted {getTime(item.created_at)}
        </p>
        <p className="common-heading text-blue f-w-600">${item.price}</p>
        <p className="common-text pe-xl-5 giveMeEllipsis">{item.description}</p>
        <div className="d-flex flex-sm-row flex-column">
          {pathname.includes("creator") ? (
            ""
          ) : (
            <ViewJobApplicationsModel
              status={item.status}
              jobId={item.id}
              buttonName="View Applicants"
              jobApproved={item.is_application_approved}
            />
          )}

          <button
            className={`atech-fade-button px-3 py-2 ${
              pathname.includes("creator") ? "" : ""
            }`}
            onClick={() => history.push(`/${type}/${item.id}`)}
          >
            View Job
          </button>
          {window.location.pathname.includes("/client") ? (
            ""
          ) : (
            <button
              className={`atech-fade-button ml-2 px-3 py-2 ${
                pathname.includes("creator") ? "" : ""
              }`}
              onClick={() =>
                history.push(`/creator/client/profile/${item.user}`)
              }
            >
              Owner
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
