import {
  BellIcon,
  ChangePasswordIcon,
  LogOutIcon,
  ProfileIcon,
} from "./icons/Icon";
import { Dropdown } from "react-bootstrap";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menubar from "../../assets/img/svg/Menubar.svg";
import { useMediaQuery } from "react-responsive";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { useLayoutProviderContext } from "../../context/LayoutProvider";
import { useState } from "react";
import Preloader from "./Preloader";

const AdminATechHeader = ({ HeaderName, history }) => {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();
  const userDetails = useSelector((state) => state.AuthReducer.userDetails);
  const userDetailsRole = useSelector(
    (state) => state.AuthReducer.userDetailsRole
  );
  const unseenNotification = useSelector(
    (state) => state.AdminAcountsReducer.unseenNotification
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ maxWidth: 1199.98 });
    return isDesktop ? children : null;
  };
  const closeSidebar = () => {
    setSidebarActive(false);
  };

  const logoutHandler = () => {
    localStorage.removeItem("a-tech-access-token");
    localStorage.removeItem("a-tech-refresh-token");
    <Preloader />;
    history.push("/");
    window.location.reload();
  };

  const pathName = window.location.pathname;

  return (
    <>
      <div className="container-fluid pt-3">
        <div className="row  justify-content-between align-items-center">
          <Desktop>
            <div className="col-auto d-flex align-items-center">
              <img
                className="menubar-icon cursor-pointer"
                onClick={() => setSidebarActive(true)}
                src={Menubar}
                alt="menubar"
              />

              <div
                className={`${sidebarActive ? "sidebar-overlay" : ""}`}
                onClick={closeSidebar}
              ></div>
            </div>
          </Desktop>
          <div className="col-auto">
            <p className="common-heading mb-0 fs-xs-25">
              {HeaderName ? HeaderName : "HeaderName"}
            </p>
          </div>
          <div className="col-auto my-auto ">
            <div className="d-flex  align-items-center ">
              <div
                className="pe-3 "
                onClick={() => history.push("/admin/notifications")}
              >
                <div className="position-relative">
                  <BellIcon />
                  {unseenNotification === 0 ? (
                    ""
                  ) : pathName.includes("/notification") ? (
                    ""
                  ) : (
                    <>
                      {unseenNotification &&
                      unseenNotification.unseen_notification === 0 ? (
                        ""
                      ) : (
                        <span className="notification_circle">
                          {unseenNotification.unseen_notification}
                        </span>
                      )}
                    </>
                  )}
                  {/* {unseenNotification &&
                  unseenNotification.unseen_notification === 0 ? (
                    ""
                  ) : (
                    <span className="notification_circle">
                      {unseenNotification.unseen_notification}
                    </span>
                  )} */}
                </div>
              </div>
              <div className="d-lg-flex d-none align-items-center dashborad-profile p-1">
                <img
                  className="profile-img"
                  src={
                    userDetails && userDetails.profile_image_path
                      ? userDetails.profile_image_path
                      : ""
                  }
                  alt="img"
                />

                <Dropdown className="three-dots">
                  <Dropdown.Toggle
                    className="border-0 profile-dropdown d-flex justify-content-center align-items-center"
                    id="dropdown-basic"
                  >
                    {userDetails.first_name && userDetails.last_name !== "" ? (
                      <>
                        <span className="d-inline-block  text-wrap-css-82">
                          {userDetails.first_name + " " + userDetails.last_name}
                        </span>
                      </>
                    ) : (
                      <>{userDetailsRole.username}</>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => history.push("/admin/profile")}
                    >
                      <span className="mr-15 dropdown-icon">
                        <ProfileIcon />
                      </span>
                      <span>Profile</span>
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        history.push("/sign-up/change-password");
                        window.location.reload();
                      }}
                    >
                      <span className="mr-15 dropdown-icon">
                        <ChangePasswordIcon />
                      </span>
                      <span>Change Password</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logoutHandler}>
                      <span className="mr-15 dropdown-icon">
                        <LogOutIcon />
                      </span>
                      <span>Log Out</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <img
                className="cursor-pointer profile-img d-flex d-lg-none"
                id="demo-positioned-button"
                aria-controls="demo-positioned-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                src={
                  userDetails && userDetails.profile_image_path
                    ? userDetails.profile_image_path
                    : ""
                }
                alt=""
              />
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={() => history.push("/admin/profile")}>
                  <span className="mr-15 dropdown-icon">
                    <ProfileIcon />
                  </span>
                  <span>Profile</span>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    history.push("/sign-up/change-password");
                    window.location.reload();
                  }}
                >
                  <span className="mr-15  dropdown-icon">
                    <ChangePasswordIcon />
                  </span>
                  <span>Change Password</span>
                </MenuItem>
                <MenuItem onClick={logoutHandler}>
                  <span className="mr-15 dropdown-icon">
                    <LogOutIcon />
                  </span>
                  <span>Log Out</span>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(AdminATechHeader);
