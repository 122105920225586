import React from "react";
import ClientIcon from "../../../assets/img/png/clients.png";

const TotalCreator = ({ adminDashboard, loading }) => {
  return (
    <div className="col-xxl-4   mt-3 mt-xxl-0">
      <div className="d-flex h-100 p-3 justify-content-between align-items-center box-shadow br-10 bg-white ">
        <div className="">
          <p className="common-text  mb-0">Total Creaters </p>
          <h1 className="common-heading-Medium max-sm-w-228  mb-0 py-1 ">
            {loading ? (
              <p className="common-text fs-xs-14 mb-0 fw-bold">Loading...</p>
            ) : (
              `${adminDashboard.total_creator}`
            )}
          </h1>
        </div>
        <span
          className="rounded-50  dashboard-icon   d-inline-block "
          style={{
            backgroundColor: "#e7f2fe",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${ClientIcon})`,
          }}
        ></span>
      </div>
    </div>
  );
};

export default TotalCreator;
