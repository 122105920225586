import React, { useMemo } from "react";
import countryList from "react-select-country-list";
import Select from "react-select";

const ExactLocation = ({ significantFormdTypes, setSignificantFormTypes }) => {
  const options = useMemo(() => countryList().getData(), []);

  return (
    <>
      <Select
        className="border-0 focus-input mb-2"
        options={options}
        placeholder="Country"
        menuPlacement="top"
        aria-describedby="emailHelp"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            country: e.label,
          })
        }
      />
      <input
        type="text"
        className="py-md-3 mb-2  border-b9babb   common-text text-8B8C8D form-control"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            state: e.target.value,
          })
        }
        placeholder="State"
      />
      <input
        type="text"
        className="py-md-3 mb-2  border-b9babb   common-text text-8B8C8D form-control"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            city: e.target.value,
          })
        }
        placeholder="City"
      />
      <input
        type="text"
        className="py-md-3 mb-2  border-b9babb common-text text-8B8C8D form-control"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            postal_code: e.target.value,
          })
        }
        placeholder="Post Code"
      />
      <textarea
        rows="4"
        className="py-md-3 mb-2  border-b9babb scommon-text text-8B8C8D form-control"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            location_explaination: e.target.value,
          })
        }
        placeholder="Details of exact location i.e. street names"
      ></textarea>
    </>
  );
};

export default ExactLocation;
