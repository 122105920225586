import { axiosRequest } from "../../helper";

/**
 * Get user gear work list api data change
 * @returns
 */
export const GetUserGearListApi = async () => {
  return await axiosRequest("GET", `/users/gears/`);
};

/**
 * submit user gear work data
 * @param {Object} data
 * @returns
 */
export const submitGearDetailsApi = async (data) => {
  return await axiosRequest("POST", `/users/gears/`, data);
};

/**
 * updates user gear work data
 * @param {Object} data
 * @returns
 */
export const UpdateGearDetailsApi = async (data, id) => {
  return await axiosRequest("PATCH", `/users/gears/${id}/`, data);
};

/**
 * Delete user gear work details api
 * @param {Object} data
 * @returns
 */
export const deleteGearDetailsApi = async (id) => {
  return await axiosRequest("DELETE", `/users/gears/${id}/`);
};

/**
 * Update user gear work details api
 * @param {Object} data
 * @returns
 */
export const updateGearDetailsApi = async (id) => {
  return await axiosRequest("PATCH", `/users/gears/${id}/`);
};
