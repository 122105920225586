import { Accordion } from "react-bootstrap";
import GreenCGIandPractical from "./CommonFields/GreenCGIandPractical";

function GreenScreenBg({ jobList }) {
  return (
    <Accordion className="footage-accordian">
      {jobList &&
        jobList.green_screen_background &&
        jobList.green_screen_background.length > 0 &&
        jobList.green_screen_background.map((value, index) => {
          return (
            <GreenCGIandPractical
              FootageType={"Green Screen Background"}
              value={value}
              index={index}
            />
          );
        })}
    </Accordion>
  );
}

export default GreenScreenBg;
