import React, { useState } from "react";
import { withRouter } from "react-router";
import JobTechnicalModel from "./JobModel/JobTechnicalModel";
import FootageElementModel from "./JobModel/FootageElementModel";
import { useDispatch } from "react-redux";
import { getTechnicalElementsList } from "../../../redux/action/client/jobFeed";

function ViewJobTechnicalElementsButton({ match }) {
  const { jobId } = match.params;
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [footageModal, setFootageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleTechnicalDetails = () => {
    setModalShow(true);
    dispatch(
      getTechnicalElementsList(setLoading, jobId, "technicals", setError, false)
    );
  };

  return (
    <>
      <div className="my-4 pt-2 d-flex flex-column flex-md-row">
        <button
          type="button"
          className="border-e8e8e8 py-2 px-4 bg-f8f8f8 common-text me-md-2 br-3 "
          onClick={() => handleTechnicalDetails(true)}
        >
          View Technical Aspects
        </button>
        <button
          className="border-e8e8e8 py-2 px-4 bg-f8f8f8 common-text mt-3 mt-md-0 br-3 "
          onClick={() => setFootageModal(true)}
        >
          View Footage Elements
        </button>
      </div>
      <JobTechnicalModel
        setModalShow={setModalShow}
        modalShow={modalShow}
        loading={loading}
        error={error}
      />

      {/* VIEW FOOTAGE ELEMENT MODAL */}
      <FootageElementModel
        setFootageModal={setFootageModal}
        footageModal={footageModal}
        jobId={jobId}
      />
    </>
  );
}

export default withRouter(ViewJobTechnicalElementsButton);
