import { Accordion } from "react-bootstrap";

function LocationModel({ value, index, FootageType }) {
  return (
    <Accordion.Item className="my-3" eventKey={index}>
      <Accordion.Header className="accordian_border">
        <span>
          {FootageType} {index + 1}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="bg-F8F8F8 p-2">
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Exact location</span>
            <span className="text-black">
              {value && value.is_exact_location ? "Yes" : "NO"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Country</span>
            <span className="text-black">
              {value && value.country ? value.country : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>City</span>
            <span className="text-black">
              {value && value.city ? value.city : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Landmark Current World Event</span>
            <span className="text-black">
              {value && value.is_landmark_or_current_world_event
                ? value.is_landmark_or_current_world_event
                : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Landmark Name</span>
            <span className="text-black">
              {" "}
              {value && value.landmark_name ? value.landmark_name : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Landmark Country</span>
            <span className="text-black">
              {" "}
              {value && value.landmark_country ? value.landmark_country : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Landmark City</span>
            <span className="text-black">
              {" "}
              {value && value.landmark_city ? value.landmark_city : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Location Explaination</span>
            <span className="text-black">
              {" "}
              {value && value.location_explaination
                ? value.location_explaination
                : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Postal Code</span>
            <span className="text-black">
              {value && value.postal_code ? value.postal_code : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>state</span>
            <span className="text-black">
              {value && value.state ? value.state : "N/A"}
            </span>
          </p>
          <p className="d-flex justify-content-between common-text border-bottom-B9BABB pb-3 mt-3 px-2 px-lg-4">
            <span>Type of Location</span>
            <span className="text-black">
              {value && value.type_of_location ? value.type_of_location : "N/A"}
            </span>
          </p>

          <p className="mt-4 mb-3 common-text px-2 px-lg-4">
            Location Explaination:
          </p>
          <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
            {value && value.type_of_location_explaination
              ? value.type_of_location_explaination
              : "N/A"}
          </p>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default LocationModel;
