import React from "react";
import uploadImg from "../../../assets/img/svg/upload-img.svg";

function SignUpAddGearForm({
  fileUrl,
  setError,
  uploadVedioValue,
  handleClose,
  register,
  loadingImage,
  errors,
}) {
  return (
    <div className="atech-border br-10">
      <div className="container ps-lg-0 pt-lg-0 pt-4">
        <div className="row">
          <div className="col-12 col-lg-4 ">
            <div className=" py-lg-0 py-5 bg-F8F8F8 h-100 br-10 d-flex flex-column justify-content-center align-items-center">
              {fileUrl ? (
                <>
                  <div className="pt-2">
                    <input
                      onChange={(e) => uploadVedioValue(e)}
                      type="file"
                      id="my-file2"
                      accept="image video/mp4"
                      disabled={loadingImage}
                      hidden
                    />
                    <button
                      type="button"
                      className="py-2 px-3 atech-fade-button "
                    >
                      <label
                        for="my-file2"
                        className="cursor-pointer text-wrap-css"
                      >
                        <span className="me-2">
                          <img
                            className="px-2 top-22"
                            src={uploadImg}
                            alt="uploadImg"
                          />
                        </span>
                        {loadingImage ? "Uploading..." : fileUrl}
                      </label>
                    </button>
                  </div>
                </>
              ) : (
                <div className=" pt-2">
                  <input
                    onChange={(e) => uploadVedioValue(e)}
                    type="file"
                    id="my-file3"
                    accept="image video/mp4"
                    disabled={loadingImage}
                    hidden
                  />
                  <button
                    type="button"
                    className="py-2 px-3 atech-fade-button "
                  >
                    <label
                      for="my-file3"
                      className="cursor-pointer text-wrap-css"
                    >
                      <span className="me-2">
                        <img
                          className=" px-2 top-22"
                          src={uploadImg}
                          alt="uploadImg"
                        />
                      </span>
                      {loadingImage ? "Uploading..." : "Upload Gear"}
                    </label>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-8 my-4 py-2 ">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <input
                    {...register("title", { required: true })}
                    type="text"
                    className="form-control py-3 atech-input "
                    placeholder="Enter Title"
                    aria-describedby="emailHelp"
                  />
                  {errors.title?.type === "required" && (
                    <p className="common-text xxs-font text-red mb-0">
                      Title is required
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6 ">
                <div className=" select-field mt-md-0 mt-2">
                  <select
                    {...register("type", { required: true })}
                    className="form-select py-3 cursor-pointer"
                  >
                    <option value="camera">Camera</option>
                    <option value="sound">Sound</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                {errors.type?.type === "required" && (
                  <p className="common-text xxs-font text-red">
                    Please select any Type
                  </p>
                )}
              </div>
            </div>
            <div className="mt-2">
              <div>
                <input
                  {...register("description", { required: true })}
                  type="text"
                  className="form-control py-3 atech-input"
                  placeholder="Description(max 100 words)"
                  aria-describedby="emailHelp"
                />
                {errors.description?.type === "required" && (
                  <p className="common-text xxs-font text-red">
                    Description is required
                  </p>
                )}
              </div>
            </div>
            <div className="d-flex flex-sm-row  flex-column mt-3">
              <button
                type="submit"
                className="px-4 atech-primary-button  py-sm-2 py-2 my-2  "
                onClick={() => setError(true)}
              >
                Save Gear
              </button>
              <button
                type="button"
                onClick={() => handleClose}
                className="px-4  py-sm-2 py-2 ms-sm-2  my-2  atech-fade-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignUpAddGearForm;
