import React, { useState } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AddUserDeatils } from "../../../redux/action/auth/UserDetailsAction";
import ErrorMessage from "../../common/ErrorMessage";

const SignUpDetailsForm = ({ options, history }) => {
  const dispatch = useDispatch();
  const [country, setCountry] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [error, setError] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const dataValue = {
      address: data.address,
      city: data.city,
      first_name: data.first_name,
      last_name: data.last_name,
      state: data.state,
      zip: data.zip,
      country: countryValue,
    };
    dispatch(AddUserDeatils(dataValue, setLoading, history, setError));
  };

  const changeHandler = (value) => {
    setCountryValue(value.label);
    setCountry(value);
    setValue(value.label);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error !== "" ? <ErrorMessage message={error} /> : ""}
      <div className="row">
        <div className="col-lg-6 col-12">
          <div className=" pt-2">
            <input
              type="text"
              {...register("first_name", {
                required: true,
                minLength: {
                  value: 4,
                  message: "Min length of FirstName is 4",
                },
              })}
              className="form-control py-3 atech-input"
              placeholder="First Name"
              aria-describedby="emailHelp"
            />
          </div>
          {errors.first_name?.type === "required" && (
            <p className="common-text xxs-font text-red mb-0">
              First Name field is required
            </p>
          )}
          {errors.first_name?.type === "minLength" && (
            <p className="common-text xxs-font text-red">
              {errors.first_name.message}
            </p>
          )}
        </div>
        <div className="col-lg-6">
          <div className=" pt-2">
            <input
              type="text"
              {...register("last_name", {
                required: true,
              })}
              className="form-control py-3 atech-input"
              placeholder="Last Name"
              aria-describedby="emailHelp"
            />
          </div>
          {errors.last_name?.type === "required" && (
            <p className="common-text xxs-font text-red mb-0">
              Last Name field is required
            </p>
          )}
        </div>
      </div>
      <div className="my-2 react-select">
        <Select
          className="border-0 focus-input"
          options={options}
          value={country}
          onChange={changeHandler}
          menuPlacement="top"
          aria-describedby="emailHelp"
        />
        {countryError && countryValue === "" ? (
          <p className="common-text xxs-font text-red mb-0">
            Country is required
          </p>
        ) : null}
      </div>
      <div className=" my-2 ">
        <div>
          <input
            {...register("state", {
              required: true,
            })}
            type="text"
            className="form-control py-3 atech-input"
            placeholder="State"
            aria-describedby="emailHelp"
          />
        </div>
        {errors.state?.type === "required" && (
          <p className="common-text xxs-font text-red mb-0">
            State is required
          </p>
        )}
      </div>
      <div className=" my-2 ">
        <div>
          <input
            {...register("city", {
              required: true,
            })}
            type="text"
            className="form-control py-3 atech-input"
            placeholder="District/City"
            aria-describedby="emailHelp"
          />
        </div>
        {errors.city?.type === "required" && (
          <p className="common-text xxs-font text-red mb-0">City is required</p>
        )}
      </div>
      <div className=" my-2 ">
        <div>
          <input
            {...register("address", {
              required: false,
            })}
            type="text"
            className="form-control py-3 atech-input"
            placeholder="Locality (optional)"
            aria-describedby="emailHelp"
          />
        </div>
      </div>
      <div className=" my-2 ">
        <div>
          <input
            {...register("zip", {
              required: false,
            })}
            type="text"
            className="form-control py-3 atech-input"
            placeholder="Zip Code (optional)"
            aria-describedby="emailHelp"
          />
        </div>
      </div>

      <button
        type="submit"
        onClick={() => setCountryError(true)}
        className={` w-100  atech-primary-button py-sm-3 px-4 py-2 my-sm-4 my-3 ${
          loading ? "loading_form cursor-not-allowed" : ""
        }`}
        disabled={loading}
      >
        {loading ? "Loading" : "Next"}
      </button>
    </form>
  );
};
export default SignUpDetailsForm;
