import { withRouter } from "react-router";
import AccountType from "./AccountType";
import ClientAccount from "./ClientAccount";
import CreatorAccount from "./CreatorAccount";

function AdminAccountsTable({ history, match }) {
  const { type } = match.params;
  return (
    <div className="bg-white box-shadow p-sm-4  mx-3 mt-4 br-10">
      <div className="container-fluid">
        <div className="d-flex flex-column">
          <AccountType history={history} type={type} />
          {type === "creator" ? (
            <CreatorAccount history={history} type={type} />
          ) : (
            <ClientAccount history={history} type={type} />
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(AdminAccountsTable);
