import React from "react";
import UserReports from "../../../components/admin/report/UserReports";
import AdminATechHeader from "../../../components/common/AdminATechHeader";
import { useLayoutProviderContext } from "../../../context/LayoutProvider";

const UserReportContainer = () => {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();
  return (
    <div className="ff-Project page-color ">
      <AdminATechHeader
        HeaderName={"User Reports"}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <UserReports />
    </div>
  );
};

export default UserReportContainer;
