import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import SearchIcon from "../../../assets/img/svg/searchicon.svg";
import {
  CreateJobFeedList,
  getSearchJobListByQuery,
} from "../../../redux/action/creator/CreatorJob";
import EmptyCard from "../../common/EmptyCard";
import InfiniteScroll from "react-infinite-scroll-component";
import BubblesLoader from "../../common/BubblesLoader";
import MyJobList from "./MyJobList";

const MyJob = ({ history }) => {
  const dispatch = useDispatch();
  const creatorJobList = useSelector((state) => state.jobs.creatorJobList);
  const creatorSearchJobList = useSelector(
    (state) => state.jobs.creatorSearchJobList
  );
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);

  const pageChangeHandler = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    const types = "on-going";
    const shortedValue = 0;
    dispatch(
      CreateJobFeedList(types, setLoading, shortedValue, page, setHasMore)
    );
  }, [dispatch, page]);

  const handleShorting = (shortedValue) => {
    const types = "on-going";
    dispatch(
      CreateJobFeedList(types, setLoading, shortedValue, page, setHasMore)
    );
  };

  const handleSearchJob = (value) => {
    const types = "on-going";
    setSearchValue(value);
    if (searchValue) {
      dispatch(getSearchJobListByQuery(setLoading, types, searchValue));
    }
  };

  const jobListArrayLength =
    searchValue === ""
      ? creatorJobList && creatorJobList.length
      : creatorSearchJobList && creatorSearchJobList.length;

  const jobListArray =
    page === 0 && loading ? (
      <div className="h-100 d-flex justify-content-center align-items-center flex-column w-100">
        <BubblesLoader />
        <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
          Please wait...
        </p>
      </div>
    ) : searchValue === "" ? (
      creatorJobList && !!creatorJobList.length ? (
        creatorJobList.map((item, index) => {
          return (
            <MyJobList
              item={item}
              index={index}
              history={history}
              type="creator/recent/job-details"
              loading={loading}
            />
          );
        })
      ) : (
        <div className="d-flex justify-content-center w-100">
          <EmptyCard message={"You’ve not any My Jobs List Yet!"} />
        </div>
      )
    ) : creatorSearchJobList && !!creatorSearchJobList.length ? (
      creatorSearchJobList.map((item, index) => {
        return (
          <MyJobList
            item={item}
            index={index}
            history={history}
            type="creator/recent/job-details"
          />
        );
      })
    ) : (
      <div className="d-flex justify-content-center w-100">
        <EmptyCard message={"You’ve not any My Jobs List Yet!"} />
      </div>
    );

  return (
    <div className="container-fluid">
      <div className="row  align-items-center justify-content-between my-sm-4 my-2">
        <div className="col-xxl-4 col-md-5">
          <div className="search-field position-relative">
            <input
              type="email"
              className="form-control"
              placeholder="Search for job..."
              aria-describedby="emailHelp"
              onChange={(e) => handleSearchJob(e.target.value)}
              name="query"
            />
            <img className="search-icon" src={SearchIcon} alt="SearchIcon" />
          </div>
        </div>
        <div className="col-xxl-3 col-md-5 d-flex  flex-sm-row flex-column ">
          <div className=" select-field  w-100 mt-md-0 mt-3">
            <select
              className="form-select py-2 cursor-pointer px-3 "
              onChange={(e) => handleShorting(e.target.value)}
            >
              <option>Sorting</option>
              <option value="0">Recently Added</option>
              <option value="1">Recently Updated</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mt-4 card-scroll admin_job" id="pending-jobs-list">
        <div className="infinite_scroll h-100 row">
          <InfiniteScroll
            className={`${
              jobListArrayLength &&
              jobListArrayLength === 0 &&
              !loading &&
              creatorSearchJobList === searchValue
                ? ""
                : "h-100"
            }`}
            dataLength={jobListArrayLength}
            next={pageChangeHandler}
            hasMore={hasMore}
            scrollableTarget="my-ongoing-jobs-list"
            loader={
              page > 0 && loading ? (
                <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                  <BubblesLoader />
                </div>
              ) : (
                ""
              )
            }
          >
            {jobListArray}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MyJob);
