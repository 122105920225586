import React, { useState } from "react";
import ClientATechHeader from "../../../components/client/ClientATechHeader";
import TechinalAspectsList from "../../../components/client/createJob/TechnicalAspects/TechinalAspectsList";
import ErrorMessage from "../../../components/common/ErrorMessage";

const TechnicalAspects = () => {
  const [error, setError] = useState("");
  return (
    <>
      <ClientATechHeader HeaderName={"Create Job"} />
      {error !== "" ? <ErrorMessage message={error} /> : ""}
      <div className="mx-sm-3 mx-1 bg-white p-md-4 p-2  border-radius_10 mt-3">
        <div className="container-fluid my-4  profile-scroll">
          <TechinalAspectsList setError={setError} />
        </div>
      </div>
    </>
  );
};

export default TechnicalAspects;
