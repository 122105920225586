import React from "react";
import { Modal } from "react-bootstrap";
import { DeleteIcon } from "./icons/Icon";
import img_1 from "../../assets/img/svg/Notification-img.svg";
import Loader from "./Loader";
import ErrorMessage from "./ErrorMessage";

const ClearNotificationModal = ({
  setShow,
  show,
  handleDeleteNotification,
  setNotifiactionId,
  deleteLoading,
  error,
  setError,
  notificationListArray,
}) => {
  return (
    <>
      <button
        onClick={() => {
          setShow(true);
          setNotifiactionId("all");
        }}
        disabled={
          notificationListArray && notificationListArray.length > 0
            ? false
            : true
        }
        className={`${
          notificationListArray && notificationListArray.length > 0
            ? ""
            : "cursor-not-allowed"
        } px-sm-3 px-1 py-2 w-100 atech-fade-button`}
      >
        <span className="px-sm-2 px-1">
          <DeleteIcon />
        </span>
        <span className="d-inline-block"> Clear Notifications</span>
      </button>

      <Modal
        className="clear-notificatin-modal"
        show={show}
        onHide={() => {
          setShow(false);
          setError("");
        }}
        centered
      >
        <Modal.Header>
          <div className="d-flex justify-content-between w-100">
            <Modal.Title className="common-heading-Medium">
              <span className="common-heading-Medium"> Notification Clear</span>
            </Modal.Title>
            <span
              className="modal-close-btn d-inline-block cursor-pointer"
              onClick={() => {
                setShow(false);
                setError("");
              }}
            ></span>
          </div>
        </Modal.Header>
        {error !== "" ? <ErrorMessage message={error} /> : ""}
        <Modal.Body className="d-flex justify-content-center p-3">
          <div>
            <img
              className="d-flex justify-content-center mx-auto"
              src={img_1}
              alt=""
            />
            <p className="common-heading-Medium md-font">
              Are you sure to clear notifications?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center p-5">
          <div>
            <button
              className="atech-primary-button px-sm-3 mr-15 px-1 py-2 "
              onClick={() => handleDeleteNotification()}
            >
              {deleteLoading ? <Loader /> : "Clear Notification"}
            </button>
            <button
              className="atech-fade-button px-sm-3 px-1 py-2 "
              onClick={() => {
                setShow(false);
                setError("");
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClearNotificationModal;
