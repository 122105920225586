import InfiniteScroll from "react-infinite-scroll-component";
import { withRouter } from "react-router-dom";
import BubblesLoader from "../common/BubblesLoader";
import ChatFriendList from "./ChatFriendList";

const FriendListItem = ({
  history,
  chatList,
  setShowChat,
  pageChangeHandler,
  hasMore,
  loading,
  setPageNo,
  page,
  userId,
}) => {
  const chatFriendListArray =
    chatList &&
    chatList.length > 0 &&
    chatList.map((item, index) => {
      return (
        <ChatFriendList
          history={history}
          setShowChat={setShowChat}
          item={item}
          index={index}
          setPageNo={setPageNo}
          userId={userId}
        />
      );
    });

  return (
    <div className="notification-scroll" id="simple-Chat-friend-list">
      <InfiniteScroll
        dataLength={chatList.length}
        next={pageChangeHandler}
        hasMore={hasMore}
        scrollableTarget="simple-Chat-friend-list"
        loader={
          page > 0 && loading ? (
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <BubblesLoader />
            </div>
          ) : (
            ""
          )
        }
      >
        {chatFriendListArray}
      </InfiniteScroll>
    </div>
  );
};

export default withRouter(FriendListItem);
