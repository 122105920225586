import moment from "moment";

const WithDrawEarningList = ({ value, index }) => {
  return (
    <div className="container-fluid">
      <div className="row pt-3" key={index}>
        <div className="col-3">
          <div>
            <p className="common-text">
              {moment(value.created_at).format("DD/MM/YY")}
            </p>
          </div>
        </div>
        <div className="col-3">
          <div>
            <p className="common-text earning-text">
              ${value.amount.toFixed(2)}
            </p>
          </div>
        </div>
        <div className="col-3">
          <div>
            <p className="common-text earning-text">
              {value.payout_id ? value.payout_id : "N/A"}
            </p>
          </div>
        </div>
        <div className="col-3">
          <div>
            <p className="common-text">{value.status}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WithDrawEarningList;
