import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ShowEye from "../../assets/img/svg/eye-icon.svg";
import { ResetYourPasswordAction } from "../../redux/action/auth/AuthAction";
import Loader from "../common/Loader";
import Eye from "../../assets/img/png/Eye.png";
import ErrorMessage from "../common/ErrorMessage";

function ResetPasswordForm({ history, match }) {
  const { tokenId, token, duration } = match.params;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hideCreatePassword, setHideCreatePassword] = useState(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const dataValue = {
      user: Number(tokenId),
      token: token,
      password: data.password,
      duration: Number(duration),
    };
    dispatch(ResetYourPasswordAction(dataValue, setLoading, history, setError));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error !== "" ? <ErrorMessage message={error} /> : ""}

      <div className="position-relative my-2 field-focus">
        <div>
          <input
            type={hideCreatePassword ? "text" : "password"}
            {...register("password", {
              required: true,
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
            })}
            className="form-control py-3 atech-input"
            placeholder="Create Password"
            aria-describedby="emailHelp"
          />
        </div>
        {/* Hide Eye Icon */}
        {hideCreatePassword ? (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setHideCreatePassword(!hideCreatePassword)}
            src={Eye}
            alt=""
          />
        ) : (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setHideCreatePassword(!hideCreatePassword)}
            src={ShowEye}
            alt=""
          />
        )}
      </div>
      {errors.password?.type === "required" && (
        <p className="common-text xxs-font text-red">
          Password field is required
        </p>
      )}
      {errors.password?.type === "minLength" && (
        <p className="common-text xxs-font text-red">
          {errors.password.message}
        </p>
      )}
      <div className="position-relative my-2 field-focus">
        <div>
          <input
            {...register("confirm_password", {
              validate: (value) => value === watch("password"),
            })}
            type={hideConfirmPassword ? "text" : "password"}
            className="form-control py-3 atech-input"
            placeholder="Confirm Password"
            aria-describedby="emailHelp"
          />
        </div>
        {/* Hide Eye Icon */}
        {hideConfirmPassword ? (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
            src={Eye}
            alt=""
          />
        ) : (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
            src={ShowEye}
            alt=""
          />
        )}
      </div>
      {errors.confirm_password?.type === "validate" && (
        <p className="common-text xxs-font text-red">
          The passwords do not match
        </p>
      )}
      {/* Next  Button */}
      <div className="btn-focus">
        <button
          type="submit"
          disabled={loading}
          className={`${
            loading ? "cursor-not-allowed" : "cursor-pointer"
          } w-100 mt-sm-2 mt-1  atech-primary-button py-sm-3 px-4 py-2`}
        >
          {loading ? <Loader /> : "Reset Password"}
        </button>
        <p
          className=" cursor-pointer common-text  pt-4 text-center"
          onClick={() => history.push("/")}
        >
          Cancel
        </p>
      </div>
    </form>
  );
}
export default ResetPasswordForm;
