import React, { useEffect, useState } from "react";
import ATechLogo from "../common/ATechLogo";
import DashboardSvg from "../../assets/img/svg/dashboard.svg";
import JobFeed from "../../assets/img/svg/jobfeed-icon.svg";
import MessagesIcon from "../../assets/img/svg/messages-icon.svg";
import { withRouter } from "react-router";
import DisputesIcon from "../../assets/img/svg/disputes-icon.svg";
import { useLayoutProviderContext } from "../../context/LayoutProvider";

function ClientSidebar({ history }) {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();
  const [activeSidebar, setActiveSidebar] = useState("");

  // GET ROUTE FROM HERE
  const pathName = window.location.pathname;

  useEffect(() => {
    setSidebarActive(false);
    if (pathName === "/") {
      setActiveSidebar("/");
    } else if (pathName.includes("/client/job-feed")) {
      setActiveSidebar("/client/job-feed");
    } else if (pathName === "/creator/chat/:id") {
      setActiveSidebar("/client/chat/:id");
    } else if (pathName.includes("/client/chat")) {
      setActiveSidebar("/client/chat/:id");
    } else if (pathName.includes("/client/disputes/view")) {
      setActiveSidebar("/client/disputes/view");
    }
  }, [pathName, setActiveSidebar, setSidebarActive]);

  return (
    <>
      <div
        className={`${
          sidebarActive ? "active-left  " : ""
        } creater-sidebar h-100 none-sidebar cursor-pointer d-flex flex-column justify-content-between `}
      >
        <div className=" d-flex flex-column">
          <div className="text-center pt-4 pb-4">
            <ATechLogo />
          </div>
          <div className=" mt-4">
            <div
              onClick={() => history.push("/")}
              className={`${
                activeSidebar === "/" ? "dashboard-btn" : "change-color-btn"
              }`}
            >
              <img
                className={`${activeSidebar === "/" ? "img-white" : ""} mx-4`}
                src={DashboardSvg}
                alt="dashboard"
              />
              <span
                className={`${
                  activeSidebar === "/"
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                Dashboard
              </span>
            </div>
          </div>

          <div className=" mt-4">
            <div
              onClick={() => history.push("/client/job-feed/approved")}
              className={`${
                activeSidebar.includes("/client/job-feed")
                  ? "dashboard-btn"
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar.includes("/client/job-feed")
                      ? "img-white"
                      : ""
                  } mx-4`}
                  src={JobFeed}
                  alt="JobFeed"
                />
              </span>
              <span
                className={`${
                  activeSidebar.includes("/client/job-feed")
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                JobFeed
              </span>
            </div>
          </div>
          <div className="my-4">
            <div
              onClick={() => history.push("/client/disputes/view")}
              className={`${
                activeSidebar.includes("/client/disputes/view")
                  ? "dashboard-btn"
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar.includes("/client/disputes/view")
                      ? "img-white"
                      : ""
                  } mx-4`}
                  src={DisputesIcon}
                  alt="JobFeed"
                />
              </span>
              <span
                className={`${
                  activeSidebar.includes("/client/disputes/view")
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                Disputes
              </span>
            </div>
          </div>
          {/* REDIRECT TO CHAT ROUTE  */}
          <div
            onClick={() => history.push("/client/chat/view")}
            className={`${
              activeSidebar.includes("/client/chat")
                ? "dashboard-btn "
                : "change-color-btn"
            }`}
          >
            <span>
              <img
                className={`${
                  activeSidebar.includes("/client/chat") ? "img-white" : ""
                } mx-4`}
                src={MessagesIcon}
                alt="MessagesIcon"
              />
            </span>
            <span
              className={`${
                activeSidebar.includes("/client/chat")
                  ? "common-text text-white f-w-600"
                  : "common-text text-8B8C8D"
              }   mb-0 px-3`}
            >
              Messages
            </span>
          </div>
        </div>
        <div className="user_details py-3 mx-4 px-3 mb-2">
          {/* <img
              className="user_image"
              src={userName.profile_image_path}
              alt=""
            /> */}
          <div className="text-center py-2">
            <p className="mb-0 fw-bold">Client Panel</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(ClientSidebar);
