import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import BubblesLoader from "../BubblesLoader";
import ClearNotificationModal from "../ClearNotificationModal";
import NotificationListItem from "./NotificationListItem";
import {
  getNotificationList,
  deleteNotification,
  RemoveUnseenNotificationAction,
} from "../../../redux/action/admin/AdminAcountsAction";
import EmptyCard from "../EmptyCard";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorMessage from "../ErrorMessage";

function NotificationTable() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifiactionId, setNotifiactionId] = useState("");
  const [error, setError] = useState("");
  const [listError, setListError] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const notificationList = useSelector(
    (state) => state.AdminAcountsReducer.notificationList
  );

  const pageChangeHandler = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    dispatch(getNotificationList(setLoading, page, setHasMore, setListError));
  }, [dispatch, setLoading, page]);

  const handleDeleteNotification = () => {
    dispatch(
      deleteNotification(notifiactionId, setDeleteLoading, setError, setShow)
    );
  };

  useEffect(() => {
    dispatch(RemoveUnseenNotificationAction());
  }, [dispatch]);

  const notificationListArray =
    page === 0 && loading ? (
      <div className="h-100 d-flex justify-content-center align-items-center flex-column">
        <BubblesLoader />
        <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
          Please wait...
        </p>
      </div>
    ) : notificationList && notificationList.length > 0 ? (
      notificationList.map((obj, index) => {
        return (
          <NotificationListItem
            key={index}
            notify={obj}
            setShow={setShow}
            setNotifiactionId={setNotifiactionId}
            setError={setError}
            loading={loading}
          />
        );
      })
    ) : (
      <div
        className={`${loading || notificationList.length === 0 ? "h-100" : ""}`}
      >
        <EmptyCard message={"You don't have any notification list yet!"} />
      </div>
    );

  return (
    <div className="container-fluid">
      <div className="bg-white box-shadow p-sm-4 p-2  mt-3 br-10 overflow-x-hidden">
        {listError !== "" ? <ErrorMessage message={listError} /> : ""}
        <div className="d-flex flex-sm-row flex-column  justify-content-sm-between align-items-center mt-3">
          <p className="common-heading-Medium xs-font  mb-sm-0">
            Notifications
          </p>

          <div className=" d-flex justify-content-end flex-sm-row flex-column">
            <ClearNotificationModal
              setShow={setShow}
              show={show}
              handleDeleteNotification={handleDeleteNotification}
              setNotifiactionId={setNotifiactionId}
              deleteLoading={deleteLoading}
              error={error}
              setError={setError}
              notificationListArray={notificationListArray}
            />
          </div>
        </div>
        <span className="border-bottom d-block pt-3"></span>
        <div className="mt-4  " id="notification-list">
          <div className="infinite_scroll h-100 row">
            <InfiniteScroll
              className={`${
                notificationList.length === 0 || loading
                  ? "justify-content-center h-100"
                  : "scrollbar_desgin d-flex flex  flex-column "
              } scrollbar_desgin`}
              dataLength={notificationList && notificationList.length}
              next={pageChangeHandler}
              hasMore={hasMore}
              scrollableTarget="notification-list"
              loader={
                loading && page > 0 ? (
                  <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                    <BubblesLoader />
                    <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                      Please wait...
                    </p>
                  </div>
                ) : (
                  ""
                )
              }
            >
              {notificationListArray}
            </InfiniteScroll>
            {/* <InfiniteScroll
              className={`${
                (notificationList === 0 &&
                  !loading &&
                  notificationList === 0) ||
                loading
                  ? "justify-content-center h-100"
                  : "scrollbar_desgin d-flex flex  flex-column"
              } scrollbar_desgin`}
              dataLength={notificationList && notificationList.length}
              next={pageChangeHandler}
              hasMore={hasMore}
              scrollableTarget="notification-list"
              loader={
                loading && page > 0 ? (
                  <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                    <BubblesLoader />
                    <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                      Please wait...
                    </p>
                  </div>
                ) : (
                  ""
                )
              }
            >
              {notificationListArray}
            </InfiniteScroll> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(NotificationTable);
