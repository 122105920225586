import React, { useEffect, useState } from "react";
import { Modal, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getChangeRequestList } from "../../../redux/action/client/Job";
import CompleteJobModal from "../../client/createJob/job/jobModel/CompleteJobModal";
import BubblesLoader from "../../common/BubblesLoader";
import EmptyCard from "../../common/EmptyCard";
import ErrorMessage from "../../common/ErrorMessage";
import { CloseIcon } from "../../common/icons/Icon";
import { getTime } from "../../common/utils/Functions";

const CreatorChangeRequest = ({
  showRequest,
  setRequestShow,
  jobId,
  myJobDetails,
}) => {
  const dispatch = useDispatch();
  const changeRequestList = useSelector(
    (state) => state.jobs.changeRequestList
  );
  const [showRating, setShowRating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(getChangeRequestList(jobId, setLoading, setError));
  }, [jobId, dispatch]);

  return (
    <>
      <Modal
        show={showRequest}
        onHide={() => setRequestShow(false)}
        centered
        size="lg"
      >
        <Modal.Header>
          <h5
            className="font-md f-w-600 text-0000 mb-1 mt-3"
            id="exampleModalToggleLabel"
          >
            Change Requests
          </h5>
          <div>
            {myJobDetails && myJobDetails.is_review === false
              ? myJobDetails &&
                myJobDetails.is_completed_by_creator === true && (
                  <button
                    className="atech-primary-button px-3 py-2"
                    onClick={() => setShowRating(true)}
                  >
                    Review
                  </button>
                )
              : ""}
            <button
              className="border-0 bg-transparent"
              onClick={() => setRequestShow(false)}
            >
              <CloseIcon />
            </button>
          </div>
        </Modal.Header>
        <div className="hr m-0-20"></div>
        <Modal.Body>
          {error !== "" ? <ErrorMessage message={error} /> : ""}
          {loading ? (
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <BubblesLoader />
              <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                Please wait...
              </p>
            </div>
          ) : changeRequestList && changeRequestList.length > 0 ? (
            changeRequestList.map((value, index) => {
              return (
                <div className="change-request-modal">
                  <Accordion key={index} className="my-2 ">
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        <div>
                          <p className="common-heading-Medium smll-font  mb-1">
                            Update - {index + 1}
                          </p>
                          <p className="xxs-font common-text mb-0 ">
                            {getTime(value.created_at)}
                          </p>
                        </div>
                      </Accordion.Header>
                      <div className="hr mt-2 mx-3"></div>
                      <Accordion.Body>
                        <p className="xxs-font common-text ">{value.details}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              );
            })
          ) : (
            <EmptyCard message={"You’ve not any Change Requests List Yet!"} />
          )}
        </Modal.Body>
      </Modal>
      <CompleteJobModal
        showRating={showRating}
        setShowRating={setShowRating}
        myJobDetails={myJobDetails}
        jobId={jobId}
      />
    </>
  );
};

export default CreatorChangeRequest;
