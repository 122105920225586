import React from "react";
import clientIcon from "../../../assets/img/png/dashboard_client_image.png";

const TotalClients = ({ adminDashboard, loading }) => {
  return (
    <>
      <div className="col-xxl-4 mt-3 mt-md-0 col-md-6">
        <div className="d-flex p-3 h-100 justify-content-between align-items-center box-shadow br-10 bg-white ">
          <div className="">
            <p className="common-text ">Total Clients</p>
            <div className="d-flex ">
              <div className="common-heading-Medium   d-flex align-items-center">
                <h2 className="common-heading-Medium  ms-2 mb-0">
                  {loading ? (
                    <p className="common-text fs-xs-14 mb-0 fw-bold">
                      Loading...
                    </p>
                  ) : (
                    `${adminDashboard.total_buyer}`
                  )}
                </h2>
              </div>
            </div>
          </div>
          <span
            className="rounded-50 dashboard-icon d-inline-block "
            style={{
              backgroundColor: "#fff6e7",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",

              backgroundImage: `url(${clientIcon})`,
            }}
          ></span>
        </div>
      </div>
    </>
  );
};

export default TotalClients;
