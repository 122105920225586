import {
  GET_CHAT_LIST,
  GET_CHAT_MESSAGE_LIST,
  GET_CURRENT_CHAT_MESSAGE_LIST,
  GET_CURRENT_DISPUTE_MESSAGE_LIST,
  GET_DISPUTES_CHAT_LIST,
  GET_DISPUTE_MESSAGE_LIST,
  START_DISPUTES_USER_DETAILS,
  START_MESSAGE_USER_DETAILS,
} from "../action/chat/ClientChat";

const initialState = {
  chatList: [],
  disputesList: [],
  chatMessageList: [],
  disputeMessageList: [],
  startChatUserDetails: {},
  startDisputesUserDetails: {},
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    // Chat list action for client chat page action (GET)
    case GET_CHAT_LIST: {
      return {
        ...state,
        chatList:
          action.data.page === 0
            ? action.data.data
            : state.chatList.concat(action.data.data),
      };
    }

    // Chat list action for client chat page action (GET)
    case GET_CHAT_MESSAGE_LIST: {
      return {
        ...state,
        chatMessageList:
          action.data.pageNo === 0
            ? action.data.data
            : state.chatMessageList.concat(action.data.data),
      };
    }

    case GET_CURRENT_CHAT_MESSAGE_LIST: {
      const newMessageListArray = [...state.chatMessageList];
      newMessageListArray.unshift(action.data);

      return {
        ...state,
        chatMessageList: newMessageListArray,
      };
    }

    // Chat list action for client chat page action (GET)
    case GET_DISPUTE_MESSAGE_LIST: {
      return {
        ...state,
        disputeMessageList:
          action.data.pageNo === 0
            ? action.data.data
            : state.disputeMessageList.concat(action.data.data),
      };
    }

    case GET_CURRENT_DISPUTE_MESSAGE_LIST: {
      const newMessageListArray = [...state.disputeMessageList];
      newMessageListArray.unshift(action.data);
      return {
        ...state,
        disputeMessageList: newMessageListArray,
      };
    }

    // Chat list action for client chat page action (GET)
    case START_MESSAGE_USER_DETAILS: {
      return {
        ...state,
        startChatUserDetails: action.data,
      };
    }

    // Chat list action for client chat page action (GET)
    case START_DISPUTES_USER_DETAILS: {
      return {
        ...state,
        startDisputesUserDetails: action.data,
      };
    }

    // disputes chat list action for client chat page action (GET)
    case GET_DISPUTES_CHAT_LIST: {
      return {
        ...state,
        disputesList: action.data,
      };
    }
    default:
      return state;
  }
}
