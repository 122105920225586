import React from "react";
import { Route } from "react-router";
import BasicDetails from "../view/client/job/BasicDetails";
import SignificantPlaceEvent from "../view/client/job/SignificantPlaceEvent";
import JobVariousElements from "../view/client/job/JobVariousElements";
import TechnicalAspects from "../view/client/job/TechnicalAspects";
import ClientSidebar from "../components/client/ClientSidebar";
import ClientProfile from "../components/client/profile/ClientProfile";
import ClientJobFeed from "../view/client/ClientJobFeed";
import ChatBox from "../components/chat/ChatBox";
import CreateJobDetailProvider from "../context/CreateJobDetailProvider";
import ClientNotification from "../redux/api/client/ClientNotification";
import ViewJob from "../view/client/job/ViewJob";
import ClientDashboard from "../view/client/ClientDashBoard";
import OnGoingJob from "../view/client/job/OnGoingJob";
import CreateAdminDisputesChat from "../components/chat/AdminDisputesChat/CreateAdminDisputesChat";
import ClientPayment from "../view/client/payment/ClientPayment";
import AdminCreatorProfile from "../view/admin/account/AdminCreatorProfile";

const CreatorRoutes = () => {
  return (
    <>
      <CreateJobDetailProvider>
        <div className="h-100vh d-flex ">
          <ClientSidebar />
          <div className="dashboard-section h-lg-screen">
            <Route exact path="/client/create/job/form">
              <BasicDetails />
            </Route>
            <Route
              exact
              path="/client/create/job/significant-places/event/:jobId"
            >
              <SignificantPlaceEvent />
            </Route>
            <Route exact path="/client/create/job/various/element/:jobId">
              <JobVariousElements />
            </Route>
            <Route exact path="/client/create/job/technical/aspects/:jobId">
              <TechnicalAspects />
            </Route>
            <Route exact path="/client/job-feed/:type">
              <ClientJobFeed />
            </Route>
            <Route exact path="/client/view/:type/jobs/:jobId">
              <ViewJob />
            </Route>
            <Route exact path="/client/:jobType/jobs/:jobId">
              <OnGoingJob />
            </Route>

            <Route exact path="/client/profile">
              <ClientProfile />
            </Route>
            <Route exact path="/">
              <ClientDashboard />
            </Route>
            {/* CHAT   */}
            <Route exact path="/client/chat/:id">
              <ChatBox />
            </Route>
            <Route exact path="/client/disputes/:id">
              <CreateAdminDisputesChat />
            </Route>

            <Route exact path="/client/notification">
              <ClientNotification />
            </Route>
            <Route
              path="/client/payment/:applicationId/:jobId"
              component={ClientPayment}
            />

            <Route exact path="/client/creator/profile/:userId">
              <AdminCreatorProfile />
            </Route>
          </div>
        </div>
      </CreateJobDetailProvider>
    </>
  );
};

export default CreatorRoutes;
