import React from "react";
import WithDrawImg from "../../../../src/assets/img/svg/withdrawimg.svg";

const Withdraw = ({ creatorEarning, loading }) => {
  return (
    <div className="col-xxl-4 mt-4 col-md-6">
      <div className="d-flex p-3  h-100 justify-content-between align-items-center box-shadow br-10 bg-white ">
        <div>
          <p className="common-text ">Available to withdraw</p>
          <p className="common-heading-Medium">
            ${" "}
            {loading
              ? "Loading..."
              : creatorEarning && creatorEarning.amount_available}
          </p>
        </div>
        <span
          className="rounded-50 dashboard-icon d-inline-block "
          style={{
            backgroundColor: "#e7f2fe",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${WithDrawImg})`,
          }}
        ></span>
      </div>
    </div>
  );
};

export default Withdraw;
