import { axiosRequest } from "../../helper";

/**
 * Get Admin all job list api
 * @param {Object} data
 * @returns
 */
export const getAdminAllJobListApi = async (type, page, shortedValue) => {
  return await axiosRequest(
    "GET",
    `/admin/jobs/?type=${type}&&sort=${shortedValue}&&offset=${
      page * 10
    }&&limit=10`
  );
};

/**
 * GET admin job details apis
 * @param {string} jobId
 * @returns
 */
export const getAdminJobDetailsApi = async (jobId) => {
  return await axiosRequest("GET", `/admin/jobs/${jobId}/`);
};

/**
 * admin job aproved by admin api
 * @param {string} jobId
 * @returns
 */
export const adminJobApprovedApi = async (jobId, data) => {
  return await axiosRequest("PATCH", `/admin/jobs/${jobId}/`, data);
};

/**
 * admin job aproved by admin api
 * @param {string} jobId
 * @returns
 */
export const adminRejectJobApi = async (jobId, data) => {
  return await axiosRequest("PATCH", `/admin/jobs/${jobId}/`, data);
};

/**
 * notification list api
 * @param {Object} data
 * @returns
 */
export const getAdminNotificationApi = async (page) => {
  return await axiosRequest(
    "GET",
    `/notifications/?offset=${page * 10}&&limit=10`
  );
};

/**
 * delete notification list api
 * @param {String} notificatinId
 * @returns
 */
export const deleteNotificationApi = async (notificatinId) => {
  return await axiosRequest("DELETE", `/notifications/${notificatinId}/`);
};

/**
 * unseen notification list api
 * @param {String} notificatinId
 * @returns
 */
export const getUnseenNotificationApi = async () => {
  return await axiosRequest("GET", `/notifications/unseen-notifications/`);
};

/**
 * searchJobApi api
 * @param {Object} data
 * @returns
 */
export const searchJobApi = async (type, query) => {
  return await axiosRequest(
    "GET",
    `/admin/jobs/search/?type=${type}&query=${query}`
  );
};
