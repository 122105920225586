import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CloseIcon } from "../../../../common/icons/Icon";
import Jobcomplete from "../../../../../assets/img/png/jobcomplete.png";
import { RatingData } from "../../../../common/Content";
import { creatorJobReviewsAction } from "../../../../../redux/action/creator/CreatorJob";
import ErrorMessage from "../../../../common/ErrorMessage";
import { useDispatch } from "react-redux";
import Loader from "../../../../common/Loader";

const CompleteJobModal = ({
  showRating,
  setShowRating,
  jobId,
  myJobDetails,
}) => {
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [jobValue, setJobValue] = useState({
    commentvalue: "",
    checkedValue: "",
    ratingValue: "",
  });
  const confirmHandler = () => {
    if (jobValue.commentvalue && jobValue.checkedValue !== "" && rating !== 0) {
      setJobValue(jobValue);
      const data = {
        job: jobId,
        application: myJobDetails.application_id,
        rating: rating,
        review_from_creator: jobValue.commentvalue,
        is_Public: true,
      };
      dispatch(
        creatorJobReviewsAction(data, setLoading, setError, setShowRating)
      );
    } else {
      setError("Please fill all the fields");
    }
  };
  const ratingHandler = (index) => {
    setRating(index + 1);
  };

  return (
    <Modal
      show={showRating}
      onHide={() => setShowRating(false)}
      centered
      size="md"
    >
      <Modal.Header className="justify-content-end">
        <button
          className="border-0 bg-transparent"
          onClick={() => setShowRating(false)}
        >
          <CloseIcon />
        </button>
      </Modal.Header>

      <Modal.Body>
        {error !== "" ? <ErrorMessage message={error} /> : ""}
        <img className="mx-auto d-block" src={Jobcomplete} alt="" />
        <p className="lg-font f-w-600 text-center">Job Completed</p>
        <p className="smll-font f-w-500 text-center text-5D5E60">
          ($100 released into your account)
        </p>

        <div className="text-center mb-5">
          {RatingData &&
            RatingData.map((value, index) => {
              return (
                <>
                  <span
                    onClick={() => ratingHandler(index)}
                    className={`${
                      rating === 0
                        ? "star-img-stroke"
                        : `${
                            rating >= index + 1 ? "star-img" : "star-img-stroke"
                          }`
                    } pe-2 cursor-pointer`}
                    key={index}
                  >
                    {value.starImg}
                  </span>
                </>
              );
            })}
        </div>

        <form>
          <div className="mb-3">
            <textarea
              rows="5"
              onChange={(e) =>
                setJobValue({
                  ...jobValue,
                  commentvalue: e.target.value,
                })
              }
              placeholder="Write a review about (optional)"
              className="form-control common-text text-8B8C8D pt-3 px-3 focus"
              id="message-text"
            ></textarea>
          </div>
        </form>
        <input
          type="checkbox"
          onChange={(e) =>
            setJobValue({
              ...jobValue,
              checkedValue: e.target.value,
            })
          }
        />
        <label className="ps-2 smll-font f-w-500 ">Make review public</label>
        <div className="text-center">
          <button
            className="atech-primary-button px-3 py-2"
            onClick={() => confirmHandler()}
            disabled={loading}
          >
            {loading ? <Loader /> : "Confirm"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompleteJobModal;
