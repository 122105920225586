import React from "react";
import ClientATechHeader from "../../../components/client/ClientATechHeader";
import ClientViewJob from "../../../components/client/ClientJobFeed/ClientViewJob";

function ViewJob() {
  return (
    <div className="ff-Project page-color">
      <ClientATechHeader HeaderName={"Jobs"} />
      <ClientViewJob />
    </div>
  );
}

export default ViewJob;
