import React, { useEffect, useState } from "react";
import AdminDisputeListItem from "../../admindasboard/AdminDisputeListItem";
import AdminDashBoardMsgListItem from "../../admindasboard/AdminDashBoardMsgListItem";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import EmptyCard from "../../common/EmptyCard.jsx";
import BubblesLoader from "../../common/BubblesLoader.jsx";
import {
  DisputesChatList,
  getChatList,
} from "../../../redux/action/chat/ClientChat.jsx";
import ErrorMessage from "../../common/ErrorMessage.jsx";

const DashBoardChat = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [disputesLoading, setDisputesLoading] = useState(false);
  const [haseMore, setHaseMore] = useState(false);
  const [disputError, setDisputError] = useState("");
  const [error, setError] = useState("");
  const chatList = useSelector((state) => state.chat.chatList);
  const disputesList = useSelector((state) => state.chat.disputesList);

  useEffect(() => {
    const pageNo = 0;
    dispatch(getChatList(setLoading, setError, pageNo, setHaseMore));
    dispatch(
      DisputesChatList(setDisputesLoading, setDisputError, pageNo, setHaseMore)
    );
  }, [dispatch]);

  return (
    <>
      <div className="col-lg-5 col-12  my-lg-3 my-2 d-flex flex-column h-100">
        <div
          className={`${
            chatList.length > 0 ? "admin-message-height" : ""
          }  dahboard-color mb-3 p-3 h-50`}
        >
          <p className="common-text cursor-pointer">
            {chatList.length > 0 ? "Messages" : ""}
          </p>
          {error !== "" ? <ErrorMessage message={error} /> : ""}
          {loading ? (
            <div className="h-100 d-flex justify-content-center flex-column align-items-center">
              <BubblesLoader />
              <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                Please wait...
              </p>
            </div>
          ) : chatList && chatList.length > 0 ? (
            chatList.map((item, index) => {
              return <AdminDashBoardMsgListItem item={item} key={index} />;
            })
          ) : (
            <EmptyCard message={"You don't have any chat list yet!"} />
          )}
        </div>
        <div
          className={`${
            disputesList.length > 0 || disputesLoading === true
              ? "admin-message-height"
              : ""
          } dahboard-color p-3 h-50`}
        >
          <p className="common-text cursor-pointer">
            {disputesList.length > 0 ? "Disputes" : ""}
          </p>
          {disputError !== "" ? <ErrorMessage message={disputError} /> : ""}
          {disputesLoading ? (
            <div className="h-100 d-flex justify-content-center flex-column align-items-center">
              <BubblesLoader />
              <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                Please wait...
              </p>
            </div>
          ) : disputesList && disputesList.length > 0 ? (
            disputesList.map((item, index) => {
              return (
                <AdminDisputeListItem
                  item={item}
                  key={index}
                  haseMore={haseMore}
                  index={index}
                />
              );
            })
          ) : (
            <EmptyCard message={"You don't have any Disputes list yet!"} />
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(DashBoardChat);
