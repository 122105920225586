import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import uploadImg from "../../../assets/img/svg/upload-img.svg";
import ATechLogo from "../../common/ATechLogo";
import { uploadImage } from "../../../redux/action/UploadImageAction";
import userImg from "../../../assets/img/png/user.png";
import { UpdateUserDetails } from "../../../redux/action/auth/UserDetailsAction";
import { withRouter } from "react-router-dom";
import ErrorMessage from "../../common/ErrorMessage";
import SignupFormWrapper from "../../hoc/SignupFormWrapper";

function SignUpProfile({ history }) {
  const dispatch = useDispatch();
  const fileUrl = useSelector((state) => state.AuthReducer.fileUrl);
  const [loadingImage, setLoadingImage] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [userImage, setUserImg] = useState();
  const [bio, setBio] = useState("");

  const uploadImageValue = (e) => {
    if (e) {
      dispatch(uploadImage(e, setLoadingImage, "image"));
      const image = URL.createObjectURL(e.target.files[0]);
      setUserImg(image);
    }
  };

  const UpdateUser = () => {
    dispatch(
      UpdateUserDetails(
        { bio: bio, file: fileUrl },
        setLoading,
        history,
        "logout",
        setError,
        setOpenProfile
      )
    );
  };

  return (
    <SignupFormWrapper>
      <div className=" col-lg-6 col-sm-10 col-12">
        <div className="bg-white box-shadow br-10 p-sm-5 p-3">
          {/* A Tech Text */}
          <ATechLogo />
          <p className="common-heading mb-5">Sign Up</p>
          {error !== "" ? (
            <ErrorMessage message={error} openProfile={openProfile} />
          ) : (
            ""
          )}
          <div className="row align-items-center">
            <div className="col-xl-4 pb-xl-0 pb-3 d-xl-block d-flex justify-content-xl-start justify-content-center">
              <div className="user-img-border ">
                <span className=" d-inline-block">
                  <img
                    className="user-img object-cover"
                    src={userImage ? userImage : userImg}
                    alt=""
                  />
                </span>
              </div>
            </div>
            <div className="col-xl-8  ">
              <div className=" pt-2  ">
                <input
                  type="file"
                  onChange={(e) => uploadImageValue(e)}
                  id="my-file"
                  hidden
                  accept="image/*"
                />
                <button className="py-3 px-3 atech-fade-button  w-100">
                  <label
                    for="my-file"
                    className={`cursor-pointer${
                      loadingImage ? "loading_form cursor-not-allowed" : ""
                    }`}
                  >
                    <span className="me-2">
                      <img
                        className="px-1 top-22"
                        src={uploadImg}
                        alt="uploadImg"
                      />
                    </span>
                    {loadingImage ? "Loading" : "Upload Image (optional)"}
                  </label>
                </button>
              </div>
            </div>
          </div>
          {/* TEXT AREA */}
          <div className="mt-4 mb-3">
            <Form.Group
              className=" textarea-focus"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                className="inputs_font_size"
                placeholder="Bio (optional)"
                as="textarea"
                rows={5}
                onChange={(e) => setBio(e.target.value)}
              />
            </Form.Group>
          </div>
          {/* Next  Button */}
          <button
            type="submit"
            onClick={() => UpdateUser()}
            disabled={loading}
            className={` w-100  atech-primary-button py-sm-3 px-4 py-2 my-sm-4 my-3 ${
              loading ? "loading_form cursor-not-allowed" : ""
            }`}
          >
            {loading ? "Loading" : "Next"}
          </button>
        </div>
      </div>
    </SignupFormWrapper>
  );
}
export default withRouter(SignUpProfile);
