import { useState } from "react";
import { useDispatch } from "react-redux";
import Deleteicon from "../../../../assets/img/svg/delete-icon.svg";
import { deleteJobElementsFootage } from "../../../../redux/action/client/Job";
import ErrorMessage from "../../../common/ErrorMessage";
import Loader from "../../../common/Loader";

const FormElementsItem = ({
  jobId,
  elementName,
  index,
  type,
  setReloadElements,
  deleteType,
}) => {
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [error, setError] = useState("");

  const handleDeleteElements = (elementId) => {
    dispatch(
      deleteJobElementsFootage(
        jobId,
        deleteType,
        elementId,
        setDeleteLoading,
        setError,
        setReloadElements
      )
    );
  };

  return (
    <div className="d-flex flex-column  px-4  pt-2 ">
      {error !== "" ? <ErrorMessage message={error} /> : ""}
      <div className="d-flex  justify-content-between  overflow-text-scrollbar">
        <div className="d-flex align-items-center ">
          <p className="common-text pe-4 mb-0 white-space-nowrap">#</p>
          <p className="common-text mb-0 white-space-nowrap pe-2">{`${type}-${
            index + 1
          }`}</p>
        </div>
        <div className="d-flex align-items-center ">
          <button
            type="button"
            onClick={() => handleDeleteElements(elementName.id)}
            className="px-4 atech-fade-button py-sm-2 py-2   d-flex  "
          >
            {deleteLoading ? (
              <Loader />
            ) : (
              <>
                <span>
                  <img className="pe-2" src={Deleteicon} alt="Deleteicon" />
                </span>
                Remove
              </>
            )}
          </button>
        </div>
      </div>
      <div className="border-bottom-grey mt-2"></div>
    </div>
  );
};

export default FormElementsItem;
