import React, { useEffect, useState } from "react";
import ATechLogo from "../common/ATechLogo";
import DashboardSvg from "../../assets/img/svg/dashboard.svg";
import JobFeed from "../../assets/img/svg/jobfeed-icon.svg";
import MessagesIcon from "../../assets/img/svg/messages-icon.svg";
import DisputesIcon from "../../assets/img/svg/disputes-icon.svg";
import EarningIcon from "../../assets/img/svg/earning.svg";
import { withRouter } from "react-router";
import { useLayoutProviderContext } from "../../context/LayoutProvider";

function CreatorSideBar({ history }) {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();
  const [activeSidebar, setActiveSidebar] = useState();

  // GET ROUTE FROM HERE
  const pathName = window.location.pathname;

  useEffect(() => {
    setSidebarActive(false);
    if (pathName === "/") {
      setActiveSidebar("/");
    } else if (
      pathName === "/creator/job-feed/recent" ||
      pathName.includes("/creator/job")
    ) {
      setActiveSidebar("/creator/job-feed/recent");
    } else if (pathName.includes("/creator/chat/")) {
      setActiveSidebar("/creator/chat/:id");
    } else if (pathName.includes("/creator/disputes/")) {
      setActiveSidebar("/creator/disputes/:id");
    } else if (pathName.includes("/creator/earning")) {
      setActiveSidebar("/creator/earning");
    }
  }, [pathName, setActiveSidebar, setSidebarActive]);

  return (
    <>
      <div
        className={`${
          sidebarActive ? "active-left" : ""
        } creater-sidebar h-100 none-sidebar cursor-pointer d-flex flex-column justify-content-between`}
      >
        <div className=" d-flex flex-column">
          <div className="text-center pt-4 pb-4">
            <ATechLogo />
          </div>
          <div className="mt-4">
            <div
              onClick={() => history.push("/")}
              className={`${
                activeSidebar === "/" ? "dashboard-btn " : "change-color-btn"
              }`}
            >
              <img
                className={`${activeSidebar === "/" ? "img-white" : ""} mx-4`}
                src={DashboardSvg}
                alt="dashboard"
              />
              <span
                className={`${
                  activeSidebar === "/"
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                Dashboard
              </span>
            </div>
          </div>
          <div className=" my-3">
            <div
              onClick={() => history.push("/creator/job-feed/recent")}
              className={`${
                activeSidebar === "/creator/job-feed/recent"
                  ? "dashboard-btn"
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar === "/creator/job-feed/recent"
                      ? "img-white"
                      : ""
                  } mx-4`}
                  src={JobFeed}
                  alt="JobFeed"
                />
              </span>
              <span
                className={`${
                  activeSidebar === "/creator/job-feed/recent"
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                JobFeed
              </span>
            </div>
          </div>
          <div>
            <div
              onClick={() => history.push("/creator/chat/view")}
              className={`${
                activeSidebar === "/creator/chat/:id"
                  ? "dashboard-btn "
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar === "/creator/chat/:id" ? "img-white" : ""
                  } mx-4`}
                  src={MessagesIcon}
                  alt="MessagesIcon"
                />
              </span>
              <span
                className={`${
                  activeSidebar === "/creator/chat/:id"
                    ? "common-text text-white f-w-600 "
                    : "common-text text-8B8C8D"
                }   mb-0 px-3 `}
              >
                Messages
              </span>
            </div>
          </div>

          <div className=" mt-3">
            <div
              onClick={() => history.push("/creator/disputes/view")}
              className={`${
                activeSidebar === "/creator/disputes/:id"
                  ? "dashboard-btn "
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar === "/creator/disputes/:id" ? "img-white" : ""
                  } mx-4`}
                  src={DisputesIcon}
                  alt="MessagesIcon"
                />
              </span>
              <span
                className={`${
                  activeSidebar === "/creator/disputes/:id"
                    ? "common-text text-white f-w-600 "
                    : "common-text text-8B8C8D"
                }   mb-0 px-3 `}
              >
                Disputes
              </span>
            </div>
          </div>
          <div className=" my-3">
            <div
              onClick={() => history.push("/creator/earning")}
              className={`${
                activeSidebar === "/creator/earning"
                  ? "dashboard-btn "
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar === "/creator/earning" ? "img-white" : ""
                  } mx-4`}
                  src={EarningIcon}
                  alt="MessagesIcon"
                />
              </span>
              <span
                className={`${
                  activeSidebar === "/creator/earning"
                    ? "common-text text-white f-w-600 "
                    : "common-text text-8B8C8D"
                }   mb-0 px-3 `}
              >
                Earnings
              </span>
            </div>
          </div>
        </div>
        <div className="user_details py-3 mx-4 px-3 mb-2">
          {/* <img
              className="user_image"
              src={userName.profile_image_path}
              alt=""
            /> */}
          <div className="text-center py-2">
            <p className="mb-0 fw-bold">Creator Panel</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(CreatorSideBar);
