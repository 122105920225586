import BackIcon from "../../../assets/img/png/back-icon.png";
import ReportIcon from "../../../assets/img/png/report-icon.png";
import ATechHeader from "../../common/ATechHeader";
import { withRouter } from "react-router";
import ViewJobTechnicalElementsButton from "../../client/ClientJobFeed/ViewJobTechnicalElementsButton";
import { useSelector } from "react-redux";
import { getTime } from "../../common/utils/Functions";
import ApplyJobForm from "./ApplyJobForm";
import ErrorMessage from "../../common/ErrorMessage";
import BubblesLoader from "../../common/BubblesLoader";

function RecentJobDetails({ handleShow, history, jobId, error, loading }) {
  const creatorRecentJobDetails = useSelector(
    (state) => state.jobs.creatorRecentJobDetails
  );

  return (
    <div className="container-fluid">
      <ATechHeader HeaderName={"View Job"} />
      <div className="box-shadow bg-white  br-10 p-4 mt-5 mx-3">
        <div className="d-flex justify-content-between">
          <button
            className="px-sm-3 px-2 py-2  atech-fade-button"
            onClick={() => history.push("/creator/job-feed/recent")}
          >
            <span className="px-sm-2">
              <img src={BackIcon} alt="backicon" />
            </span>
            <span className="d-inline-block">Back</span>
          </button>
          <button
            className="px-sm-3 px-2 py-2  atech-fade-button"
            onClick={() => handleShow(true)}
          >
            <span className="px-sm-2">
              <img src={ReportIcon} alt="backicon" />
            </span>
            <span className="d-inline-block ">Report Job</span>
          </button>
        </div>
        <div className="border-bottom my-4"></div>
        {loading ? (
          <div className="h-100 d-flex justify-content-center align-items-center flex-column">
            <BubblesLoader />
            <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
              Please wait...
            </p>
          </div>
        ) : (
          <div className="overflow-x-hidden">
            {" "}
            {error !== "" && <ErrorMessage error={error} />}
            <p className="common-heading-Medium veiw-job-heading">
              {creatorRecentJobDetails && creatorRecentJobDetails.title}
            </p>
            <p className="common-text">
              Posted{" "}
              {getTime(
                creatorRecentJobDetails && creatorRecentJobDetails.created_at
              )}
            </p>
            <p className="common-heading-Medium text-blue">
              $ {creatorRecentJobDetails && creatorRecentJobDetails.price}
            </p>
            <p className="common-text pe-sm-5 me-sm-5">
              {creatorRecentJobDetails && creatorRecentJobDetails.description}
            </p>
            <div className="border-bottom my-4"></div>
            <ViewJobTechnicalElementsButton />
            <ApplyJobForm jobId={jobId} />
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(RecentJobDetails);
