import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import {
  getClientSearchJobByQuery,
  getJobListForClient,
} from "../../../../redux/action/client/jobFeed";
import JobCard from "./JobCard";

function ClientPendingJob({ history }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const pageChangeHandler = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    const types = "my-pending";
    const shortedValue = 0;
    dispatch(
      getJobListForClient(setLoading, types, page, shortedValue, setHasMore)
    );
  }, [dispatch, page]);

  const handleSearchJob = (e) => {
    console.log("searchValue", e.target.value);
    const types = "my-pending";
    if (e.target.value) {
      dispatch(
        getClientSearchJobByQuery(setLoading, types, e.target.value, page)
      );
    }
  };

  const handleShorting = (shortedValue) => {
    const page = 0;
    const types = "my-pending";
    dispatch(getJobListForClient(setLoading, types, page, shortedValue));
  };

  return (
    <JobCard
      history={history}
      handleShorting={handleShorting}
      loading={loading}
      searchValue={searchValue}
      handleSearchJob={handleSearchJob}
      hasMore={hasMore}
      pageChangeHandler={pageChangeHandler}
      page={page}
      setPage={setPage}
    />
  );
}

export default withRouter(ClientPendingJob);
