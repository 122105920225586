import DetailsLocationForm from "../SignificantJobType/DetailsLocationForm";

const LocationDetails = ({
  significantFormdTypes,
  setSignificantFormTypes,
}) => {
  return (
    <div className="mb-md-4 pb-2">
      <label for="inputYes/no" className="form-label common-text mb-md-3 mb-2 ">
        Details of location
      </label>
      <select
        id="inputYes/no"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            type_of_location: e.target.value,
          })
        }
        className="form-select common-text text-8B8C8D form-control border-b9babb br-3 mb-2  py-md-3"
      >
        <option className="common-text text-8B8C8D" selected value="interior">
          Interior
        </option>
        <option className="common-text text-8B8C8D" value="exterior">
          Exterior
        </option>
      </select>

      <textarea
        rows="4"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            type_of_location_explaination: e.target.value,
          })
        }
        className="py-md-3 mt-3  border-b9babb common-text text-8B8C8D form-control"
        id="exampleFormControlInput8"
        placeholder="Describe the location e.g. office building with desks and chairs"
      ></textarea>
      {significantFormdTypes.type_of_location === "exterior" ? (
        <DetailsLocationForm
          setSignificantFormTypes={setSignificantFormTypes}
          significantFormdTypes={significantFormdTypes}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default LocationDetails;
