import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import ShowEye from "../../assets/img/svg/eye-icon.svg";
import ATechLogo from "../common/ATechLogo";
import { loginAction } from "../../redux/action/auth/AuthAction";
import Eye from "../../assets/img/png/Eye.png";
import ErrorMessage from "../common/ErrorMessage";

const LoginForm = ({ history }) => {
  const [hide, setHide] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(loginAction(data, setLoading, history, setError));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white br-10 box-shadow p-sm-5 p-3">
        <ATechLogo />
        <p className="common-heading mb-1">Log In</p>
        <p className="common-text  pb-4">
          Please enter your login credentials to log in
        </p>
        {error !== "" ? <ErrorMessage message={error} /> : ""}
        <div>
          <input
            type="text"
            {...register("username", { required: true })}
            className="form-control py-3 atech-input"
            placeholder="Username or E-mail "
            aria-describedby="emailHelp"
          />
        </div>
        {errors.username?.type === "required" && (
          <p className="common-text xxs-font text-red">Username is required </p>
        )}
        <div className="position-relative my-2">
          <input
            type={hide ? "text" : "password"}
            className="form-control py-3 atech-input"
            placeholder="Password"
            aria-describedby="emailHelp"
            {...register("password", {
              required: true,
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
            })}
          />
          {hide ? (
            <img
              className="eye-icon-img cursor-pointer"
              onClick={() => setHide(!hide)}
              src={Eye}
              alt="ShowEye"
            />
          ) : (
            <img
              className="eye-icon-img cursor-pointer"
              onClick={() => setHide(!hide)}
              src={ShowEye}
              alt="ShowEye"
            />
          )}
          {errors.password?.type === "required" && (
            <p className="common-text xxs-font text-red">
              Password field is required
            </p>
          )}
          {errors.password?.type === "minLength" && (
            <p className="common-text xxs-font text-red">
              {errors.password.message}
            </p>
          )}
          <p
            className="common-text xxs-font text-red pt-1 cursor-pointer"
            onClick={() => history.push("/forgot-password")}
          >
            Forgot Password?
          </p>
        </div>

        <div>
          <button
            type="submit"
            disabled={loading}
            className={` w-100 mt-sm-2 mt-1  atech-primary-button  py-sm-3 px-4 py-2 ${
              loading ? "loading_form cursor-not-allowed" : ""
            }`}
          >
            {loading ? "Loading" : "Log In"}
          </button>
        </div>
        <p className="text-center common-text pt-3 ">
          Not a user?
          <span
            className="common-text text-blue cursor-pointer ps-1"
            onClick={() => history.push("/sign-up")}
          >
            Sign Up
          </span>
        </p>
      </div>
    </form>
  );
};

export default withRouter(LoginForm);
