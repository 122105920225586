import React, { useMemo } from "react";
import countryList from "react-select-country-list";
import Select from "react-select";

const LandMarkForm = ({ significantFormdTypes, setSignificantFormTypes }) => {
  const options = useMemo(() => countryList().getData(), []);

  return (
    <>
      <input
        type="text"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            landmark_name: e.target.value,
          })
        }
        className="form-control py-3 atech-input  mb-2"
        id="exampleFormControlInput6"
        placeholder="Name of Landmark"
      />

      <Select
        className="border-0 focus-input mb-2"
        options={options}
        placeholder="Country"
        menuPlacement="top"
        aria-describedby="emailHelp"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            landmark_country: e.label,
          })
        }
      />

      <input
        type="text"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            landmark_city: e.target.value,
          })
        }
        className="form-control py-3 atech-input mb-2"
        placeholder="City"
        minLength="0"
        maxLength="40"
      />
    </>
  );
};

export default LandMarkForm;
