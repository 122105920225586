import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import uploadImg from "../../../assets/img/svg/upload-img.svg";
import {
  uploadImage,
  RemoveFileUrlLink,
} from "../../../redux/action/UploadImageAction";

function SignUpWorkForm({
  pastWorkArray,
  handleClose,
  setShow,
  openEditFormData,
  index,
}) {
  const dispatch = useDispatch();
  const [loadingImage, setLoadingImage] = useState(false);
  const fileUrl = useSelector((state) => state.AuthReducer.fileUrl);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: openEditFormData && openEditFormData,
  });

  const onSubmit = (data) => {
    if (index === 0 || !!index) {
      pastWorkArray.splice(index, 1, data);
      handleClose();
    } else if (setShow) {
      if (fileUrl) {
        const dataValue = {
          media_link: fileUrl,
          description: data.description,
          title: data.title,
        };
        pastWorkArray.push(dataValue);
        dispatch(RemoveFileUrlLink());
        setShow(false);
      } else {
        Swal.fire("Opps!", "Upload your past work video", "error");
      }
    }
  };

  const uploadVedioValue = (e) => {
    const type = "video";
    dispatch(uploadImage(e, setLoadingImage, type));
  };

  return (
    <div className="atech-border br-10">
      <div className="container ps-lg-0 pt-lg-0 pt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-4">
              <div className="bg-F8F8F8 py-lg-0 py-5 h-100 br-10">
                <div className="d-flex  justify-content-center align-items-center h-100">
                  {fileUrl || pastWorkArray.length > 0 ? (
                    <>
                      <input
                        type="file"
                        id="my-file2"
                        onChange={(e) => uploadVedioValue(e)}
                        hidden
                        disabled={loadingImage}
                        accept="video/mp4"
                      />
                      <button
                        type="button"
                        className="py-2 px-3 atech-fade-button "
                      >
                        <label
                          for="my-file2"
                          className="cursor-pointer text-wrap-css "
                        >
                          <span className="me-2 d-inline-block">
                            <img
                              className=" px-2 top-22"
                              src={uploadImg}
                              alt="uploadImg"
                            />
                          </span>
                          {loadingImage
                            ? "Uploading..."
                            : fileUrl
                            ? fileUrl
                            : pastWorkArray &&
                              pastWorkArray[index] &&
                              pastWorkArray[index].media_link
                            ? pastWorkArray[index].media_link
                            : "Upload Video"}
                        </label>
                      </button>
                    </>
                  ) : (
                    <>
                      <input
                        onChange={(e) => uploadVedioValue(e)}
                        type="file"
                        id="my-file"
                        hidden
                        disabled={loadingImage}
                        accept="video/mp4"
                      />
                      <button
                        type="button"
                        className="py-2 px-3 atech-fade-button "
                      >
                        <label
                          for="my-file"
                          className="cursor-pointer text-wrap-css"
                        >
                          <span className="me-2 ">
                            <img
                              className=" px-2 top-22"
                              src={uploadImg}
                              alt="uploadImg"
                            />
                          </span>
                          {loadingImage ? "Uploading..." : "Upload Video"}
                        </label>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-8 my-4">
              <div className="mb-2  ">
                <input
                  {...register("title", { required: true })}
                  type="text"
                  className="form-control py-3 atech-input"
                  placeholder="Enter Title"
                  aria-describedby="emailHelp"
                />
                {errors.title?.type === "required" && (
                  <p className="common-text xxs-font text-red">
                    Title is required
                  </p>
                )}
              </div>
              <div className=" mb-2">
                <input
                  {...register("description", { required: true })}
                  type="text"
                  className="form-control py-3 atech-input"
                  placeholder="Description(max 100 words)"
                  aria-describedby="emailHelp"
                />
                {errors.description?.type === "required" && (
                  <p className="common-text xxs-font text-red">
                    Description is required
                  </p>
                )}
              </div>
              <div className="d-flex flex-sm-row  flex-column mt-3  pe-sm-0 ">
                <button
                  type="submit"
                  className="px-4 atech-primary-button  py-sm-2 py-2   my-2  "
                >
                  Save-work
                </button>
                <button
                  onClick={() => handleClose()}
                  type="button"
                  className="px-4 atech-fade-button py-sm-2 py-2 ms-sm-2  my-2 "
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default SignUpWorkForm;
