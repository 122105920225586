import React, { useMemo } from "react";
import countryList from "react-select-country-list";
import Select from "react-select";

const CurrentEventForm = ({
  significantFormdTypes,
  setSignificantFormTypes,
}) => {
  const options = useMemo(() => countryList().getData(), []);

  return (
    <>
      <input
        type="text"
        className="py-md-3 mb-2  border-b9babb   common-text text-8B8C8D form-control"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            event_name: e.target.value,
          })
        }
        placeholder="Name of Event"
      />
      <Select
        className="border-0 focus-input mb-2"
        options={options}
        placeholder="Country"
        menuPlacement="top"
        aria-describedby="emailHelp"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            event_country: e.label,
          })
        }
      />
      <input
        type="text"
        className="py-md-3 mb-2  border-b9babb   common-text text-8B8C8D form-control"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            event_city: e.target.value,
          })
        }
        placeholder="City"
      />
      <textarea
        rows="4"
        className="py-md-3 mb-2  border-b9babb scommon-text text-8B8C8D form-control"
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            event_details: e.target.value,
          })
        }
        placeholder="Details of exact location i.e. street names"
      ></textarea>
    </>
  );
};

export default CurrentEventForm;
