import React from "react";
import VariousElement from "../../../components/client/createJob/job/VariousElement";
import ClientATechHeader from "../../../components/client/ClientATechHeader";
import { useLayoutProviderContext } from "../../../context/LayoutProvider";

const JobVariousElements = () => {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();

  return (
    <>
      <ClientATechHeader
        HeaderName={"Profile"}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div className="px-3">
        <div className=" bg-white p-md-4 p-2  border-radius_10 mt-3">
          <div className="container-fluid my-4  profile-scroll">
            <VariousElement />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobVariousElements;
