import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import img_1 from "../../../../assets/img/png/Recording-movie-pana.png";
import { AddJobTechnicalAspects } from "../../../../redux/action/client/Job";
import Loader from "../../../common/Loader";
import JobCreatedSuccessfully from "./JobCreatedSuccessfully";
import ErrorMessage from "../../../common/ErrorMessage.jsx";

const TechinalAspectsList = ({ match, history }) => {
  const { jobId } = match.params;
  const dispatch = useDispatch();
  const [checkedValue, setCheckedValue] = useState("no");

  const [formData, setFormdata] = useState({
    camera_angle: "",
    camera_type: "",
    short_type: "",
    camera_movement: "",
    stablisations_type: "",
    microphone_type: "",
    recorder_type: "",
    resolution: "",
    slow_motion: "",
    duration: 0,
    notes: "",
    isSoundOn: false,
  });
  const handleRadioChange = (e) => {
    setCheckedValue(e.target.value);
    if (checkedValue === "no") {
      setFormdata({
        camera_angle: "",
        camera_type: "",
        short_type: "",
        camera_movement: "",
        stablisations_type: "",
        microphone_type: "",
        recorder_type: "",
        resolution: "",
        slow_motion: "",
        duration: 0,
        notes: "",
        isSoundOn: false,
      });
    }
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const finishHandler = () => {
    const data = {
      is_camera_angle: formData.camera_angle,
      camera_movement: formData.camera_movement,
      camera_type: formData.camera_type,
      durations: parseInt(formData.duration),
      others: formData.notes,
      resulations: formData.resolution,
      slow_motion: formData.slow_motion,
      stabalisation: formData.stablisations_type,
      shot_type: formData.short_type,
      is_sound_on: checkedValue === "yes" ? true : false,
      recorder_type: formData.recorder_type,
      microphone_type: formData.microphone_type,
      job: jobId,
    };
    if (checkedValue === "yes") {
      if (
        formData.camera_angle ||
        formData.camera_movement ||
        formData.camera_type ||
        formData.notes ||
        formData.resolution ||
        formData.slow_motion ||
        formData.microphone_type ||
        formData.recorder_type ||
        formData.stablisations_type ||
        formData.short_type !== "" ||
        formData.duration !== 0
      ) {
        dispatch(AddJobTechnicalAspects(data, setLoading, setShow));
      } else {
        setError(
          "Please fill one filed because the camera specification is selected as yes."
        );
      }
    } else {
      dispatch(AddJobTechnicalAspects(data, setLoading, setShow));
    }
  };

  return (
    <div>
      <Col>
        <section>
          <p className="common-heading-Medium xs-font ">
            Step-4 : Technical Aspects
          </p>
          <div className="border-top-1-E8E8E8 mt-2"></div>
          <Row className=" scroll-bar-add justify-content-center">
            {/* {error !== "" ? <ErrorMessage message={error} /> : ""} */}

            <Col xl={6} className="order-xl-1 order-2">
              <p className="common-text mb-0 py-3">
                Add technical specifications
              </p>
              <input
                type="radio"
                id="no"
                name="fav_language"
                className="cursor-pointer"
                value="no"
                checked={checkedValue === "no"}
                onChange={(e) => handleRadioChange(e)}
              />
                <label for="no">No</label> {" "}
              <input
                type="radio"
                id="yes"
                name="fav_language"
                className="cursor-pointer"
                value="yes"
                checked={checkedValue === "yes"}
                onChange={(e) => handleRadioChange(e)}
              />{" "}
              <label for="yes">Yes</label>
              {checkedValue === "yes" ? (
                <>
                  {" "}
                  <div className="Add-actor-input-description w-100 mt-3  p-2">
                    <textarea
                      onChange={(e) =>
                        setFormdata({
                          ...formData,
                          camera_angle: e.target.value,
                        })
                      }
                      className="form-control border-0 w-100 h-100 textarea_focus_border_0 inputs_font_size"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={formData.camera_angle}
                      placeholder="Describe the camera specs"
                    ></textarea>
                  </div>
                  <div className="Add-actor-input-description w-100 mt-3  p-2">
                    <textarea
                      onChange={(e) =>
                        setFormdata({
                          ...formData,
                          camera_type: e.target.value,
                        })
                      }
                      className="form-control border-0 w-100 h-100 textarea_focus_border_0 inputs_font_size"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={formData.camera_type}
                      placeholder=" Describe Camera Type"
                    ></textarea>
                  </div>
                  <div className="Add-actor-input-description w-100 mt-3  p-2">
                    <textarea
                      onChange={(e) =>
                        setFormdata({
                          ...formData,
                          microphone_type: e.target.value,
                        })
                      }
                      className="form-control border-0 w-100 h-100 textarea_focus_border_0 inputs_font_size"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={formData.microphone_type}
                      placeholder="Describe Microphone"
                    ></textarea>
                  </div>
                  <div className="Add-actor-input-description w-100 mt-3  p-2">
                    <textarea
                      onChange={(e) =>
                        setFormdata({
                          ...formData,
                          recorder_type: e.target.value,
                        })
                      }
                      className="form-control border-0 w-100 h-100 textarea_focus_border_0 inputs_font_size"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={formData.recorder_type}
                      placeholder="Describe  Recorder"
                    ></textarea>
                  </div>
                  <div className="Add-actor-input-description w-100 mt-3  p-2">
                    <textarea
                      onChange={(e) =>
                        setFormdata({
                          ...formData,
                          short_type: e.target.value,
                        })
                      }
                      className="form-control border-0 w-100 h-100 textarea_focus_border_0 inputs_font_size"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={formData.short_type}
                      placeholder="Describe Shot Type"
                    ></textarea>
                  </div>
                  <div className="Add-actor-input-description w-100 mt-3  p-2">
                    <textarea
                      onChange={(e) =>
                        setFormdata({
                          ...formData,
                          camera_movement: e.target.value,
                        })
                      }
                      className="form-control border-0 w-100 h-100 textarea_focus_border_0 inputs_font_size"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={formData.camera_movement}
                      placeholder="Describe Camera Movement"
                    ></textarea>
                  </div>
                  <div className="Add-actor-input-description w-100 mt-3  p-2">
                    <textarea
                      onChange={(e) =>
                        setFormdata({
                          ...formData,
                          stablisations_type: e.target.value,
                        })
                      }
                      className="form-control border-0 w-100 h-100 textarea_focus_border_0 inputs_font_size"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={formData.stablisations_type}
                      placeholder="Describe Stabalisation Type"
                    ></textarea>
                  </div>
                  <div className="Add-actor-input-description w-100 mt-3  p-2">
                    <textarea
                      onChange={(e) =>
                        setFormdata({
                          ...formData,
                          resolution: e.target.value,
                        })
                      }
                      className="form-control border-0 w-100 h-100 textarea_focus_border_0 inputs_font_size"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={formData.resolution}
                      placeholder="Describe Resolution"
                    ></textarea>
                  </div>
                  <div className="Add-actor-input-description w-100 mt-3  p-2">
                    <textarea
                      onChange={(e) =>
                        setFormdata({
                          ...formData,
                          slow_motion: e.target.value,
                        })
                      }
                      className="form-control border-0 w-100 h-100 textarea_focus_border_0 inputs_font_size"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={formData.slow_motion}
                      placeholder="Describe Slow Motion"
                    ></textarea>
                  </div>
                  <div className="Add-actor-input-duration w-100 mt-3  pb-5 ">
                    <input
                      onChange={(e) =>
                        setFormdata({
                          ...formData,
                          duration: e.target.value,
                        })
                      }
                      className="form-control border-0 w-100 h-100 textarea_focus_border_0 inputs_font_size"
                      id="exampleFormControlTextarea1"
                      type="number"
                      placeholder="Video Duration in seconds"
                    />
                  </div>
                  <div className="Add-actor-input-description w-100 my-3 p-2">
                    <textarea
                      onChange={(e) =>
                        setFormdata({
                          ...formData,
                          notes: e.target.value,
                        })
                      }
                      className="form-control border-0 w-100 h-100 textarea_focus_border_0 inputs_font_size"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Other Notes"
                      value={formData.notes}
                    ></textarea>
                  </div>
                </>
              ) : (
                ""
              )}
              {error !== "" ? <ErrorMessage message={error} /> : ""}
              <div className="mt-xl-4 mt-3  pt-lg-4">
                <button
                  onClick={() => finishHandler()}
                  disabled={loading}
                  className={`${
                    loading ? "cursor-not-allowed" : ""
                  } create-job-btn atech-primary-button mr-10`}
                >
                  {loading ? <Loader /> : "Finish"}
                </button>
              </div>
            </Col>

            <Col xl={6} lg={8} md={9} sm={10} className="order-xl-2 order-1">
              <div className="bg-white d-flex  br-10 justify-content-center flex-column align-items-center h-100 p-sm-5 p-2">
                <img className="pb-5 w-100" src={img_1} alt="NoNotification" />
              </div>
            </Col>
          </Row>
        </section>
      </Col>
      <JobCreatedSuccessfully show={show} setShow={setShow} />
    </div>
  );
};

export default withRouter(TechinalAspectsList);
