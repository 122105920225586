import ChatBox from "../components/chat/ChatBox";

const Chat = () => {
  return (
    <div className="ff-Project page-color">
      <ChatBox />
    </div>
  );
};
export default Chat;
