import React, { useState } from "react";
import plusIcon from "../../../assets/img/svg/plus-icon.svg";
import ATechLogo from "../../common/ATechLogo";
import SignUpAddGear from "./SignUpAddGear";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { submitGearDetails } from "../../../redux/action/creator/CreatorGrarsWorkAction";
import UpdateGearForm from "./UpdateGearForm";
import SignupFormWrapper from "../../hoc/SignupFormWrapper";
import Preloader from "../../common/Preloader";

function AddGear({ history }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [gearWorkArray, setGearWorkArray] = useState([]);

  const submitGearWorkDetails = () => {
    if (!!gearWorkArray.length) {
      const gears = {
        gears: gearWorkArray,
      };
      dispatch(submitGearDetails(gears, setLoading, history, "logout"));
    }
  };

  return (
    <SignupFormWrapper>
      <div className="col bg-white box-shadow br-10 ">
        <div className=" p-lg-5 p-sm-4 p-2 ">
          <ATechLogo />
          <p className="common-heading mb-3">Add Gear</p>
          {/**Sign Up gear form */}
          <UpdateGearForm
            gearWorkArray={gearWorkArray}
            setGearWorkArray={setGearWorkArray}
          />
          {/** Add past Gear form */}
          {show ? (
            <SignUpAddGear
              gearWorkArray={gearWorkArray}
              show={show}
              setShow={setShow}
            />
          ) : null}

          <div className="d-flex  flex-sm-row flex-column justify-content-sm-end justify-content-center mt-3 px-0 ">
            <button
              type="button"
              className="px-3 atech-fade-button py-sm-2 py-2 ms-sm-2  my-2 "
              onClick={() => setShow(true)}
            >
              <span>
                <img className="px-2" src={plusIcon} alt="" />
                <span> Add Work</span>
              </span>
            </button>
          </div>

          <div className="d-flex  flex-sm-row flex-column justify-content-sm-start justify-content-center mt-3 px-0 ">
            <button
              onClick={() => submitGearWorkDetails()}
              disabled={
                gearWorkArray.length > 0 ? false : loading ? loading : true
              }
              className={`px-4 atech-primary-button py-sm-3 py-2 ms-sm-2  my-2 ${
                !!gearWorkArray.length
                  ? "cursor-pointer"
                  : loading
                  ? "cursor-not-allowed"
                  : "cursor-not-allowed"
              }`}
            >
              {loading ? "Loading..." : "Next"}
            </button>
            <button
              onClick={() => {
                <Preloader />;
                history.push("/");
                window.location.reload();
              }}
              className="px-4 atech-fade-button py-sm-3 py-2 ms-sm-2  my-2 "
            >
              Skip & Finish
            </button>
          </div>
        </div>
      </div>
    </SignupFormWrapper>
  );
}
export default withRouter(AddGear);
