import React from "react";
import ClientATechHeader from "../../../components/client/ClientATechHeader";
import NotificationTable from "../../../components/common/notification/NotificationTable";

function ClientNotification() {
  return (
    <div className="ff-Project page-color ">
      <ClientATechHeader HeaderName={"Notifications"} />
      <NotificationTable />
    </div>
  );
}

export default ClientNotification;
