import React from "react";
import ClientATechHeader from "../../../components/client/ClientATechHeader";
import ViewOnGoingJob from "../../../components/client/ClientJobFeed/JobModel/ViewOnGoingJob";
import AdminATechHeader from "../../../components/common/AdminATechHeader";

function OnGoingJob() {
  return (
    <div className="ff-Project page-color">
      {window.location.pathname.includes("/admin/jobs/view") ? (
        <AdminATechHeader HeaderName={"Profile"} />
      ) : (
        <ClientATechHeader HeaderName={"Jobs"} />
      )}
      <ViewOnGoingJob />
    </div>
  );
}

export default OnGoingJob;
