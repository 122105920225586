import { useEffect, useState } from "react";
import DisputesChatMessage from "./DisputesChatMessage";
import AdminATechHeader from "../../common/AdminATechHeader";
import AdminDisputesMessagaeList from "./AdminDisputesMessagaeList";
import NoMessages from "../../nullStates/NoMessages";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  DisputesChatList,
  getDisputeMessageList,
  startDisputesUserDetails,
} from "../../../redux/action/chat/ClientChat.jsx";
import ClientATechHeader from "../../client/ClientATechHeader";
import ATechHeader from "../../common/ATechHeader";
import ErrorMessage from "../../common/ErrorMessage";
import BubblesLoader from "../../common/BubblesLoader";

const CreateAdminDisputesChat = ({ match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const pathname = window.location.pathname;

  const disputesList = useSelector((state) => state.chat.disputesList);
  const disputeMessageList = useSelector(
    (state) => state.chat.disputeMessageList
  );
  const startDisputesUserDetailsList = useSelector(
    (state) => state.chat.startDisputesUserDetails
  );

  const [clickOnChat, setClickOnChat] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [hasMessageMore, setHasMessageMore] = useState(true);

  const pageChangeHandler = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    dispatch(DisputesChatList(setLoading, setError, page, setHasMore));
  }, [dispatch, page]);

  const pageMessageChangeHandler = () => {
    setPageNo(pageNo + 1);
  };

  useEffect(() => {
    if (id !== "view") {
      dispatch(
        getDisputeMessageList(
          id,
          setMessageLoading,
          setError,
          pageNo,
          setHasMessageMore
        )
      );
    }
  }, [id, dispatch, setError, setMessageLoading, pageNo, setHasMessageMore]);

  useEffect(() => {
    if (id !== "view") {
      dispatch(startDisputesUserDetails(id));
    }
  }, [id, dispatch]);

  return (
    <div className="ff-Project page-color">
      {pathname.includes("/admin") ? (
        <AdminATechHeader HeaderName={"Messages"} />
      ) : pathname.includes("/client") ? (
        <ClientATechHeader HeaderName={"Messages"} />
      ) : pathname.includes("/creator") ? (
        <ATechHeader HeaderName={"Messages"} />
      ) : (
        ""
      )}
      {error !== "" ? (
        <div className="mx-3 mt-2">
          <ErrorMessage message={error} />
        </div>
      ) : (
        ""
      )}
      {loading ? (
        <div className="h-calc-100vh-170 d-flex justify-content-center align-items-center flex-column ">
          <BubblesLoader />
          <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
            Please wait...
          </p>
        </div>
      ) : disputesList && disputesList.length > 0 ? (
        <div className="bg-white p-lg-4 p-2 mx-3  mt-4 br-10">
          <div className="container-fluid">
            <div className="row">
              <div
                className={`${
                  clickOnChat || showChat ? "friend-list-none " : ""
                } myjob mt-lg-0 mt-4  col-xxl-3 col-lg-4 col-sm-12 border-end-e8e8e8 pr-lg-20 `}
              >
                <AdminDisputesMessagaeList
                  page={page}
                  pageChangeHandler={pageChangeHandler}
                  hasMore={hasMore}
                  setShowChat={setShowChat}
                  loading={loading}
                  setPageNo={setPageNo}
                  userId={id}
                  disputesList={disputesList}
                  error={error}
                />
              </div>

              <div
                className={`${
                  clickOnChat || showChat
                    ? "show-chat d-flex flex-column  chat-box-height"
                    : "hide-chat"
                }  col-xxl-9 col-lg-8 col-sm-12 ps-lg-4   `}
              >
                {id === "view" ? (
                  <NoMessages
                    message={"Select You friend and start chating."}
                  />
                ) : messageLoading && pageNo === 0 ? (
                  <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                    <BubblesLoader />
                    <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                      Please wait...
                    </p>
                  </div>
                ) : (
                  <DisputesChatMessage
                    disputeMessageList={disputeMessageList}
                    setShowChat={setShowChat}
                    setClickOnChat={setClickOnChat}
                    startDisputesUserDetailsList={startDisputesUserDetailsList}
                    hasMessageMore={hasMessageMore}
                    pageMessageChangeHandler={pageMessageChangeHandler}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-calc-100vh-190 mt-3 mt-lg-4">
          <NoMessages message={"You don't have any Dispute chat list yet!"} />
        </div>
      )}
    </div>
  );
};
export default withRouter(CreateAdminDisputesChat);
