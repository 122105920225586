import { useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AddReporterJobDetails } from "../../../../../redux/action/client/JobForm";
import Loader from "../../../../common/Loader";
import ErrorMessage from "../../../../common/ErrorMessage";
import { withRouter } from "react-router-dom";

function AddReporterForm({ match, setReloadElements }) {
  const { jobId } = match.params;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setShow(false);
    setError("");
  };

  const handleShow = () => setShow(true);

  const onSubmit = (data) => {
    const dataValue = { job: Number(jobId) };
    const finalResult = Object.assign(data, dataValue);
    dispatch(
      AddReporterJobDetails(
        finalResult,
        setLoading,
        setError,
        handleClose,
        setReloadElements
      )
    );
    reset();
  };
  return (
    <>
      <div className="my-sm-0 my-2  me-sm-2">
        <Button
          onClick={handleShow}
          className="atech-primary-button  primary-button-padding mr-10 w-100"
        >
          Add Reporter
        </Button>

        <Offcanvas
          className="Sidebar-width h-100vh "
          show={show}
          placement="end"
          onHide={handleClose}
        >
          <Offcanvas.Body className="d-flex justify-content-center   pt-lg-1">
            <div className="container">
              <Offcanvas.Header
                className="p-0 py-3 d-flex justify-content-end"
                closeButton
              ></Offcanvas.Header>
              <div className="w-100 pt-sm-2 pb-3">
                <h1 className="dash_head">Add Reporter</h1>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                {error !== "" ? <ErrorMessage message={error} /> : ""}
                <div className="my-2">
                  <Form.Group
                    className=" textarea-focus"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      {...register("details", { required: true })}
                      placeholder="Details e.g. Clothing Preference"
                      as="textarea"
                      rows={5}
                      className="inputs_font_size"
                    />
                    {errors.details?.type === "required" && (
                      <p className="common-text xxs-font text-red">
                        Details is required
                      </p>
                    )}
                  </Form.Group>
                </div>
                <div className="my-2">
                  <Form.Group
                    className=" textarea-focus"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      {...register("actions", { required: true })}
                      placeholder="Actions : What would you like the reporter to do?"
                      as="textarea"
                      rows={5}
                      className="inputs_font_size"
                    />
                    {errors.actions?.type === "required" && (
                      <p className="common-text xxs-font text-red">
                        Actions is required
                      </p>
                    )}
                  </Form.Group>
                </div>
                <div className="my-2">
                  <Form.Group
                    className=" textarea-focus"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      {...register("dialogs", { required: true })}
                      placeholder="Dialogue: What would you like the actor to say?"
                      as="textarea"
                      rows={5}
                      className="inputs_font_size"
                    />
                    {errors.dialogs?.type === "required" && (
                      <p className="common-text xxs-font text-red">
                        Dialogs is required
                      </p>
                    )}
                  </Form.Group>
                </div>

                <div className="d-flex flex-sm-row  flex-column mt-3">
                  <button
                    className={`${
                      loading ? "cursor-not-allowed" : ""
                    } px-4 atech-primary-button  py-sm-2 py-2 ms-sm-2  my-2`}
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Save"}
                  </button>
                  <button
                    className="px-4  py-sm-2 py-2 ms-sm-2  my-2  atech-fade-button"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}

export default withRouter(AddReporterForm);
