import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import ATechLogo from "../../common/ATechLogo";
import DashboardSvg from "../../../assets/img/svg/dashboard.svg";
import JobFeed from "../../../assets/img/svg/jobfeed-icon.svg";
import MessagesIcon from "../../../assets/img/svg/messages-icon.svg";
import DisputesIcon from "../../../assets/img/svg/disputes-icon.svg";
import AccountsIcon from "../../../assets/img/svg/accounts-icon.svg";
import ReportIcon from "../../../assets/img/svg/report-icon.svg";
import Payout from "../../../assets/img/svg/payout.svg";
import { useLayoutProviderContext } from "../../../context/LayoutProvider";

function AdminSidebar({ history }) {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();
  const [activeSidebar, setActiveSidebar] = useState();

  // GET ROUTE FROM HERE
  const pathName = window.location.pathname;

  useEffect(() => {
    setSidebarActive(false);
    if (pathName === "/") {
      setActiveSidebar("/");
    } else if (
      pathName === "/admin/jobs/pending-jobs" ||
      pathName.includes("/admin/jobs")
    ) {
      setActiveSidebar("/admin/jobs/pending-jobs");
    } else if (pathName.includes("/admin/chat")) {
      setActiveSidebar("/admin/chat/:id");
    } else if (pathName.includes("/payout")) {
      setActiveSidebar("/payout");
    } else if (pathName.includes("/admin/disputes/view")) {
      setActiveSidebar("/admin/disputes/view");
    } else if (
      pathName === "/admin/accounts" ||
      pathName.includes("/admin/accounts")
    ) {
      setActiveSidebar("/admin/accounts");
    } else if (
      pathName === "/admin/user/report" ||
      pathName.includes("/admin/user/report")
    ) {
      setActiveSidebar("/admin/user/report");
    } else if (
      pathName === "/admin/job/report" ||
      pathName.includes("/admin/job/report/")
    ) {
      setActiveSidebar("/admin/job/report");
    }
  }, [pathName, setActiveSidebar, setSidebarActive]);

  return (
    <>
      <div
        className={`${
          sidebarActive ? "active-left  " : ""
        } creater-sidebar h-100 none-sidebar cursor-pointer d-flex flex-column justify-content-between overflow-auto siderbar-scrollbar`}
      >
        <div className=" d-flex flex-column">
          <div className="text-center pt-4 pb-4">
            <ATechLogo />
          </div>
          <div className="mt-2">
            <div
              onClick={() => history.push("/")}
              className={`${
                activeSidebar === "/" ? "dashboard-btn" : "change-color-btn"
              }`}
            >
              <img
                className={`${activeSidebar === "/" ? "img-white" : ""} mx-4`}
                src={DashboardSvg}
                alt="dashboard"
              />
              <span
                className={`${
                  activeSidebar === "/"
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                Dashboard
              </span>
            </div>
          </div>
          <div className=" mt-2">
            <div
              onClick={() => history.push("/admin/jobs/pending-jobs")}
              className={`${
                activeSidebar === "/admin/jobs/pending-jobs"
                  ? "dashboard-btn"
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar === "/admin/jobs/pending-jobs"
                      ? "img-white"
                      : ""
                  } mx-4`}
                  src={JobFeed}
                  alt="JobFeed"
                />
              </span>
              <span
                className={`${
                  activeSidebar === "/admin/jobs/pending-jobs"
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                Jobs
              </span>
            </div>
          </div>

          {/* REDIRECT TO CHAT ROUTE  */}
          <div className="mt-2">
            <div
              onClick={() => history.push("/admin/chat/view")}
              className={`${
                activeSidebar === "/admin/chat/:id"
                  ? "dashboard-btn"
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar === "/admin/chat/:id" ? "img-white" : ""
                  } mx-4`}
                  src={MessagesIcon}
                  alt="MessagesIcon"
                />
              </span>
              <span
                className={`${
                  activeSidebar === "/admin/chat/:id"
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                Messages
              </span>
            </div>
          </div>
          <div className="mt-2">
            <div
              onClick={() => history.push("/admin/disputes/view")}
              className={`${
                activeSidebar === "/admin/disputes/view"
                  ? "dashboard-btn"
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar === "/admin/disputes/view" ? "img-white" : ""
                  } mx-4`}
                  src={DisputesIcon}
                  alt="MessagesIcon"
                />
              </span>
              <span
                className={`${
                  activeSidebar === "/admin/disputes/view"
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                Disputes
              </span>
            </div>
          </div>
          <div className="mt-2">
            <div
              onClick={() => history.push("/admin/job/report")}
              className={`${
                activeSidebar === "/admin/job/report"
                  ? "dashboard-btn"
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar === "/admin/job/report" ? "img-white" : ""
                  } mx-4`}
                  src={ReportIcon}
                  alt="MessagesIcon"
                />
              </span>
              <span
                className={`${
                  activeSidebar === "/admin/job/report"
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                Job Reports
              </span>
            </div>
          </div>
          <div className="mt-2">
            <div
              onClick={() => history.push("/admin/user/report")}
              className={`${
                activeSidebar === "/admin/user/report"
                  ? "dashboard-btn"
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar === "/admin/user/report" ? "img-white" : ""
                  } mx-4`}
                  src={ReportIcon}
                  alt="MessagesIcon"
                />
              </span>
              <span
                className={`${
                  activeSidebar === "/admin/user/report"
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                User Reports
              </span>
            </div>
          </div>
          <div className="mt-2">
            <div
              onClick={() => history.push("/admin/accounts/creator")}
              className={`${
                activeSidebar === "/admin/accounts"
                  ? "dashboard-btn"
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar === "/admin/accounts" ? "img-white" : ""
                  } mx-4`}
                  src={AccountsIcon}
                  alt="MessagesIcon"
                />
              </span>
              <span
                className={`${
                  activeSidebar === "/admin/accounts"
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                Accounts
              </span>
            </div>
          </div>
          <div className="mt-2">
            <div
              onClick={() => history.push("/payout")}
              className={`${
                activeSidebar === "/payout"
                  ? "dashboard-btn"
                  : "change-color-btn"
              }`}
            >
              <span>
                <img
                  className={`${
                    activeSidebar === "/payout" ? "img-white" : ""
                  } mx-4`}
                  src={Payout}
                  alt="MessagesIcon"
                />
              </span>
              <span
                className={`${
                  activeSidebar === "/payout"
                    ? "common-text text-white f-w-600"
                    : "common-text text-8B8C8D"
                }   mb-0 px-3`}
              >
                PayOut
              </span>
            </div>
          </div>
        </div>
        <div className="user_details py-4 mx-4 px-3 mb-2">
          {/* <img
              className="user_image"
              src={userName.profile_image_path}
              alt=""
            /> */}
          <div className="text-center py-2">
            <p className="mb-0 fw-bold">Admin Panel</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(AdminSidebar);
