import React from "react";
import AngryImg from "../../../../assets/img/png/angry.png";
import SmileImg from "../../../../assets/img/png/smile.png";
import UserImg from "../../../../assets/img/png/userImg.png";

const ClientGraph = ({ dashBoardLoading, creatorDashboard }) => {
  return (
    <div className="col-xxl-3 mt-3 mt-md-0 col-md-6">
      <div className="d-flex p-3 h-100 justify-content-between align-items-center box-shadow br-10 bg-white ">
        <div>
          <p className="common-text ">Clients</p>
          <div className="d-flex align-items-center">
            <div className="common-heading-Medium   d-flex align-items-center">
              <div className="smile-icon">
                <img src={SmileImg} alt="" />
              </div>
              <h2 className="common-heading-Medium  ms-2 mb-0">
                {dashBoardLoading ? (
                  <span className="common-text fs-xs-14 fw-bold text-black mb-0 ">
                    Loading
                  </span>
                ) : (
                  creatorDashboard.total_happy_clients
                )}
              </h2>
            </div>
            <div className="common-heading-Medium ms-xlg-3 ms-2 d-flex align-items-center">
              <div className="smile-icon">
                <img src={AngryImg} alt="" />
              </div>
              <h2 className="common-heading-Medium  ms-2 mb-0 ">
                {dashBoardLoading ? (
                  <span className="common-text fs-xs-14 fw-bold text-black mb-0 ">
                    Loading
                  </span>
                ) : (
                  creatorDashboard.total_angry_clients
                )}
              </h2>
            </div>
          </div>
        </div>
        <span
          className="rounded-50 dashboard-icon d-inline-block "
          style={{
            backgroundColor: "#e7f2fe",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${UserImg})`,
          }}
        ></span>
      </div>
    </div>
  );
};

export default ClientGraph;
