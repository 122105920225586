import React from "react";
import { Route } from "react-router";
import CreaterSidebar from "../components/creator/CreatorSideBar";
import Dashboard from "../view/creator/Dashboard";
import Notification from "../view/Notification";
import Chat from "../view/Chat";
import CreatorProfile from "../view/creator/CreatorProfile";
import MyJobView from "../view/creator/MyJobView";
import CreaterJobDetails from "../view/creator/job/CreaterJobDetails";
import CreatorJobFeed from "../view/creator/job/CreatorJobFeed";
import CreateAdminDisputesChat from "../components/chat/AdminDisputesChat/CreateAdminDisputesChat";
import Earning from "../view/creator/earning/Earning";
import AdminCreatorProfile from "../view/admin/account/AdminCreatorProfile";

const CreatorRoutes = ({ userName }) => {
  return (
    <>
      <div className="h-100vh d-flex ">
        <CreaterSidebar userName={userName} />
        <div className="dashboard-section h-lg-screen">
          <>
            {/**==================== DashBoard Routes ====================*/}
            <Route exact path="/">
              <Dashboard />
            </Route>

            <Route path="/creator/job-feed/:type">
              <CreatorJobFeed />
            </Route>

            <Route exact path="/creator/job-view/details/:jobId">
              <CreaterJobDetails />
            </Route>
            {/** ==================== Payment Routes ==================== */}

            {/** Notification Routes */}
            <Route exact path="/creator/notification">
              <Notification />
            </Route>
            {/** Chat Routes */}

            <Route exact path="/creator/chat/:id">
              <Chat />
            </Route>

            <Route exact path="/creator/disputes/:id">
              <CreateAdminDisputesChat />
            </Route>
            <Route exact path="/creator/earning">
              <Earning />
            </Route>

            {/** Profile Routes */}

            <Route exact path="/creator/profile/edit">
              <CreatorProfile />
            </Route>

            <Route exact path="/creator/view/:jobType/:jobId">
              <MyJobView />
            </Route>

            <Route exact path="/creator/client/profile/:userId">
              <AdminCreatorProfile />
            </Route>
          </>
        </div>
      </div>
    </>
  );
};

export default CreatorRoutes;
