import React from "react";

function AdminJobsTypes({ history, types }) {
  return (
    <div className="border-right job-feed-type-section ">
      <div className=" px-sm-3  mt-3 ">
        <div>
          <p className="common-text f-w-600 xs-font mb-0">Job Feed</p>
        </div>
        <div className="d-flex  flex-column justify-content-xl-between">
          <div
            className="form-check py-2 radio-button"
            onClick={() => history.push("/admin/jobs/pending-jobs")}
          >
            <input
              className="form-check-input cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value={"pending-jobs"}
              checked={types === "pending-jobs" ? true : false}
            />
            <label
              className="form-check-label common-text"
              for="flexRadioDefault1"
            >
              Pending Jobs
            </label>
          </div>
          <div
            className="form-check py-2"
            onClick={() => history.push("/admin/jobs/all-jobs")}
          >
            <input
              className="form-check-input cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              value={"all-jobs"}
              checked={types === "all-jobs" ? true : false}
            />
            <label
              className="form-check-label common-text"
              for="flexRadioDefault2"
            >
              All Jobs
            </label>
          </div>
          <div
            className="form-check py-2"
            onClick={() => history.push("/admin/jobs/completed-jobs")}
          >
            <input
              className="form-check-input cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault`"
              value={"completed-jobs"}
              checked={types === "completed-jobs" ? true : false}
            />
            <label
              className="form-check-label common-text"
              for="flexRadioDefault3"
            >
              Completed Jobs
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminJobsTypes;
