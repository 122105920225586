import React, { useContext, useState } from "react";

const CreateJobDetailProviderContext = React.createContext();

export function useCreateJobDetailProviderContext() {
  return useContext(CreateJobDetailProviderContext);
}

//  FOOTAGE CONTAIN FAMOUS LANDMARK

const initialLandmarkForm = {
  name: "",
  country: "",
  city: "",
};

// FOOTAGE CONTAIN DURING EVENT INITIAL STATE

const initialEventValue = [
  {
    name: "",
    country: "",
    state: "",
    details: "",
    city: "",
  },
];
//  FOOTAGE HAVE AN EXECT LOCATION
const initialLocation = [
  {
    city: "",
    country: "",
    state: "",
    postcode: "",
    details: "",
  },
];

// ADD ACTOR FORM
const initialActor = {
  age: "",
  height: "",
  eyeColor: "",
  hairColor: "",
  ethnicity: "",
  gender: "",
  clothing: "",
  bodyType: "",
  otherDetails: "",
  actions: "",
  dialogs: "",
};

// FINAL FORM OF CREATE JOB FORM
const initialState = {
  isLandmark: false,
  isCurrentEvent: false,
  isNo: false,
  isCurrentLocation: false,
  landmarks: [],
  currentEvent: [
    {
      name: "",
      country: "",
      state: "",
      details: "",
      city: "",
    },
  ],
  locationDetails: {
    locationType: "",
    details: "",
    condition: "",
    extremes: "",
  },
  actors: [],
};

export default function CreateJobDetailProvider({ children }) {
  const [actor, setActor] = useState(initialActor);
  const [landmarkForm, setLandmarkForm] = useState(initialLandmarkForm);
  const [eventForm, setEventForm] = useState(initialEventValue);
  const [locationForm, setLocationForm] = useState(initialLocation);
  const [jobForm, SetJobForm] = useState(initialState);

  const [showSide, setShowSide] = useState("");

  const createJobFormHandler = () => {
    if (jobForm.isLandmark) {
      SetJobForm((pre) => ({ ...pre, landmarks: [{ ...landmarkForm }] }));
    }
  };

  const value = {
    createJobFormHandler,
    SetJobForm,
    jobForm,
    landmarkForm,
    setLandmarkForm,
    eventForm,
    setEventForm,
    locationForm,
    setLocationForm,
    actor,
    setActor,
    showSide,
    setShowSide,
  };

  return (
    <CreateJobDetailProviderContext.Provider value={value}>
      {children}
    </CreateJobDetailProviderContext.Provider>
  );
}
