import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ShowEye from "../../../assets/img/svg/eye-icon.svg";
import { SignUpAction } from "../../../redux/action/auth/AuthAction";
import Loader from "../../common/Loader";
import Eye from "../../../assets/img/png/Eye.png";
import ErrorMessage from "../../common/ErrorMessage";

function SignUpForm({ history }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [usernameValue, setUsername] = useState("");
  const [hideCreatePassword, setHideCreatePassword] = useState(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(false);
  var nameRegex = /^[a-zA-Z1-9\-]+$/;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (nameRegex.test(usernameValue) === true) {
      dispatch(SignUpAction(data, setLoading, history, setError));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error !== "" ? <ErrorMessage message={error} /> : ""}
      <div className="my-2 select-field">
        <select
          {...register("is_creator", { required: true })}
          className="form-select py-3 cursor-pointer"
        >
          <option value={true}>I’m a Creator</option>
          <option value={false}>I’m a Buyer</option>
        </select>
      </div>
      {errors.is_creator?.type === "required" && (
        <p className="common-text xxs-font text-red">Please select any field</p>
      )}
      <div>
        <input
          type="email"
          {...register("email", { required: true })}
          className="form-control py-3 atech-input"
          placeholder="Email Address"
          aria-describedby="emailHelp"
        />
      </div>
      {errors.email?.type === "required" && (
        <p className="common-text xxs-font text-red"> Email is required </p>
      )}
      <div className=" my-2">
        <input
          type="text"
          {...register("username", { required: true })}
          className="form-control py-3 atech-input"
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      {errors.username?.type === "required" ? (
        <p className="common-text xxs-font text-red"> Username is required </p>
      ) : usernameValue !== "" && nameRegex.test(usernameValue) === false ? (
        <p className="common-text xxs-font text-red"> Enter Valid Username </p>
      ) : (
        ""
      )}
      <div className="position-relative my-2 field-focus">
        <div>
          <input
            type={hideCreatePassword ? "text" : "password"}
            {...register("password", {
              required: true,
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
            })}
            className="form-control py-3 atech-input"
            placeholder="Create Password"
            aria-describedby="emailHelp"
          />
        </div>
        {/* Hide Eye Icon */}
        {hideCreatePassword ? (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setHideCreatePassword(!hideCreatePassword)}
            src={Eye}
            alt=""
          />
        ) : (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setHideCreatePassword(!hideCreatePassword)}
            src={ShowEye}
            alt=""
          />
        )}
      </div>
      {errors.password?.type === "required" && (
        <p className="common-text xxs-font text-red">
          Password field is required
        </p>
      )}
      {errors.password?.type === "minLength" && (
        <p className="common-text xxs-font text-red">
          {errors.password.message}
        </p>
      )}
      <div className="position-relative my-2 field-focus">
        <div>
          <input
            {...register("confirm_password", {
              validate: (value) => value === watch("password"),
            })}
            type={hideConfirmPassword ? "text" : "password"}
            className="form-control py-3 atech-input"
            placeholder="Confirm Password"
            aria-describedby="emailHelp"
          />
        </div>
        {/* Hide Eye Icon */}
        {hideConfirmPassword ? (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
            src={Eye}
            alt=""
          />
        ) : (
          <img
            className="eye-icon-img cursor-pointer"
            onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
            src={ShowEye}
            alt=""
          />
        )}
      </div>
      {errors.confirm_password?.type === "validate" && (
        <p className="common-text xxs-font text-red">
          The passwords do not match
        </p>
      )}
      {/* Next  Button */}
      <div className="btn-focus">
        <button
          type="submit"
          disabled={loading}
          className={`${
            loading ? "cursor-not-allowed" : ""
          } w-100 mt-sm-2 mt-1  atech-primary-button py-sm-3 px-4 py-2`}
        >
          {loading ? <Loader /> : "Next"}
        </button>
      </div>
    </form>
  );
}
export default SignUpForm;
