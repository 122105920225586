import React from "react";
import ATechHeader from "../../components/common/ATechHeader";
import MyJobDetails from "../../components/creator/jobFeed/MyJobDetails";

function MyJob() {
  return (
    <>
      <div className="mx-sm-3">
        <ATechHeader HeaderName={"View Job"} />
      </div>
      <div className="d-flex flex-xl-row flex-column  mt-4 mx-3">
        <MyJobDetails />
      </div>
    </>
  );
}

export default MyJob;
