import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../common/ErrorMessage";
import Loader from "../../../common/Loader";
import FormElementsItem from "./FormElementsItem";

const FormElementsName = ({ loading, error, setReloadElements }) => {
  const jobList = useSelector((state) => state.jobs.jobList);

  const {
    actors,
    animals,
    birds,
    buildings,
    cgi,
    effects,
    green_screen_background,
    marine,
    modals,
    property,
    reporters,
    trees,
    job,
    vehicles,
    extras,
  } = jobList && jobList;

  return (
    <Col xxl={6} lg={5} className=" mt-lg-0 mt-3 max_height_745 ">
      <div className="model-border py-3 h-100 overflow-auto scrollbar_desgin">
        <div className="d-flex flex-column  px-4  pt-3">
          {error !== "" ? <ErrorMessage message={error} /> : ""}
          <div className="d-flex align-items-center pb-2">
            <p className="common-text pe-4 mb-0">#</p>
            <p className="common-text mb-0">Element Name</p>
          </div>
          <div className="border-bottom-grey"></div>
        </div>
        {loading ? (
          <div className="text-center py-5">
            <Loader />
          </div>
        ) : (
          <>
            {actors &&
            actors.length === 0 &&
            animals &&
            animals.length === 0 &&
            birds &&
            birds.length === 0 &&
            buildings &&
            buildings.length === 0 &&
            cgi &&
            cgi.length === 0 &&
            effects &&
            effects.length === 0 &&
            green_screen_background &&
            green_screen_background.length === 0 &&
            marine &&
            marine.length === 0 &&
            modals &&
            modals.length === 0 &&
            property &&
            property.length === 0 &&
            reporters &&
            reporters.length === 0 &&
            trees &&
            trees.length === 0 &&
            extras &&
            extras.length === 0 &&
            vehicles &&
            vehicles.length === 0 ? (
              <div className="text-center py-5">
                <p className="mb-0">
                  You don't have any Various Elements to Your Footage
                </p>
              </div>
            ) : (
              <>
                {actors && actors.length > 0
                  ? actors.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Actor"}
                          deleteType={"filmmodels"}
                        />
                      );
                    })
                  : ""}
                {animals && animals.length > 0
                  ? animals.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Land Animal"}
                          deleteType={"birdoranimals"}
                        />
                      );
                    })
                  : ""}
                {birds && birds.length > 0
                  ? birds.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Bird"}
                          deleteType={"birdoranimals"}
                        />
                      );
                    })
                  : ""}
                {buildings && buildings.length > 0
                  ? buildings.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Building"}
                          deleteType={"treeandbuildings"}
                        />
                      );
                    })
                  : ""}
                {cgi && cgi.length > 0
                  ? cgi.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"CGI"}
                          deleteType={"cgis"}
                        />
                      );
                    })
                  : ""}
                {effects && effects.length > 0
                  ? effects.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Particle Effects"}
                          deleteType={"effects"}
                        />
                      );
                    })
                  : ""}
                {extras && extras.length > 0
                  ? extras.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Extras"}
                          deleteType={"extras"}
                        />
                      );
                    })
                  : ""}
                {green_screen_background && green_screen_background.length > 0
                  ? green_screen_background.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Green Screen Background"}
                          deleteType={"effects"}
                        />
                      );
                    })
                  : ""}
                {marine && marine.length > 0
                  ? marine.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Marine Animal/Fish"}
                          deleteType={"birdoranimals"}
                        />
                      );
                    })
                  : ""}
                {modals && modals.length > 0
                  ? modals.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Model"}
                          deleteType={"filmmodels"}
                        />
                      );
                    })
                  : ""}
                {property && property.length > 0
                  ? property.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Property"}
                          deleteType={"birdoranimals"}
                        />
                      );
                    })
                  : ""}
                {reporters && reporters.length > 0
                  ? reporters.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Reporter"}
                          deleteType={"reporters"}
                        />
                      );
                    })
                  : ""}
                {trees && trees.length > 0
                  ? trees.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Tree/Plant"}
                          deleteType={"treeandbuildings"}
                        />
                      );
                    })
                  : ""}
                {vehicles && vehicles.length > 0
                  ? vehicles.map((elementName, index) => {
                      return (
                        <FormElementsItem
                          setReloadElements={setReloadElements}
                          elementName={elementName}
                          jobId={job && job.id}
                          index={index}
                          type={"Vehicles"}
                          deleteType={"vehicles"}
                        />
                      );
                    })
                  : ""}
              </>
            )}
          </>
        )}
      </div>
    </Col>
  );
};

export default FormElementsName;
