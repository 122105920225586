import React from "react";
import JobReportDetails from "../../../components/admin/report/JobReportDetails";
import AdminATechHeader from "../../../components/common/AdminATechHeader";

const ViewJobReport = () => {
  return (
    <>
      <AdminATechHeader HeaderName={"Job Reports"} />
      <div className="mx-3 mx-1 bg-white p-md-4 p-2 br-10 mt-3 ">
        <div className="container-fluid my-4  profile-scroll">
          <JobReportDetails />
        </div>
      </div>
    </>
  );
};

export default ViewJobReport;
