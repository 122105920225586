import { getTime } from "../../common/utils/Functions";
import ViewJobTechnicalElementsButton from "./ViewJobTechnicalElementsButton";
import React from "react";
import BubblesLoader from "../../common/BubblesLoader";

function ClientViewJobDetails({ clientJobViewDetails, loading }) {
  return (
    <div className="job-scroll">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <BubblesLoader />
        </div>
      ) : (
        <>
          <p className="common-heading-Medium veiw-job-heading">
            {clientJobViewDetails.title}
          </p>
          <p className="common-text">
            Posted {getTime(clientJobViewDetails.created_at)}
          </p>
          <p className="common-heading-Medium text-blue">
            $ {clientJobViewDetails.price}
          </p>
          <p className="common-text pe-sm-5 me-sm-5">
            {clientJobViewDetails.description}
          </p>
          <div className="border-bottom "></div>
          <div className="d-flex flex-sm-row flex-column ">
            <ViewJobTechnicalElementsButton />
          </div>
        </>
      )}
    </div>
  );
}

export default ClientViewJobDetails;
