import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentChatMessageListAction,
  getCurrentDisputeMessageAction,
} from "../../action/chat/ClientChat";
import { useSocket } from "./SocketProvider";

let watingMessageArray = [];
export default function JoinChat() {
  const socket = useSocket();
  const dispatch = useDispatch();
  const chatList = useSelector((state) => state.chat.chatList);
  const disputesList = useSelector((state) => state.chat.disputesList);
  const [watingMessage, setWatingMessage] = useState(false);
  const [watingMessageList, setWatingMessageList] = useState([]);
  //   Join FRIEND chat
  useEffect(() => {
    if (!socket) return;
    !!chatList &&
      !!chatList.length &&
      chatList.forEach((obj) => {
        socket.send(
          JSON.stringify({
            event: "join-chat",
            room: obj.id,
          })
        );
      });
  }, [socket, chatList]);

  useEffect(() => {
    if (!socket) return;
    !!disputesList &&
      !!disputesList.length &&
      disputesList.forEach((obj) => {
        socket.send(
          JSON.stringify({
            event: "join-dispute",
            room: obj.id,
          })
        );
      });
  }, [socket, disputesList]);

  useEffect(() => {
    if (socket === null) return;
    if (socket && socket.readyState === 1) {
      socket.onmessage = (e) => {
        const socketEvent = JSON.parse(e.data);
        if (socketEvent.event === "receive-chat-message") {
          dispatch(getCurrentChatMessageListAction(socketEvent.message));
          setWatingMessageList([]);
          watingMessageArray = [];
          setWatingMessage(false);
        }
        if (socketEvent.event === "receive-dispute-message") {
          dispatch(getCurrentDisputeMessageAction(socketEvent.message));
          setWatingMessageList([]);
          watingMessageArray = [];
        }
      };
    }
  }, [socket, disputesList, dispatch]);

  return {
    watingMessage,
    setWatingMessage,
    watingMessageList,
    setWatingMessageList,
    watingMessageArray,
  };
}
