import { Accordion } from "react-bootstrap";
import ActorsAndModel from "./CommonFields/ActorsAndModel";

function Actors({ jobList }) {
  return (
    <Accordion className="footage-accordian">
      {jobList &&
        jobList.actors.length > 0 &&
        jobList.actors.map((value, index) => {
          return (
            <ActorsAndModel
              FootageType={"Actors"}
              value={value}
              index={index}
            />
          );
        })}
    </Accordion>
  );
}

export default Actors;
