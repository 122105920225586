import InfiniteScroll from "react-infinite-scroll-component";
import { withRouter } from "react-router-dom";
import BubblesLoader from "../../common/BubblesLoader";
import DisputesList from "./DisputesList";

const DisputeListItem = ({
  history,
  match,
  disputesList,
  setShowChat,
  pageChangeHandler,
  hasMore,
  loading,
  setPageNo,
  page,
  userId,
}) => {
  const { id } = match.params;

  const DisputeFriendListArray =
    disputesList &&
    disputesList.length > 0 &&
    disputesList.map((item, index) => {
      return (
        <DisputesList
          history={history}
          setShowChat={setShowChat}
          item={item}
          id={id}
          index={index}
          setPageNo={setPageNo}
          userId={userId}
        />
      );
    });

  return (
    <div className="notification-scroll" id="disputes-Chat-friend-list">
      <InfiniteScroll
        dataLength={disputesList.length}
        next={pageChangeHandler}
        hasMore={hasMore}
        scrollableTarget="disputes-Chat-friend-list"
        loader={
          page > 0 && loading ? (
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <BubblesLoader />
            </div>
          ) : (
            ""
          )
        }
      >
        {DisputeFriendListArray}
      </InfiniteScroll>
    </div>
  );
};

export default withRouter(DisputeListItem);
