import React from "react";
import uploadImg from "../../../assets/img/svg/upload-img.svg";
import Loader from "../../common/Loader";

function AddNewGearForm({
  fileUrl,
  setError,
  uploadVedioValue,
  handleClose,
  register,
  loadingImage,
  loading,
  errors,
  gearWorkArray,
  index,
}) {
  return (
    <div className="container-fluid">
      <div className="row atech-border br-10 justify-content-center ">
        <div className="col-11 col-lg-4 mt-4 mt-lg-0 p-4 p-lg-0 bg-F8F8F8 atech-border d-flex flex-column justify-content-center align-items-center">
          {fileUrl || gearWorkArray ? (
            <>
              <div className=" pt-2">
                <input
                  onChange={(e) => uploadVedioValue(e)}
                  type="file"
                  id="my-file"
                  accept="image video/mp4"
                  disabled={loadingImage}
                  hidden
                />
                <button type="button" className="py-2 px-3 atech-fade-button ">
                  <label
                    for="my-file"
                    className={`${
                      loadingImage ? "cursor-not-allowed" : "cursor-pointer"
                    } text-wrap-css `}
                  >
                    <span className="me-2">
                      <img
                        className=" px-2 top-22"
                        src={uploadImg}
                        alt="uploadImg"
                      />
                    </span>
                    {loadingImage
                      ? "Loading..."
                      : fileUrl
                      ? fileUrl
                      : gearWorkArray && gearWorkArray[index].media_link}
                  </label>
                </button>
              </div>
            </>
          ) : (
            <div className=" pt-2">
              <input
                onChange={(e) => uploadVedioValue(e)}
                type="file"
                id="my-file"
                accept="image video/mp4"
                disabled={loadingImage}
                hidden
              />
              <button type="button" className="py-2 px-3 atech-fade-button ">
                <label
                  for="my-file"
                  className={`${
                    loadingImage ? "cursor-not-allowed" : "cursor-pointer"
                  } text-wrap-css `}
                >
                  <span className="me-2">
                    <img
                      className=" px-2 top-22"
                      src={uploadImg}
                      alt="uploadImg"
                    />
                  </span>
                  {loadingImage ? "Loading..." : "Upload Gear"}
                </label>
              </button>
            </div>
          )}
        </div>
        <div className="col-12 col-lg-8 my-4 py-2 px-sm-4 px-2">
          <div className="row">
            <div className="col-md-6">
              <div>
                <input
                  {...register("title", { required: true })}
                  type="text"
                  className="form-control py-3 atech-input "
                  placeholder={
                    gearWorkArray ? gearWorkArray[index].title : "Enter Title"
                  }
                  aria-describedby="emailHelp"
                />
                {errors.title?.type === "required" && (
                  <p className="common-text xxs-font text-red mb-0">
                    Title is required
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-6 mt-md-0 mt-2">
              <div className=" select-field">
                <select
                  {...register("type", { required: true })}
                  className="form-select py-3 cursor-pointer"
                  placeholder={
                    gearWorkArray
                      ? gearWorkArray[index].type
                      : "Enter Your Type"
                  }
                >
                  <option value="camera">Camera</option>
                  <option value="sound">Sound</option>
                  <option value="other">Other</option>
                </select>
              </div>
              {errors.type?.type === "required" && (
                <p className="common-text xxs-font text-red">
                  Please select any Type
                </p>
              )}
            </div>
          </div>
          <div className="mt-2">
            <div>
              <input
                {...register("description", { required: true })}
                type="text"
                className="form-control py-3 atech-input"
                aria-describedby="emailHelp"
                placeholder={
                  gearWorkArray && gearWorkArray[index].description
                    ? gearWorkArray[index].description
                    : "Description(max 100 words)"
                }
              />
              {errors.description?.type === "required" && (
                <p className="common-text xxs-font text-red">
                  Description is required
                </p>
              )}
            </div>
          </div>
          <div className="d-flex flex-sm-row  flex-column mt-3">
            <button
              type="submit"
              disabled={loading}
              className={`${
                loading ? "cursor-not-allowed" : "cursor-pointer"
              } px-4 atech-primary-button  py-sm-2 py-2 ms-sm-2  my-2`}
              onClick={() => setError(true)}
            >
              {loading ? <Loader /> : "Save Gear"}
            </button>
            <button
              type="button"
              onClick={() => handleClose()}
              className="px-4  py-sm-2 py-2 ms-sm-2  my-2  atech-fade-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddNewGearForm;
