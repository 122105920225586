import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UpdateUnseenNotificationAction } from "../../action/admin/AdminAcountsAction";
import { WEB_SOCKET_URL } from "../../helper";

const SocketContext = React.createContext();

export function useSocket() {
  return useContext(SocketContext);
}

function SocketProvider({ children }) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("a-tech-access-token");
  const [socket, setSocket] = useState();

  useEffect(() => {
    if (!!token) {
      connectSocket();
    }
  }, [token]);

  function connectSocket() {
    if (token === null) return;
    const newSocket = new WebSocket(`${WEB_SOCKET_URL}?token=${token}`);
    newSocket.onopen = (_) => console.log("Socket connected");
    setSocket(newSocket);
    return () => newSocket.close();
  }
  if (socket) {
    socket.onclose = (_) => console.log("disconnected");
  }
  if (socket) {
    socket.onmessage = (e) => {
      const socketEvent = JSON.parse(e.data);
      if (socketEvent.event === "notification") {
        dispatch(UpdateUnseenNotificationAction());
      }
    };
  }
  if (socket) {
    socket.onerror = (_) => console.log("error form socket");
  }

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
}
export default SocketProvider;
