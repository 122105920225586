import React from "react";
import AdminDashboardMyJob from "../../components/admin/dashboard/AdminDashboardMyJob";
import AdminATechHeader from "../../components/common/AdminATechHeader";
import AdminDashboardCard from "../../components/admin/dashboard/AdminDashboardCard";

const AdminDashbord = ({ userDetailsRole }) => {
  return (
    <div className="ff-Project page-color">
      <AdminATechHeader
        userDetailsRole={userDetailsRole}
        HeaderName={"DashBoard"}
      />
      <div className="mt-3 br-10">
        <AdminDashboardCard />
        <div className="container-fluid flex-grow-1 pb-sm-5 main_height_adminDashboard overflow_hidden">
          <div className="row h-100">
            <AdminDashboardMyJob />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashbord;
