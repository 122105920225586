import Swal from "sweetalert2";
import {
  getJobListForClientApi,
  getJobViewDetailsApi,
  getJobApplicationListApi,
  getTechnicalElementsDetailsApi,
  getTechnicalElementsListApi,
  clientSearchJobApi,
  getJobApplicationDetailsApi,
  ApplictionAcceptOfferApi,
  deleteJobApi,
  getJobsDeliveriesApi,
  getJobsDeliveriesDetailsApi,
  jobApprovedByClientApi,
} from "../../api/client/Job";
import { RemoveFileUrlLink } from "../UploadImageAction";

export const GET_CLIENT_ALL_JOBS_LIST = "GET_CLIENT_ALL_JOBS_LIST";
export const GET_JOB_VIEW_DETAILS = "GET_JOB_VIEW_DETAILS";
export const GET_JOB_APPLICATION_LIST = "GET_JOB_APPLICATION_LIST";
export const GET_TECHNICAL_ELEMENTS_DETAILS = "GET_TECHNICAL_ELEMENTS_DETAILS";
export const GET_TECHNICAL_ELEMENTS_LIST = "GET_TECHNICAL_ELEMENTS_LIST";
export const SEARCH_JOBS_LIST_FOR_CLIENT = "SEARCH_JOBS_LIST_FOR_CLIENT";
export const GET_JOB_APPLICATION_DETAILS = "GET_JOB_APPLICATION_DETAILS";
export const GET_JOBS_DELIVERIES_LIST = "GET_JOBS_DELIVERIES_LIST";
export const GET_JOBS_DELIVERIES_DETAILS_LIST =
  "GET_JOBS_DELIVERIES_DETAILS_LIST";

/**
 * Get client all job list action
 * @param {Array} data
 * @returns
 */
export const getJobListForClientAction = (data) => ({
  type: GET_CLIENT_ALL_JOBS_LIST,
  data,
});

export const getJobListForClient =
  (setLoading, type, page, shortedValue, setHasMore) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await getJobListForClientApi(type, page, shortedValue);
      if (response.success) {
        dispatch(
          getJobListForClientAction({ data: response.data, page: page })
        );
        setLoading(false);
        if (response.data.length < 10) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

/**
 * Get search job list action
 * @param {Array} data
 * @returns
 */
export const clientSearchJobList = (data) => ({
  type: SEARCH_JOBS_LIST_FOR_CLIENT,
  data,
});

export const getClientSearchJobByQuery =
  (setLoading, type, query, page) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await clientSearchJobApi(type, query);
      if (response.success) {
        dispatch(clientSearchJobList({ data: response.data, page: page }));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

/**
 * Get client job view details action
 * @param {Array} data
 * @returns
 */
export const getJobViewDetailsAction = (data) => ({
  type: GET_JOB_VIEW_DETAILS,
  data,
});

export const getJobViewDetails = (setLoading, jobId) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await getJobViewDetailsApi(jobId);
    if (response.success) {
      dispatch(getJobViewDetailsAction(response.data));
      setLoading(false);
    } else {
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
  }
};

/**
 * delete Jobs action
 * @param {Array} data
 * @returns
 */

export const deleteJobAction =
  (setLoading, jobId, setError, history) => async () => {
    setLoading(true);
    setError("");
    try {
      const response = await deleteJobApi(jobId);
      if (response.success) {
        setLoading(false);
        history.push("/client/job-feed/all-jobs");
      } else {
        setLoading(false);
        setError("Oops! Failed to delete job");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Failed to delete job");
    }
  };

/**
 * Get client job view list action
 * @param {Array} data
 * @returns
 */
export const getJobApplicationListAction = (data) => ({
  type: GET_JOB_APPLICATION_LIST,
  data,
});

export const getJobApplicationList =
  (setLoading, jobId, setError) => async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await getJobApplicationListApi(jobId);
      if (response.success) {
        dispatch(getJobApplicationListAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
        setError("Ooops! Failed to load Applictions list");
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong");
    }
  };

/**
 * Get client job view details action
 * @param {Array} data
 * @returns
 */
export const getJobApplicationDetailsAction = (data) => ({
  type: GET_JOB_APPLICATION_DETAILS,
  data,
});

export const getJobApplicationDetails =
  (setLoading, jobId, applicationId, setError) => async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await getJobApplicationDetailsApi(jobId, applicationId);
      if (response.success) {
        dispatch(getJobApplicationDetailsAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
        setError("Ooops! Failed to load Applictions Details");
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong");
    }
  };

/**
 * Accept cretor job appliction action
 * @param {Boolen} setLoading
 * @param {String} jobId
 * @param {String} applicationId
 * @param {Boolen} setError
 * @returns
 */
export const ApplictionAcceptOffer =
  (setLoading, jobId, applicationId, setError, data, setAcceptOffer, history) =>
  async () => {
    setLoading(true);
    try {
      setError("");
      const response = await ApplictionAcceptOfferApi(
        jobId,
        applicationId,
        data
      );
      if (response.success) {
        setLoading(false);
        setAcceptOffer(false);
        history.push(`/client/${"on-going"}/jobs/${jobId}`);
      } else {
        setLoading(false);
        setError("Ooops! Failed to Accept Applictions Details");
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong");
    }
  };

/**
 * Get client job view details list
 * @param {Array} data
 * @returns
 */
export const getTechnicalElementsListAction = (data) => ({
  type: GET_TECHNICAL_ELEMENTS_LIST,
  data,
});

export const getTechnicalElementsList =
  (setLoading, jobId, elementsType, setError, valueConcat) =>
  async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await getTechnicalElementsListApi(jobId, elementsType);
      if (response.success) {
        dispatch(
          getTechnicalElementsListAction({
            data: response.data,
            valueConcat: valueConcat,
          })
        );
        setLoading(false);
      } else {
        setLoading(false);
        setError("Ooops! Failed to load Applictions list");
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong");
    }
  };

/**
 * Get client job view details action
 * @param {Array} data
 * @returns
 */
export const getTechnicalElementsDetailsAction = (data) => ({
  type: GET_TECHNICAL_ELEMENTS_DETAILS,
  data,
});

export const getTechnicalElementsDetails =
  (setLoading, jobId, elementsType, elementsId, setError) =>
  async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await getTechnicalElementsDetailsApi(
        jobId,
        elementsType,
        elementsId
      );
      if (response.success) {
        dispatch(getTechnicalElementsDetailsAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
        setError("Ooops! Failed to load Applictions list");
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong");
    }
  };

/**
 * Get Job deliveries list
 * @param {Array} data
 * @returns
 */
export const getJobsDeliveriesAction = (data) => ({
  type: GET_JOBS_DELIVERIES_LIST,
  data,
});

export const getJobsDeliveries =
  (setLoading, jobId, setError) => async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await getJobsDeliveriesApi(jobId);
      if (response.success) {
        dispatch(getJobsDeliveriesAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
        setError("Ooops! Failed to load Job Deliveries list");
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong");
    }
  };

/**
 * Get Job deliveries details
 * @param {Array} data
 * @returns
 */
export const getJobsDeliveriesDetailsAction = (data) => ({
  type: GET_JOBS_DELIVERIES_DETAILS_LIST,
  data,
});

export const getJobsDeliveriesDetails =
  (setLoading, setError, data) => async (dispatch) => {
    setLoading(true);
    try {
      setError("");
      const response = await getJobsDeliveriesDetailsApi(data);
      if (response.success) {
        dispatch(getJobsDeliveriesDetailsAction(response.data));
        setLoading(false);
        dispatch(RemoveFileUrlLink());
      } else {
        setLoading(false);
        setError("Ooops! Failed to Deliver file Details");
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong");
    }
  };

/**
 * Job Approved By CLient
 */
export const jobApprovedByClient =
  (setLoading, jobId, data, setApprovedJob) => async () => {
    setLoading(true);
    try {
      const response = await jobApprovedByClientApi(jobId, data);
      if (response.success) {
        setLoading(false);
        setApprovedJob(true);
        Swal.fire("Success!", "Approved Job Successfully", "success");
        setTimeout(Swal.close, 2000);
      } else {
        setLoading(false);
        Swal.fire("Ooops!", "Failed to Approved Job", "error");
        setTimeout(Swal.close, 2000);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Ooops!", "Failed to Approved Job", "error");
      setTimeout(Swal.close, 2000);
    }
  };
