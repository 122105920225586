import React from "react";
import ClientATechHeader from "../../components/client/ClientATechHeader";
import { useLayoutProviderContext } from "../../context/LayoutProvider";
import JobFeed from "../../components/client/ClientJobFeed/JobFeed/JobFeed";

const ClientJobFeed = () => {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();

  return (
    <>
      <ClientATechHeader
        HeaderName={"Job Feed"}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />

      <JobFeed />
    </>
  );
};

export default ClientJobFeed;
