import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { CreateJobFeedList } from "../../../redux/action/creator/CreatorJob";
import BubblesLoader from "../../common/BubblesLoader";
import noNotification from "../../../assets/img/svg/nonotification.svg";

import MyJobsListItem from "./MyJobsListItem";

const DashboardMyJobList = ({ history }) => {
  const dispatch = useDispatch();
  const creatorJobList = useSelector((state) => state.jobs.creatorJobList);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    const types = "on-going";
    const shortedValue = 0;
    const page = 0;
    dispatch(
      CreateJobFeedList(types, setLoading, shortedValue, page, setHasMore)
    );
  }, [dispatch]);

  return (
    <>
      <Col lg={6} className="h-100">
        <section
          className={`${
            creatorJobList.length > 0 ? "overflow-auto" : ""
          } myjob creator_height p-4 `}
        >
          <div className="d-flex justify-content-between">
            <p className="common-text">
              {creatorJobList.length > 0 ? "My Jobs" : ""}
            </p>
            {creatorJobList && creatorJobList.length > 0 ? (
              <p
                className="common-text xxs-font view-all"
                onClick={() => history.push("/creator/job-feed/my-jobs")}
              >
                View All
              </p>
            ) : (
              ""
            )}
          </div>

          <>
            {loading ? (
              <div className="d-flex h-100 justify-content-center align-items-center flex-column">
                <BubblesLoader />
                <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                  Please wait...
                </p>
              </div>
            ) : creatorJobList && creatorJobList.length > 0 ? (
              creatorJobList.map((item, index) => {
                return (
                  <MyJobsListItem
                    item={item}
                    key={index}
                    hasMore={hasMore}
                    history={history}
                  />
                );
              })
            ) : (
              <>
                <div className=" d-flex justify-content-center flex-column align-items-center h-100">
                  <img src={noNotification} alt="NoNotification" />
                  <p className="text-center common-heading-Medium pt-3">
                    You have no jobs currently
                  </p>
                  <button
                    className="py-2 px-4 border-0 btn-primary"
                    onClick={() => history.push("/creator/job-feed/recent")}
                  >
                    Find Job
                  </button>
                </div>
              </>
            )}
          </>
        </section>
      </Col>
    </>
  );
};

export default withRouter(DashboardMyJobList);
