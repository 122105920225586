import React from "react";
import { withRouter } from "react-router-dom";
import MessagesPhoto from "../../assets/img/png/message_photo_png.png";

const AdminDisputeListItem = ({ history, index, item }) => {
  return (
    <>
      <div
        className="d-flex justify-content-between inside-dispute-message-box p-2 mt-2 cursor-pointer"
        onClick={() => history.push("/admin/disputes/:id")}
      >
        <div className="d-flex px-3">
          <img
            src={MessagesPhoto}
            className="admin-user-image"
            alt="MessagesPhoto"
          />
          <span className="messenger-name px-3 d-flex align-items-center ">
            Dispute-{index + 1}
          </span>
        </div>

        {item && item.total_unseen === 0 ? (
          ""
        ) : (
          <div className=" d-flex align-items-center px-2">
            <span className="bg-number-dipsute no-list-dipsute-messege mb-0 ">
              {item.total_unseen}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(AdminDisputeListItem);
