import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import AdminATechHeader from "../../../components/common/AdminATechHeader";
import AdminJobs from "../../../components/admin/adminJobs/AdminJobs";
import AdminJobsTypes from "../../../components/admin/adminJobs/AdminJobsTypes";
import AdminPendingJobs from "../../../components/admin/adminJobs/AdminPendingJobs";
import { useLayoutProviderContext } from "../../../context/LayoutProvider";
import AdminCompletdJobs from "../../../components/admin/adminJobs/AdminCompletdJobs";

function Jobs({ history, match }) {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();
  const { type } = match.params;
  const [types, setTypes] = useState("pending-jobs");

  useEffect(() => {
    if (type) {
      setTypes(type);
    }
  }, [type]);

  return (
    <>
      <AdminATechHeader
        HeaderName={"Jobs"}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div className="d-flex flex-xl-row flex-column  mt-4">
        <AdminJobsTypes history={history} types={types} />
        {types === "pending-jobs" ? (
          <AdminPendingJobs types={types} />
        ) : types === "completed-jobs" ? (
          <AdminCompletdJobs types={types} />
        ) : (
          <AdminJobs types={types} />
        )}
      </div>
    </>
  );
}

export default withRouter(Jobs);
