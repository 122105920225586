import { useState } from "react";
import Deleteicon from "../../../assets/img/svg/delete-icon.svg";
import { EditIcon } from "../../common/icons/Icon";
import GearEditPopup from "./GearEditPopup";
import uploadImg from "../../../assets/img/svg/upload-img.svg";

function UpdateGearForm({ gearWorkArray }) {
  const [openEditForm, setOpenEditForm] = useState(false);
  const [IndexValue, setIndex] = useState(0);
  const [openEditFormData, setOpenEditFormData] = useState({});
  const handleClose = () => setOpenEditForm(false);
  const [newArray, setNewArray] = useState([gearWorkArray]);

  const deleteAddPastWork = (index) => {
    gearWorkArray.splice(index, 1);
    setNewArray(gearWorkArray);
  };

  const EditPastWork = (index) => {
    setOpenEditForm(true);
    setIndex(index);
    setOpenEditFormData(gearWorkArray[index]);
  };

  return (
    <form>
      {gearWorkArray && !!gearWorkArray.length
        ? gearWorkArray.map((value, index) => {
            return (
              <div className="atech-border br-10 my-3">
                <div className="container ps-lg-0 pt-lg-0 pt-4">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="bg-F8F8F8 py-lg-0 py-5 h-100 br-10">
                        <div className="d-flex  justify-content-center align-items-center h-100">
                          <input
                            type="file"
                            id="my-file"
                            hidden
                            disabled
                            accept="video/mp4"
                          />
                          <button
                            type="button"
                            className="py-2 px-3 atech-fade-button "
                          >
                            <label
                              for="my-file2"
                              className="cursor-pointer text-wrap-css "
                            >
                              <span className="me-2 d-inline-block">
                                <img
                                  className=" px-2 top-22"
                                  src={uploadImg}
                                  alt="uploadImg"
                                />
                              </span>
                              {value.media_link}
                            </label>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 my-5">
                      <p className="common-heading-Medium xs-font pe-xl-5 me-xl-5 text-sm-start text-center">
                        {value.title}
                      </p>
                      <p className="common-text pe-xl-5 me-xl-5 text-sm-start text-center">
                        {value.description}
                      </p>
                      <div className="d-flex flex-sm-row  flex-column mt-3">
                        <button
                          className="px-4 atech-primary-button  py-sm-2 py-2   my-2  "
                          onClick={(e) => EditPastWork(index, e)}
                        >
                          <span className="edit-icon pe-2">
                            <EditIcon />
                          </span>
                          Edit
                        </button>

                        <button
                          type="button"
                          onClick={() => deleteAddPastWork(index)}
                          className="px-4 atech-fade-button py-sm-2 py-2 ms-sm-2  my-2 "
                        >
                          <span>
                            <img
                              className="pe-2"
                              src={Deleteicon}
                              alt="Deleteicon"
                            />
                          </span>
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
      {openEditForm ? (
        <GearEditPopup
          handleClose={handleClose}
          show={openEditForm}
          openEditFormData={openEditFormData}
          gearWorkArray={gearWorkArray}
          index={IndexValue}
          newArray={newArray}
        />
      ) : null}
    </form>
    // <form>
    //   {gearWorkArray && !!gearWorkArray.length
    //     ? gearWorkArray.map((value, index) => {
    //         return (
    //           <div className="container">
    //             <div className="row atech-border br-10 justify-content-center ">
    //               <div className="col-11 col-lg-3 mt-4 mt-lg-0 p-4 p-lg-4 bg-F8F8F8 br-10 d-flex flex-column justify-content-center align-items-center">
    //                 <div className=" pt-2">
    //                   <input
    //                     type="file"
    //                     id="my-file"
    //                     accept="video/mp4"
    //                     disabled
    //                     hidden
    //                   />
    //                   <button
    //                     type="button"
    //                     className="py-2 px-3 atech-fade-button "
    //                   >
    //                     <label
    //                       for="my-file"
    //                       className="cursor-pointer text-wrap-css"
    //                     >
    //                       <span className="me-2">
    //                         <img
    //                           className=" px-2 top-22"
    //                           src={uploadImg}
    //                           alt="uploadImg"
    //                         />
    //                       </span>
    //                       {value.media_link}
    //                     </label>
    //                   </button>
    //                 </div>
    //               </div>
    //               <div className="col-12 col-lg-9 my-4 py-2 px-4">
    //                 <div>
    //                   <p className="common-heading-Medium xs-font pe-xl-5 me-xl-5">
    //                     {value.title}
    //                   </p>
    //                 </div>
    //                 <div className=" mt-2">
    //                   <p className="common-text pe-xl-5 me-xl-5">
    //                     {value.description}
    //                   </p>
    //                 </div>
    //                 <div className=" mt-2">
    //                   <p className="common-text pe-xl-5 me-xl-5">
    //                     {value.type}
    //                   </p>
    //                 </div>
    //                 <div className="d-flex flex-sm-row  flex-column mt-3">
    //                   <button
    //                     type="button"
    //                     onClick={() => EditPastWork(index)}
    //                     className="px-4 atech-primary-button  py-sm-2 py-2 ms-sm-2  my-2  "
    //                   >
    //                     <span className="edit-icon pe-2">
    //                       <EditIcon />
    //                     </span>
    //                     Edit
    //                   </button>
    //                   <button
    //                     type="button"
    //                     onClick={() => deleteAddPastWork(index)}
    //                     className="px-4 atech-fade-button py-sm-2 py-2 ms-sm-2  my-2 "
    //                   >
    //                     <span>
    //                       <img
    //                         className="pe-2"
    //                         src={Deleteicon}
    //                         alt="Deleteicon"
    //                       />
    //                     </span>
    //                     Delete
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         );
    //       })
    //     : null}
    //   {openEditForm ? (
    //     <GearEditPopup
    //       handleClose={handleClose}
    //       show={openEditForm}
    //       openEditFormData={openEditFormData}
    //       gearWorkArray={gearWorkArray}
    //       index={IndexValue}
    //       newArray={newArray}
    //     />
    //   ) : null}
    // </form>
  );
}
export default UpdateGearForm;
