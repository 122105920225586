import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  paymentToCreator,
  ReleasePaymentByAdmin,
} from "../../../redux/action/client/JobForm";
import Loader from "../../common/Loader";

const PayOutSelection = ({ item }) => {
  let itemId = item.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [loadingValue, setLoadingValue] = useState(false);

  const releasePayment = (value) => {
    setLoadingValue(value);
    const data = {
      status: value,
    };
    dispatch(ReleasePaymentByAdmin(itemId, data, setLoading));
  };
  const paymentToCreatorHandler = (value) => {
    setLoadingValue(value);
    const data = {
      payout_id: itemId,
      host: window.location.origin,
    };
    dispatch(paymentToCreator(data, setPaymentLoading));
  };

  return (
    <>
      <div className="d-flex  justify-content-end  mt-3">
        {item.status === "paid" ? (
          ""
        ) : !item.is_stripe_account_connected ? (
          <div>
            <p className="common-text xxs-font text-red">
              Stripe account is not connected by creator
            </p>
          </div>
        ) : (
          <div className=" d-flex  flex-row  ">
            <button
              disabled={item.status === "hold" ? true : false}
              className={`${
                item.status === "hold" ? "cursor-not-allowed" : ""
              } px-sm-3 px-sm-4  px-3 py-2  me-sm-3 me-2 reject-btn br-10`}
              onClick={() => releasePayment("hold")}
            >
              <span className="d-inline-block">
                {loading && loadingValue === "hold" ? <Loader /> : "Hold"}
              </span>
            </button>

            <button
              className="px-sm-3 px-sm-4  px-3 py-2 accept-btn br-10 me-sm-3 me-2"
              onClick={() => releasePayment("paid")}
            >
              <span className="d-inline-block">
                {loading && loadingValue === "paid" ? <Loader /> : "Mark Paid"}
              </span>
            </button>
            {/**  {item.is_stripe_account_connected ? (
              <a href={item.paylink} target="_blank">
                <button className="px-sm-3 px-sm-4  px-3 py-2 atech-primary-button br-10">
                  <span className="d-inline-block">Pay</span>
                </button>
              </a>
            ) : (
              ""
            )}*/}
            <button
              className={`px-sm-3 px-sm-4  px-3 py-2 atech-primary-button br-10 ${
                paymentLoading ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={() => paymentToCreatorHandler()}
            >
              <span className="d-inline-block">
                {paymentLoading ? <Loader /> : "Pay"}
              </span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PayOutSelection;
