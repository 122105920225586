import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VideoImg from "../../../../assets/img/png/videoimgbtn.png";
import {
  getJobsDeliveries,
  getJobsDeliveriesDetails,
} from "../../../../redux/action/client/jobFeed";
import CreatorVideoModal from "./jobModel/CreatorVideoModal";
import { uploadImage } from "../../../../redux/action/UploadImageAction";
import Loader from "../../../common/Loader";
import ErrorMessage from "../../../common/ErrorMessage";
import DeliveredFile from "./DeliveredFile";

const CreatorJobDelivery = ({ jobId, myJobDetails }) => {
  const dispatch = useDispatch();
  const fileUrl = useSelector((state) => state.AuthReducer.fileUrl);
  const jobDeliveriesList = useSelector(
    (state) => state.jobs.jobDeliveriesList
  );
  const [videoBlog, setVideoBlog] = useState([]);
  const [video, setVideo] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [error, setError] = useState("");
  const [showVideo, setShowVideo] = useState();

  const showVideoHandler = (e) => {
    let video = e.target.files[0];
    let value = URL.createObjectURL(video);
    videoBlog.push(value);
    setVideo(videoBlog);
    const type = "video";
    dispatch(uploadImage(e, setLoadingImage, type));
  };
  const videoHandler = (item) => {
    setShow(true);
    setShowVideo(item);
  };

  const handleDeliveryFile = () => {
    const data = {
      creator: myJobDetails.user.id,
      file_link: fileUrl,
      application: myJobDetails.application_id,
      job: Number(jobId),
    };
    dispatch(getJobsDeliveriesDetails(setLoading, setError, data));
  };

  useEffect(() => {
    dispatch(getJobsDeliveries(setListLoading, jobId, setError));
  }, [dispatch, jobId]);

  return (
    <>
      {error !== "" ? <ErrorMessage message={error} /> : ""}
      {jobDeliveriesList && jobDeliveriesList.length > 0 && (
        <DeliveredFile
          jobDeliveriesList={jobDeliveriesList}
          videoHandler={videoHandler}
          listLoading={listLoading}
        />
      )}
      {video && video.length > 0 && <p className="common-text">Deliveries</p>}
      <div className="row">
        {video &&
          !!video.length &&
          video.map((item, index) => {
            return (
              <div className="col-md-4 col-sm-6 col-12" key={index}>
                <div className="position-relative">
                  <video
                    className="deliver-img br-10"
                    width="100%"
                    src={item}
                  />
                  <div className="position-absolute top-0 d-flex justify-content-center h-100 w-100 align-items-center">
                    <img
                      className="cursor-pointer"
                      onClick={() => videoHandler(item)}
                      src={VideoImg}
                      alt=""
                    />
                  </div>
                </div>
                <p className="common-text text-black pt-3">
                  Delivery-
                  {jobDeliveriesList && jobDeliveriesList.length > 0
                    ? jobDeliveriesList.length + index + 1
                    : index + 1}
                </p>
              </div>
            );
          })}
      </div>
      {(jobDeliveriesList && jobDeliveriesList.length >= 3) ||
      (video && video.length > 3) ? (
        ""
      ) : (
        <>
          {myJobDetails && myJobDetails.is_completed_by_creator === false && (
            <div className="mt-3">
              {fileUrl === "" ? (
                <>
                  <input
                    type="file"
                    hidden
                    id="my-file"
                    onChange={(e) => showVideoHandler(e)}
                    accept="video/*"
                    disabled={loadingImage}
                  />
                  <button
                    className={`${
                      loadingImage ? "cursor-not-allowed" : "cursor-pointer"
                    } Start-Dispute-hover  common-text px-4 py-3  border-e8e8e8 br-3`}
                    disabled={loadingImage}
                  >
                    <label
                      for={video.length >= 3 ? "" : "my-file"}
                      className="cursor-pointer"
                    >
                      {loadingImage ? <Loader /> : "Upload files"}
                    </label>
                  </button>
                </>
              ) : (
                <button
                  className="atech-primary-button px-4 py-3 ms-2"
                  onClick={() => handleDeliveryFile()}
                  disabled={loading}
                >
                  {loading ? <Loader /> : "Deliver Files"}
                </button>
              )}
            </div>
          )}
        </>
      )}
      <CreatorVideoModal
        setShow={setShow}
        show={show}
        showVideo={showVideo}
        setVideoBlog={setVideoBlog}
      />
    </>
  );
};

export default CreatorJobDelivery;
