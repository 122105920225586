import React from "react";
import DolorIcon from "../../../../src/assets/img/svg/dolar.svg";

const TotalEarning = ({ creatorEarning, loading }) => {
  return (
    <div className="col-xxl-4 col-md-6  mt-4">
      <div className="d-flex p-3 h-100 justify-content-between align-items-center box-shadow br-10 bg-white">
        <div>
          <p className="common-text fs-xs-14">Total Earnings</p>
          <h1 className="common-heading-Medium ">
            $
            {loading
              ? "Loading..."
              : creatorEarning && creatorEarning.total_earnings}
          </h1>
        </div>
        <span
          className="rounded-50  dashboard-icon d-inline-block"
          style={{
            backgroundColor: "#ecf9f4",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${DolorIcon})`,
          }}
        ></span>
      </div>
    </div>
  );
};

export default TotalEarning;
