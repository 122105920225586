import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LocationIcon from "../../assets/img/svg/location.svg";
import {
  connectStripeAccount,
  verifyStripeAccountConnection,
} from "../../redux/action/client/JobForm";
import { EditIcon } from "./icons/Icon";
import Loader from "./Loader";

function ProfileView({ userDetails, setOpenProfile, setError, history }) {
  const pathUrl = window.location.search;
  const code = pathUrl && pathUrl.split("&")[1].split("=")[1];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isStripeConnected, setIsStripeConnected] = useState(
    userDetails && !!userDetails.stripe_access_token ? true : false
  );
  const userDetailsRole = useSelector(
    (state) => state.AuthReducer.userDetailsRole
  );

  const connectStripeHandler = () => {
    dispatch(connectStripeAccount(setLoading, setError));
  };

  useEffect(() => {
    if (code) {
      dispatch(
        verifyStripeAccountConnection(
          code,
          setLoading,
          setError,
          history,
          setIsStripeConnected
        )
      );
    }
  }, [code, setError, dispatch, history]);
  return (
    <>
      <div className="col-12 col-md-4  pt-1 my-auto d-flex flex-column  align-items-md-start align-items-center pt-xxl-0 pt-3">
        <div className="  mb-2  ">
          <p className="common-heading">
            {userDetails && userDetails.first_name
              ? userDetails.first_name + " " + userDetails.last_name
              : userDetails && userDetails.username
              ? userDetails.username
              : "N/A"}
          </p>
        </div>
        <div className="  mb-2  ">
          <p className="common-text f-w-600">
            {userDetailsRole.is_admin
              ? "Admin"
              : userDetailsRole.is_creator
              ? "Creator"
              : "Client"}
          </p>
        </div>
        <div className="mb-2 d-flex">
          <span>
            <img className="pe-3" src={LocationIcon} alt="location" />
          </span>
          <p className="common-text">
            {userDetails && userDetails.city ? userDetails.city : "N/A"} ,
            {userDetails && userDetails.country ? userDetails.country : "N/A"}
          </p>
        </div>
      </div>
      <div className="col-12 col-xl-4 col-lg-5 col-md-6  mt-2 mt-sm-0 ">
        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-sm-end">
          {userDetailsRole && userDetailsRole.is_creator ? (
            <>
              {isStripeConnected ? (
                <button
                  className={`${"cursor-not-allowed"} py-2 px-3 atech-primary-button  mt-2 mt-sm-0 ms-0 ms-sm-2 mb-3`}
                >
                  Stripe Connected
                </button>
              ) : (
                <button
                  className={`${
                    loading ? "cursor-not-allowed" : "cursor-pointer"
                  } py-2 px-3 atech-primary-button  mt-2 mt-sm-0 ms-0 ms-sm-2 mb-3`}
                  onClick={() => connectStripeHandler()}
                  disabled={
                    loading
                      ? loading
                      : userDetails.reset_password_token === null
                      ? false
                      : true
                  }
                >
                  {loading ? <Loader /> : "Connect Stripe Account"}
                </button>
              )}
            </>
          ) : (
            ""
          )}
          <button
            className="py-2 px-3 atech-primary-button   ms-0 ms-sm-2 mb-3 "
            onClick={() => setOpenProfile(true)}
          >
            <span className="edit-icon pe-2">
              <EditIcon />
            </span>
            Edit Profile
          </button>
        </div>
      </div>
    </>
  );
}

export default ProfileView;
