import { axiosRequest } from "../../helper";

/**
 * Get user work list api
 * @returns
 */
export const GetUserWorkListApi = async () => {
  return await axiosRequest("GET", `/users/works/`);
};

/**
 * submit user work data
 * @param {Object} data
 * @returns
 */
export const submitWorkDetailsApi = async (data) => {
  return await axiosRequest("POST", `/users/works/`, data);
};

/**
 * submit user work data
 * @param {Object} data
 * @returns
 */
export const UpdateWorkDetailsApi = async (data, id) => {
  return await axiosRequest("PATCH", `/users/works/${id}/`, data);
};

/**
 * Delete user work details api
 * @param {Object} data
 * @returns
 */
export const deleteWorkDetailsApi = async (id) => {
  return await axiosRequest("DELETE", `/users/works/${id}/`);
};

/**
 * Creator dashboard Details Api
 * @returns
 */
export const CreatordashboardDetailsApi = async () => {
  return await axiosRequest("GET", `/admin/dashboard-creator`);
};
