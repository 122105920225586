import { CLIENT_DASHBOARD_DETAILS } from "../../action/client/Dashboard";
import { CREATOR_DASHBOARD_DETAILS } from "../../action/creator/Dashboard";

const initialState = {
  clientDashboard: {},
  creatorDashboard: {},
};

export default function client(state = initialState, action) {
  switch (action.type) {
    // Chat list action for client chat page action (GET)
    case CLIENT_DASHBOARD_DETAILS: {
      return {
        ...state,
        clientDashboard: action.data,
      };
    }

    case CREATOR_DASHBOARD_DETAILS: {
      return {
        ...state,
        creatorDashboard: action.data,
      };
    }

    default:
      return state;
  }
}
