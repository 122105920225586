import Swal from "sweetalert2";
import {
  AddJobTechnicalAspectsApi,
  CreateBasicDetailsApi,
  deleteJobElementsFootageApi,
  getAllJobsDetailsListApi,
  getChangeRequestApi,
  getChangeRequestListApi,
  SignificantPlacesDetailsApi,
} from "../../api/client/Job";

export const CREATE_BASIC_JOB_DETAILS = "CREATE_BASIC_JOB_DETAILS";
export const SIGNIFICANT_PLACE_DETAILS = "SIGNIFICANT_PLACE_DETAILS";
export const GET_CHANGE_REQUEST_LIST = "GET_CHANGE_REQUEST_LIST";
export const GET_ALL_JOBS_FORMS_DETAILS_LIST =
  "GET_ALL_JOBS_FORMS_DETAILS_LIST";

/**
 * Get user work list action
 * @param {Object} data
 * @returns
 */
const CreateBasicDetailsAction = (data) => ({
  type: CREATE_BASIC_JOB_DETAILS,
  data,
});

export const CreateBasicDetails =
  (data, setLoading, setError, history) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await CreateBasicDetailsApi(data);
      if (response.success) {
        dispatch(CreateBasicDetailsAction(response.data));
        setLoading(false);
        history.push(
          `/client/create/job/significant-places/event/${response.data.id}`
        );
      } else {
        setLoading(false);
        setError("Oops! Failed to create job!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Get Significant Places Details work list action
 * @param {Object} data
 * @returns
 */
const SignificantPlacesDetailsAction = (data) => ({
  type: SIGNIFICANT_PLACE_DETAILS,
  data,
});

export const SignificantPlacesDetails =
  (data, setLoading, setError, history, jobId) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await SignificantPlacesDetailsApi(data);
      if (response.success) {
        dispatch(SignificantPlacesDetailsAction(response.data));
        setLoading(false);
        history.push(`/client/create/job/various/element/${jobId}`);
      } else {
        setLoading(false);
        setError("Oops! Failed to create job!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * get all jobs forms details list action
 * @param {Object} data
 * @returns
 */
const getAllJobsDetailsListAction = (data) => ({
  type: GET_ALL_JOBS_FORMS_DETAILS_LIST,
  data,
});

export const getAllJobsDetailsList =
  (jobId, setLoading, setError) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await getAllJobsDetailsListApi(jobId);
      if (response.success) {
        dispatch(getAllJobsDetailsListAction(response));
        setLoading(false);
      } else {
        setLoading(false);
        setError("Oops! Failed To get Job details form list!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Create Job Technical Aspects action (POST)
 * @param {Object} data
 * @returns
 */

export const AddJobTechnicalAspects =
  (data, setLoading, setError, setShow) => async () => {
    setLoading(true);
    setError("");
    try {
      const response = await AddJobTechnicalAspectsApi(data);
      if (response.success) {
        setLoading(false);
        setShow(true);
      } else {
        setLoading(false);
        setError("Oops! Failed To Create Job Technical Aspects!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * delete job elements  to Your Footage
 * @param {Object} data
 * @returns
 */

export const deleteJobElementsFootage =
  (jobId, type, elementId, setDeleteLoading, setError, setReloadElements) =>
  async () => {
    setDeleteLoading(true);
    setError("");
    try {
      const response = await deleteJobElementsFootageApi(
        jobId,
        type,
        elementId
      );
      if (response.success) {
        setDeleteLoading(false);
        setReloadElements(true);
        Swal.fire("Success!", "Job Element Deleted successfully", "success");
        setTimeout(Swal.close, 2000);
      } else {
        setDeleteLoading(false);
        setError(
          `Oops! Failed To Delete ${type} Job Elements to Your Footage!`
        );
      }
    } catch (error) {
      setDeleteLoading(false);
      setError("Oops! Explain Your Changes");
    }
  };
/**
 * Request Data Action
 * @param {Object} data
 * @returns
 */

export const requestChangesAction =
  (
    data,
    setLoading,
    setRequestChanges,
    setRequestData,
    setModelError,
    setError
  ) =>
  async () => {
    setLoading(true);
    setModelError(false);
    try {
      const response = await getChangeRequestApi(data);
      if (response.success) {
        setLoading(false);
        setModelError(false);
        setRequestChanges(false);
        setRequestData("");
      } else {
        setLoading(false);
        setModelError(false);
        setError("Oops! Failed To Request Changes!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Failed To Request Changes!");
      setModelError(false);
    }
  };

/**
 * Change Request List Action for creator
 * @param {Object} data
 * @returns
 */
const getChangeRequestListAction = (data) => ({
  type: GET_CHANGE_REQUEST_LIST,
  data,
});

export const getChangeRequestList =
  (jobId, setLoading, setError) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await getChangeRequestListApi(jobId);
      if (response.success) {
        dispatch(getChangeRequestListAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
        setError("Oops! Failed To Get Change Request list!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };
