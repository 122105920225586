import React from "react";
import ATechLogo from "../components/common/ATechLogo";
import { withRouter } from "react-router";
import SignupFormWrapper from "../components/hoc/SignupFormWrapper";

function EmailMessage({ history, match }) {
  return (
    <SignupFormWrapper>
      <div className=" col-lg-6 col-sm-10 col-12">
        <div className="bg-white br-10 box-shadow p-sm-5 p-2">
          <p className="common-heading mb-1">
            <ATechLogo />
          </p>
          <p className="common-heading mb-1">E-mail Sent Successfully</p>
          <p className="common-text pb-4">
            {`A reset email has been sent to ${match.params.email} Please look for
              the reset password email in your inbox and click the link in that
              email`}
          </p>
          <p className="common-text pb-4">
            Please check your email for further process
          </p>

          <div className="mt-3">
            <button
              type="submit"
              onClick={() => history.push("/")}
              className=" w-100 mt-sm-2 mt-1  atech-primary-button  py-sm-3 px-4 py-2"
            >
              Back To Home
            </button>
          </div>
        </div>
      </div>
    </SignupFormWrapper>
  );
}

export default withRouter(EmailMessage);
