import Axios from "axios";
export let SERVER_URL = "atech-api.herokuapp.com";
// export let SERVER_URL = "192.168.29.204:8000";
export let PUBLIC_URL = `https://${SERVER_URL}/api/v1`;

export let WEB_SOCKET_URL = `${
  window.location.protocol === "https:" ? "wss" : "ws"
}://${SERVER_URL}/ws/`;

export async function axiosRequest(method, url, data = null) {
  try {
    const token = localStorage.getItem("a-tech-access-token");
    const headers = {};
    if (!token || token === "null") {
      headers["Content-Type"] = "application/json";
    } else if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    let response = await Axios({
      method: method,
      url: `${PUBLIC_URL}${url}`,
      headers: headers,
      data: data,
    });
    return await response.data;
  } catch (error) {
    return error;
  }
}
