import { useSelector } from "react-redux";
import React from "react";
import SearchIcon from "../../../../assets/img/svg/searchicon.svg";
import BubblesLoader from "../../../common/BubblesLoader";
import EmptyCard from "../../../common/EmptyCard";
import CompletedJobList from "./CompletedJobList";

function ClientCompletedJob({
  history,
  handleShorting,
  loading,
  searchValue,
  handleSearchJob,
}) {
  const clientJobList = useSelector((state) => state.jobs.clientJobList);
  const searchClientJobList = useSelector(
    (state) => state.jobs.searchClientJobList
  );

  return (
    <div className="container-fluid  ">
      <div className="row  align-items-center justify-content-between my-sm-4 my-2">
        <div className="col-xxl-4  col-xl-6 col-md-4">
          <div className="search-field position-relative">
            <input
              type="email"
              className="form-control "
              placeholder="Search for job..."
              onChange={(e) => handleSearchJob(e.target.value)}
              aria-describedby="emailHelp"
            />
            <img className="search-icon" src={SearchIcon} alt="SearchIcon" />
          </div>
        </div>
        <div className="col-xxl-3 col-xl-5 col-md-4 d-flex mt-md-0 mt-3  flex-sm-row flex-column ">
          <button
            className="atech-primary-button  me-3 w-100 py-md-0 py-2"
            onClick={() => history.push("/client/create/job/form")}
          >
            Create Job
          </button>
          <div className=" select-field  w-100 mt-sm-0 mt-3">
            <select
              className="form-select py-2 cursor-pointer px-3"
              onChange={(e) => handleShorting(e.target.value)}
            >
              <option>Sorting</option>
              <option value="0">Recently Added</option>
              <option value="1">Recently Updated</option>
            </select>
          </div>
        </div>
      </div>

      <div className="card-scroll admin_job ">
        <div
          className={`${
            clientJobList.length < 1 || loading || searchValue !== ""
              ? "h-100"
              : ""
          } row`}
        >
          {loading ? (
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <BubblesLoader />
              <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                Please wait...
              </p>
            </div>
          ) : searchValue === "" ? (
            clientJobList && !!clientJobList.length ? (
              clientJobList.map((item, index) => {
                return (
                  <CompletedJobList
                    item={item}
                    index={index}
                    history={history}
                    type="client/view/all-jobs/jobs"
                  />
                );
              })
            ) : (
              <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                <EmptyCard message={"You’ve not any Jobs List Yet!"} />
              </div>
            )
          ) : searchClientJobList && !!searchClientJobList.length ? (
            searchClientJobList.map((item, index) => {
              return (
                <CompletedJobList
                  item={item}
                  index={index}
                  history={history}
                  type="client/view/all-jobs/jobs"
                />
              );
            })
          ) : (
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <EmptyCard
                message={`You’ve not any Jobs List found from ${searchValue}!`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClientCompletedJob;
