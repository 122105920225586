import React from "react";
import ReactPlayer from "react-player";

const ClientMsgDispute = ({ value, index, type }) => {
  return (
    <div className="my-3" key={index}>
      <span className="your-message d-inline-block">
        {value && value.file !== null && value.is_image ? (
          <img src={value.file} alt="" />
        ) : (
          <ReactPlayer
            width="100%"
            className={`${value.file ? "your-message-video" : ""}`}
            height="100%"
            stopOnUnmount={true}
            controls={true}
            light={value.file}
            url={value.main_file}
            // playIcon={<VideoPlayIcon />}
            // playing={thumbnail.url ? true : false}
          />
        )}
        <div className="text-start">
          <span className="fs-16-500 color-17191c  you-message">
            {type} - {value && value.message}
          </span>
        </div>
      </span>
    </div>
  );
};

export default ClientMsgDispute;
