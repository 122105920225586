import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "./StripeForm";

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

const stripePromise = loadStripe(
  "pk_test_51LA9ZeLqE8f2aS4aJUUI20PbAwBkSW20rWb2jWfuQZpg4lD8X2JWUz9xmSbx5ZFisJ3r4OOKDMrQnAVOLhPEu2Z800ZS38uLG9"
);

const Stripe = ({ clientJobApplicationDetails, jobId, applicationId }) => {
  return (
    <div className="AppWrapper">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <StripeForm
          clientJobApplicationDetails={clientJobApplicationDetails}
          jobId={jobId}
          applicationId={applicationId}
        />
      </Elements>
    </div>
  );
};
export default Stripe;
