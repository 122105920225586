import React, { useEffect, useState } from "react";
import clockIcon from "../../../assets/img/png/clock.png";
import { getDateRemaning, getTime } from "../../common/utils/Functions";

const CurrentProjectGraphs = ({ clientDashboard }) => {
  const [timeLeft, setTimeLeft] = useState(
    getTime(
      clientDashboard &&
        clientDashboard.current_project &&
        clientDashboard.current_project.updated_at,
      clientDashboard &&
        clientDashboard.current_project &&
        clientDashboard.current_project.duration
    )
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(
        getDateRemaning(
          clientDashboard &&
            clientDashboard.current_project &&
            clientDashboard.current_project.updated_at,
          clientDashboard &&
            clientDashboard.current_project &&
            clientDashboard.current_project.duration
        )
      );
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="col-xxl-6   mt-3 mt-xxl-0">
      <div className="d-flex h-100 p-3 justify-content-between align-items-center box-shadow br-10 bg-white ">
        <div className="">
          <p className="common-text  mb-0">Current Project</p>
          <h1 className="common-heading-Medium max-sm-w-228  mb-0 py-1 ">
            {clientDashboard &&
              clientDashboard.current_project &&
              clientDashboard.current_project.title}
          </h1>
          {clientDashboard &&
          clientDashboard.current_project &&
          clientDashboard.current_project.updated_at ? (
            <p className="common-text fs-xs-14 mb-0">{timeLeft}</p>
          ) : (
            "No current project"
          )}
        </div>
        <span
          className="rounded-50  dashboard-icon   d-inline-block "
          style={{
            backgroundColor: "#fff6e7",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${clockIcon})`,
          }}
        ></span>
      </div>
    </div>
  );
};

export default CurrentProjectGraphs;
