import Preloader from "../../../components/common/Preloader";
import {
  LoginApi,
  SignUpApi,
  getAccessTokenApi,
  ChangePasswordApi,
  ResetPasswordApi,
  ForgotPasswordApi,
  verifyTokenApi,
} from "../../api/auth/AuthApi";
import { userDetailAction } from "./UserDetailsAction";
export const LOGIN_SUCCESSFULLY = "LOGIN_SUCCESSFULLY";
export const SIGN_UP_SUCCESSFULLY = "SIGN_UP_SUCCESSFULLY";

/**
 * Login action
 * @param {Object} data
 * @returns
 */
export const LoginSuccess = (data) => ({
  type: LOGIN_SUCCESSFULLY,
  data,
});

export const loginAction =
  (data, setLoading, history, setError) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await LoginApi(data);
      setError("");
      if (response.success) {
        localStorage.setItem("a-tech-refresh-token", response.data.refresh);
        localStorage.setItem("a-tech-access-token", response.data.access);
        dispatch(LoginSuccess(response));
        setLoading(false);
        history.push("/");
        window.location.reload();
      } else {
        setLoading(false);
        setError(
          response && response.message
            ? response.message
            : "Incorrect username or password"
        );
        localStorage.setItem("a-tech-access-token", null);
        localStorage.setItem("a-tech-refresh-token", null);
      }
    } catch (error) {
      setLoading(false);
      localStorage.setItem("a-tech-access-token", null);
      localStorage.setItem("a-tech-refresh-token", null);

      setError("Incorrect username or password");
    }
  };

/**
 * Sign up action
 * @param {Object} data
 * @returns
 */
const SignUpSuccess = (data) => ({
  type: SIGN_UP_SUCCESSFULLY,
  data,
});

export const SignUpAction =
  (data, setLoading, history, setError) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await SignUpApi(data);
      setError("");
      if (response.success) {
        localStorage.setItem("a-tech-access-token", response.data.access);
        localStorage.setItem("a-tech-refresh-token", response.data.refresh);
        dispatch(SignUpSuccess(response));
        setLoading(false);
        history.push("/sign-up/details");
      } else {
        setLoading(false);
        setError(
          response &&
            response.response &&
            response.response.data &&
            response.response.data.message &&
            response.response.data.message[0]
            ? response.response.data.message[0].split(":")[1]
            : "This email is already taken"
        );
        localStorage.setItem("a-tech-access-token", null);
        localStorage.setItem("a-tech-refresh-token", null);
      }
    } catch (error) {
      setLoading(false);
      localStorage.setItem("a-tech-access-token", null);
      localStorage.setItem("a-tech-refresh-token", null);
      setError("Ooops! Something went wrong.");
    }
  };

/**
 * GET ACCESS TOKEN
 * @param {Object} data
 * @returns
 */
export const getAccessToken = (refresh, setLoading, history) => async () => {
  setLoading(true);
  try {
    const response = await getAccessTokenApi(refresh);
    if (response) {
      setLoading(false);
      const access = response.access;
      localStorage.setItem("a-tech-access-token", access);
      userDetailAction(access, setLoading, history);
    } else {
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
  }
};

/**
 * Change Password
 * @param {Object} data
 * @param {Boolean} setLoading
 * @returns
 */
export const ChangePasswordAction =
  (data, setLoading, history, setError) => async () => {
    setLoading(true);
    try {
      const response = await ChangePasswordApi(data);
      if (response.success) {
        setLoading(false);
        history.push("/");
      } else {
        setLoading(false);
        setError("Wrong password.");
      }
    } catch (error) {
      setLoading(false);
      setError("Wrong password.");
    }
  };

/**
 * Forgot Password
 * @param {Object} data
 * @param {Boolean} setLoading
 * @returns
 */
export const ForgotPasswordApiAction =
  (data, setLoading, history, setError) => async () => {
    setLoading(true);
    try {
      setError("");
      const response = await ForgotPasswordApi(data);
      if (response.success) {
        setLoading(false);
        history.push(`/send-message/${data.email}`);
      } else {
        setLoading(false);
        setError(
          response &&
            response.response &&
            response.response.data &&
            response.response.data.message
            ? response.response.data.message
            : "No user found associated with the email address"
        );
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong.");
    }
  };

/**
 * Reset Your Password
 * @param {Object} data
 * @param {Boolean} setLoading
 * @returns
 */
export const ResetYourPasswordAction =
  (data, setLoading, history, setError) => async () => {
    setLoading(true);
    try {
      const response = await ResetPasswordApi(data);
      if (response.success) {
        setLoading(false);
        history.push("/");
      } else {
        setLoading(false);
        setError("Ooops! Something went wrong.");
      }
    } catch (error) {
      setLoading(false);
      setError("Ooops! Something went wrong.");
    }
  };

/**
 * verify Token Action
 * @param {Object} data
 * @param {Boolean} setVerifyLoading
 * @returns
 */
export const verifyTokenAction =
  (data, setVerifyLoading, setError, history) => async () => {
    setVerifyLoading(true);
    try {
      setError("");
      const response = await verifyTokenApi(data);
      if (response.success) {
        setVerifyLoading(false);
        <Preloader />;
        history.push(`/reset/form/${data.user}/${data.token}/${data.duration}`);
        window.location.reload();
      } else {
        setVerifyLoading(true);
        setError(
          response &&
            response.response &&
            response.response.data &&
            response.response.data.message
            ? response.response.data.message
            : "Reset password link has expired"
        );
      }
    } catch (error) {
      setVerifyLoading(true);
      setError("Ooops! Something went wrong.");
    }
  };
