import React, { useMemo } from "react";
import { withRouter } from "react-router";
import countryList from "react-select-country-list";
import ATechLogo from "../../common/ATechLogo";
import SignUpDetailsForm from "./SignUpDetailsForm";
import SignupFormWrapper from "../../hoc/SignupFormWrapper";

const SignUpDetails = ({ history }) => {
  const options = useMemo(() => countryList().getData(), []);

  return (
    <SignupFormWrapper>
      <div className=" col-xl-6 col-sm-10 col-12">
        <div className="bg-white box-shadow br-10 p-sm-5 p-3">
          <ATechLogo />
          <p className="common-heading mb-5">Sign Up</p>
          {/** Sign up details form */}
          <SignUpDetailsForm options={options} history={history} />
        </div>
      </div>
    </SignupFormWrapper>
  );
};
export default withRouter(SignUpDetails);
