import React, { useState } from "react";

const DetailsLocationForm = ({
  significantFormdTypes,
  setSignificantFormTypes,
}) => {
  const [checkedValue, setCheckedValue] = useState("no");
  const handleRadioChange = (e) => {
    setCheckedValue(e.target.value);
    if (checkedValue === "yes") {
      setSignificantFormTypes({
        ...significantFormdTypes,
        is_extream_weather: true,
      });
    } else {
      setSignificantFormTypes({
        ...significantFormdTypes,
        is_extream_weather: false,
      });
    }
  };
  return (
    <>
      <label for="inputLocation" className="form-label common-text mb-3">
        Does your footage contain extreme weather conditions?
      </label>
      <div className="d-flex mb-3">
        <input
          type="radio"
          id="no"
          name="fav_language"
          className="cursor-pointer"
          value="no"
          checked={checkedValue === "no"}
          onChange={(e) => handleRadioChange(e)}
        />
          <label for="no">No</label> {" "}
        <div className="ps-3">
          <input
            type="radio"
            id="yes"
            name="fav_language"
            className="cursor-pointer"
            value="yes"
            checked={checkedValue === "yes"}
            onChange={(e) => handleRadioChange(e)}
          />{" "}
          <label for="yes">Yes</label>
        </div>
      </div>

      <textarea
        rows="4"
        value={significantFormdTypes.weather_explaination}
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            weather_explaination: e.target.value,
          })
        }
        className="w-100 form-control common-text text-8B8C8D border-b9babb br-3 py-3 px-4 "
        placeholder="Describe the weather conditions in your footage."
      ></textarea>
    </>
  );
};

export default DetailsLocationForm;
