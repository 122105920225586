import React, { useState } from "react";
import LocationIcon from "../../../../assets/img/svg/location.svg";
import { MessageIcon } from "../../../common/icons/Icon";
import LockAccountIcon from "../../../../assets/img/svg/lockaccounticon.svg";
import {
  lockUserAccount,
  startSendMessage,
} from "../../../../redux/action/admin/AdminAcountsAction";
import Loader from "../../../common/Loader";
import { useDispatch } from "react-redux";

function AdminUserProfileBoxDetails({
  adminUserDetails,
  type,
  userId,
  history,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [isActive, setIsActive] = useState(
    adminUserDetails && adminUserDetails.is_active
  );

  const lockUserAccountHandler = (value) => {
    const data = {
      is_active: value,
    };
    dispatch(lockUserAccount(userId, data, setLoading, setIsActive, isActive));
  };

  const sendMessageHandler = () => {
    const data = {
      receiver: userId,
    };
    dispatch(startSendMessage(data, setStartLoading, history, "admin"));
  };
  return (
    <>
      <div className="row  pb-4 br-bottom">
        <div className="col-xxl-4 col-sm-6  d-flex justify-content-xxl-start justify-content-center pb-3">
          <img
            className="profile-upload w-100 br-10"
            src={adminUserDetails && adminUserDetails.profile_image_path}
            alt=""
          />
        </div>
        <div className="col-xxl-4 col-sm-6  pt-1 my-auto d-flex flex-column  align-items-xxl-start align-items-center ">
          <div className="mb-2">
            <p className="common-heading">
              {adminUserDetails && adminUserDetails.first_name
                ? adminUserDetails.first_name + " " + adminUserDetails.last_name
                : adminUserDetails && adminUserDetails.username
                ? adminUserDetails.username
                : "N/A"}
            </p>
          </div>
          <div className="mb-2">
            <p className="common-text f-w-600">{type}</p>
          </div>
          <div className="mb-2 d-flex align-items-center">
            <span>
              <img className="pe-3" src={LocationIcon} alt="location" />
            </span>
            <p className="common-text mb-0">
              {adminUserDetails && adminUserDetails.city}&nbsp;,
              {adminUserDetails && adminUserDetails.country}
            </p>
          </div>
        </div>
        <div className="col-xxl-4 mt-2 mt-sm-0 ">
          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-end">
            <button
              className="py-2 px-2 atech-primary-button  mt-2 mt-sm-0 me-sm-3"
              onClick={() => sendMessageHandler()}
              disabled={startLoading}
            >
              <span className="pe-2">
                <MessageIcon />
              </span>
              {startLoading ? <Loader /> : "Send Message"}
            </button>
            {window.location.pathname.includes("/client/creator/profile") ? (
              ""
            ) : window.location.pathname.includes("/creator/client/profile") ? (
              ""
            ) : (
              <button
                className="py-2 px-2 atech-fade-button my-sm-0 my-3"
                onClick={() =>
                  isActive
                    ? lockUserAccountHandler(false)
                    : lockUserAccountHandler(true)
                }
              >
                <span className="pe-2">
                  <img src={LockAccountIcon} alt="LockAccountIcon" />
                </span>
                {loading ? (
                  <Loader />
                ) : isActive ? (
                  "Lock Account"
                ) : (
                  "Unlock Account"
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* ====================================Bio-section===================================== */}
      <div className="row">
        <div className="col-12 pb-4  br-bottom">
          <p className="my-4 common-heading-Medium  sm-font">Bio</p>
          <div className=" atech-border">
            <p className="common-text pt-3 px-lg-4 px-2  ">
              {adminUserDetails && adminUserDetails.bio
                ? adminUserDetails.bio
                : "N/A"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminUserProfileBoxDetails;
