import { Accordion } from "react-bootstrap";
import ActorsAndModel from "./CommonFields/ActorsAndModel";

function Model({ jobList }) {
  return (
    <Accordion className="footage-accordian">
      {jobList &&
        jobList.modals &&
        jobList.modals.length > 0 &&
        jobList.modals.map((value, index) => {
          return (
            <ActorsAndModel FootageType={"Model"} value={value} index={index} />
          );
        })}
    </Accordion>
  );
}

export default Model;
