import { StarIcon } from "../common/icons/Icon";

export const RatingData = [
  {
    starImg: <StarIcon />,
  },
  {
    starImg: <StarIcon />,
  },
  {
    starImg: <StarIcon />,
  },
  {
    starImg: <StarIcon />,
  },
  {
    starImg: <StarIcon />,
  },
];

function getExtension(filename) {
  if (!!filename) {
    const type = typeof filename;
    const fileUrl = type === "object" ? filename[0] : filename;
    var parts = fileUrl && fileUrl.split(".");
    return parts && parts[parts.length - 1];
  }
}

/**
 * Checking Image size
 * @param {String} filename
 * @returns
 */
export function isImage(filename) {
  if (!!filename) {
    var ext = getExtension(filename);
    switch (ext && ext.toLowerCase()) {
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
      case "png":
        //etc
        return true;
      default:
        return false;
    }
  }
}

/**
 * Checking video size
 * @param {String} filename
 * @returns
 */
export function isVideo(filename) {
  if (!!filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
      case "mov":
        // etc
        return true;
      default:
        return false;
    }
  }
}

/**
 * download the video to the local storage
 * and play it in the browser using the video
 * tag in the html page and download the video
 * to the local storage
 * @param {String} filePath
 */
export const downloadvedio = async (filePath) => {
  if (!!filePath) {
    const response = await fetch(filePath);
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", "video.mp4"); //or any other extension
      document.body.appendChild(a);
      a.click();
    });
  }
};
