import React from "react";
import { DeleteIcon } from "../icons/Icon";
import { getTime } from "../utils/Functions";

const NotificationListItem = ({ notify, setShow, setNotifiactionId }) => {
  const { message, created_at, user } = notify;
  return (
    <>
      <div className=" br-10 notification-section p-2 my-2">
        <div className="d-flex align-items-center justify-content-between ">
          <div className=" d-flex align-items-center pe-0">
            <span>
              <img
                className="notification-user-img "
                src={user.profile_image_path}
                alt="notifyimg"
              />
            </span>
            <div className="ps-sm-3 ps-2">
              <p className="mb-0 common-text text-black wrap f-w-600 ">
                {message}
              </p>
              <p className="mb-0 common-text xxs-font wrap">
                {getTime(created_at)}
              </p>
            </div>
          </div>
          <div className=" ps-0">
            <button
              className="px-sm-3 px-2 py-2  atech-fade-button"
              onClick={() => {
                setShow(true);
                setNotifiactionId(notify.id);
              }}
            >
              <span className="px-sm-2">
                <DeleteIcon />
              </span>
              <span className=" d-inline-block fs-Montserrat "> Clear</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationListItem;
