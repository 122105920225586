import React from "react";
import AddTreeAndBuildingForm from "../AddTreeAndBuildingForm";

const AddBuilding = ({ setReloadElements }) => {
  return (
    <AddTreeAndBuildingForm
      type="building"
      message={"Add Building"}
      label={"building"}
      setReloadElements={setReloadElements}
    />
  );
};

export default AddBuilding;
