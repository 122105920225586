import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import plusIcon from "../../../assets/img/svg/plus-icon.svg";
import { submitWorkDetails } from "../../../redux/action/creator/CreatorWorkAction";
import ATechLogo from "../../common/ATechLogo";
import AddPastWork from "./AddPastWork";
import SignUpWorkForm from "./SignUpWorkForm";
import SignupFormWrapper from "../../hoc/SignupFormWrapper";

function SignUpWork({ history }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pastWorkArray, setPastWorkArray] = useState([]);

  const submitPastWorkDetails = () => {
    if (!!pastWorkArray.length) {
      const work = {
        works: pastWorkArray,
      };
      dispatch(submitWorkDetails(work, setLoading, history, "logout"));
    }
  };

  return (
    <SignupFormWrapper>
      <div className="col bg-white box-shadow br-10 ">
        <div className=" p-lg-5 p-sm-4 p-2 ">
          <ATechLogo />
          <p className="common-heading mb-3">Add Past Work</p>
          {/** Add past work form details and update */}
          <AddPastWork
            pastWorkArray={pastWorkArray}
            setPastWorkArray={setPastWorkArray}
          />
          {/** Add past work form */}
          {show ? (
            <SignUpWorkForm
              pastWorkArray={pastWorkArray}
              show={show}
              setShow={setShow}
            />
          ) : null}

          <div className="d-flex  flex-sm-row flex-column justify-content-sm-end justify-content-center mt-3 px-0 ">
            <button
              className="px-3 atech-fade-button py-sm-2 py-2 ms-sm-2  my-2 "
              onClick={() => setShow(true)}
            >
              <span>
                <img className="px-2" src={plusIcon} alt="" />
                <span> Add Work</span>
              </span>
            </button>
          </div>

          <div className="d-flex  flex-sm-row flex-column justify-content-sm-start justify-content-center mt-3 px-0 ">
            <button
              onClick={() => submitPastWorkDetails()}
              disabled={
                !!pastWorkArray.length ? false : loading ? loading : true
              }
              className={`px-4 atech-primary-button py-sm-3 py-2   my-2 ${
                !!pastWorkArray.length
                  ? "cursor-pointer"
                  : loading
                  ? "cursor-not-allowed"
                  : "cursor-not-allowed"
              }`}
            >
              {loading ? "Loading..." : "Next"}
            </button>
            <button
              onClick={() => history.push("/sign-up/add-gear")}
              className="px-4 atech-fade-button py-sm-3 py-2 ms-sm-2  my-2 "
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </SignupFormWrapper>
  );
}
export default withRouter(SignUpWork);
