import AdminViewJobDetails from "../../../components/admin/adminJobs/AdminViewJobDetails";
import AdminATechHeader from "../../../components/common/AdminATechHeader";
import { useLayoutProviderContext } from "../../../context/LayoutProvider";

function AdminViewJobs() {
  const { sidebarActive, setSidebarActive } = useLayoutProviderContext();
  return (
    <div className="container-fluid">
      <AdminATechHeader
        HeaderName={"Jobs"}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <AdminViewJobDetails />
    </div>
  );
}

export default AdminViewJobs;
