import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminAllUserList } from "../../../redux/action/admin/AdminAcountsAction";
import AccountsListItem from "./AccountsListItem";
import BubblesLoader from "../../common/BubblesLoader";
import EmptyCard from "../../common/EmptyCard";
import InfiniteScroll from "react-infinite-scroll-component";

function CreatorAccount({ history, type }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const adminAllUserList = useSelector(
    (state) => state.AdminAcountsReducer.adminAllUserList
  );
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const pageChangeHandler = () => {
    setPage(page + 1);
  };
  useEffect(() => {
    const type = "creator";
    dispatch(getAdminAllUserList(setLoading, type, page, setHasMore));
  }, [dispatch, page]);

  const adminAllUserListArray =
    page === 0 && loading ? (
      <div className="h-100 d-flex justify-content-center align-items-center flex-column">
        <BubblesLoader />
        <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
          Please wait...
        </p>
      </div>
    ) : adminAllUserList && adminAllUserList.length > 0 ? (
      adminAllUserList.map((obj, index) => (
        <AccountsListItem
          accountData={obj}
          key={index}
          history={history}
          type={type}
        />
      ))
    ) : (
      <EmptyCard message={"You’ve not any Creator List Yet!"} />
    );

  return (
    <div className="notification-scroll " id="creator-list-account">
      <InfiniteScroll
        className={`${page === 0 && !loading ? "" : "h-100 scrollbar_desgin"}`}
        dataLength={adminAllUserList.length}
        next={pageChangeHandler}
        hasMore={hasMore}
        scrollableTarget="creator-list-account"
        loader={
          page > 0 && loading ? (
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <BubblesLoader />
            </div>
          ) : (
            ""
          )
        }
      >
        {adminAllUserListArray}
      </InfiniteScroll>
    </div>
  );
}
export default CreatorAccount;
