import React from "react";
import AddGSBandParticleEffectsForm from "../AddGSBandParticleEffectsForm";

const AddGreenScreenFootage = ({ setReloadElements }) => {
  return (
    <AddGSBandParticleEffectsForm
      type={"green_screen_background"}
      message={"Add Green Screen Background"}
      label={"Describe green screen background"}
      setReloadElements={setReloadElements}
    />
  );
};

export default AddGreenScreenFootage;
