import { axiosRequest } from "../../helper";

/**
 * User details submit api
 * @param {Object} data
 * @returns
 */
export const AddUserDeatilsApi = async (data) => {
  return await axiosRequest("PATCH", `/users/`, data);
};

/**
 * Update User details api
 * @param {Object} data
 * @returns
 */
export const UpdateUserDetailsApi = async (data) => {
  return await axiosRequest("PATCH", `/users/`, data);
};

/**
 * USER DETAIL API
 * @param {Object} data
 * @returns
 */
export const GetUserDeatialApi = async (access) => {
  return await axiosRequest("GET", `/users/`, access);
};
