import React from "react";
import ReactLoading from "react-loading";
function BubblesLoader() {
  return (
    <>
      <ReactLoading
        className="preloader"
        type="spinningBubbles"
        color="#127cf9"
      />
    </>
  );
}

export default BubblesLoader;
