import React from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import { VideoPlayIcon } from "../../../../components/common/icons/Icon";

const DeliveryModel = ({
  show,
  setShow,
  setRequestChanges,
  setModelError,
  setRequestData,
  showVedio,
  clientJobViewDetails,
  approvedjob,
}) => {
  const ClickHandler = () => {
    setModelError(false);
    setRequestChanges(true);
    setRequestData("");
  };
  const download = () => {
    var element = document.createElement("a");
    var file = new Blob([`${showVedio && showVedio.file}`], {
      type: "video.mp4/*",
    });

    element.href = URL.createObjectURL(file);
    element.download = "video.mp4";
    element.click();
  };
  return (
    <Modal
      show={show}
      id="video-modal"
      onHide={() => setShow(false)}
      centered
      dialogClassName="modal-100w p-4"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header>
        <Modal.Title
          className="head_earn text-dark-black d-flex  justify-content-between w-100"
          id="example-custom-modal-styling-title"
        >
          Delivery
          <div>
            <button
              onClick={() => download()}
              className="me-sm-2 w-xs-100 border-e8e8e8 py-2 px-md-3 px-2  para  br-3 my-2 Start-Dispute-hover common-text"
            >
              Download Video
            </button>
            {window.location.pathname.includes("/admin/jobs/view") ? (
              ""
            ) : clientJobViewDetails &&
              clientJobViewDetails.is_completed_by_creator === false &&
              !approvedjob ? (
              <button
                className="border-e8e8e8 py-2 px-md-3 px-2 my-2 view-job-btn w-xs-100"
                onClick={() => ClickHandler()}
              >
                Request Changes
              </button>
            ) : (
              ""
            )}
          </div>
        </Modal.Title>
      </Modal.Header>
      <div className="border-bottom "></div>
      <Modal.Body>
        <div className="profile-modal">
          <ReactPlayer
            width="100%"
            className="w-100-video modal-video-height "
            stopOnUnmount={true}
            controls={true}
            light={showVedio && showVedio.thumb_path && showVedio.thumb_path}
            autoPlay={true}
            url={showVedio && showVedio.file}
            playIcon={<VideoPlayIcon />}
          />
        </div>
        {/* <p className="common-text mt-2">{item && item.description}</p> */}
      </Modal.Body>
    </Modal>
  );
};

export default DeliveryModel;
