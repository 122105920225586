import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import StripeModel from "../../../components/payment/StripeModel";
import {
  getJobApplicationDetails,
  getJobViewDetails,
} from "../../../redux/action/client/jobFeed";

const ClientPayment = ({ match }) => {
  const { jobId, applicationId } = match.params;
  const dispatch = useDispatch();
  const clientJobApplicationDetails = useSelector(
    (state) => state.jobs.clientJobApplicationDetails
  );
  const clientJobViewDetails = useSelector(
    (state) => state.jobs.clientJobViewDetails
  );
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (jobId && applicationId) {
      dispatch(
        getJobApplicationDetails(setLoading, jobId, applicationId, setError)
      );
      dispatch(getJobViewDetails(setLoading, jobId));
    }
  }, [jobId, applicationId, dispatch]);

  return (
    <StripeModel
      showStripeForm={showStripeForm}
      setShowStripeForm={setShowStripeForm}
      loading={loading}
      error={error}
      clientJobApplicationDetails={clientJobApplicationDetails}
      jobId={jobId}
      applicationId={applicationId}
      clientJobViewDetails={clientJobViewDetails}
    />
  );
};

export default withRouter(ClientPayment);
