import React, { useEffect, useState } from "react";
import TotalIncome from "./TotalIncome";
import TotalClients from "./TotalClients";
import TotalCreator from "./TotalCreator";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardChatDetails } from "../../../redux/action/admin/AdminAcountsAction";

const AdminDashboardCard = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const adminDashboard = useSelector(
    (state) => state.AdminAcountsReducer.adminDashboard
  );

  useEffect(() => {
    dispatch(getDashboardChatDetails(setLoading));
  }, [dispatch]);

  return (
    <div className="container-fluid">
      <div className="row">
        <TotalIncome loading={loading} adminDashboard={adminDashboard} />
        <TotalClients loading={loading} adminDashboard={adminDashboard} />
        <TotalCreator loading={loading} adminDashboard={adminDashboard} />
      </div>
    </div>
  );
};

export default AdminDashboardCard;
