import Swal from "sweetalert2";
import {
  adminUserGearsDetailsApi,
  adminUserProfileDetailsApi,
  adminUserWorkDetailsApi,
  getAdminAllUserListApi,
  getDashboardChatDetailsApi,
  getReportJobDetailsApi,
  getReportJobListApi,
  getReportUserDetailsApi,
  getReportUserListApi,
  lockUserAccountApi,
  startSendMessageApi,
} from "../../api/admin/AdminAcountsApi";
import {
  deleteNotificationApi,
  getAdminNotificationApi,
  getUnseenNotificationApi,
} from "../../api/admin/AdminJob";

export const GET_ADMIN_ALL_USER_LIST = "GET_ADMIN_ALL_USER_LIST";
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const GET_NOTIFICATION_LIST_DELETE = "GET_NOTIFICATION_LIST_DELETE";
export const GET_USERS_DETAILS_FOR_ADMIN = "GET_USERS_DETAILS_FOR_ADMIN";
export const GET_USERS_WORK_DETAILS_ADMIN = "GET_USERS_WORK_DETAILS_ADMIN";
export const GET_USERS_GEARS_DETAILS_ADMIN = "GET_USERS_GEARS_DETAILS_ADMIN";
export const GET_DASHBOARD_CHAT_DETAILS = "GET_DASHBOARD_CHAT_DETAILS";
export const GET_JOB_REPORT_LIST = "GET_JOB_REPORT_LIST";
export const GET_USER_REPORT_LIST = "GET_USER_REPORT_LIST";
export const GET_USER_REPORT_DETAILS = "GET_USER_REPORT_DETAILS";
export const GET_JOB_REPORT_DETAILS = "GET_JOB_REPORT_DETAILS";
export const GET_UNSEEN_NOTIFICATION = "GET_UNSEEN_NOTIFICATION";
export const GET_UPDATE_UNSEEN_NOTIFICATION = "GET_UPDATE_UNSEEN_NOTIFICATION";
export const REMOVE_UNSEEN_NOTIFICATION = "REMOVE_UNSEEN_NOTIFICATION";

/**
 * Get Admin all user list action
 * @param {Array} data
 * @returns
 */
export const getAdminAllUserListAction = (data) => ({
  type: GET_ADMIN_ALL_USER_LIST,
  data,
});

export const getAdminAllUserList =
  (setLoading, type, page, setHasMore) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await getAdminAllUserListApi(type, page);
      if (response.success) {
        dispatch(getAdminAllUserListAction(response.data));
        setLoading(false);
        if (response.data.length < 10) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

/**
 * Get Admin Notification List
 * @param {Array} data
 * @returns
 */
export const getAdminNotificationListAction = (data) => ({
  type: GET_NOTIFICATION_LIST,
  data,
});

export const getNotificationList =
  (setLoading, page, setHasMore, setError) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await getAdminNotificationApi(page);
      if (response.success) {
        dispatch(
          getAdminNotificationListAction({ data: response.data, page: page })
        );
        setLoading(false);
        if (response.data.length < 10) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
        setError("Oops! Failed to load Notification List");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };
/**
 * Get Admin Notification List
 * @param {Array} data
 * @returns
 */
export const deleteNotificationAction = (data) => ({
  type: GET_NOTIFICATION_LIST_DELETE,
  data,
});

export const deleteNotification =
  (notificatinId, setDeleteLoading, setError, setShow) => async (dispatch) => {
    setDeleteLoading(true);
    setError("");
    try {
      const response = await deleteNotificationApi(notificatinId);
      if (response.success) {
        dispatch(deleteNotificationAction(notificatinId));
        setDeleteLoading(false);
        setShow(false);
        Swal.fire("Success!", "Notification deleted successfully", "success");
        setTimeout(Swal.close, 2000);
      } else {
        setDeleteLoading(false);
        setError("Oops! Failed To delete Notification");
      }
    } catch (error) {
      setDeleteLoading(false);
      setError("Oops! Failed To delete Notification");
    }
  };

/**
 * Get Admin unseen Notification List
 * @param {Array} data
 * @returns
 */
export const getUnseenNotificationAction = (data) => ({
  type: GET_UNSEEN_NOTIFICATION,
  data,
});

export const UpdateUnseenNotificationAction = () => ({
  type: GET_UPDATE_UNSEEN_NOTIFICATION,
});

export const RemoveUnseenNotificationAction = () => ({
  type: REMOVE_UNSEEN_NOTIFICATION,
});

export const getUnseenNotification = () => async (dispatch) => {
  try {
    const response = await getUnseenNotificationApi();
    if (response.success) {
      dispatch(getUnseenNotificationAction(response.data));
    }
  } catch {}
};

/**
 * Get users details for admin
 * @param {Array} data
 * @returns
 */
export const adminUserProfileDetailsAction = (data) => ({
  type: GET_USERS_DETAILS_FOR_ADMIN,
  data,
});

export const adminUserProfileDetails =
  (userId, setLoading) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await adminUserProfileDetailsApi(userId);
      if (response.success) {
        dispatch(adminUserProfileDetailsAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

/**
 * Get users work details for admin
 * @param {Array} data
 * @returns
 */
export const adminUserWorkDetailsAction = (data) => ({
  type: GET_USERS_WORK_DETAILS_ADMIN,
  data,
});

export const adminUserWorkDetails =
  (userId, setLoading) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await adminUserWorkDetailsApi(userId);
      if (response.success) {
        dispatch(adminUserWorkDetailsAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

/**
 * Get users gears details for admin
 * @param {Array} data
 * @returns
 */
export const adminUserGearsDetailsAction = (data) => ({
  type: GET_USERS_GEARS_DETAILS_ADMIN,
  data,
});

export const adminUserGearsDetails =
  (userId, setLoading) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await adminUserGearsDetailsApi(userId);
      if (response.success) {
        dispatch(adminUserGearsDetailsAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

/**
 * Get users gears details for admin
 * @param {Array} data
 * @returns
 */
export const getDashboardChatDetailsAction = (data) => ({
  type: GET_DASHBOARD_CHAT_DETAILS,
  data,
});

export const getDashboardChatDetails = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await getDashboardChatDetailsApi();
    if (response.success) {
      dispatch(getDashboardChatDetailsAction(response.data));
      setLoading(false);
    } else {
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
  }
};

/**
 * lock user account by admin
 * @param {String} userId
 * @param {Boolen} setLoading
 * @returns
 */
export const lockUserAccount =
  (userId, data, setLoading, setIsActive, isActive) => async () => {
    try {
      setLoading(true);
      const response = await lockUserAccountApi(userId, data);
      if (response.success) {
        setLoading(false);
        setIsActive(!isActive);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

/**
 * start chat with user api
 * @param {Boolen} setLoading
 * @returns
 */
export const startSendMessage =
  (data, setLoading, history, type) => async () => {
    try {
      setLoading(true);
      const response = await startSendMessageApi(data);
      if (response.success) {
        setLoading(false);
        history.push(
          `/${type}/chat/${response && response.chat && response.chat.id}`
        );
      } else {
        setLoading(false);
        history.push(
          `/${type}/chat/${response && response.chat && response.chat.id}`
        );
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Opps!", "Chat Already Started", "error");
      setTimeout(Swal.close, 2000);
    }
  };

/**
 * Get Admin Report job list
 * @param {Array} data
 * @returns
 */
export const getReportJobListAction = (data) => ({
  type: GET_JOB_REPORT_LIST,
  data,
});

export const getReportJobList =
  (setLoading, page, setHasMore, setError) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await getReportJobListApi(page);
      if (response.success) {
        dispatch(getReportJobListAction({ data: response.data, page: page }));
        setLoading(false);
        if (response.data.length < 10) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
        setError("Oops! Failed to load Job Report List");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Get Admin Report job Details
 * @param {Array} data
 * @returns
 */
export const getReportJobDetailsAction = (data) => ({
  type: GET_JOB_REPORT_DETAILS,
  data,
});

export const getReportJobDetails =
  (setLoading, setError, jobId) => async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await getReportJobDetailsApi(jobId);
      if (response.success) {
        dispatch(getReportJobDetailsAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
        setError("Oops! Failed to load Job Report Details");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Get Admin Report user list
 * @param {Array} data
 * @returns
 */
export const getReportUserListAction = (data) => ({
  type: GET_USER_REPORT_LIST,
  data,
});

export const getReportUserList =
  (setLoading, page, setHasMore, setError) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await getReportUserListApi(page);
      if (response.success) {
        dispatch(getReportUserListAction({ data: response.data, page: page }));
        setLoading(false);
        if (response.data.length < 10) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
        setError("Oops! Failed to load User Report List");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Get Admin Report job Details
 * @param {Array} data
 * @returns
 */
export const getReportUserDetailsAction = (data) => ({
  type: GET_USER_REPORT_DETAILS,
  data,
});

export const getReportUserDetails =
  (setLoading, setError, jobId) => async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await getReportUserDetailsApi(jobId);
      if (response.success) {
        dispatch(getReportUserDetailsAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
        setError("Oops! Failed to load User Report Details");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };
