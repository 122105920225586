import React, { useEffect, useState } from "react";
import { EyeIcon } from "../common/icons/Icon";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { getJobListForDisplute } from "../../redux/action/chat/ClientChat";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../common/ErrorMessage";
import BubblesLoader from "../common/BubblesLoader";
import EmptyCard from "../common/EmptyCard";

const ViewAllJobModel = ({ show, setShow, userId }) => {
  const pathname = window.location.pathname;
  const pathnameDirection = pathname.includes("admin")
    ? "admin/jobs/view"
    : pathname.includes("client")
    ? "client/view/all-jobs/jobs"
    : "creator/job-view/details";

  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const jobListForDisplute = useSelector(
    (state) => state.jobs.jobListForDisplute
  );

  useEffect(() => {
    if (userId) {
      dispatch(getJobListForDisplute(userId, setLoading, setError));
    }
  }, [userId, dispatch]);

  return (
    <Modal
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => setShow(false)}
      className="message_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Job List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error !== "" ? <ErrorMessage message={error} /> : ""}
        {loading ? (
          <div className="h-100 d-flex justify-content-center align-items-center flex-column">
            <BubblesLoader />
            <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
              Please wait...
            </p>
          </div>
        ) : jobListForDisplute && jobListForDisplute.length > 0 ? (
          jobListForDisplute.map((val, index) => (
            <div
              className="message_modal_job d-flex justify-content-between p-3 p-sm-4 mb-4 align-items-center"
              key={index}
            >
              <p className="mb-0 message_title_text common-text">{val.title}</p>
              <Link
                to={`/${pathnameDirection}/${userId}`}
                className="text-decoration-none atech-fade-button px-2 px-sm-3 py-2"
              >
                <span class="pe-2">
                  <EyeIcon />
                </span>
                <span class=" d-inline-block fs-Montserrat ">View</span>
              </Link>
            </div>
          ))
        ) : (
          <EmptyCard message={"You don't have any job list for This user."} />
        )}
      </Modal.Body>
    </Modal>
  );
};
export default ViewAllJobModel;
