import { Accordion } from "react-bootstrap";

function PropertyLandMarineAndBird({ value, index, FootageType }) {
  return (
    <Accordion.Item className="my-3" eventKey={index}>
      <Accordion.Header className="accordian_border">
        <span>
          {FootageType} {index + 1}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <div className="bg-F8F8F8 p-2">
          <p className="mt-4 mb-3 common-text px-2 px-lg-4">Name Props:</p>
          <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
            {value && value.name ? value.name : "N/A"}
          </p>
          <p className="mt-4 common-text px-2 px-lg-4">Describe Props:</p>
          <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
            {value && value.describe ? value.describe : "N/A"}
          </p>
          <p className="mt-4 common-text px-2 px-lg-4">
            Describe what is done with the props:
          </p>
          <p className="common-text mb-1 px-2 px-lg-4 border-bottom-B9BABB pb-3">
            {value && value.details ? value.details : "N/A"}
          </p>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default PropertyLandMarineAndBird;
