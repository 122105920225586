import React from "react";
import AddActorForm from "../AddActorForm";
import AddReporterForm from "../AddReporterForm";
import AddExtrasForm from "../AddExtrasForm";

const PeopleFootage = ({ setReloadElements }) => {
  return (
    <div className="d-flex flex-sm-row flex-column">
      <AddActorForm
        type={"actor"}
        message={"Add Actor"}
        setReloadElements={setReloadElements}
      />
      <AddReporterForm setReloadElements={setReloadElements} />
      <AddActorForm
        type={"model"}
        message={"Add Model"}
        setReloadElements={setReloadElements}
      />
      <AddExtrasForm setReloadElements={setReloadElements} />
    </div>
  );
};

export default PeopleFootage;
