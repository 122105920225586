import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import {
  adminUserGearsDetails,
  adminUserProfileDetails,
  adminUserWorkDetails,
} from "../../../redux/action/admin/AdminAcountsAction";
import AdminATechHeader from "../../../components/common/AdminATechHeader";
import AdminUserProfileBoxDetails from "../../../components/admin/accounts/profile/AdminUserProfileBoxDetails";
import BubblesLoader from "../../../components/common/BubblesLoader";
import AdminUserGearsDetail from "../../../components/admin/accounts/profile/AdminUserGearsDetail";
import AdminUserPastWorkDetail from "../../../components/admin/accounts/profile/AdminUserPastWorkDetail";
import ATechHeader from "../../../components/common/ATechHeader";
import ClientATechHeader from "../../../components/client/ClientATechHeader";

function AdminCreatorProfile({ match, history }) {
  const adminUserDetails = useSelector(
    (state) => state.AdminAcountsReducer.adminUserDetails
  );
  const dispatch = useDispatch();
  const { userId } = match.params;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userId) {
      dispatch(adminUserProfileDetails(userId, setLoading));
      dispatch(adminUserWorkDetails(userId, setLoading));
      dispatch(adminUserGearsDetails(userId, setLoading));
    }
  }, [dispatch, userId]);

  return (
    <>
      {window.location.pathname.includes("admin") ? (
        <AdminATechHeader HeaderName={"Profile"} />
      ) : window.location.pathname.includes("/client/creator") ? (
        <ClientATechHeader HeaderName={"Profile"} />
      ) : (
        <ATechHeader HeaderName={"Profile"} />
      )}
      <div className="mx-3 mx-1 bg-white p-md-4 p-2 br-10 mt-3 ">
        <div className="container-fluid my-4  profile-scroll">
          {loading ? (
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <BubblesLoader />
              <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                Please wait...
              </p>
            </div>
          ) : (
            <>
              <AdminUserProfileBoxDetails
                adminUserDetails={adminUserDetails}
                type="Creator"
                userId={userId}
                history={history}
              />
              <AdminUserPastWorkDetail />
              <AdminUserGearsDetail />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(AdminCreatorProfile);
