import React from "react";
import AddCGIForm from "../AddCGIForm";
import AddGSBandParticleEffectsForm from "../AddGSBandParticleEffectsForm";

const AddEffectsFootage = ({ setReloadElements }) => {
  return (
    <div className="d-flex flex-sm-row flex-column">
      <AddCGIForm setReloadElements={setReloadElements} />
      <AddGSBandParticleEffectsForm
        type={"effect"}
        message={"Add Practical Effects"}
        label={"Describe practical effect e.g. Blood squib"}
        setReloadElements={setReloadElements}
      />
    </div>
  );
};

export default AddEffectsFootage;
