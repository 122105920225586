import React from "react";
import { withRouter } from "react-router-dom";

const ClientMessage = ({ item, history }) => {
  return (
    <section className="job-list p-2 mt-4 d-flex justify-content-between align-items-center cursor-pointer">
      <div
        className="d-flex align-items-center mx-2"
        onClick={() => history.push(`/client/chat/${item.id}`)}
      >
        <img
          className="massage-img"
          src={item && item.user && item.user.proile_image_path}
          alt=""
        />
        <p className="common-text text-black mb-0 mx-lg-4 mx-2">
          {item && item.user && item.user.first_name
            ? item &&
              item.user &&
              item.user.first_name + " " + item.user.last_name
            : item && item.user && item.user.username}
        </p>
      </div>
      {item && item.total_unseen === 0 ? (
        ""
      ) : (
        <div className="number-bg mx-3">
          <p className="no-list mb-0 ">{item.total_unseen}</p>
        </div>
      )}
    </section>
  );
};

export default withRouter(ClientMessage);
