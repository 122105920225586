import React from "react";
import { withRouter } from "react-router-dom";
import { getDate } from "../../common/utils/Functions";

const OnGoingJob = ({ item, index, history }) => {
  return (
    <section className="job-list p-lg-3 p-2 mt-4" key={index}>
      <p className="common-text text-black f-w-600 mb-0 ">
        {item && item.title}
      </p>
      <p className="common-text xxs-font mb-0 mt-1">
        <span className="mr-10">
          <span>Creator: </span>
          <span className="mx-1">{item && item.user && item.user.name}</span>
        </span>
        <span className="mr-10">
          <span>Start Date:</span>
          <span className="mx-1">
            {new Date(item.updated_at).toLocaleDateString()}
          </span>
        </span>
        <span className="mr-10">
          <span>Deadline: </span>
          <span className="mx-1">
            {getDate(item.updated_at, item.duration)}
          </span>
        </span>
      </p>
      <p className="common-text text-blue f-w-600  mt-1 mb-0">$ {item.price}</p>
      <div className="mt-3">
        <button
          className="atech-primary-button px-3 py-2 my-sm-0 "
          onClick={() => history.push(`/client/${"on-going"}/jobs/${item.id}`)}
        >
          View progress
        </button>
      </div>
    </section>
  );
};

export default withRouter(OnGoingJob);
