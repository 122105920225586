function AccountType({ history, type }) {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="d-flex flex-sm-row flex-column  justify-content-sm-between align-items-center mt-3">
            <p className="common-heading-Medium xs-font mb-sm-0">
              {type === "creator" ? "Creators" : "Clients"}
            </p>

            <div className=" d-flex justify-content-sm-end flex-sm-row flex-column w-100">
              <button
                className={`px-sm-3 px-4 py-2  me-sm-3 ${
                  type === "creator"
                    ? "atech-primary-button"
                    : "atech-fade-button"
                }`}
              >
                <span
                  className=" d-inline-block"
                  onClick={() => history.push("/admin/accounts/creator")}
                >
                  Creators
                </span>
              </button>
              <button
                className={`px-sm-3 px-4 py-2  my-sm-0 my-3 ${
                  type === "client"
                    ? "atech-primary-button"
                    : "atech-fade-button"
                }`}
              >
                <span
                  className=" d-inline-block "
                  onClick={() => history.push("/admin/accounts/client")}
                >
                  Clients
                </span>
              </button>
            </div>
          </div>
          <span className="border-bottom d-inline-block pt-3 w-100"></span>
        </div>
      </div>
    </div>
  );
}
export default AccountType;
