import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import JobApplied from "../../../assets/img/png/jobapplied.png";
import { adminJobApproved } from "../../../redux/action/admin/AdminJobs";
import Loader from "../../common/Loader";

function ApprovedJobModel({ history, jobId }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);

  const handleApprovedJob = () => {
    const data = {
      status: "accepted",
      is_approved_by_admin: true,
    };
    dispatch(adminJobApproved(data, setLoading, setShow, history, jobId));
  };
  return (
    <>
      <button
        onClick={() => handleApprovedJob()}
        disabled={loading}
        type="submit"
        className="mt-sm-2 mt-1  atech-primary-button  py-sm-3 px-4 py-2 mb-3"
      >
        {loading ? <Loader /> : "Approve Job"}
      </button>

      <Modal
        className="job-applicants-successfully-modal  "
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header>
          <div className="d-flex mb-4 pt-4 align-items-center justify-content-between w-100">
            <span
              className="btn-close d-inline-block cursor-pointer"
              onClick={handleClose}
            ></span>
          </div>
        </Modal.Header>

        <Modal.Body className=" p-5 pt-0">
          <div className="job-application-img-width mx-auto">
            <img className="w-100" src={JobApplied} alt="" />
          </div>
          <p className="common-heading-Medium text-center mt-2 ">
            Job Approved Successfully
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ApprovedJobModel;
