import React, { useState } from "react";
import ClientATechHeader from "../../components/client/ClientATechHeader";
import ClientDashBoardContent from "../../components/client/dashboard/ClientDashboard";
import ClientDashboardGraphs from "../../components/client/DashboardGraphs/ClientDashboardGraphs";

const ClientDashboard = () => {
  const [dashBoardLoading, setDashBoardLoading] = useState(false);
  return (
    <div className="ff-Project page-color ">
      <ClientATechHeader HeaderName={"Dashboard"} />
      <div className="container-fluid mt-4 ">
        <ClientDashboardGraphs dashBoardLoading={dashBoardLoading} />
        <ClientDashBoardContent setDashBoardLoading={setDashBoardLoading} />
      </div>
    </div>
  );
};

export default ClientDashboard;
