import React, { useState } from "react";
import ATechLogo from "../components/common/ATechLogo";
import { withRouter } from "react-router";
import { useForm } from "react-hook-form";
import { ForgotPasswordApiAction } from "../redux/action/auth/AuthAction";
import { useDispatch } from "react-redux";
import Loader from "../components/common/Loader";
import ErrorMessage from "../components/common/ErrorMessage";
import SignupFormWrapper from "../components/hoc/SignupFormWrapper";

function ForgotPassword({ history }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data.email) {
      const Emailvalue = {
        host: window.location.origin,
        email: data.email,
      };
      dispatch(
        ForgotPasswordApiAction(Emailvalue, setLoading, history, setError)
      );
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SignupFormWrapper>
        <div className=" col-lg-6 col-sm-10 col-12">
          <div className="bg-white br-10 box-shadow p-sm-5 p-2">
            <ATechLogo />
            <p className="common-heading mb-1">Forgot Password</p>
            <p className="common-text pb-4">
              Please enter your registered email address
            </p>
            {error !== "" ? <ErrorMessage message={error} /> : ""}
            <input
              type="email"
              className="form-control py-3 atech-input"
              placeholder=" E-mail"
              {...register("email", { required: true })}
              aria-describedby="emailHelp"
            />
            {errors.email?.type === "required" && (
              <p className="common-text xxs-font text-red">Email is required</p>
            )}

            <div className="mt-3">
              <button
                type="submit"
                loading={loading}
                className={`${
                  loading ? "cursor-not-allowed" : ""
                } w-100 mt-sm-2 mt-1  atech-primary-button  py-sm-3 px-4 py-2`}
              >
                {loading ? <Loader /> : "Send Link"}
              </button>
              <p
                className=" cursor-pointer common-text  pt-4 text-center"
                onClick={() => history.push("/")}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      </SignupFormWrapper>
    </form>
  );
}

export default withRouter(ForgotPassword);
