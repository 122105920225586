import React from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import JobApplied from "../../../../assets/img/png/Social 04.png";

const JobCreatedSuccessfully = ({ show, setShow, history }) => {
  return (
    <Modal
      className="job-applicants-successfully-modal  "
      show={show}
      onHide={() => setShow(false)}
      centered
    >
      <Modal.Header>
        <div className="d-flex mb-4 pt-4 align-items-center justify-content-between w-100">
          <span
            className="btn-close d-inline-block cursor-pointer"
            onClick={() => setShow(false)}
          ></span>
        </div>
      </Modal.Header>

      <Modal.Body className=" p-5 pt-0">
        <div className="job-application-img-width mx-auto">
          <img className="w-100" src={JobApplied} alt="" />
        </div>
        <p className="common-heading-Medium text-center mt-2 ">
          Job Created Successfull
        </p>
        <p className="common-text text-center">
          Thankyou for creating jobsxsxsxs
        </p>
        <div className="mx-auto d-flex  justify-content-center pt-3">
          <button
            className="atech-primary-button px-sm-3  px-1 py-2 "
            onClick={() => history.push("/client/job-feed/approved")}
          >
            Return to Job Feed
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(JobCreatedSuccessfully);
