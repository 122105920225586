import Swal from "sweetalert2";
import { axiosRequest } from "../helper";

/**
 * Image upload api
 * @param {Object} data
 * @returns
 */
const uploadImageApi = async (data) => {
  return await axiosRequest("POST", `/file/upload/`, data);
};

/** Image Type */
export const UPLOAD_IAMGE = "UPLOAD_IAMGE";
export const REMOVE_FILE_URL_LINK = "REMOVE_FILE_URL_LINK";

const uploadImageSuccess = (file) => ({
  type: UPLOAD_IAMGE,
  file,
});

//Remove File Url Link
export const RemoveFileUrlLink = () => ({
  type: REMOVE_FILE_URL_LINK,
});

/**
 * Upload Image Action
 * @param {Event} e
 * @param {Boolean} setLoadingImage
 * @param {String} type
 * @returns
 */
export const uploadImage = (e, setLoadingImage, type) => async (dispatch) => {
  try {
    if (e.target.files[0]) {
      setLoadingImage(true);
      const file = e.target.files[0];
      const fileSize = file.size / 2024 / 2024;

      if (fileSize > 50) {
        setLoadingImage(false);
        Swal.fire("Opps!", "File must be less than 50 MB", "error");
        setTimeout(Swal.close, 2000);
        return;
      }

      // Get File Extention
      if (type === "image") {
        //If file is image
        if (file.type.includes("image")) {
          // FORM DATA
          const form = new FormData();
          form.append("file", file);
          const response = await uploadImageApi(form);
          if (response.success) {
            setLoadingImage(false);
            dispatch(uploadImageSuccess(response.file.url));
          } else {
            setLoadingImage(false);
            Swal.fire(
              "Failed to upload Image!",
              "Please check your network connection",
              "error"
            );
            setTimeout(Swal.close, 2000);
            setLoadingImage(false);
          }
        } else {
          Swal.fire(
            "Failed to upload Your File!",
            "Please Upload any Image",
            "error"
          );
          setTimeout(Swal.close, 2000);
        }
      } else if (type === "video") {
        //If file is video
        if (file.type.includes("video")) {
          const form = new FormData();
          form.append("file", file);
          const response = await uploadImageApi(form);
          if (response.success) {
            setLoadingImage(false);
            dispatch(uploadImageSuccess(response.file.url));
          } else {
            setLoadingImage(false);
            Swal.fire(
              "Failed to upload Video!",
              "Please check your network connection",
              "error"
            );
            setTimeout(Swal.close, 2000);
          }
        } else {
          setLoadingImage(false);
          Swal.fire(
            "Failed to upload Your File!",
            "Please Upload any video",
            "error"
          );
          setTimeout(Swal.close, 2000);
        }
      } else {
        setLoadingImage(false);
      }
    }
  } catch (error) {
    setLoadingImage(false);
  }
};

export const sendMessageFile = (e, setLoadingImage) => async (dispatch) => {
  try {
    if (e.target.files[0]) {
      setLoadingImage(true);
      const file = e.target.files[0];
      const fileSize = file.size / 2024 / 2024;

      if (fileSize > 50) {
        setLoadingImage(false);
        Swal.fire("Opps!", "File must be less than 50 MB", "error");
        setTimeout(Swal.close, 2000);
        return;
      }
      const form = new FormData();
      form.append("file", file);
      const response = await uploadImageApi(form);
      if (response.success) {
        setLoadingImage(false);
        dispatch(uploadImageSuccess(response.file.url));
      } else {
        setLoadingImage(false);
        Swal.fire(
          "Failed to upload Files!",
          "Please check your network connection",
          "error"
        );
        setTimeout(Swal.close, 2000);
        setLoadingImage(false);
      }
    }
  } catch (error) {
    setLoadingImage(false);
  }
};
