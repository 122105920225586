import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { adminUserProfileDetails } from "../../../redux/action/admin/AdminAcountsAction";
import AdminATechHeader from "../../../components/common/AdminATechHeader";
import AdminUserProfileBoxDetails from "../../../components/admin/accounts/profile/AdminUserProfileBoxDetails";
import BubblesLoader from "../../../components/common/BubblesLoader";

function AdminClientProfile({ match, history }) {
  const adminUserDetails = useSelector(
    (state) => state.AdminAcountsReducer.adminUserDetails
  );
  const dispatch = useDispatch();
  const { userId } = match.params;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userId) {
      dispatch(adminUserProfileDetails(userId, setLoading));
    }
  }, [dispatch, userId]);

  return (
    <>
      <AdminATechHeader HeaderName={"Profile"} />
      <div className="mx-sm-4 mx-1 bg-white p-md-4 p-2 br-10 mt-3 ">
        <div className="container-fluid my-4  profile-scroll">
          {loading ? (
            <div className="h-calc-100vh-170 d-flex justify-content-center align-items-center flex-column">
              <BubblesLoader />
              <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                Please wait...
              </p>
            </div>
          ) : (
            <AdminUserProfileBoxDetails
              adminUserDetails={adminUserDetails}
              type="Client"
              userId={userId}
              history={history}
            />
          )}
        </div>
      </div>
    </>
  );
}
export default withRouter(AdminClientProfile);
