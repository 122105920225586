import React from "react";
import BubblesLoader from "../../common/BubblesLoader";
import ClientMessage from "./ClientMessage";
import NoMessages from "../../nullStates/NoMessages";

const ClientMessageList = ({ chatList, loading }) => {
  return (
    <div className=" mt-lg-0 mt-4 myjob  admin-message-height h-100">
      <section className="p-4 h-100">
        <p className="common-text">{chatList.length > 0 ? "Messages" : ""}</p>
        {loading ? (
          <div className="h-100 d-flex justify-content-center align-items-center flex-column">
            <BubblesLoader />
            <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
              Please wait...
            </p>
          </div>
        ) : (
          <>
            {chatList && chatList.length > 0 ? (
              chatList.map((item, index) => {
                return <ClientMessage item={item} key={index} />;
              })
            ) : (
              <>
                <NoMessages message={` You don't have any chat list yet!`} />
              </>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default ClientMessageList;
