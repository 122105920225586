import React from "react";
import { withRouter } from "react-router-dom";
import ATechHeader from "../../../components/common/ATechHeader";
import CreatorJobFeedType from "../../../components/creator/jobFeed/CreatorJobFeedType";
import RecentJob from "../../../components/creator/jobFeed/RecentJob";
import MyJob from "../../../components/creator/jobFeed/MyJob";
import CompletedJob from "../../../components/creator/jobFeed/CompletedJob";

const CreatorJobFeed = ({ match }) => {
  const { type } = match.params;
  return (
    <>
      <ATechHeader HeaderName={"Job Feed"} />
      <div className="d-flex flex-xl-row flex-column mx-2 px-1 mt-4">
        <CreatorJobFeedType type={type} />
        {type === "recent" ? (
          <RecentJob />
        ) : type === "completed-jobs" ? (
          <CompletedJob />
        ) : (
          <MyJob />
        )}
      </div>
    </>
  );
};

export default withRouter(CreatorJobFeed);
