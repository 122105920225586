import {
  adminJobApprovedApi,
  adminRejectJobApi,
  getAdminAllJobListApi,
  getAdminJobDetailsApi,
  searchJobApi,
} from "../../api/admin/AdminJob";

export const ADMIN_ALL_JOBS_LIST = "ADMIN_ALL_JOBS_LIST";
export const SEARCH_JOBS_LIST = "SEARCH_JOBS_LIST";
export const ADMIN_JOB_DETAILS = "ADMIN_JOB_DETAILS";

/**
 * Get Admin all job list action
 * @param {Array} data
 * @returns
 */
export const getAdminAllJobListAction = (data) => ({
  type: ADMIN_ALL_JOBS_LIST,
  data,
});

export const getAdminAllJobList =
  (setLoading, type, page, shortedValue, setHasMore) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await getAdminAllJobListApi(type, page, shortedValue);
      if (response.success) {
        dispatch(getAdminAllJobListAction({ data: response.data, page: page }));
        setLoading(false);
        if (response.data.length < 10) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

/**
 * Get Admin job details action
 * @param {Object} data
 * @returns
 */
export const getAdminJobDetailsAction = (data) => ({
  type: ADMIN_JOB_DETAILS,
  data,
});

export const getAdminJobDetails = (jobId, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await getAdminJobDetailsApi(jobId);
    if (response.success) {
      dispatch(getAdminJobDetailsAction(response.data));
      setLoading(false);
    } else {
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
  }
};

/**
 * Get search job list action
 * @param {Array} data
 * @returns
 */
export const searchJobAction = (data) => ({
  type: SEARCH_JOBS_LIST,
  data,
});

export const getSearchJobByQuery =
  (setLoading, type, query) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await searchJobApi(type, query);
      if (response.success) {
        dispatch(searchJobAction(response.data));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

/**
 *
 * @param {Booleen} setLoading
 * @param {Booleen} setShow
 * @returns
 */
export const adminJobApproved =
  (data, setLoading, setShow, history, jobId) => async () => {
    setLoading(true);
    try {
      const response = await adminJobApprovedApi(jobId, data);
      if (response.success) {
        setLoading(false);
        setShow(true);
        setTimeout(() => {
          history.push("/admin/jobs/pending-jobs");
        }, 2000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

/**
 *
 * @param {Booleen} setLoading
 * @param {Booleen} setShow
 * @returns
 */
export const adminRejectJob =
  (data, setLoading, setShow, history, jobId) => async () => {
    setLoading(true);
    try {
      const response = await adminRejectJobApi(jobId, data);
      if (response.success) {
        setLoading(false);
        setShow(true);
        setTimeout(() => {
          history.push("/admin/jobs/pending-jobs");
        }, 2000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
