import { Modal } from "react-bootstrap";
import SignUpWorkForm from "./SignUpWorkForm";

const PastEditPopup = ({
  handleClose,
  show,
  openEditFormData,
  pastWorkArray,
  index,
}) => {
  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="p">
          <p>Edit Form</p>
          <SignUpWorkForm
            handleClose={handleClose}
            openEditFormData={openEditFormData}
            pastWorkArray={pastWorkArray}
            index={index}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PastEditPopup;
