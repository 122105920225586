import React from "react";
import ATechHeader from "../components/common/ATechHeader";
import NotificationTable from "../components/common/notification/NotificationTable";

function Notification() {
  return (
    <>
      <ATechHeader HeaderName={"Notification"} />
      <div className="ff-Project page-color ">
        <NotificationTable />
      </div>
    </>
  );
}
export default Notification;
