import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "../../../assets/img/svg/searchicon.svg";
import {
  getAdminAllJobList,
  getSearchJobByQuery,
} from "../../../redux/action/admin/AdminJobs";
import BubblesLoader from "../../common/BubblesLoader";
import EmptyCard from "../../common/EmptyCard";
import AdminJobListItem from "./AdminJobListItem";

function AdminCompletdJobs({ types }) {
  const dispatch = useDispatch();
  const adminAllJobList = useSelector((state) => state.jobs.adminAllJobList);
  const searchAdminAllJobList = useSelector(
    (state) => state.jobs.searchAdminAllJobList
  );
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const pageChangeHandler = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    const shortedValue = 0;
    dispatch(
      getAdminAllJobList(setLoading, types, page, shortedValue, setHasMore)
    );
  }, [dispatch, types, page]);

  const handleSearchJob = (value) => {
    setSearchValue(value);
    if (searchValue) {
      dispatch(getSearchJobByQuery(setLoading, types, searchValue));
    }
  };
  const handleShorting = (shortedValue) => {
    dispatch(
      getAdminAllJobList(setLoading, types, page, shortedValue, setHasMore)
    );
  };

  const jobListArrayLength =
    searchValue === "" ? adminAllJobList.length : searchAdminAllJobList.length;

  const jobListArray =
    page === 0 && loading ? (
      <div className="h-100 d-flex justify-content-center align-items-center flex-column w-100">
        <BubblesLoader />
        <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
          Please wait...
        </p>
      </div>
    ) : searchValue === "" ? (
      adminAllJobList && !!adminAllJobList.length ? (
        adminAllJobList.map((item, index) => {
          return <AdminJobListItem item={item} index={index} />;
        })
      ) : (
        <div className="d-flex w-100 justify-content-center">
          <EmptyCard message={"You’ve not any Pending Jobs List Yet!"} />
        </div>
      )
    ) : searchAdminAllJobList && !!searchAdminAllJobList.length ? (
      searchAdminAllJobList.map((item, index) => {
        return <AdminJobListItem item={item} index={index} />;
      })
    ) : (
      <div className="d-flex w-100 justify-content-center h-100">
        <EmptyCard
          message={`You’ve not any Jobs List found from ${searchValue}!`}
        />
      </div>
    );

  return (
    <div className="container-fluid ">
      <div className="row  align-items-center justify-content-between my-sm-4 my-2">
        <div className="col-xxl-4 col-sm-7 col-12">
          <div className="search-field position-relative">
            <input
              type="email"
              className="form-control "
              placeholder="Search for job..."
              aria-describedby="emailHelp"
              onChange={(e) => handleSearchJob(e.target.value)}
              name="query"
            />
            <img className="search-icon" src={SearchIcon} alt="SearchIcon" />
          </div>
        </div>
        <div className="col-xxl-2 col-sm-4 col-12">
          <div className="my-2 select-field">
            <select
              className="form-select py-2 cursor-pointer px-3"
              onChange={(e) => handleShorting(e.target.value)}
            >
              <option>Sorting</option>
              <option value="0">Recently Added</option>
              <option value="1">Recently Updated</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mt-4 card-scroll admin_job" id="pending-jobs-list">
        <div className="infinite_scroll h-100 row">
          <InfiniteScroll
            className={`${
              jobListArrayLength.length === 0 || loading || searchValue !== ""
                ? "h-100"
                : ""
            } scrollbar_desgin`}
            dataLength={jobListArrayLength}
            next={pageChangeHandler}
            hasMore={hasMore}
            scrollableTarget="pending-jobs-list"
            loader={
              page > 0 && loading ? (
                <div className="h-100 d-flex justify-content-center align-items-center flex-column w-100">
                  <BubblesLoader />
                </div>
              ) : (
                ""
              )
            }
          >
            {jobListArray}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}

export default AdminCompletdJobs;
