import Swal from "sweetalert2";
import {
  AddActorJobDetailsApi,
  AddReporterJobDetailsApi,
  AddExtrasJobDetailsApi,
  AddVehicleJobDetailsApi,
  AddCommanDetailsForJobApi,
  AddTreePlantsJobDetailsApi,
  AddBuildingJobDetailsApi,
  AddGreenScreenDetailsApi,
  AddCGIJobDetailsApi,
  AddGSBandParticleEffectsApi,
  paymentToPlatFormByClientApi,
  connectStripeAccountApi,
  verifyStripeAccountConnectionApi,
  ReleaseFundsApi,
  getReleaseFundsListApi,
  ReleasePaymentByAdminApi,
  paymentToCreatorApi,
  updatePaymentStatusApi,
} from "../../api/client/Job";

export const ADD_JOB_ELEMENT = "ADD_JOB_ELEMENT";
export const GET_RELEASE_FUNDS_LIST = "GET_RELEASE_FUNDS_LIST";

const getAllJobsDetailsListAction = (data) => ({
  type: ADD_JOB_ELEMENT,
  data,
});

/**
 * Add actor job details action (POST)
 * to store (redux) and redirect to next form
 * @param {Object} data
 * @returns
 */
export const AddActorJobDetails =
  (data, setLoading, setError, handleClose, setReloadElements) =>
  async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await AddActorJobDetailsApi(data);
      if (response.success) {
        dispatch(
          getAllJobsDetailsListAction({ data: response.data, type: "actor" })
        );
        setReloadElements(true);
        setLoading(false);
        handleClose();
      } else {
        setLoading(false);
        setError("Oops! Failed to add Actor job Details!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Add reporter job details action (POST)
 * to store (redux) and redirect to next form
 * @param {Object} data
 * @returns
 */
export const AddReporterJobDetails =
  (data, setLoading, setError, handleClose, setReloadElements) =>
  async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await AddReporterJobDetailsApi(data);
      if (response.success) {
        dispatch(
          getAllJobsDetailsListAction({
            data: response.data,
            type: "reporters",
          })
        );
        setLoading(false);
        setReloadElements(true);
        handleClose();
      } else {
        setLoading(false);
        setError("Oops! Failed to add Reporter job Details!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Add extras job details action (POST)
 * to store (redux) and redirect to next form
 * @param {Object} data
 * @returns
 */
export const AddExtrasJobDetails =
  (data, setLoading, setError, handleClose, setReloadElements) =>
  async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await AddExtrasJobDetailsApi(data);
      if (response.success) {
        dispatch(
          getAllJobsDetailsListAction({ data: response.data, type: "actor" })
        );
        setLoading(false);
        setReloadElements(true);
        handleClose();
      } else {
        setLoading(false);
        setError("Oops! Failed to add Extra job Details!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Add common job details action (POST)
 * to store (redux) and redirect to next form
 * @param {Object} data
 * @returns
 */
export const AddCommanDetailsForJob =
  (data, setLoading, setError, handleClose, setReloadElements) =>
  async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await AddCommanDetailsForJobApi(data);
      if (response.success) {
        dispatch(
          getAllJobsDetailsListAction({ data: response.data, type: "property" })
        );
        setLoading(false);
        setReloadElements(true);
        handleClose();
      } else {
        setLoading(false);
        setError("Oops! Failed to add job Details!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Add reporter job details action (POST)
 * to store (redux) and redirect to next form
 * @param {Object} data
 * @returns
 */
export const AddVehicleJobDetails =
  (data, setLoading, setError, handleClose, setReloadElements) =>
  async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await AddVehicleJobDetailsApi(data);
      if (response.success) {
        dispatch(
          getAllJobsDetailsListAction({ data: response.data, type: "vehicle" })
        );
        setLoading(false);
        setReloadElements(true);
        handleClose();
      } else {
        setLoading(false);
        setError("Oops! Failed to add Vehicle job Details!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Add reporter job details action (POST)
 * to store (redux) and redirect to next form
 * @param {Object} data
 * @returns
 */
export const AddTreePlantsJobDetails =
  (data, setLoading, setError, handleClose) => async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await AddTreePlantsJobDetailsApi(data);
      if (response.success) {
        dispatch(
          getAllJobsDetailsListAction({ data: response.data, type: "trees" })
        );
        setLoading(false);
        handleClose();
      } else {
        setLoading(false);
        setError("Oops! Failed to add Tree/Plant job Details!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Add Buildings Job Details action (POST)
 * to store (redux) and redirect to next form
 * @param {Object} data
 * @returns
 */
export const AddBuildingsJobDetails =
  (data, setLoading, setError, handleClose, setReloadElements) =>
  async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await AddBuildingJobDetailsApi(data);
      if (response.success) {
        dispatch(
          getAllJobsDetailsListAction({
            data: response.data,
            type: "buildings",
          })
        );
        setLoading(false);
        setReloadElements(true);
        handleClose();
      } else {
        setLoading(false);
        setError("Oops! Failed to add Building job Details!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Add Buildings Job Details action (POST)
 * to store (redux) and redirect to next form
 * @param {Object} data
 * @returns
 */
export const AddGreenScreenDetails =
  (data, setLoading, setError, handleClose) => async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await AddGreenScreenDetailsApi(data);
      if (response.success) {
        dispatch(
          getAllJobsDetailsListAction({
            data: response.data,
            type: "green_screen_background",
          })
        );
        setLoading(false);
        handleClose();
      } else {
        setLoading(false);
        setError("Oops! Failed to add Green Screen job Details!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Add CGI Job Details action (POST)
 * to store (redux) and redirect to next form
 * @param {Object} data
 * @returns
 */
export const AddCGIJobDetails =
  (data, setLoading, setError, handleClose, setReloadElements) =>
  async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await AddCGIJobDetailsApi(data);
      if (response.success) {
        dispatch(
          getAllJobsDetailsListAction({ data: response.data, type: "cgi" })
        );
        setLoading(false);
        setReloadElements(true);
        handleClose();
      } else {
        setLoading(false);
        setError("Oops! Failed to add CGI job Details!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * Add Practical Effects Job Details action (POST)
 * to store (redux) and redirect to next form
 * @param {Object} data
 * @returns
 */
export const AddGSBandParticleEffectsDetails =
  (data, setLoading, setError, handleClose, setReloadElements) =>
  async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await AddGSBandParticleEffectsApi(data);
      if (response.success) {
        dispatch(
          getAllJobsDetailsListAction({ data: response.data, type: "effects" })
        );
        setLoading(false);
        handleClose();
        setReloadElements(true);
      } else {
        setLoading(false);
        setError("Oops! Failed to add Practical Effects job Details!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * payment for job and redirect to next form
 * @param {Object} data
 * @returns
 */
export const paymentToPlatFormByClient =
  (data, setLoadingPayment, setError, setAcceptOffer) => async () => {
    setLoadingPayment(true);
    setError("");
    try {
      const response = await paymentToPlatFormByClientApi(data);
      if (response.success) {
        setAcceptOffer(true);
      } else {
        setLoadingPayment(false);
        setError("Oops! Failed to add Payment!");
      }
    } catch (error) {
      setLoadingPayment(false);
      setError("Oops! Something went wrong");
    }
  };

/**
 * join stripe account and redirect to next link (payment)
 * @param {Object} data
 * @returns
 */
export const connectStripeAccount = (setLoading, setError) => async () => {
  setLoading(true);
  setError("");
  try {
    const response = await connectStripeAccountApi();
    if (response.success) {
      window.location.href = response.data.connect_account_link;
    } else {
      setLoading(false);
      setError("Oops! Failed to Connect Stripe Account!");
    }
  } catch (error) {
    setLoading(false);
    setError("Oops! Failed to Connect Stripe Account!");
  }
};

/**
 * verify stripe account and redirect to next link (payment)
 * @param {Object} data
 * @returns
 */
export const verifyStripeAccountConnection =
  (code, setLoading, setError, history, setIsStripeConnected) => async () => {
    setLoading(true);
    setError("");
    try {
      const response = await verifyStripeAccountConnectionApi(code);
      if (response.success) {
        setLoading(false);
        setIsStripeConnected(true);
        history.push("/creator/profile/edit");
      } else {
        setLoading(false);
        setError("Oops! Failed to Connect Stripe Account!");
        history.push("/creator/profile/edit");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Failed to Connect Stripe Account!");
      history.push("/creator/profile/edit");
    }
  };

/**
 * Release Fund action (POST)
 * @param {Object} data
 * @returns
 */
export const ReleaseFundsForUser = (data, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await ReleaseFundsApi(data);
    if (response.success) {
      setLoading(false);
      Swal.fire(
        "Success",
        "Payment release successfully! Please wait for admin response",
        "success"
      );
      setTimeout(Swal.close, 4000);
    } else {
      setLoading(false);
      Swal.fire(
        "Ooops",
        response &&
          response.response &&
          response.response.data &&
          response.response.data.message
          ? response.response.data.message
          : "Failed to Connect Stripe Account!",
        "error"
      );
      setTimeout(Swal.close, 2000);
    }
  } catch (error) {
    setLoading(false);
    Swal.fire("Ooops", "Failed to Connect Stripe Account!", "error");
    setTimeout(Swal.close, 2000);
  }
};

/**
 * Release Fund action (POST)
 * @param {Object} data
 * @returns
 */
export const ReleasePaymentByAdmin = (itemId, data, setLoading) => async () => {
  setLoading(true);

  try {
    const response = await ReleasePaymentByAdminApi(itemId, data);
    if (response.success) {
      setLoading(false);
      Swal.fire(
        "Success",
        data.status === "hold"
          ? "User has been released from hold successfully"
          : "Fund has been released successfully",
        "success"
      );
      setTimeout(Swal.close, 2000);
    } else {
      setLoading(false);
      Swal.fire("Oops!", " Failed to Release Fund To User!", "error");
      setTimeout(Swal.close, 2000);
    }
  } catch (error) {
    setLoading(false);

    Swal.fire("Oops!", " Failed to Release Fund To User!", "error");
    setTimeout(Swal.close, 2000);
  }
};
/**
 * Release Fund action (POST)
 * @param {Object} data
 * @returns
 */
export const paymentToCreator = (data, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await paymentToCreatorApi(data);
    if (response.success) {
      window.location.href = response.data.url;
      Swal.fire(
        "success",
        "Payment sent successfully! Please wait...",
        "Success"
      );
      setTimeout(Swal.close, 4000);
    } else {
      setLoading(false);
      Swal.fire("Oops!", " Failed to sent Payment To User!", "error");
      setTimeout(Swal.close, 2000);
    }
  } catch (error) {
    setLoading(false);
    Swal.fire("Oops!", " Failed to sent Payment To User!", "error");
    setTimeout(Swal.close, 2000);
  }
};

/**
 * Release Fund action (POST)
 * @param {Object} data
 * @returns
 */
export const getReleaseFundsListAction = (data) => ({
  type: GET_RELEASE_FUNDS_LIST,
  data,
});

export const getReleaseFundsList =
  (setLoading, setError, setHasMore, page) => async (dispatch) => {
    setLoading(true);
    setError("");
    try {
      const response = await getReleaseFundsListApi(page);
      if (response.success) {
        setLoading(false);
        dispatch(
          getReleaseFundsListAction({ data: response.data, page: page })
        );
        if (response.data.length < 0) {
          setHasMore(false);
        }
      } else {
        setLoading(false);
        setError("Oops! Failed to Get Release Fund List!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Failed to Get Release Fund List!");
    }
  };

export const updatePaymentStatus =
  (data, payoutId, setLoading, setError) => async () => {
    setLoading(true);
    setError("");
    try {
      const response = await updatePaymentStatusApi(data, payoutId);
      if (response.success) {
        setLoading(false);
        window.location.href = "/payout";
      } else {
        setLoading(false);
        setError("Oops! Failed To update Payment Status!");
      }
    } catch (error) {
      setLoading(false);
      setError("Oops! Failed To update Payment Status! ");
    }
  };
