import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ATechHeader from "../../../components/common/ATechHeader";
import ErrorMessage from "../../../components/common/ErrorMessage";
import {
  creatorEarningDetailsAction,
  getTransationHistoryListAction,
} from "../../../redux/action/creator/CreatorJob";
import EarningCards from "./EarningCards";
import EarningModal from "./EarningModal";
import WithDrawEarningTable from "./WithDrawEarningTable";

const Earning = () => {
  const dispatch = useDispatch();
  const creatorEarning = useSelector((state) => state.jobs.creatorEarning);
  const [show, setShow] = useState(false);
  const [EarningLoading, setEarningLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    dispatch(creatorEarningDetailsAction(setLoading, setError));
  }, [dispatch]);

  const pageChangeHandler = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    dispatch(
      getTransationHistoryListAction(
        setEarningLoading,
        page,
        setHasMore,
        setError
      )
    );
  }, [dispatch, page]);

  return (
    <div>
      <ATechHeader HeaderName={"Earnings"} />
      {error !== "" ? <ErrorMessage message={error} /> : ""}
      <EarningCards loading={loading} creatorEarning={creatorEarning} />
      <WithDrawEarningTable
        setShow={setShow}
        pageChangeHandler={pageChangeHandler}
        page={page}
        EarningLoading={EarningLoading}
        hasMore={hasMore}
        creatorEarning={creatorEarning}
      />
      <EarningModal
        setShow={setShow}
        show={show}
        creatorEarning={creatorEarning}
      />
    </div>
  );
};

export default Earning;
