import React, { useState } from "react";
import Deleteicon from "../../../assets/img/svg/delete-icon.svg";
import PastEditPopup from "./PastEditPopup";
import uploadImg from "../../../assets/img/svg/upload-img.svg";
import { EditIcon } from "../../common/icons/Icon";

function AddPastWork({ pastWorkArray }) {
  const [openEditForm, setOpenEditForm] = useState(false);
  const [IndexValue, setIndex] = useState(0);
  const [openEditFormData, setOpenEditFormData] = useState({});
  const handleClose = () => setOpenEditForm(false);
  const [newArray, setNewArray] = useState([pastWorkArray]);

  const deleteAddPastWork = (index) => {
    pastWorkArray.splice(index, 1);
    setNewArray(pastWorkArray);
  };

  const EditPastWork = (index, e) => {
    e.preventDefault();
    setOpenEditForm(true);
    setIndex(index);
    setOpenEditFormData(pastWorkArray[index]);
  };

  return (
    <form>
      {pastWorkArray && !!pastWorkArray.length
        ? pastWorkArray.map((value, index) => {
            return (
              <div className="atech-border br-10 my-3">
                <div className="container ps-lg-0 pt-lg-0 pt-4">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="bg-F8F8F8 py-lg-0 py-5 h-100 br-10">
                        <div className="d-flex  justify-content-center align-items-center h-100">
                          {" "}
                          <input
                            type="file"
                            id="my-file"
                            hidden
                            disabled
                            accept="video/mp4"
                          />
                          <button
                            type="button"
                            className="py-2 px-3 atech-fade-button "
                          >
                            <label
                              for="my-file2"
                              className="cursor-pointer text-wrap-css "
                            >
                              <span className="me-2 d-inline-block">
                                <img
                                  className=" px-2 top-22"
                                  src={uploadImg}
                                  alt="uploadImg"
                                />
                              </span>
                              {value.media_link}
                            </label>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 my-5">
                      <p className="common-heading-Medium xs-font pe-xl-5 me-xl-5 text-sm-start text-center">
                        {value.title}
                      </p>
                      <p className="common-text pe-xl-5 me-xl-5 text-sm-start text-center">
                        {value.description}
                      </p>
                      <div className="d-flex flex-sm-row  flex-column mt-3">
                        <button
                          className="px-4 atech-primary-button  py-sm-2 py-2 ms-sm-2  my-2  "
                          onClick={(e) => EditPastWork(index, e)}
                        >
                          <span className="edit-icon pe-2">
                            <EditIcon />
                          </span>
                          Edit
                        </button>

                        <button
                          type="button"
                          onClick={() => deleteAddPastWork(index)}
                          className="px-4 atech-fade-button py-sm-2 py-2 ms-sm-2  my-2 "
                        >
                          <span>
                            <img
                              className="pe-2"
                              src={Deleteicon}
                              alt="Deleteicon"
                            />
                          </span>
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
      {openEditForm ? (
        <PastEditPopup
          handleClose={handleClose}
          show={openEditForm}
          openEditFormData={openEditFormData}
          pastWorkArray={pastWorkArray}
          index={IndexValue}
          newArray={newArray}
        />
      ) : null}
    </form>
  );
}
export default AddPastWork;
