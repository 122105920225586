import { withRouter } from "react-router-dom";

const CreatorJobFeedType = ({ history, type }) => {
  const changeHandler = (type) => {
    history.push(`/creator/job-feed/${type}`);
  };

  return (
    <div className="d-flex flex-row flex-xl-column justify-content-xl-start  job-feed-type-section ms-xl-2">
      <div className="form-check py-2 radio-button me-4">
        <input
          className="form-check-input cursor-pointer"
          type="radio"
          name="flexRadioDefault"
          onChange={() => changeHandler("recent")}
          checked={type === "recent" ? true : false}
          id="flexRadioDefault1"
        />
        <label
          className="form-check-label common-text"
          htmlFor="flexRadioDefault1"
        >
          Recent
        </label>
      </div>

      <div className="form-check py-2">
        <input
          className="form-check-input cursor-pointer"
          type="radio"
          name="flexRadioDefault"
          onChange={() => changeHandler("my-jobs")}
          checked={type === "my-jobs" ? true : false}
          id="flexRadioDefault4"
        />
        <label
          className="form-check-label common-text"
          htmlFor="flexRadioDefault4"
        >
          My Jobs
        </label>
      </div>
      <div className="form-check py-2">
        <input
          className="form-check-input cursor-pointer"
          type="radio"
          name="flexRadioDefault"
          onChange={() => changeHandler("completed-jobs")}
          checked={type === "completed-jobs" ? true : false}
          id="flexRadioDefault5"
        />
        <label
          className="form-check-label common-text"
          htmlFor="flexRadioDefault5"
        >
          Completed Jobs
        </label>
      </div>
    </div>
  );
};

export default withRouter(CreatorJobFeedType);
