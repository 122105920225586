import LoginForm from "../components/auth/LoginForm";
import SignupFormWrapper from "../components/hoc/SignupFormWrapper";

const Login = () => {
  return (
    <SignupFormWrapper>
      <div className=" col-lg-6 col-sm-10 col-12">
        <LoginForm />
      </div>
    </SignupFormWrapper>
  );
};

export default Login;
