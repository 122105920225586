import DashboardGraphs from "./DashboardGraphs/DashboardGraphs";
import DashboardMyJobList from "./DashboardMyJobList";
import DashboardMessageList from "./DashboardMessageList";

const DashboardContainer = () => {
  return (
    <div className=" py-4">
      <div className="container-fluid">
        <DashboardGraphs />
        <div className="flex-grow-1  creator_height overflow_hidden">
          <div className="row mt-4 h-100">
            <DashboardMyJobList />
            <DashboardMessageList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardContainer;
