import DisputeChatInput from "./DisputeChatInput";
import ClientMsgDispute from "./ClientMsgDispute";
import MessageByMe from "./MessageByMe";
import DisputeHeader from "./DisputeHeader";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMessages from "../../nullStates/NoMessages";
import BubblesLoader from "../../common/BubblesLoader";
import JoinChat from "../../../redux/hook/socket/JoinChat";
import { withRouter } from "react-router";

const DisputesChatMessage = ({
  setClickOnChat,
  setShowChat,
  disputeMessageList,
  startDisputesUserDetailsList,
  hasMessageMore,
  pageMessageChangeHandler,
  history,
}) => {
  const timelineRef = useRef();
  const userDetails = useSelector((state) => state.AuthReducer.userDetails);
  const [showHide, setShowHide] = useState(false);
  const { setWatingMessageList, watingMessageArray } = JoinChat();
  return (
    <>
      <div className="d-flex justify-content-between flex-column h-100">
        <div>
          <DisputeHeader
            startDisputesUserDetailsList={startDisputesUserDetailsList}
            setClickOnChat={setClickOnChat}
            setShowChat={setShowChat}
          />
          <div className="d-flex  flex-column  pb-3 ">
            <div className="d-flex justify-content-between pb-2 align-items-center">
              <p className="common-text ps-3 mb-0 ">Details of dispute</p>
              <button
                className="px-sm-3 px-2 py-2  atech-fade-button"
                onClick={() => setShowHide(!showHide)}
              >
                <span className=" d-inline-block fs-Montserrat ">
                  {showHide ? "Show details" : "Hide details"}
                </span>
              </button>
            </div>
            <>
              {showHide ? (
                ""
              ) : (
                <>
                  <p className="common-text ps-3 mb-0 ">
                    {startDisputesUserDetailsList &&
                      startDisputesUserDetailsList.message}
                  </p>

                  <p
                    className="common-text ps-3  pt-2 giveMeEllipsis cursor-pointer"
                    onClick={() =>
                      history.push(
                        `/admin/${
                          startDisputesUserDetailsList.user.id ===
                          startDisputesUserDetailsList.seller.id
                            ? "client"
                            : "creator"
                        }/profile/${startDisputesUserDetailsList.user.id}`
                      )
                    }
                  >
                    {`Created by: ${
                      startDisputesUserDetailsList &&
                      startDisputesUserDetailsList.user &&
                      startDisputesUserDetailsList.user.first_name
                        ? startDisputesUserDetailsList &&
                          startDisputesUserDetailsList.user &&
                          startDisputesUserDetailsList.user.first_name +
                            " " +
                            startDisputesUserDetailsList.user.last_name
                        : startDisputesUserDetailsList &&
                          startDisputesUserDetailsList.user &&
                          startDisputesUserDetailsList.user.username
                    }`}
                  </p>
                </>
              )}
            </>
          </div>
          <div className="border-bottom-e8e8e8"></div>
        </div>
        {/* <!-- CHAT BOX  --> */}
        {disputeMessageList && disputeMessageList.length > 0 ? (
          <div
            className={` ${
              showHide ? "dispute_height_420" : "dispute_height"
            } my-xxl-3 my-2 mt-xxl-4 d-flex flex-column-reverse pe-xxl-5   overflow-auto  pe-3 chat-box`}
            id="chat-message-scroll-div"
            style={{
              flexDirection: "column-reverse",
            }}
            ref={timelineRef}
          >
            <InfiniteScroll
              dataLength={disputeMessageList.length}
              next={pageMessageChangeHandler}
              style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
              inverse={true}
              hasMore={hasMessageMore}
              loader={
                <div className="d-flex justify-content-center ">
                  <BubblesLoader />
                </div>
              }
              scrollableTarget="chat-message-scroll-div"
            >
              {disputeMessageList &&
                disputeMessageList.length > 0 &&
                disputeMessageList.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className=" d-flex flex-column-reverse   overflow-auto  chat-box"
                    >
                      {userDetails && userDetails.user === value.sender.id ? (
                        <MessageByMe value={value} index={index} />
                      ) : (
                        <ClientMsgDispute
                          value={value}
                          index={index}
                          type={value.role}
                        />
                      )}
                    </div>
                  );
                })}
            </InfiniteScroll>
          </div>
        ) : (
          <NoMessages
            message={`Here you can send messages and share files for solved disputes @ ${
              startDisputesUserDetailsList &&
              startDisputesUserDetailsList.user &&
              startDisputesUserDetailsList.user.first_name
                ? startDisputesUserDetailsList &&
                  startDisputesUserDetailsList.user &&
                  startDisputesUserDetailsList.user.first_name +
                    " " +
                    startDisputesUserDetailsList.user.last_name
                : startDisputesUserDetailsList &&
                  startDisputesUserDetailsList.user &&
                  startDisputesUserDetailsList.user.username
            }.`}
          />
        )}
        <DisputeChatInput
          userDetails={userDetails}
          startDisputesUserDetails={startDisputesUserDetailsList}
          setWatingMessageList={setWatingMessageList}
          watingMessageArray={watingMessageArray}
        />
      </div>
    </>
  );
};
export default withRouter(DisputesChatMessage);
