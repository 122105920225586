import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import EmptyCard from "../../../common/EmptyCard";
import ErrorMessage from "../../../common/ErrorMessage";
import BubblesLoader from "../../../common/BubblesLoader";
import ViewApplicationListItem from "../ViewApplicationListItem";

const JobApplicationsListModel = ({
  handleClose,
  loading,
  error,
  show,
  jobId,
  jobApproved,
}) => {
  const clientJobApplicationList = useSelector(
    (state) => state.jobs.clientJobApplicationList
  );

  return (
    <Modal
      className="view-applicants-modal modal-dialog-scrollable"
      show={show}
      onHide={handleClose}
      centered
      size="lg"
    >
      <Modal.Header>
        <div className="d-flex align-items-center justify-content-between w-100">
          <Modal.Title className="f-w-600 text-dark-black md-font">
            Job Applications
          </Modal.Title>
          <span
            className="btn-close-modal d-inline-block cursor-pointer"
            onClick={handleClose}
          ></span>
        </div>
      </Modal.Header>
      <div className="w-100">
        <span className="border-top-1-E8E8E8"></span>
      </div>

      <Modal.Body className="p-0">
        <div className="px-3  overflow-auto view-applicants-modal-scroll-bar">
          {error !== "" ? <ErrorMessage message={error} /> : ""}
          {loading ? (
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <BubblesLoader />
              <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
                Please wait...
              </p>
            </div>
          ) : clientJobApplicationList &&
            clientJobApplicationList.length > 0 ? (
            clientJobApplicationList.map((item, index) => {
              return (
                <ViewApplicationListItem
                  item={item}
                  key={index}
                  jobId={jobId}
                  handleClose={handleClose}
                  jobApproved={jobApproved}
                />
              );
            })
          ) : (
            <EmptyCard message={"You’ve not any Application for this Job!"} />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default JobApplicationsListModel;
