import CommonFormForSomeJobs from "../CommonFormForSomeJobs";

const AddWildLifeFootage = ({ setReloadElements }) => {
  return (
    <div className="d-flex flex-sm-row flex-column">
      <CommonFormForSomeJobs
        type="land_animal"
        message={"Add Land Animal"}
        setReloadElements={setReloadElements}
      />
      <CommonFormForSomeJobs
        type="marine_animal"
        message={"Add Marine Animal/Fish"}
        setReloadElements={setReloadElements}
      />
      <CommonFormForSomeJobs
        type="bird"
        message={"Add Bird"}
        setReloadElements={setReloadElements}
      />
    </div>
  );
};

export default AddWildLifeFootage;
