import React from "react";
import DolorIcon from "../../../../assets/img/png/dolorIcon.png";

const EarningGraph = ({ dashBoardLoading, creatorDashboard }) => {
  return (
    <div className="col-xxl-3 col-md-6 ">
      <div className="d-flex p-3 h-100 justify-content-between align-items-center box-shadow br-10 bg-white ">
        <div>
          <p className="common-text fs-xs-14">Earnings</p>
          <h1 className="common-heading-Medium ">
            $
            {dashBoardLoading ? (
              <span className="common-text fs-xs-14 fw-bold text-black mb-0 ps-2">
                Loading
              </span>
            ) : (
              creatorDashboard.total_earnings
            )}
          </h1>
        </div>
        <span
          className="rounded-50  dashboard-icon d-inline-block "
          style={{
            backgroundColor: "#ecf9f4",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${DolorIcon})`,
          }}
        ></span>
      </div>
    </div>
  );
};

export default EarningGraph;
