import React from "react";
import { withRouter } from "react-router-dom";

const AdminDashBoardMsgListItem = ({ item, history }) => {
  return (
    <div
      className="d-flex justify-content-between inside-box-messeges cursor-pointer p-2 mt-2"
      onClick={() => history.push(`/admin/chat/${item.id}`)}
    >
      <div className="d-flex px-3">
        <img
          className="admin-user-image"
          src={item && item.user && item.user.proile_image_path}
          alt="profile"
        />
        <span className="messenger-name px-3 d-flex align-items-center ">
          {item && item.user && item.user.first_name
            ? item &&
              item.user &&
              item.user.first_name + " " + item.user.last_name
            : item && item.user && item.user.username}
        </span>
      </div>
      {item && item.total_unseen === 0 ? (
        ""
      ) : (
        <div className=" d-flex align-items-center px-2">
          <span className="bg-number-dipsute no-list-dipsute mb-0 ">
            {item.total_unseen}
          </span>
        </div>
      )}
    </div>
  );
};

export default withRouter(AdminDashBoardMsgListItem);
