import React from "react";
import CommonFormForSomeJobs from "../CommonFormForSomeJobs";
import AddVehicleForm from "../AddVehicleForm";
import AddTreeAndBuildingForm from "../AddTreeAndBuildingForm";

const ObjectFootage = ({ setReloadElements }) => {
  return (
    <div className="d-flex flex-sm-row flex-column">
      <CommonFormForSomeJobs
        type="property"
        message={"Add Property"}
        setReloadElements={setReloadElements}
      />
      <AddVehicleForm setReloadElements={setReloadElements} />
      <AddTreeAndBuildingForm
        type="tree"
        message={"Add Tree/Plant"}
        label={"Tree/Plant"}
        setReloadElements={setReloadElements}
      />
    </div>
  );
};

export default ObjectFootage;
