import { Modal } from "react-bootstrap";
import SignUpAddGear from "./SignUpAddGear";

const GearEditPopup = ({
  handleClose,
  show,
  openEditFormData,
  gearWorkArray,
  index,
}) => {
  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p>Edit Form</p>
        <SignUpAddGear
          handleClose={handleClose}
          openEditFormData={openEditFormData}
          gearWorkArray={gearWorkArray}
          index={index}
        />
      </Modal.Body>
    </Modal>
  );
};
export default GearEditPopup;
