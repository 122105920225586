import { Accordion } from "react-bootstrap";
import GreenCGIandPractical from "./CommonFields/GreenCGIandPractical";

function PracticalEffects({ jobList }) {
  return (
    <Accordion className="footage-accordian">
      {jobList &&
        jobList.effects &&
        jobList.effects.length > 0 &&
        jobList.effects.map((value, index) => {
          return (
            <GreenCGIandPractical
              FootageType={"Practical Effects"}
              value={value}
              index={index}
            />
          );
        })}
    </Accordion>
  );
}

export default PracticalEffects;
