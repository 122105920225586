import React from "react";
import ATechLogo from "../components/common/ATechLogo";
import ChangePasswordForm from "../components/auth/ChangePasswordForm";
import { withRouter } from "react-router-dom";
import SignupFormWrapper from "../components/hoc/SignupFormWrapper";

function ChangePassword({ history }) {
  return (
    <SignupFormWrapper>
      <div className=" col-lg-6 col-sm-10 col-12">
        <div className="bg-white br-10 box-shadow p-sm-5 p-3">
          <ATechLogo />
          <p className="common-heading mb-1">Change Password</p>
          <p className="common-text  pb-4">Create a new password</p>
          {/**Change password form */}
          <ChangePasswordForm />
          <p
            className=" cursor-pointer common-text pt-4 text-center"
            onClick={() => history.push("/")}
          >
            Cancel
          </p>
        </div>
      </div>
    </SignupFormWrapper>
  );
}

export default withRouter(ChangePassword);
