import { useState } from "react";
import { useDispatch } from "react-redux";
import { ReleaseFundsForUser } from "../../../redux/action/client/JobForm";
import { BackIcon, StartDisputeIcon } from "../../common/icons/Icon";
import { getDate } from "../../common/utils/Functions";
import { withRouter } from "react-router";
import Loader from "../../common/Loader";

const MyJobDetailsView = ({
  myJobDetails,
  history,
  setShow,
  setRequestShow,
  match,
}) => {
  const { jobId, jobType } = match.params;
  const dispatch = useDispatch();
  const [isReleaseFundLoding, setIsReleaseFundLoding] = useState(false);
  /**
   * Release funds for user when job is completed
   * by client and client has to pay for the job
   */
  const ReleaseFundsHandler = () => {
    const data = {
      job: Number(jobId),
      application: myJobDetails.application_id,
    };
    dispatch(ReleaseFundsForUser(data, setIsReleaseFundLoding));
  };

  return (
    <>
      <div className="d-sm-flex justify-content-between align-items-center mt-3 mb-2 ">
        <div>
          <button
            className="common-text border-e8e8e8 py-2 px-md-3 px-2 my-2 Start-Dispute-hover w-xs-100"
            onClick={() => history.push(`/creator/job-feed/${jobType}`)}
          >
            <span className="me-2">
              <BackIcon />
            </span>
            Back
          </button>
        </div>
        <div>
          {myJobDetails && myJobDetails.is_completed_by_creator === false && (
            <button
              onClick={() => setShow(true)}
              className="me-sm-2 w-xs-100 border-e8e8e8 py-2 px-md-3 px-2  para  br-3 my-2 Start-Dispute-hover common-text"
            >
              <StartDisputeIcon />
              Start Dispute
            </button>
          )}
          <button
            className="border-e8e8e8 py-2 px-md-3 px-2 my-2 view-job-btn w-xs-100"
            onClick={() => setRequestShow(true)}
          >
            View Change Requests
          </button>
          {myJobDetails && myJobDetails.is_completed_by_creator === true && (
            <button
              onClick={() => ReleaseFundsHandler()}
              className="me-sm-2 w-xs-100 border-e8e8e8 py-2 margin-left-10 px-md-3 px-2  para  br-3 my-2 Start-Dispute-hover common-text"
            >
              {isReleaseFundLoding ? <Loader /> : "Release Funds"}
            </button>
          )}
        </div>
      </div>
      <div className="hr"></div>
      <p className="common-heading-Medium mt-3 pt-3">{myJobDetails.title}</p>
      <p className="common-text">
        Start Date: {new Date(myJobDetails.updated_at).toLocaleDateString()}
        <span className="ms-2">
          {" "}
          Deadline: {getDate(myJobDetails.updated_at, myJobDetails.duration)}
        </span>
      </p>
      <p className="common-text d-flex">
        Job Price:
        <p className="common-heading-Medium text-blue margin-5">
          {" "}
          ${myJobDetails.price}
        </p>
      </p>
      <p className="common-text d-flex">
        Application Price:
        <p className="common-heading-Medium text-blue margin-5">
          {" "}
          $
          {myJobDetails &&
          myJobDetails.application_user &&
          myJobDetails.application_user.amount
            ? myJobDetails.application_user.amount
            : myJobDetails.price}
        </p>
      </p>
      <p className="common-text mb-4">{myJobDetails.description}</p>
      <div className="hr my-4"></div>
    </>
  );
};

export default withRouter(MyJobDetailsView);
