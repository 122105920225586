import { withRouter } from "react-router-dom";

function ClientJobFeedTypes({ history, type }) {
  const changeHandler = (type) => {
    history.push(`/client/job-feed/${type}`);
  };

  return (
    <div className="border-right job-feed-type-section ">
      <div className="px-3  mt-3">
        <div>
          <p className="common-text f-w-600 xs-font">Job Feed</p>
        </div>
        <div className="d-flex flex-sm-row flex-column flex-xl-column justify-content-between">
          <div className="form-check py-2 radio-button">
            <input
              className="form-check-input  cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault5"
              onChange={() => changeHandler("approved")}
              checked={type === "approved" ? true : false}
            />
            <label
              htmlFor="flexRadioDefault5"
              className="form-check-label common-text"
            >
              Approved
            </label>
          </div>
          <div className="form-check py-2">
            <input
              className="form-check-input  cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault4"
              onChange={() => changeHandler("all-jobs")}
              checked={type === "all-jobs" ? true : false}
            />
            <label
              className="form-check-label common-text"
              htmlFor="flexRadioDefault4"
            >
              All Jobs
            </label>
          </div>
          <div className="form-check py-2">
            <input
              className="form-check-input  cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault3"
              onChange={() => changeHandler("on-going-jobs")}
              checked={type === "on-going-jobs" ? true : false}
            />
            <label
              className="form-check-label common-text"
              htmlFor="flexRadioDefault3"
            >
              On Going Jobs
            </label>
          </div>
          <div className="form-check py-2">
            <input
              className="form-check-input  cursor-pointer"
              type="radio"
              name="flexRadioDefault1"
              id="flexRadioDefault1"
              onChange={() => changeHandler("completed-job")}
              checked={type === "completed-job" ? true : false}
            />
            <label
              className="form-check-label common-text"
              htmlFor="flexRadioDefault1"
            >
              Completed Jobs
            </label>
          </div>
          <div className="form-check py-2">
            <input
              className="form-check-input cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              onChange={() => changeHandler("pending-jobs")}
              checked={type === "pending-jobs" ? true : false}
            />
            <label
              className="form-check-label common-text"
              htmlFor="flexRadioDefault2"
            >
              Pending Jobs
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ClientJobFeedTypes);
