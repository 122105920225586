import { LOGIN_SUCCESSFULLY } from "../../action/auth/AuthAction";
import { SIGN_UP_DETAILS_SUCCESSFULLY } from "../../action/auth/UserDetailsAction";
import {
  DELETE_GEAR_DETAILS_SUCCESS,
  GET_USER_GEAR_LIST,
  SUBMIT_GEAR_DETAILS_SUCCESS,
  UPDATES_GEAR_DETAILS_SUCCESS,
} from "../../action/creator/CreatorGrarsWorkAction";
import {
  DELETE_WORK_DETAILS_SUCCESS,
  GET_USER_WORK_LIST,
  SUBMIT_WORK_DETAILS_SUCCESS,
  UPDATES_PAST_WORK_DETAILS_SUCCESS,
} from "../../action/creator/CreatorWorkAction";
import {
  REMOVE_FILE_URL_LINK,
  UPLOAD_IAMGE,
} from "../../action/UploadImageAction";

const initialState = {
  auth: false,
  userDetails: {},
  fileUrl: "",
  userDetailsRole: {},
  userPastWorkList: [],
  userGearList: [],
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    // LOGIN REDUCER TO SAVE USER DATA
    case LOGIN_SUCCESSFULLY: {
      return {
        ...state,
        userDetails: action.data.user_details,
        userDetailsRole: action.data.user_roles,
        auth: action.data.success,
      };
    }

    case SIGN_UP_DETAILS_SUCCESSFULLY: {
      return {
        ...state,
        userDetails: action.data.user_details,
        userDetailsRole: action.data.user_roles,
        auth: action.data.success,
      };
    }

    //get user past work list
    case GET_USER_WORK_LIST: {
      return {
        ...state,
        userPastWorkList: action.data,
      };
    }

    /**
     * add new work details to user work
     * list and update user work list in store
     */
    case SUBMIT_WORK_DETAILS_SUCCESS: {
      return {
        ...state,
        userPastWorkList: state.userPastWorkList.concat(action.data),
      };
    }

    //Delete past work details reducer to delete past work details
    case DELETE_WORK_DETAILS_SUCCESS: {
      const userPastWorkListArray = [...state.userPastWorkList];
      const userPastWorkArray = userPastWorkListArray.filter(
        (item) => item.id !== action.data
      );
      return {
        ...state,
        userPastWorkList: userPastWorkArray,
      };
    }

    // update past work details reducer to update
    // past work details in store and update
    // in database and update in store
    case UPDATES_PAST_WORK_DETAILS_SUCCESS: {
      const userPastWorkListArray = [...state.userPastWorkList];
      userPastWorkListArray.splice(action.data.index, 1, action.data.data);
      return {
        ...state,
        userPastWorkList: userPastWorkListArray,
      };
    }

    //============================================= GET USER GEAR LIST =======================//

    //get list of gear details
    case GET_USER_GEAR_LIST: {
      return {
        ...state,
        userGearList: action.data,
      };
    }

    //delete gear details reducer to delete gear details
    case DELETE_GEAR_DETAILS_SUCCESS: {
      const userGearListArray = [...state.userGearList];
      const userGearListDeleteArray = userGearListArray.filter(
        (item) => item.id !== action.data
      );
      return {
        ...state,
        userGearList: userGearListDeleteArray,
      };
    }

    /**
     * add new work details to user work
     * list and update user work list in store
     */
    case SUBMIT_GEAR_DETAILS_SUCCESS: {
      return {
        ...state,
        userGearList: state.userGearList.concat(action.data),
      };
    }

    // update gears details reducer to update
    // gears details in store and update
    // in database and update in store
    case UPDATES_GEAR_DETAILS_SUCCESS: {
      const userGearListArray = [...state.userGearList];
      userGearListArray.splice(action.data.index, 1, action.data.data);
      return {
        ...state,
        userGearList: userGearListArray,
      };
    }

    //Add File Url Link
    case UPLOAD_IAMGE: {
      return {
        ...state,
        fileUrl: action.file,
      };
    }

    //Reomve File Url Link
    case REMOVE_FILE_URL_LINK: {
      return {
        ...state,
        fileUrl: "",
      };
    }

    default:
      return state;
  }
}
