import React, { useState } from "react";
import PayOutDetailsModal from "./PayOutDetailsModal";

const PayOutListDetails = ({ item }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <tr>
        <td className=" vertical-align word-break-text wrap max-width-30">
          {item.creator_details.username}
        </td>
        <td className=" vertical-align text-center word-break-text  wrap max-width-30">
          {item.buyer_details.username}
        </td>
        <td className=" vertical-align  text-center word-break-text  wrap max-width-30">
          {item.job_details.title}
        </td>
        <td className=" vertical-align  text-center word-break-text  wrap max-width-30">
          {item.status}
        </td>
        <td className=" vertical-align text-end">
          <button className="px-sm-3 px-2 py-2  atech-fade-button">
            <span
              className=" d-inline-block fs-Montserrat "
              onClick={() => setShow(true)}
            >
              View
            </span>
          </button>
        </td>
      </tr>
      <PayOutDetailsModal show={show} setShow={setShow} item={item} />
    </>
  );
};

export default PayOutListDetails;
