import noNotification from "../../assets/img/svg/nonotification.svg";

const EmptyCard = ({ message }) => {
  return (
    <div className="d-flex justify-content-center flex-column align-items-center h-100 w-100">
      <img src={noNotification} alt="NoNotification" />

      <p className="text-center common-heading-Medium pt-3">{message}</p>
    </div>
  );
};
export default EmptyCard;
