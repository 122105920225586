import Swal from "sweetalert2";
import {
  deleteWorkDetailsApi,
  GetUserWorkListApi,
  submitWorkDetailsApi,
  UpdateWorkDetailsApi,
} from "../../api/creator/CreatorWorkApi";

export const GET_USER_WORK_LIST = "GET_USER_WORK_LIST";
export const SUBMIT_WORK_DETAILS_SUCCESS = "SUBMIT_WORK_DETAILS_SUCCESS";
export const DELETE_WORK_DETAILS_SUCCESS = "DELETE_WORK_DETAILS_SUCCESS";
export const UPDATES_PAST_WORK_DETAILS_SUCCESS =
  "UPDATES_PAST_WORK_DETAILS_SUCCESS";

/**
 * Get user work list action
 * @param {Object} data
 * @returns
 */
const GetUserWorkListAction = (data) => ({
  type: GET_USER_WORK_LIST,
  data,
});

export const GetUserWorkList = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await GetUserWorkListApi();
    if (response.success) {
      dispatch(GetUserWorkListAction(response.data));
      setLoading(false);
    } else {
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
  }
};

/**
 * submit user work details action
 * @param {Object} data
 * @returns
 */
const submitWorkDetailsAction = (data) => ({
  type: SUBMIT_WORK_DETAILS_SUCCESS,
  data,
});

export const submitWorkDetails =
  (data, setLoading, history, type, setShowForm) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await submitWorkDetailsApi(data);
      if (response.success) {
        dispatch(submitWorkDetailsAction(response.data));
        setLoading(false);
        if (type !== "login") {
          history.push("/sign-up/add-gear");
        } else {
          setShowForm(false);
        }
      } else {
        setLoading(false);
        Swal.fire("Opps!", "Failed To create past Work", "error");
        setTimeout(Swal.close, 2000);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Opps!", "Failed To create past Work", "error");
      setTimeout(Swal.close, 2000);
    }
  };

/**
 * updates user work details action
 * @param {Object} data
 * @returns
 */
const UpdateWorkDetailsAction = (data) => ({
  type: UPDATES_PAST_WORK_DETAILS_SUCCESS,
  data,
});

export const UpdateWorkDetails =
  (data, setLoading, handleClose, id, index) => async (dispatch) => {
    setLoading(true);
    try {
      const response = await UpdateWorkDetailsApi(data, id);
      if (response.success) {
        dispatch(UpdateWorkDetailsAction({ data: response.data, index }));
        setLoading(false);
        handleClose();
      } else {
        setLoading(false);
        Swal.fire("Opps!", "Failed To Updates past Work", "error");
        setTimeout(Swal.close, 2000);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire("Opps!", "Failed To Updates past Work", "error");
      setTimeout(Swal.close, 2000);
    }
  };

/**
 * Delete user work details action
 * @param {Object} data
 * @returns
 */
const deleteWorkDetailsAction = (data) => ({
  type: DELETE_WORK_DETAILS_SUCCESS,
  data,
});

export const deleteWorkDetails = (id, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await deleteWorkDetailsApi(id);
    if (response.success) {
      dispatch(deleteWorkDetailsAction(id));
      setLoading(false);
    } else {
      setLoading(false);
      Swal.fire("Opps!", "Failed To delete Work", "error");
      setTimeout(Swal.close, 2000);
    }
  } catch (error) {
    setLoading(false);
    Swal.fire("Opps!", "Failed To delete Work", "error");
    setTimeout(Swal.close, 2000);
  }
};
