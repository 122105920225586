import React, { useEffect, useState } from "react";

const ChatFriendList = ({
  history,
  setShowChat,
  item,
  index,
  setPageNo,
  userId,
}) => {
  const adminChatHandler = (item) => {
    setPageNo(0);
    history.push(`/${pathToRedirect}/chat/${item}`);
    setShowChat(true);
  };

  const [pathToRedirect, setPathToRedirect] = useState("");
  const pathname = window.location.pathname;

  useEffect(() => {
    if (pathname.includes("/admin")) {
      setPathToRedirect("admin");
    } else if (pathname.includes("/creator")) {
      setPathToRedirect("creator");
    } else {
      setPathToRedirect("client");
    }
  }, [pathname]);

  return (
    <section
      key={index}
      onClick={() => adminChatHandler(item.id)}
      className={`${
        Number(userId) === item.id ? "chatactive" : ""
      } job-list p-2 mb-4 d-flex justify-content-between align-items-center cursor-pointer`}
    >
      <div className="d-flex align-items-center mx-2">
        <img
          className="massage-list-img"
          src={item && item.user && item.user.proile_image_path}
          alt="profile"
        />
        <p className="common-heading smll-font  mb-0 mx-xxl-3 mx-2">
          {item && item.user && item.user.first_name
            ? item &&
              item.user &&
              item.user.first_name + " " + item.user.last_name
            : item && item.user && item.user.username}
        </p>
      </div>
      {item && item.total_unseen === 0 ? (
        ""
      ) : (
        <span>
          <span className="bg-number no-list mb-0 ">{item.total_unseen}</span>
        </span>
      )}
    </section>
  );
};

export default ChatFriendList;
