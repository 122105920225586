import {
  GET_ADMIN_ALL_USER_LIST,
  GET_DASHBOARD_CHAT_DETAILS,
  GET_JOB_REPORT_DETAILS,
  GET_JOB_REPORT_LIST,
  GET_NOTIFICATION_LIST_DELETE,
  GET_UNSEEN_NOTIFICATION,
  GET_UPDATE_UNSEEN_NOTIFICATION,
  GET_USERS_DETAILS_FOR_ADMIN,
  GET_USERS_GEARS_DETAILS_ADMIN,
  GET_USERS_WORK_DETAILS_ADMIN,
  GET_USER_REPORT_DETAILS,
  GET_USER_REPORT_LIST,
  REMOVE_UNSEEN_NOTIFICATION,
} from "../../action/admin/AdminAcountsAction";
import { GET_NOTIFICATION_LIST } from "../../action/admin/AdminAcountsAction";
import { GET_RELEASE_FUNDS_LIST } from "../../action/client/JobForm";

const initialState = {
  adminAllUserList: [],
  notificationList: [],
  releaseFundsList: [],
  adminUserDetails: {},
  adminUserWorkDetails: [],
  adminUserGearsDetails: [],
  jobReportList: [],
  userReportList: [],
  adminDashboard: {},
  jobReportDetails: {},
  userReportDetails: {},
  unseenNotification: 0,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    // Get Admin all user list
    case GET_ADMIN_ALL_USER_LIST: {
      return {
        ...state,
        adminAllUserList: action.data,
      };
    }

    // Get Notification List
    case GET_NOTIFICATION_LIST: {
      return {
        ...state,
        notificationList:
          action.data.page === 0
            ? action.data.data
            : state.notificationList.concat(action.data.data),
      };
    }

    // Get job report  List
    case GET_JOB_REPORT_LIST: {
      return {
        ...state,
        jobReportList:
          action.data.page === 0
            ? action.data.data
            : state.jobReportList.concat(action.data.data),
      };
    }

    // Get unseen notification  List
    case GET_UNSEEN_NOTIFICATION: {
      return {
        ...state,
        unseenNotification: action.data,
      };
    }

    case GET_UPDATE_UNSEEN_NOTIFICATION: {
      let unseenNotificationList = state.unseenNotification;
      if (
        unseenNotificationList &&
        unseenNotificationList.unseen_notification >= 0
      ) {
        unseenNotificationList.unseen_notification += 1;
      }
      return {
        ...state,
        unseenNotification: unseenNotificationList,
      };
    }
    case REMOVE_UNSEEN_NOTIFICATION: {
      const unseenNotificationList = state.unseenNotification;
      unseenNotificationList.unseen_notification = 0;
      return {
        ...state,
        unseenNotification: unseenNotificationList,
      };
    }

    // Get job report  details
    case GET_JOB_REPORT_DETAILS: {
      return {
        ...state,
        jobReportDetails: action.data,
      };
    }

    // Get user report List
    case GET_USER_REPORT_LIST: {
      return {
        ...state,
        userReportList:
          action.data.page === 0
            ? action.data.data
            : state.userReportList.concat(action.data.data),
      };
    }

    // Get release funds List
    case GET_RELEASE_FUNDS_LIST: {
      return {
        ...state,
        releaseFundsList:
          action.data.page === 0
            ? action.data.data
            : state.releaseFundsList.concat(action.data.data),
      };
    }

    // Get user report details
    case GET_USER_REPORT_DETAILS: {
      return {
        ...state,
        userReportDetails: action.data,
      };
    }

    // Get delete Notification List
    case GET_NOTIFICATION_LIST_DELETE: {
      let deleteNotification = [...state.notificationList];
      const deleteNotificationList =
        action.data === "all"
          ? []
          : deleteNotification.filter((item) => item.id !== action.data);
      return {
        ...state,
        notificationList: deleteNotificationList,
      };
    }

    // Get Admin Notification List
    case GET_USERS_DETAILS_FOR_ADMIN: {
      return {
        ...state,
        adminUserDetails: action.data,
      };
    }

    // Get Admin user gears details List
    case GET_USERS_GEARS_DETAILS_ADMIN: {
      return {
        ...state,
        adminUserGearsDetails: action.data,
      };
    }
    // Get Admin user work details List
    case GET_USERS_WORK_DETAILS_ADMIN: {
      return {
        ...state,
        adminUserWorkDetails: action.data,
      };
    }

    // Get Admin user work details List
    case GET_DASHBOARD_CHAT_DETAILS: {
      return {
        ...state,
        adminDashboard: action.data,
      };
    }

    default:
      return state;
  }
}
