import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminAllUserList } from "../../../redux/action/admin/AdminAcountsAction";
import AccountsListItem from "./AccountsListItem";
import BubblesLoader from "../../common/BubblesLoader";
import EmptyCard from "../../common/EmptyCard";
import InfiniteScroll from "react-infinite-scroll-component";

function ClientAccount({ history, type }) {
  const dispatch = useDispatch();
  const adminAllUserList = useSelector(
    (state) => state.AdminAcountsReducer.adminAllUserList
  );
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const pageChangeHandler = () => {
    setPage(page + 1);
  };
  useEffect(() => {
    const type = "client";
    dispatch(getAdminAllUserList(setLoading, type, page, setHasMore));
  }, [dispatch, page]);

  const adminAllUserListArray =
    page === 0 && loading ? (
      <div className="h-100 d-flex justify-content-center align-items-center flex-column">
        <BubblesLoader />
        <p className="pt-3 common-heading-Medium  f-w-600 text-black md-font">
          Please wait...
        </p>
      </div>
    ) : adminAllUserList && adminAllUserList.length ? (
      adminAllUserList.map((obj, index) => (
        <AccountsListItem
          accountData={obj}
          key={index}
          history={history}
          type={type}
        />
      ))
    ) : (
      <EmptyCard message={"You’ve not any Client List Yet!"} />
    );

  return (
    <div className="col-12">
      <div className="row mt-4">
        <div className="col">
          <div className="notification-scroll" id="client-list-account">
            <InfiniteScroll
              className={`${
                page === 0 && !loading ? "" : "h-100 scrollbar_desgin"
              }`}
              dataLength={adminAllUserList.length}
              next={pageChangeHandler}
              hasMore={hasMore}
              scrollableTarget="client-list-account"
              loader={
                page > 0 && loading ? (
                  <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                    <BubblesLoader />
                  </div>
                ) : (
                  ""
                )
              }
            >
              {adminAllUserListArray}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ClientAccount;
