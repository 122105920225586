import ExactLocation from "../SignificantJobType/ExactLocation";

const LocationExact = ({ significantFormdTypes, setSignificantFormTypes }) => {
  return (
    <div className=" pb-2">
      <label for="inputs" className="form-label common-text mb-3">
        Does your footage have an exact location?
      </label>
      <select
        id="isCurrentLocation "
        onChange={(e) =>
          setSignificantFormTypes({
            ...significantFormdTypes,
            is_exact_location: e.target.value,
          })
        }
        className="form-select common-text text-8B8C8D form-control border-b9babb br-3 mb-2   py-md-3"
      >
        <option className="common-text text-8B8C8D" selected value={false}>
          No
        </option>
        <option className="common-text text-8B8C8D" value={true}>
          Yes
        </option>
      </select>

      {significantFormdTypes.is_exact_location === "true" ? (
        <ExactLocation
          setSignificantFormTypes={setSignificantFormTypes}
          significantFormdTypes={significantFormdTypes}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default LocationExact;
