import React, { useContext, useState, createContext } from "react";

const LayoutProviderContext = createContext();

export function useLayoutProviderContext() {
  return useContext(LayoutProviderContext);
}

export default function LayoutProvider({ children }) {
  const [sidebarActive, setSidebarActive] = useState(false);

  const value = {
    sidebarActive,
    setSidebarActive,
  };

  return (
    <LayoutProviderContext.Provider value={value}>
      {children}
    </LayoutProviderContext.Provider>
  );
}
