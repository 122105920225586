import RecentJobDetails from "../../../components/creator/jobFeed/RecentJobDetails";
import ReportJobModal from "../../../components/creator/jobFeed/ReportJobModal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { creatorJobDetails } from "../../../redux/action/creator/CreatorJob";
import { withRouter } from "react-router-dom";

function CreaterJobDetails({ match }) {
  const dispatch = useDispatch();
  const { jobId } = match.params;
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (jobId) {
      dispatch(creatorJobDetails(setLoading, jobId, setError));
    }
  }, [dispatch, jobId]);

  return (
    <>
      <RecentJobDetails
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
        loading={loading}
        jobId={jobId}
        error={error}
      />
      <ReportJobModal setShow={setShow} show={show} jobId={jobId} />
    </>
  );
}

export default withRouter(CreaterJobDetails);
